import React, { useContext, useMemo, useState } from "react";
import styled from "styled-components";
import "moment-duration-format";
import { Bar } from "react-chartjs-2";
import moment from "moment";
import { PRTree } from "@evidenceb/gameplay-interfaces";
import { ModuleDashboard } from "../../../../interfaces/Dashboard";
import { configStore } from "../../../../contexts/ConfigContext";
import { dataStore } from "../../../../contexts/DataContext";
import { getModuleById } from "../../../../utils/dataRetrieval";
import { colors } from "../utils";

const barChartOptions = {
    scales: {
        x: {
            stacked: true,
            grid: { display: false },
            ticks: {
                // Only show the first letter of the day in the legend
                callback: function (value: any): string {
                    /* @ts-ignore: otherwise TS doesn't recognize "this" as valid */
                    return this.getLabelForValue(value).slice(0, 1);
                },
            },
        },
        y: {
            display: false,
            stacked: true,
            grid: { display: false },
        },
    },
    plugins: {
        legend: {
            position: "bottom",
        },
        tooltip: {
            callbacks: {
                label: function (context: any) {
                    let label = context.dataset.label || "";

                    if (label) {
                        label += ": ";
                    }
                    if (context.parsed.y !== 0) {
                        label += moment
                            .duration(context.parsed.y, "seconds")
                            .format("mm [min] ss [sec]");
                    } else {
                        label += "00 min 00 sec";
                    }
                    return label;
                },
            },
        },
    },
};

interface TimeSpentProps {
    studentModules: ModuleDashboard[] | undefined;
    studentId: string;
    prTree: PRTree | undefined;
}

interface ModuleDataObj {
    label: string;
    data: Number[];
    backgroundColor?: string;
}

interface GraphDataTypes {
    labels: string[];
    datasets: ModuleDataObj[];
}

export default function TimeSpentChart({
    studentModules,
    studentId,
    prTree,
}: TimeSpentProps) {
    const { config } = useContext(configStore);
    const { data } = useContext(dataStore);

    const [currentStartingDayOfWeek, setCurrentStartingDayOfWeek] =
        useState<string>(moment().startOf("isoWeek").format("YYYY-M-D"));

    const graphData = useMemo<GraphDataTypes>(() => {
        const datasets: ModuleDataObj[] = [];
        studentModules?.forEach((module, i) => {
            const moduleData = {
                label: module.id,
                data: [] as number[],
                backgroundColor: `${colors.modules[i] || "#fff"}`,
            };
            moduleData.label = `${getModuleById(module.id, data).title
                .short!} ${
                prTree?.modules[module.id].status !== "unlocked"
                    ? `(${config.i18n.prm.manager.listItem.deactivated.toLowerCase()})`
                    : ""
            }`;
            for (let i = 0; i <= 6; i++) {
                let timeSpentKey = moment(currentStartingDayOfWeek)
                    .add(i, "days")
                    .format("YYYY-M-D");
                const timeSpentValue =
                    module.students[studentId].timeSpent[timeSpentKey] || 0;
                moduleData.data.push(timeSpentValue);
            }
            datasets.push(moduleData);
        });

        return {
            labels: config.i18n.dashboard!.studentDetails.weekdays,
            datasets,
        };
    }, [
        studentModules,
        prTree,
        config.i18n.dashboard,
        config.i18n.prm.manager.listItem.deactivated,
        currentStartingDayOfWeek,
        data,
        studentId,
    ]);

    const timePassedOnModules = useMemo(() => {
        let minutesPassedOnModules = 0;
        studentModules?.forEach((module, i) => {
            for (let i = 0; i <= 6; i++) {
                let timeSpentKey = moment(currentStartingDayOfWeek)
                    .add(i, "days")
                    .format("YYYY-M-D");
                const timeSpentValue =
                    module.students[studentId].timeSpent[timeSpentKey] || 0;
                minutesPassedOnModules =
                    minutesPassedOnModules + timeSpentValue;
            }
        });
        return minutesPassedOnModules;
    }, [studentModules, currentStartingDayOfWeek, studentId]);

    const handleChangeCurrentWeek = (arg: string) => {
        if (arg === "previous") {
            const previousMonday = moment(currentStartingDayOfWeek)
                .subtract(7, "days")
                .isoWeekday("Monday")
                .format("YYYY-M-DD");
            setCurrentStartingDayOfWeek(previousMonday);
        } else if (arg === "next") {
            const currentMonday = moment()
                .isoWeekday("Monday")
                .format("YYYY-M-DD");

            if (currentMonday === currentStartingDayOfWeek) {
                return;
            } else {
                const nextMonday = moment(currentStartingDayOfWeek)
                    .add(7, "days")
                    .isoWeekday("Monday")
                    .format("YYYY-M-DD");
                setCurrentStartingDayOfWeek(nextMonday);
            }
        }
    };

    return (
        <div
            className={`card medium ${
                !config.features.clustering ? "large" : ""
            }`}
        >
            <h2 className="card-title">
                {config.i18n.dashboard?.studentDetails.timePassedOnRessources}
            </h2>
            <ChartCard>
                <ChartDateContainer>
                    <ChartDateButton
                        onClick={() => handleChangeCurrentWeek("previous")}
                    >
                        <span className="material-icons" translate="no">
                            arrow_back
                        </span>
                    </ChartDateButton>
                    <div>
                        {moment(currentStartingDayOfWeek)
                            .isoWeekday("Monday")
                            .format("DD/MM/YYYY")}{" "}
                        -{" "}
                        {moment(currentStartingDayOfWeek)
                            .isoWeekday("Sunday")
                            .format("DD/MM/YYYY")}
                    </div>
                    <ChartDateButton
                        onClick={() => handleChangeCurrentWeek("next")}
                    >
                        <span className="material-icons" translate="no">
                            arrow_forward
                        </span>
                    </ChartDateButton>
                </ChartDateContainer>

                <WeekTotal>
                    <HoursSpent>
                        {moment("2021-01-01")
                            .startOf("day")
                            .seconds(timePassedOnModules)
                            .format("HH:mm")}
                    </HoursSpent>{" "}
                    {config.i18n.dashboard?.studentDetails.thisWeek}
                </WeekTotal>

                <ChartWrapper>
                    <Bar data={graphData} options={barChartOptions} />
                </ChartWrapper>
            </ChartCard>
        </div>
    );
}

const ChartCard = styled.div`
    padding: 8px;
    width: calc(100% - 16px);
    height: calc(100% - 52px);
    display: flex;
    flex-direction: column;
    border-radius: 2px;
    background: var(--white);
    box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
    border: 1px solid var(--medium-grey);
`;

const ChartDateContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const ChartDateButton = styled.div`
    background-color: #ccc;
    border-radius: 100%;
    width: 24px;
    height: 24px;
    padding: 8px;
    margin: 16px 8px;
    cursor: pointer;
    user-select: none;
`;

const WeekTotal = styled.h3`
    padding: 8px 16px;
    font-size: 1.2em;
`;

const HoursSpent = styled.span`
    font-size: 1.8em;
`;

const ChartWrapper = styled.div`
    width: 400px;
    height: calc(100% - 48px);
    padding: 24px;
    margin: 0 auto;
    display: flex;
    align-items: center;
`;
