export const BAR_COLOR_SCHEMES = {
    unlockedModule: {
        bg: "#f2f6fb",
        value: "var(--color-primary)",
        progress: "var(--color-primary-light)",
    },
    lockedModule: {
        bg: "#F5F7FD",
        value: "black",
        progress: "#EFEDFF",
    },
    default: {
        bg: "#f2f6fb",
        value: "var(--color-primary)",
        progress: "var(--color-primary-light)",
    },
};
