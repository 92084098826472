import React, { useContext, useEffect, useState } from "react";
import parse from "html-react-parser";
import {
    Playlist,
    PlaylistExecutionStage,
    Shell,
} from "@evidenceb/gameplay-interfaces";
import { UserType } from "../../../../interfaces/User";
import { dataStore } from "../../../../contexts/DataContext";
import { configStore } from "../../../../contexts/ConfigContext";
import { sessionStore } from "../../../../contexts/SessionContext";
import { getExerciseById } from "../../../../utils/dataRetrieval";
import StatementsError from "../../PlaylistPlayer/StatementsError/StatementsError";
import ExerciseResult, { GoToNext } from "./ExerciseResult/ExerciseResult";
import Welcome from "./Welcome";
import { CurrentExercise } from "./CurrentExercise";
import ChatBubble from "./ChatBubble/ChatBubble";
import useEmotionalReport from "../../PlaylistPlayer/EmotionalReport/useEmotionalReport";
import EmotionalReport from "../../PlaylistPlayer/EmotionalReport/EmotionalReport";

import "./ChatbotShell.scss";

interface Props<Data, Answer> extends Shell<Data, Answer> {
    playlist: Playlist;
    clearHistory?: () => void;
}

const ChatbotShell: React.FC<Props<any, any>> = ({
    playlist,
    onExerciseResult,
    onGoToNextExercise,
    clearHistory,
    opts,
}) => {
    const { data } = useContext(dataStore);
    const { config } = useContext(configStore);
    const {
        session: { userType, version },
    } = useContext(sessionStore);
    const emotionalReportController = useEmotionalReport();
    const [started, setStarted] = useState<boolean>(
        opts?.skipIntro ? true : false
    );
    const [playlistHasInitialTest, setPlaylistHasInitialTest] =
        useState<boolean>();
    const [showWelcome, setShowWelcome] = useState<boolean>(
        opts?.skipIntro ? false : true
    );

    useEffect(() => {
        if (playlist.isInitialTest) setPlaylistHasInitialTest(true);
    }, [playlist.isInitialTest]);

    return (
        <div className="chatbot-layout">
            <div className="notifications__container">
                <StatementsError />
            </div>

            <div className="chatbot-core__container">
                <div className="chatbot-core">
                    {showWelcome && (
                        <Welcome
                            onStart={() => setStarted(true)}
                            started={started}
                            welcomeMessages={
                                playlistHasInitialTest
                                    ? [
                                          ...config.i18n.chatbot
                                              .welcomeMessages,
                                          config.i18n.chatbot
                                              .initialTestMessages.start,
                                      ]
                                    : config.i18n.chatbot.welcomeMessages
                            }
                            startButtonText={config.i18n.chatbot.start}
                        />
                    )}
                    <>
                        {playlist!.exerciseResults.map(
                            (exerciseResult, index) => {
                                const isLastExerciseResult =
                                    index ===
                                    playlist!.exerciseResults.length - 1;
                                return (
                                    <ExerciseResult
                                        exercise={getExerciseById(
                                            exerciseResult.exerciseId,
                                            data,
                                            true,
                                            version,
                                            config.apiUrls.endpoints.assetsProxy
                                        )}
                                        exerciseResult={exerciseResult}
                                        key={index}
                                        onGoToNextExercise={
                                            isLastExerciseResult &&
                                            playlist?.currentExecutionStage ===
                                                PlaylistExecutionStage.ShowingCurrentExerciseResultFeedback
                                                ? () => {
                                                      if (
                                                          config.features
                                                              .emotionalReport &&
                                                          userType ===
                                                              UserType.Student &&
                                                          playlist.comingNext !==
                                                              "retry" &&
                                                          !playlist.isInitialTest
                                                      )
                                                          emotionalReportController.recordValidatedExercise();
                                                      onGoToNextExercise(
                                                          playlist.comingNext
                                                      );
                                                  }
                                                : undefined
                                        }
                                        comingNext={playlist?.comingNext}
                                    />
                                );
                            }
                        )}
                    </>
                    {started &&
                    playlist?.currentExercise &&
                    playlist?.currentExecutionStage ===
                        PlaylistExecutionStage.PlayingCurrentExercise &&
                    !emotionalReportController.showEmotionalReportPopup ? (
                        <>
                            <CurrentExercise
                                currentExercise={playlist.currentExercise}
                                key={`current-${playlist.currentExercise.id}-${playlist.currentTry}`}
                                onExerciseResult={onExerciseResult}
                            />
                            <div className="current-exercise__background-shader"></div>
                        </>
                    ) : null}
                    {started &&
                        playlist?.currentExecutionStage ===
                            PlaylistExecutionStage.ShowingEndOfInitialTestMessage && (
                            <>
                                <ChatBubble
                                    direction={"left"}
                                    botAvatar="visible"
                                    color="var(--chatbot-color-bot-bubble)"
                                >
                                    {parse(
                                        config.i18n.chatbot.initialTestMessages
                                            .end.$html
                                    )}
                                </ChatBubble>
                                <GoToNext
                                    onGoToNextExercise={() => {
                                        if (
                                            config.features.emotionalReport &&
                                            userType === "STUDENT" &&
                                            playlist.comingNext !== "retry" &&
                                            !playlist.isInitialTest
                                        )
                                            emotionalReportController.recordValidatedExercise();
                                        if (clearHistory) clearHistory();
                                        setShowWelcome(false);
                                        setPlaylistHasInitialTest(false);
                                        onGoToNextExercise(playlist.comingNext);
                                    }}
                                    comingNext={playlist.comingNext}
                                />
                            </>
                        )}
                </div>
            </div>

            {config.features.emotionalReport &&
                userType === UserType.Student &&
                !playlist.isInitialTest && (
                    <EmotionalReport {...emotionalReportController} />
                )}
        </div>
    );
};
export default ChatbotShell;
