import React, { useEffect, useState, useContext, ChangeEvent } from "react";

import { configStore,  } from "../../contexts/ConfigContext";
import { errorStore } from "../../contexts/ErrorContext";

import { ErrorPageI18n } from "../../interfaces/Error";

import errorImg from "./error.png";
import langIcon from "./lang-icon.png";
import "./Error.scss";

interface Lang {
    short: string;
    long: string;
}

const Error = () => {

    const { config } = useContext(configStore);
    const { errorInfo } = useContext(errorStore);
    
    const locals = [ {short: "en", long: "English"}, {short: "fr", long: "Français"}, {short: "it", long: "Italiano"}];

    const [lang, setLang] = useState<Lang>({short:"en", long:"English"});
    
    const [error, setError] = useState<ErrorPageI18n>()

    const onSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
        let value = e.currentTarget.value;
        setLang({short: value, long: locals.find(local => local.short === value)!.long })
    }

    useEffect(() => {
        let currentError = config.i18n.errors.pages.find(err => err.code === errorInfo.page?.code)
        if(config.lang !== "en"){
            setLang({short:config.lang, long: ""})
        }
        if(currentError){
            setError(currentError)
        }else{
            setError(config.i18n.errors.pages[config.i18n.errors.pages.length - 1])
        }
        console.log("currentError",currentError);
    }, [config, errorInfo])

    return (
        <div className="error-container">
            <div className="content">
                <h1 className="roboto">{error?.content.message.title[lang.short]}</h1>
                <p>{ error?.content.message.text[lang.short] }</p>

                {/* Temporary disabled */}
                {/* {
                    error?.content.btn &&
                        <a href="/" title={error?.content.btn.label[lang.short]}>{error?.content.btn.label[lang.short]}</a>
                } */}

                {
                    lang.long !== "" &&
                        <div className="select-container">
                            <img src={langIcon} alt="" aria-hidden="true" />
                            <select onChange={onSelectChange}>
                                {
                                    locals.map( 
                                        (local, i) => 
                                            <option 
                                                key={local +'-'+ i} 
                                                value={local.short}
                                                className={lang.short === local.short ? "current" : ""}
                                                >
                                                    {local.long}
                                            </option> )
                                }
                            </select>
                        </div>
                }
            </div>
            <div className="img">
                <img src={errorImg} alt="" />
            </div>
            {/* Keep for later for more details on error
                <code>
                    <pre>
                        {JSON.stringify((log as any).response, null, 4)}
                    </pre>
                </code> 
            */}
        </div>
    );
}

export default Error;
