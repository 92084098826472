import { ComingNext } from "@evidenceb/gameplay-interfaces";
import { Config } from "../interfaces/Config";

export const getFeedbackButtonText = (
    comingNext: ComingNext,
    config: Config
): string => {
    switch (comingNext) {
        case "endOfPlaylist":
            return config.i18n.exerciseShell.feedback.endPlaylist;
        case "nextExercise":
            return config.i18n.exerciseShell.feedback.nextExercise;
        case "retry":
            return config.i18n.exerciseShell.feedback.retry;
        case "nextActivity":
            return config.i18n.exerciseShell.feedback.nextActivity;
        default:
            return config.i18n.exerciseShell.close;
    }
};
