import React, { useContext } from "react";
import moment from "moment";
import { Student, Objective, Activity } from "../../../interfaces/Dashboard";
import { Config } from "../../../interfaces/Config";
import { dataStore } from "../../../contexts/DataContext";
import { configStore } from "../../../contexts/ConfigContext";
import {
    getObjectiveById,
    getResourceIndex,
} from "../../../utils/dataRetrieval";
import { parse } from "../../../utils/parse-text";
import DemoBanner from "../../../components/DemoBanner/DemoBanner";
import ProgressionGraph from "./Components/ProgressionGraph/ProgressionGraph";
import {
    LayerModal,
    LayerModalBackdrop,
    LayerModalMain,
} from "../../../design-system-components/LayerModal/LayerModal";
import Chip, { ChipProps } from "../../../design-system-components/Chip/Chip";
import VisuallyHidden from "../../../components/VisuallyHidden/VisuallyHidden";

import "./StudentProgression.scss";
import "simplebar/dist/simplebar.min.css";

import Icon from "../../../design-system-components/Icon/Icon";

export interface ProgressionData {
    objective: Objective;
    student: Pick<Student, "firstname" | "lastname">;
}

export interface Props extends ProgressionData {
    onDismiss: () => void;
}

const StudentProgression = ({ student, objective, onDismiss }: Props) => {
    const { data } = useContext(dataStore);
    const {
        config: { i18n, features },
    } = useContext(configStore);

    const totalNumberOfExercises = getTotalNumberOfExercises(
        objective.progression.activities
    );

    return (
        <LayerModal onDismiss={onDismiss} className="student-progression">
            <LayerModalBackdrop>
                {features.demoMode?.displayIn.studentProgression && (
                    <DemoBanner
                        position={"top"}
                        message={features.demoMode?.message.studentProgression}
                    />
                )}

                <div className="student-progression__header-container">
                    <div className="student-identity__icon">
                        <Icon
                            size={"large"}
                            color={"#fff"}
                            path={"person"}
                        />
                    </div>

                    <div className="header__student-identity">
                        <h1>
                            {student.firstname} {student.lastname}
                        </h1>
                        <p>
                            {i18n.hierarchy.objective.short
                                .charAt(0)
                                .toUpperCase()}
                            {getResourceIndex(
                                objective.id,
                                data.modules.find((mod) =>
                                    mod.objectiveIds.includes(objective.id)
                                )!.objectiveIds
                            )}
                            {i18n.misc.colon}
                            {parse(
                                getObjectiveById(objective.id, data, true)
                                    .description.$html
                            )}
                        </p>
                    </div>

                    <div className="header__info-labels">
                        <Chip
                            colorMode="dark"
                            input={`${totalNumberOfExercises} ${
                                totalNumberOfExercises === 1
                                    ? i18n.hierarchy.exercise.full
                                    : i18n.dashboard!.studentProgression
                                          .exercises
                            }`}
                        />

                        <Chip
                            colorMode="dark"
                            label={
                                i18n.dashboard!.studentProgression
                                    .meanSuccessRate
                            }
                            input={`${meanSuccess(
                                objective.progression.activities
                            ).toFixed(1).replace(/\.0$/, "")}/1`}
                        />

                        <Chip
                            colorMode="dark"
                            icon={{
                                path: "time",
                            }}
                            label={
                                i18n.dashboard!.studentProgression[
                                    objective.status === "completed"
                                        ? "objectiveCompletedIn"
                                        : "timeSpentOnTheObjective"
                                ]
                            }
                            input={formatTime(objective.timeSpent, i18n)}
                        />

                        <Chip
                            colorMode="dark"
                            type={getType(objective.alert)}
                            label={i18n.dashboard!.studentProgression.trend}
                            input={getTrendText(objective.alert, i18n)}
                        />
                    </div>

                    <button
                        className="header__close-button"
                        onClick={onDismiss}
                    >
                        <Icon
                            size="clickable"
                            path="close"
                            color="var(--white)"
                        />
                        <VisuallyHidden>{i18n.misc.modalClose}</VisuallyHidden>
                    </button>
                </div>
            </LayerModalBackdrop>

            <LayerModalMain>
                <ProgressionGraph
                    activities={objective.progression.activities}
                />
            </LayerModalMain>
        </LayerModal>
    );
};

const getType = (alert: Objective["alert"]): ChipProps["type"] => {
    switch (alert) {
        case "negative":
            return "inf";
        case "none":
            return "conf";
        case "positive":
            return "sup";
        default:
            throw new Error(`Alert type ${alert} not handled`);
    }
};

const getTotalNumberOfExercises = (activities: Activity[]): number => {
    return activities.reduce((total, activity) => {
        return total + activity.exercises.length;
    }, 0);
};

const meanSuccess = (activities: Activity[]): number => {
    const totalScore = activities.reduce((totalScore, activity) => {
        return (
            totalScore +
            activity.exercises.reduce((activityTotalScore, exercise) => {
                return activityTotalScore + exercise.success.current;
            }, 0)
        );
    }, 0);

    return totalScore / getTotalNumberOfExercises(activities);
};

const getTrendText = (
    alert: Objective["alert"],
    i18n: Config["i18n"]
): string => {
    if (alert === "positive")
        return i18n.dashboard!.studentProgression.superiorSuccessRate;
    if (alert === "negative")
        return i18n.dashboard!.studentProgression.inferiorSuccessRate;
    return i18n.dashboard!.studentProgression.expectedSuccessRate;
};

const formatTime = (time: number, i18n: Config["i18n"]): string => {
    return moment
        .duration(time, "seconds")
        .format(
            `h[${i18n.dashboard!.studentProgression.timeFormat.hour}] mm[${
                i18n.dashboard!.studentProgression.timeFormat.minute
            }] ss[${i18n.dashboard!.studentProgression.timeFormat.second}]`
        );
};

export default StudentProgression;
