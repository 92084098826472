import React from 'react'
import Button from '../../Button/Button';
import { AlertDialogLabel, AlertDialogDescription } from "@reach/alert-dialog";

import './Popup.scss';
import { ErrorContent } from '../../../interfaces/Error';

interface PopupProps {
    content: ErrorContent;
    action?: () => void;
    close?: () => void;
}

const Popup = ({content, action, close}: PopupProps) => {

    const callToAction = () => {
        action && action()
        close && close()
    }

    return (
        <div className="error-popup">
            <AlertDialogLabel>{content.title}</AlertDialogLabel>
            {
                content.text &&
                    <AlertDialogDescription>{content.text}</AlertDialogDescription>
            }
            <div className="alert-buttons">
                { content.btn &&
                    <>
                        { content.btn.cancel &&
                            <Button 
                                type={"secondary"} 
                                label='Cancel' centeredLabel={true} 
                                onClick={close} 
                            />
                        }
                        <Button 
                            type={"primary"} 
                            label={content!.btn.label} 
                            centeredLabel={true} 
                            onClick={callToAction} 
                        />
                    </>
                }
            </div>
        </div>
    )
}

export default Popup
