import React, { useContext, useMemo } from 'react';
// External modules
import SimpleBar from 'simplebar-react';
// Contexts
import { configStore } from '../../../../../contexts/ConfigContext';
import { dataStore } from '../../../../../contexts/DataContext';
// Components
import Icon from '../../../../../design-system-components/Icon/Icon';
// Hooks
// Utils
import { getActivityById } from '../../../../../utils/dataRetrieval';
// SCSS
import './ProgressionGraph.scss';
// Interfaces
import { Activity } from '../../../../../interfaces/Dashboard';
import { Link } from 'react-router-dom';
interface ProgressionGraphProps {
    activities: Activity[]
}
const ProgressionGraph = ({ activities }: ProgressionGraphProps) => {
    // Contexts
    const { config:{i18n} } = useContext(configStore)
    const { data } = useContext(dataStore)
    // States
    // Functions    
    // Calculate maximum success to have most appropriate bar height
    const maximumSuccess = useMemo<number>( () => {
        let number = activities.reduce((max, activity) => {
            const activityMax = activity.exercises.reduce((activityMax, exercise) => {
                if (exercise.success.current >= exercise.success.expected && exercise.success.current >= activityMax)
                    return exercise.success.current;
                if (exercise.success.expected >= exercise.success.current && exercise.success.expected >= activityMax)
                    return exercise.success.expected;
                return activityMax;
            }, 0);
            return activityMax > max ? activityMax : max;
        }, 0)
        return number
    }, [activities]);

    return (
        <div className='ProgressionGraph'>
            <h2>
                {i18n.dashboard!.studentProgression.contentTitle}
            </h2>
            <p>{i18n.dashboard!.studentProgression.contentSubtitle}</p>
            <div className="legend">
                <div className='col'>
                    <div>
                        <span className="color expected" aria-hidden="true"><span></span></span>
                        <span>{i18n.dashboard?.studentProgression.legend.successLevelExpected}</span>
                    </div>
                    <div>
                        <span className="color success-level" aria-hidden="true"><span></span></span>
                        <span>{i18n.dashboard?.studentProgression.legend.successLevelStudent}</span>
                    </div>
                </div>
                <div className='col'>
                    <div>
                        <Icon size="medium" path="alert_sup" />
                        <span>{i18n.dashboard?.progressionOverview.legend.progressionAcceleration}</span>
                    </div>
                    <div>
                        <Icon size="medium" path="alert_inf" />
                        <span>{i18n.dashboard?.progressionOverview.legend.progressionRegression}</span>
                    </div>
                    <div>
                        <Icon size="medium" path="alert_conf" />
                        <span>{i18n.dashboard?.studentProgression.expectedSuccessRate}</span>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className='axis'>
                    <div className="axis__x"></div>
                    <div className="axis__y">
                        <span>{i18n.success}</span>
                    </div>
                </div>
                <SimpleBar style={{ height: 381, width: "100%" }} autoHide={false}>
                    <div className="graphic">
                        {
                            activities.map((activity, activityIndex) =>
                                <div className="activity" key={'act-' + activityIndex}>
                                    <div className="container">
                                        {
                                            activity.exercises.map((exercise, i) =>
                                                <div className="exercise" key={exercise.id + ("-ex" + i)}>
                                                    <div className="bars">
                                                        {/* The height of the bars should have a minimum so that even if the score is 0 the bar still shows */}
                                                        <div className="expected" style={{ height: 15 + exercise.success.expected / maximumSuccess * 85 + '%' }}></div>
                                                        <div
                                                            className={`${exercise.success.alert} current`}
                                                            style={{ height: 15 + exercise.success.current / maximumSuccess * 85 + '%' }}
                                                        >
                                                            {exercise.success.alert === "none" && <Icon size="medium" color={"white"} path="conf_simple" />}
                                                            {exercise.success.alert === "negative" && <Icon size="medium" color={"white"} path="arrow_drop_down" />}
                                                            {exercise.success.alert === "positive" && <Icon size="medium" color={"white"} path="arrow_drop_up" />}
                                                        </div>
                                                    </div>
                                                    <div className="title">
                                                        <Link
                                                            target="_blank" rel="noopener noreferrer"
                                                            to={'/play/' + exercise.id}>
                                                                {`${i18n.dashboard?.studentProgression.exerciseShort} ${getContinuousIndex(activities, activityIndex, i)}`}
                                                        </Link>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                    <div className="title">
                                        {getActivityById(activity.id, data).title.long}
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </SimpleBar>
            </div>
        </div>
    )
}

/**
 * Indexing the exercises in the chart should not reset when the same activity
 * appears twice. This function calculates the index of the exercice, taking
 * possible exercices in the same activity that have already appreared
 */
const getContinuousIndex = (
    activities: Activity[],
    currentActivityIndex: number,
    exerciseIndexInCurrentActivity: number
): number => {
    return activities
        .slice(0, currentActivityIndex)
        .reduce((nbOfExercicesInSameActivity, activity) => {
            if (activity.id !== activities[currentActivityIndex].id) return nbOfExercicesInSameActivity;
            return nbOfExercicesInSameActivity + activity.exercises.length;
        }, 0)
        + exerciseIndexInCurrentActivity
        + 1;
};

export default ProgressionGraph