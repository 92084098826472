import React, { DetailedHTMLProps, ImgHTMLAttributes, useContext } from "react";
import { getAssetUrl } from "../../utils/assetsUtils";
import { sessionStore } from "../../contexts/SessionContext";
import { configStore } from "../../contexts/ConfigContext";

const ImageAsset = ({
    src,
    ...props
}: DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> & {
    src: string;
}) => {
    const {
        session: { version },
    } = useContext(sessionStore);
    const {
        config: { apiUrls },
    } = useContext(configStore);

    if (!apiUrls.endpoints.assetsProxy || !version) {
        return null;
    }

    return (<>
        {/* eslint-disable-next-line jsx-a11y/alt-text */}
        <img
            {...props}
            src={getAssetUrl(src, version, apiUrls.endpoints.assetsProxy)}
        />
    </>);
};

export default ImageAsset;
