import React, { useContext, useState } from "react";
import { useHistory } from "react-router";
import { Link, LinkProps } from "react-router-dom";
import { configStore } from "../../../../../contexts/ConfigContext";
import { sessionStore } from "../../../../../contexts/SessionContext";
import { syncStore } from "../../../../../contexts/Sync";
import useStatements from "../../../../../hooks/useStatements";
import BanditManchotErrorModal from "../../../../../components/BanditManchotErrorModal/BanditManchotErrorModal";

interface Props {
    moduleId: string;
}

const SafeguardedLink = ({ moduleId, ...props }: LinkProps & Props) => {
    const {
        sync: { unsyncedStatements, syncingStatements },
    } = useContext(syncStore);
    const { setSession } = useContext(sessionStore);
    const {
        config: { i18n },
    } = useContext(configStore);
    const { syncStatements } = useStatements();
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const history = useHistory();

    if (!unsyncedStatements && !syncingStatements)
        return <Link className="player-header__exit" {...props} />;

    return (
        <>
            {isModalOpen && (
                <BanditManchotErrorModal
                    title={
                        i18n.errors.statements.navigationWhenUnsyncedStatements
                            .modal.title
                    }
                    description={
                        i18n.errors.statements.navigationWhenUnsyncedStatements
                            .modal.description
                    }
                    primaryButton={{
                        label: i18n.errors.statements
                            .navigationWhenUnsyncedStatements.modal.syncButton,
                        fn: () => {
                            syncStatements();
                            setIsModalOpen(false);
                        },
                    }}
                    secondaryButton={{
                        label: i18n.errors.statements
                            .navigationWhenUnsyncedStatements.modal
                            .leaveAnywayButton,
                        fn: () => {
                            // The module should be made unavailable because
                            // the bandit manchot instance is now loaded with
                            // statements that weren't synced, and further
                            // playing will create a gap in theuser's history
                            setSession((curr) => {
                                return {
                                    ...curr,
                                    banditManchot: {
                                        ...curr.banditManchot,
                                        [moduleId]: {
                                            ...curr.banditManchot![moduleId],
                                            error: true,
                                        },
                                    },
                                };
                            });
                            history.push(props.to as string);
                        },
                    }}
                ></BanditManchotErrorModal>
            )}

            <button
                className="player-header__exit"
                onClick={() => {
                    setIsModalOpen(true);
                }}
            >
                {props.children}
            </button>
        </>
    );
};

export default SafeguardedLink;
