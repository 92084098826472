import React, { useEffect, useState, useContext, useMemo } from "react";
import { useParams, useHistory } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { PRTree } from "@evidenceb/gameplay-interfaces";
import {
    Classroom,
    ModuleDashboard,
    ParamTypes,
} from "../../../interfaces/Dashboard";
import { UserType } from "../../../interfaces/User";
import { configStore } from "../../../contexts/ConfigContext";
import { sessionStore } from "../../../contexts/SessionContext";
import { errorStore } from "../../../contexts/ErrorContext";
import { dataStore } from "../../../contexts/DataContext";
import { buildPRTree } from "../../../utils/prm";
import useAthenaAPIClient from "../../../hooks/useAthenaAPIClient";
import Breadcrumbs, {
    Breadcrumb,
} from "../../../components/Breadcrumbs/Breadcrumbs";
import ProgressionTable from "./ProgressionTable/ProgressionTable";
import ClassroomsNav from "./ClassroomsNav/ClassroomsNav";
import LockedResourceWarningBanner from "./LockedResourceWarningBanner/LockedResourceWarningBanner";

import "@reach/tooltip/styles.css";

interface Props {
    classrooms: Classroom[];
}

const ProgressionOverview = ({ classrooms }: Props) => {
    const { config } = useContext(configStore);
    const {
        session: { userId },
    } = useContext(sessionStore);
    const { data } = useContext(dataStore);
    const { setErrorInfo } = useContext(errorStore);
    const history = useHistory();
    const { moduleId, classroomId } = useParams<ParamTypes>();
    const athenaAPIClient = useAthenaAPIClient();

    const [module, setModule] = useState<ModuleDashboard>();
    const [prTree, setPRTree] = useState<PRTree | "error">();

    const classroom = useMemo(() => {
        const classroom = classrooms.find(
            (classroom) => classroom.id === classroomId
        );
        if (!classroom) {
            // [TODO] Error handling
            history.push(
                `/${config.i18n.dashboard!.paths.dashboard}/${
                    config.i18n.dashboard!.paths.classes
                }/${config.i18n.dashboard!.paths.progression}/${
                    classrooms[0].id
                }/${classroomId}`
            );
            return;
        }
        return classroom;
    }, [classrooms, config.i18n.dashboard, classroomId, history]);

    const breadcrumbs = useMemo<Breadcrumb[] | undefined>(() => {
        if (!classroom) return;

        return [
            {
                title: config.i18n.dashboard!.common.classes,
                url: `/${config.i18n.dashboard!.paths.dashboard}/${
                    config.i18n.dashboard!.paths.dashboard
                }`,
            },
            {
                title: classroom.name,
                url: `/${config.i18n.dashboard!.paths.dashboard}/${
                    config.i18n.dashboard!.paths.classes
                }/${classroom.id}`,
            },
            {
                title: config.i18n.dashboard!.common.progression,
                url: "",
                current: true,
            },
        ];
    }, [config.i18n.dashboard, classroom]);

    // Init module
    useEffect(() => {
        if (!classroom) return;

        let module = classroom.modulesList.find(
            (module) => module.id === moduleId
        );
        if (!module) {
            // [TODO] Error handling
            history.push(
                `/${config.i18n.dashboard!.paths.dashboard}/${
                    config.i18n.dashboard!.paths.classes
                }/${config.i18n.dashboard!.paths.progression}/${classroomId}/${
                    classroom.modulesList[0].id
                }`
            );
            return;
        }
        setModule(module);
    }, [moduleId, classroomId, classroom, config.i18n.dashboard, history]);

    // Fetch lock status
    useEffect(() => {
        if (prTree || prTree === "error" || !classroom) return;

        (async () => {
            try {
                const allClassesLockStatus =
                    await athenaAPIClient.getResourcesLockStatus(
                        userId,
                        UserType.Teacher
                    );
                const prTree = buildPRTree(
                    allClassesLockStatus[classroom.id],
                    data,
                    config.ai!
                );
                setPRTree(prTree);
            } catch (e) {
                Sentry.captureException(e);
                setErrorInfo({
                    displayModal: true,
                    modal: {
                        type: "NOTIFICATION",
                        content: {
                            title: config.i18n.prm.lockStatusErrorText,
                        },
                    },
                });
                setPRTree("error");
            }
        })();
    }, [
        athenaAPIClient,
        userId,
        prTree,
        classroom,
        setErrorInfo,
        config.i18n.prm.lockStatusErrorText,
        config.ai,
        data,
    ]);

    // [TODO] Error handling
    if (!module || !classroom) return <></>;

    return (
        <div className="classrooms-modules-overview">
            {prTree && prTree !== "error" && (
                <LockedResourceWarningBanner
                    moduleId={moduleId}
                    classroomId={classroomId}
                    prTree={prTree}
                />
            )}

            <header>
                {breadcrumbs && <Breadcrumbs items={breadcrumbs} />}
                <h1>{config.i18n.dashboard?.common.progression}</h1>
                <ClassroomsNav
                    classrooms={classrooms}
                    classroom={classroom}
                    prTree={prTree === "error" ? undefined : prTree}
                />
            </header>

            <ProgressionTable
                students={module.students}
                objectives={module.objectives}
                prTree={prTree === "error" ? undefined : prTree}
                moduleId={moduleId}
            />
        </div>
    );
};

export default ProgressionOverview;
