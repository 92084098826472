import React, { useContext } from "react";
import parse from "html-react-parser";
import {
    PRHierarchy,
    PRItem,
    PRLockStatus,
    PRTree,
} from "@evidenceb/gameplay-interfaces";
import { Data } from "../../../../interfaces/Data";
import { configStore } from "../../../../contexts/ConfigContext";
import { dataStore } from "../../../../contexts/DataContext";
import { UsePRManagerReturn } from "../usePRManager";
import { UsePRConfirmationReturn } from "../usePRConfirmation";
import { prHierarchysToHierarchyIds } from "../../../../utils/hierarchyTranslator";
import {
    getModuleById,
    getObjectiveById,
} from "../../../../utils/dataRetrieval";
import Toggle from "../../../../components/Toggle/Toggle";
import { LevelData, ParentLevelData } from "../../PedagogicalResourcesManagement";

import "./BatchControls.scss";

interface Props {
    level: LevelData;
    setLevel: React.Dispatch<React.SetStateAction<LevelData>>;
    prLockStatus: PRLockStatus;
    prTree: PRTree;
    toggleItems: UsePRManagerReturn["toggleItems"];
    confirmToggleAttempt: UsePRConfirmationReturn["confirm"];
}

const BatchControls = ({
    level,
    setLevel,
    prLockStatus,
    prTree,
    toggleItems,
    confirmToggleAttempt,
}: Props) => {
    const {
        config: { i18n },
    } = useContext(configStore);
    const { data } = useContext(dataStore);

    return (
        <div
            className={`batch-controls ${
                level.parentData ? "batch-controls--has-parent" : ""
            }`}
        >
            {level.parentData && (
                <button
                    className="batch-controls__back"
                    onClick={() => {
                        setLevel(
                            level.parentData
                                ? level.parentData
                                : {
                                      hierarchy: PRHierarchy.Modules,
                                      prPool: data.modules,
                                  }
                        );

                        // Reset confirmation
                        if (confirmToggleAttempt) confirmToggleAttempt(false);
                    }}
                >
                    <span className="material-icons">arrow_back</span>
                </button>
            )}

            <div className="batch-controls__content">
                {level.parentData && (
                    <>
                        <span className="content__title">
                            {getLevelTitle(level.parentData, data)}
                        </span>
                        <span className="content__separator"></span>
                        <span className="content__list-title">
                            {parse(level.parentData.listTitle)}
                        </span>
                    </>
                )}

                <div className="control__activate-all">
                    <span aria-hidden="true">
                        {i18n.prm.manager.activateAll}
                    </span>
                    <Toggle
                        isSelected={areAllItemsActivated(level, prTree)}
                        onChange={() => {
                            const itemsToToggle = getItemsToToggle(
                                level,
                                prLockStatus,
                                areAllItemsActivated(level, prTree)
                            );
                            toggleItems(itemsToToggle);
                        }}
                        accessibleLabel={i18n.prm.manager.activateAll}
                    />
                </div>
            </div>
        </div>
    );
};

const areAllItemsActivated = (
    level: LevelData,
    currentPRTree: PRTree
): boolean => {
    return level.prPool.every(
        (item) => currentPRTree[level.hierarchy][item.id].status === "unlocked"
    );
};

const getItemsToToggle = (
    level: LevelData,
    currentPRLockStatus: PRLockStatus,
    areAllItemsActivated: boolean
): PRItem[] => {
    if (areAllItemsActivated)
        return level.prPool.map((item) => {
            return { id: item.id, hierarchy: level.hierarchy };
        });

    return level.prPool
        .filter((item) =>
            currentPRLockStatus[
                prHierarchysToHierarchyIds(level.hierarchy)
            ].includes(item.id)
        )
        .map((item) => {
            return { id: item.id, hierarchy: level.hierarchy };
        });
};

const getLevelTitle = (level: ParentLevelData, data: Data): string => {
    switch (level.hierarchy) {
        case PRHierarchy.Modules:
            const mod = getModuleById(level.id, data);
            return mod.title.short!;
        case PRHierarchy.Objectives:
            const obj = getObjectiveById(level.id, data);
            return obj.title.short!;
    }
};

export default BatchControls;
