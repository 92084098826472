import { UserType } from "../interfaces/User";
import * as localStorage from "../utils/localStorage";

// USERS
export const resetUser = async (url:string, userType: string | null,
    athenaAPIClient: any
) => {
    if(userType === UserType.Teacher || userType === UserType.Student){
        const tokenPayload = await athenaAPIClient.getTokenPayload();
        console.log(`ENT: GET: USER (${tokenPayload.role})`, tokenPayload);
        const user = await athenaAPIClient.getUser(tokenPayload);
        if (user) {
            let userBody;
            if(userType === UserType.Student){
                userBody = {
                    first_name: '',
                    last_name: '',
                    classrooms:[]
                }
            }else{
                userBody = {
                    first_name: '',
                    last_name: '',
                }
            }
            const userUpdated = await athenaAPIClient.updateUser(tokenPayload.role, user.id, userBody);
            console.log(`API: PATCH: USER (${tokenPayload.role})`, userUpdated);
            window.location.replace('/')
        }
    }else{
        return 'Wrong user type: needs to be TEACHER or STUDENT (case sensitive)'
    }
}

// LOCAL STORAGE
export const clearLocalStorage = (): string => {
    localStorage.clear()
    return "LocalStorage cleared"
}