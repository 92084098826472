import React from "react";
import Icon, { IconProps } from "../Icon/Icon";
// SCSS
import "./Button.scss";
// Interfaces
export interface ButtonProps
    extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, "children"> {
    variant: "primary" | "secondary" | "secondary-light" | "tertiary";
    label: string;
    icon?: Pick<IconProps, "path" | "className"> & {
        /** @default medium */
        size?: IconProps["size"];
        position: "left" | "right";
    };
    /** @default default */
    size?: "default" | "large";
    /**
     * Optional color for the button. "primary" and "secondary" reference the
     * primary and secondary colors of the variation.
     * @default default
     */
    color?: "default" | "primary" | "secondary";
}

const Button = ({
    variant,
    label,
    icon,
    className,
    size = "default",
    color = "default",
    ...props
}: ButtonProps) => {
    return (
        <button
            className={`
                ds-btn ds-btn--${variant} ds-btn--size-${size} ds-btn--color-${color}
                ds-btn--${icon?.position ?? "centered"}
                ${className ?? ""}
            `}
            {...props}
        >
            {icon && <Icon {...icon} />}
            <span className="ds-btn__label">{label}</span>
        </button>
    );
};
export default Button;
