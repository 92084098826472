const COLOR_SCHEME = {
    locked: {
        "--objective-completed-color": "#8A9FEC",
        "--objective-in-progress-color": "#D6DFF7",
        "--table-border-color": "#8A9FEC",
    },
    unlocked: {
        "--objective-completed-color": "#8AC0EC",
        "--objective-in-progress-color": "#CBE1EA",
        "--table-border-color": "#8AC0EC",
    },
};

export default COLOR_SCHEME;
