import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router";
import { Classroom } from "../../../../interfaces/Dashboard";
import { Config } from "../../../../interfaces/Config";
import { configStore } from "../../../../contexts/ConfigContext";
import { dashboardStore } from "../../../../contexts/DashboardContext";
import ClassroomName from "./Components/ClassroomName/ClassroomName";
import StudentsOverview from "./Components/StudentsOverview/StudentsOverview";
import Breadcrumbs from "../../../../components/Breadcrumbs/Breadcrumbs";
import Loader from "../../../../components/Loader/Loader";
import ModulesList from "./Components/ModulesList/ModulesList";

import "@reach/tabs/styles.css";
import "./SingleClassroom.scss";

export interface StudentInDifficulty {
    firstname: string;
    lastname: string;
    id: string;
    progression: number;
}

interface StudentsSummary {
    total: number;
    hasImproved: number;
    hasDifficulties: number;
}

const SingleClassroom = () => {
    const { config } = useContext(configStore);
    const {
        dashboard: { classrooms },
    } = useContext(dashboardStore);
    let { classroomId } = useParams<{ classroomId: string }>();
    const history = useHistory();

    const [classroom, setClassroom] = useState<Classroom>();
    const [studentInDifficulty, setStudentInDifficulty] = useState<
        StudentInDifficulty[]
    >([]);
    const [studentsSummary, setStudentsSummary] = useState<StudentsSummary>({
        total: 0,
        hasImproved: 0,
        hasDifficulties: 0,
    });

    const getStudentsInDifficulties = (
        currentClassroom: Classroom | undefined
    ) => {
        const table: StudentInDifficulty[] = [];
        currentClassroom?.modulesList.forEach((module) =>
            Object.keys(module.students).forEach((studentId) => {
                if (module.students[studentId].studentInDifficulty) {
                    table.push({
                        firstname: module.students[studentId].firstname,
                        lastname: module.students[studentId].lastname,
                        id: module.students[studentId].id,
                        progression:
                            module.students[studentId].moduleProgression * 100,
                    });
                }
            })
        );
        setStudentInDifficulty(removeDuplicate(table));
    };

    const getStudentsSummary = (currentClassroom: Classroom) => {
        let hasImproved = 0;
        let hasDifficulties = 0;
        currentClassroom.modulesList.forEach((mod) => {
            hasImproved = hasImproved + mod.alert.hasImproved;
            hasDifficulties = hasDifficulties + mod.alert.hasDifficulties;
        });
        setStudentsSummary({
            total: currentClassroom.studentsNumber,
            hasImproved: hasImproved,
            hasDifficulties: hasDifficulties,
        });
    };

    useEffect(() => {
        const currentClassroom = classrooms.find(
            (classroom) => classroom.id === classroomId
        );
        setClassroom(currentClassroom);
        getStudentsInDifficulties(currentClassroom);
        if (currentClassroom) {
            getStudentsSummary(currentClassroom);
        }
    }, [classrooms, classroomId]);

    // TODO there's probably some error handling to do here
    if (!classroom) return <Loader />;

    return (
        <div className="single-classroom">
            <Breadcrumbs items={breadcrumbs(config, classrooms, classroomId)} />

            <div className="single-classroom-container">
                <div className="name">
                    <ClassroomName id={classroomId} name={classroom.name} />
                </div>

                <button
                    className="prm-access-button"
                    onClick={() => {
                        history.push("/prm/" + classroomId);
                    }}
                >
                    <span className="material-icons" translate="no">tune</span>
                    <span>{config.i18n.classList.settingPRMLabel}</span>
                </button>

                <div className="container">
                    <div
                        className={`modules-container ${
                            studentInDifficulty.length === 0 ? "full-width" : ""
                        }`}
                    >
                        {classroom.modulesList.length ? (
                            <ModulesList classroom={classroom} />
                        ) : (
                            <p>
                                {config.i18n.dashboard?.common.noStudentsData}
                            </p>
                        )}
                    </div>

                    {classroom.modulesList.length && (
                        <StudentsOverview
                            summary={studentsSummary}
                            classroomId={classroomId}
                            students={studentInDifficulty}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

const breadcrumbs = (
    config: Config,
    classrooms: Classroom[],
    classroomId: string
) => {
    return [
        {
            title: config.i18n.dashboard!.common.classes,
            url: `/${config.i18n.dashboard!.paths.dashboard}/${
                config.i18n.dashboard!.paths.dashboard
            }`,
        },
        {
            title: classrooms.find((classroom) => classroom.id === classroomId)!
                .name,
            url: "",
            current: true,
        },
    ];
};

const removeDuplicate = (arr: StudentInDifficulty[]) => {
    let sortedArraya = arr.sort(
        (a: StudentInDifficulty, b: StudentInDifficulty) =>
            a.id > b.id ? 1 : b.id > a.id ? -1 : 0
    );
    return sortedArraya.filter(function (item, pos, ary) {
        return !pos || item.id !== ary[pos - 1].id;
    });
};

export default SingleClassroom;
