import React, {
    createContext,
    useState,
    Dispatch,
    SetStateAction,
    useEffect,
} from "react";
import { AuthMode, Config } from "../interfaces/Config";
import { errorI18n } from "../pages/Error/errorI18n";

// Context Typing
interface ConfigContext {
    config: Config;
    setConfig: Dispatch<SetStateAction<Config>>;
}

// Initial State
const initialState: Config = {
    versioning: 0,
    declinaison: "",
    client_name: "",
    lang: "en",
    apiUrls: {
        match: "",
        endpoints: {
            content: "",
            assetsProxy: "",
        },
    },
    activate_contentPage: false,
    features: {
        teacherDashboard: true,
    },
    pages: [],
    auth: {
        mode: AuthMode.DirectAccess,
        rightImage: "",
    },
    i18n: {
        student: "",
        groupCharacteristic: "",
        auth: {
            student: {
                registerClass: {
                    title: "",
                    text: { $html: "" },
                    classCode: {
                        info: { $html: "" },
                    },
                },
                signIn: {
                    text: { $html: "" },
                },
            },
            teacher: {
                signIn: {
                    title: "",
                    text: { $html: "" },
                },
            },
            form: {
                classCode: "",
                error: {
                    codeClassError: { $html: "" },
                    authError: { $html: "" },
                    name: "",
                    firstname: "",
                },
                button: {
                    start: "",
                    next: "",
                },
                firstname: "",
                name: "",
            },
        },
        dashboard: undefined,
        hierarchy: {
            module: {
                full: "",
                short: "",
            },
            objective: {
                full: "",
                short: "",
            },
            activity: {
                full: "",
                short: "",
            },
            exercise: {
                full: "",
                short: "",
            },
        },
        moduleList: {
            info: "",
            title: "",
            areAllModulesLocked: "",
            student: {
                message: {
                    default: { $html: "" },
                    visited: { $html: "" },
                },
                progression: "",
                successRate: "",
                start: "",
                continue: "",
                completedModule: "",
                studentPathIconAlt: "",
                studentPathTitle: "",
            },
            lockedModule: {
                modalTitle: "",
                modalDescription:
                    "",
                modalButton: "",
                modalLink: ""
            }
        },
        endPlaylist: {
            congratulations: "",
            completed: "",
            result: "",
            exerciseCorrect: "",
            exerciseIncorrect: "",
            moduleList: "",
            nextModule: "",
            dashboard: "",
        },
        exerciseShell: {
            close: "",
            feedback: {
                nextExercise: "",
                nextActivity: "",
                endPlaylist: "",
                retry: "",
            },
            tryAgain: { $html: "" },
            initialTestMessages: {
                start: { $html: "" },
                end: { $html: "" },
                btnStart: "",
                btnEnd: "",
            },
            emotionalReport: {
                frustrated: "",
                bored: "",
                confused: "",
                neutral: "",
                popupTitle: "",
                popupSendBtn: "",
            },
            timeoutModalTitle: "",
            timeoutModalDescription: "",
            timeoutModalContinueButton: "",
            timeoutModalQuitButton: "",
        },
        misc: {
            goToTopButtonLabel: "",
            logout: "",
            logoutUrl: [],
            contact: "",
            developedBy: "",
            colon: ":",
            userDataDownload: "",
            modalClose: "",
        },
        chatbot: {
            welcomeMessages: [],
            start: { $html: "" },
            initialTestMessages: {
                start: { $html: "" },
                end: { $html: "" },
            },
        },
        gameplays: {
            generic: {
                btnValidate: "",
            },
        },
        drawer: {
            targetedDifficulties: "",
        },
        features: {
            studentChatbotPlayerInfoPanel: {
                title: "",
                details: "",
                reduceDetails: "",
            },
        },
        errors: {
            pages: errorI18n,
            getData: "",
            postData: "",
            patchData: "",
            statements: {
                cannotRetrieveHistory: {
                    title: "",
                    description: "",
                    goToHomepageButton: "",
                },
                cannotSendStatement: {
                    modal: {
                        title: "",
                        description: "",
                        continueButton: "",
                    },
                    notification: {
                        description: "",
                        tryToSyncButton: "",
                        attemptingToSync: "",
                        syncSuccess: "",
                    },
                },
                navigationWhenUnsyncedStatements: {
                    modal: {
                        title: "",
                        description: "",
                        leaveAnywayButton: "",
                        syncButton: "",
                    },
                },
                unavailableModule: {
                    moduleCard: {
                        description: "",
                        modal: {
                            title: "",
                            description: "",
                            continueButton: "",
                        },
                    },
                },
            },
        },
        prm: {
            lockStatusErrorBtn: "",
            lockStatusErrorText: "",
            lockStatusErrorTitle: "",
            listOf: "",
            moduleMap: "",
            home: {
                title: "",
                titleGeneric: "",
                description:
                    "",
                descriptionGeneric: ""
            },
            manager: {
                activateAll: "",
                listItem: {
                    accessibleActivate: "",
                    accessibleDeactivate: "",
                    activated: "",
                    deactivated: "",
                    deactivatePrompt: "",
                    activatePrompt: "",
                    activate: "",
                    deactivate: "",
                    initialTestActivateDescription: "",
                    initialTest: "",
                    initialTestDeactivateDescription: "",
                    cancel: ""
                },
                cancel: "",
                confirm: "",
                cancelModal: {
                    title: "",
                    description: "",
                    primaryButton: "",
                    secondaryButton: ""
                },
                successModal: {
                    title: "",
                    description: "",
                    primaryButton: ""
                },
                errorModal: {
                    title: "",
                    description: "",
                    primaryButton: "",
                    secondaryButton: ""
                }
            },
        },
        classList: {
            settingPRMLabel: "",
            lockStatusErrorTitle: "",
            lockedModuleButtonLabel: "",
            prmSettingsLabel: ""
        },
        singleClass: {
            separatorTitle: "",
            inactiveModuleDescription: "",
            toggleActivateModuleAccessibleLabel: "",
            noModuleActivatedTitle: "",
            noModuleActivatedDescription: "",
            activateModuleModalTitle: "",
            activateModuleModalDescription: "",
            activateModuleModalCancel: "",
            activateModuleModalConfirm: "",
            savedProgressionSingular: "",
            savedProgressionPlural: "",
            progressionRegressionSingular: "",
            progressionRegressionPlural: "",
            progressionAccelerationSingular: "",
            progressionAccelerationPlural: ""
        },
        clustering:{
            seeProgressionOverview:""
        },
        success: ""
    },
    logos: {
        avatar: "",
        header_client: "",
        endPlaylistIcon: "",
        footer_client: "",
        footer_evidenceb: "",
    },
    contact: "",
};

// Context init
const configStore = createContext<ConfigContext>({
    config: initialState,
    setConfig: () => null,
});

// Provider init
const { Provider } = configStore;

// Final setup
const ConfigProvider = ({ children }: { children: JSX.Element }) => {
    const [config, setConfig] = useState(initialState);
    useEffect(()=>{
        console.log("CONTEXT CONFIG", config);
        
    },[config])
    return <Provider value={{ config, setConfig }}>{children}</Provider>;
};

export { configStore, ConfigProvider };
