import { Module, PRTree } from "@evidenceb/gameplay-interfaces";
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import MaterialIcon from "../../../../components/MaterialIcon/MaterialIcon";
import VisuallyHidden from "../../../../components/VisuallyHidden/VisuallyHidden";
import { configStore } from "../../../../contexts/ConfigContext";
import { dataStore } from "../../../../contexts/DataContext";
import { Config } from "../../../../interfaces/Config";
import { Data } from "../../../../interfaces/Data";
import { getModuleById, notInitialTest } from "../../../../utils/dataRetrieval";

import "./LockedResourceWarningBanner.scss";

interface Props {
    classroomId: string;
    prTree: PRTree;
    moduleId: string;
}

const LockedResourceWarningBanner = ({
    classroomId,
    prTree,
    moduleId,
}: Props) => {
    const {
        config: { i18n },
    } = useContext(configStore);
    const { data } = useContext(dataStore);
    const [dismissed, setDismissed] = useState<boolean>(false);

    const module = getModuleById(moduleId, data);

    if (
        prTree.modules[module.id].status === "unlocked" &&
        module.objectiveIds
            .filter(notInitialTest(data))
            .every((objId) => prTree.objectives[objId].status === "unlocked")
    )
        return null;

    const { warningTxt, linkTxt } = getText(prTree, module, i18n, data);

    return (
        <>
            {!dismissed && (
                <div className="locked-module-warning-banner">
                    <p>
                        <MaterialIcon className="warning-banner__info-icon">
                            info
                        </MaterialIcon>
                        <span>{warningTxt}</span>
                        <Link to={`/prm/${classroomId}`}>{linkTxt}</Link>
                    </p>
                    <button
                        onClick={() => {
                            setDismissed(true);
                        }}
                    >
                        <MaterialIcon>close</MaterialIcon>
                        <VisuallyHidden>{i18n.misc.modalClose}</VisuallyHidden>
                    </button>
                </div>
            )}
        </>
    );
};

const getText = (
    prTree: PRTree,
    module: Module,
    i18n: Config["i18n"],
    data: Data
): { warningTxt: string | undefined; linkTxt: string | undefined } => {
    if (prTree.modules[module.id].status !== "unlocked")
        return {
            warningTxt: i18n.dashboard!.progressionOverview.lockedModuleWarning,
            linkTxt: i18n.dashboard!.progressionOverview.LockedModuleLinkToPRM,
        };
    if (
        module.objectiveIds
            .filter(notInitialTest(data))
            .some((objId) => prTree.objectives[objId].status !== "unlocked")
    )
        return {
            warningTxt:
                i18n.dashboard!.progressionOverview.lockedResourceWarning,
            linkTxt:
                i18n.dashboard!.progressionOverview.LockedResourceLinkToPRM,
        };

    return {
        warningTxt: undefined,
        linkTxt: undefined,
    };
};

export default LockedResourceWarningBanner;
