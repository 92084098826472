import { PRHierarchy, PRHierarchyIds } from "@evidenceb/gameplay-interfaces";

export const prHierarchysToHierarchyIds = (hierarchys: PRHierarchy): PRHierarchyIds => {
  switch (hierarchys) {
      case PRHierarchy.Modules:
          return PRHierarchyIds.ModuleIds;
      case PRHierarchy.Objectives:
          return PRHierarchyIds.ObjectiveIds;
  }
};

export const prHierarchysToHierarchy = (hierarchys: PRHierarchy): "module" | "objective" => {
  switch (hierarchys) {
      case PRHierarchy.Modules:
          return "module";
      case PRHierarchy.Objectives:
          return "objective";
  }
};