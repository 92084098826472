import React from "react";

import "./ManagerListItem.scss";

type Element = JSX.Element | false | string | null;
export interface Props
    extends React.DetailedHTMLProps<
        React.LiHTMLAttributes<HTMLLIElement>,
        HTMLLIElement
    > {
    status:
        | "locked"
        | "unlocked"
        | "activateDependency"
        | "deactivateDependency";
    children: Element | Element[];
}

const ManagerListItem = ({
    status,
    children,
    className,
    ...props
}: Props): JSX.Element => {
    return (
        <li className={`manager-list-item --${status} ${className}`} {...props}>
            {children}
        </li>
    );
};

export default ManagerListItem;
