import { useContext, useState } from "react";
import BoredBot from "./bored-bot";
import ConfusedBot from "./confused-bot";
import FrustratedBot from "./frustrated-bot";
import NeutralBot from "./neutral-bot";
import { configStore } from "../../../../contexts/ConfigContext";
import { sessionStore } from "../../../../contexts/SessionContext";
import { makeEmotionalReportStatement } from "../../../../utils/tracking";
import * as Sentry from "@sentry/react";
import { getContext } from "../../../../utils/sentry";
import useAthenaAPIClient from "../../../../hooks/useAthenaAPIClient";

export enum Emotion {
    Frustrated = "FRUSTRATED",
    Confused = "CONFUSED",
    Bored = "BORED",
    Neutral = "NEUTRAL",
}

export interface EmotionalReportController {
    showEmotionalReportPopup: boolean;
    clickedButtons: Emotion[] | null;
    recordReport: (
        reportedEmotions: Emotion,
        reportType: "FORCED" | "SELF-REPORT"
    ) => void;
    recordValidatedExercise: () => void;
}

export const EMOTIONS: {
    [key in Emotion]: {
        i18nKey: "frustrated" | "neutral" | "confused" | "bored";
        Component: () => JSX.Element;
    };
} = {
    [Emotion.Frustrated]: {
        Component: FrustratedBot,
        i18nKey: "frustrated",
    },
    [Emotion.Bored]: {
        Component: BoredBot,
        i18nKey: "bored",
    },
    [Emotion.Confused]: {
        Component: ConfusedBot,
        i18nKey: "confused",
    },
    [Emotion.Neutral]: {
        Component: NeutralBot,
        i18nKey: "neutral",
    },
};

const NUMBER_OF_EXERCISES_BEFORE_FORCED_REPORT = 10;
const DURATION_OF_EMOTIONAL_REPORT_UI_FEEDBACK = 3000;

const useEmotionalReport = (): EmotionalReportController => {
    const { config } = useContext(configStore);
    const { session } = useContext(sessionStore);
    const athenaAPIClient = useAthenaAPIClient();
    const [clickedButtons, setClickedButtons] = useState<Emotion[] | null>(
        null
    );
    const [exercisesSinceLastReport, setExercisesSinceLastReport] = useState(0);

    return {
        showEmotionalReportPopup:
            exercisesSinceLastReport > NUMBER_OF_EXERCISES_BEFORE_FORCED_REPORT,

        clickedButtons,

        async recordReport(clickedButton, reportType) {
            // Send statement to LRS
            const statement = makeEmotionalReportStatement(
                config.declinaison,
                session.evidencebId,
                clickedButton,
                session.sessionId,
                reportType
            );
            try {
                await athenaAPIClient.postStatement(statement);
            } catch (err) {
                // [TODO] Error handling
                Sentry.captureException(
                    err,
                    getContext()
                );
            }

            setClickedButtons((curr) => {
                if (!curr) return [clickedButton];
                if (curr.includes(clickedButton)) return curr;
                return [...curr, clickedButton];
            });
            setTimeout(() => {
                setClickedButtons((curr) => {
                    if (!curr)
                        throw new Error("Problem in useEmotionalReport logic");
                    return curr.filter((emotion) => emotion !== clickedButton);
                });
            }, DURATION_OF_EMOTIONAL_REPORT_UI_FEEDBACK);
            setExercisesSinceLastReport(0);
        },
        
        recordValidatedExercise() {
            setExercisesSinceLastReport(exercisesSinceLastReport + 1);
        },
    };
};

export default useEmotionalReport;
