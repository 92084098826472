import { useContext, useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
import { Statement } from "@xapi/xapi";
import {
    BanditManchot,
    BanditManchotWhisperer,
    History,
} from "@evidenceb/bandit-manchot";
import { UserType } from "../interfaces/User";
import { sessionStore } from "../contexts/SessionContext";
import { dataStore } from "../contexts/DataContext";
import { configStore } from "../contexts/ConfigContext";
import useAthenaAPIClient from "./useAthenaAPIClient";
import useStatements from "./useStatements";
import {
    filterStatementsByModule,
    statementsToHistory,
} from "../utils/tracking";
import * as localStorage from "../utils/localStorage";
import { chooseStudentClassroom } from "../utils/user";
import {
    completeLockStatus,
    isBMWithLockStatus,
} from "../utils/pedagogical-ressources";

interface BanditManchotError {
    status: "error";
}

interface BanditManchotLoading {
    status: "loading";
}

export interface BanditManchotSuccess {
    status: "success";
    banditManchot: BanditManchot;
}

type UseBanditManchotReturn =
    | BanditManchotError
    | BanditManchotLoading
    | BanditManchotSuccess;

/**
 * A hook to use the bandit manchot that makes sure it is properly initialized
 */
const useBanditManchot = (): UseBanditManchotReturn => {
    const {
        session: {
            banditManchot,
            initialHistory,
            prLockStatus,
            userId,
            userType,
            flags: { useHistoryFrom },
        },
        setSession,
    } = useContext(sessionStore);
    const { data } = useContext(dataStore);
    const { config } = useContext(configStore);
    const athenaAPIClient = useAthenaAPIClient();
    const { getResultStatements } = useStatements();
    const [status, setStatus] = useState<UseBanditManchotReturn["status"]>(
        userType === UserType.Teacher ? "error" : "loading"
    );

    // Get lock status
    useEffect(() => {
        if (prLockStatus || status === "error") return;

        setSession((curr) => {
            return {
                ...curr,
                prLockStatus: {
                    status: "pending",
                },
            };
        });

        (async () => {
            try {
                const allClassroomsLockStatus =
                    await athenaAPIClient.getResourcesLockStatus(
                        userId,
                        UserType.Student
                    );
                const studentClassroomId = chooseStudentClassroom(
                    Object.keys(allClassroomsLockStatus)
                );

                setSession((curr) => {
                    return {
                        ...curr,
                        prLockStatus: {
                            status: "settled",
                            value: completeLockStatus(
                                allClassroomsLockStatus[studentClassroomId]
                            ),
                        },
                    };
                });
            } catch (err) {
                Sentry.captureException(err);
                setStatus("error");
                setSession((curr) => {
                    return {
                        ...curr,
                        prLockStatus: undefined,
                    };
                });
            }
        })();
    }, [prLockStatus, athenaAPIClient, setSession, status, userId]);

    // Get initial history
    useEffect(() => {
        if (initialHistory || status === "error") return;

        setSession((curr) => {
            return {
                ...curr,
                initialHistory: {
                    status: "pending",
                },
            };
        });
        (async () => {
            let statements: Statement[];
            if (useHistoryFrom === "LRS") {
                try {
                    statements = await getResultStatements();
                } catch (err) {
                    Sentry.captureException(err);
                    setStatus("error");
                    setSession((curr) => {
                        return {
                            ...curr,
                            initialHistory: undefined,
                        };
                    });
                    return;
                }
            } else {
                statements =
                    localStorage.getItem<Statement[]>(
                        localStorage.Key.LOCAL_HISTORY
                    ) ?? [];
            }
            const _initialHistory = data.modules.reduce((history, module) => {
                history[module.id] = statementsToHistory(
                    filterStatementsByModule(statements, module.id)
                );
                return history;
            }, {} as History);
            setSession((curr) => {
                return {
                    ...curr,
                    initialHistory: {
                        status: "settled",
                        value: _initialHistory,
                    },
                };
            });
        })();
    }, [
        initialHistory,
        useHistoryFrom,
        getResultStatements,
        data.modules,
        setSession,
        status,
    ]);

    // Init Bandit Manchot
    useEffect(() => {
        if (
            banditManchot ||
            !initialHistory ||
            initialHistory.status !== "settled" ||
            !prLockStatus ||
            prLockStatus.status !== "settled" ||
            status === "error"
        )
            return;

        (async () => {
            try {
                const freshBM = await BanditManchotWhisperer.initBanditManchot(
                    config.ai!,
                    data,
                    (err) => {
                        Sentry.captureException(err);
                    }
                );
                const loadedBM = BanditManchotWhisperer.loadAllModulesHistory(
                    freshBM,
                    initialHistory.value,
                    (err) => {
                        Sentry.captureException(err);
                    }
                );
                let withLockStatusBM: BanditManchot;
                if (isBMWithLockStatus(loadedBM)) {
                    withLockStatusBM =
                        BanditManchotWhisperer.deactivateResources(
                            loadedBM,
                            data,
                            prLockStatus.value
                        );
                }
                setSession((curr) => {
                    return {
                        ...curr,
                        banditManchot: withLockStatusBM ?? loadedBM,
                    };
                });
            } catch (err) {
                Sentry.captureException(err);
                setStatus("error");
            }
        })();
    }, [
        initialHistory,
        prLockStatus,
        banditManchot,
        config.ai,
        data,
        setSession,
        status,
    ]);

    // Mark as success
    useEffect(() => {
        if (!banditManchot || status === "error") return;

        setStatus("success");
    }, [banditManchot, status]);

    if (status !== "success") return { status };
    return { status, banditManchot: banditManchot! };
};

export default useBanditManchot;
