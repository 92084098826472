import React, { useContext } from "react";
import { configStore } from "../../../../contexts/ConfigContext";
import { UserType } from "../../../../interfaces/User";
import { getUrl } from "../../../../utils/dataRetrieval";
import { PlayerHeaderProps } from "../../PlayerBuilder";
import { BMPlaylistManager } from "../../useBanditManchotPlaylistManager";
import PlayerHeader from "../PlayerHeader";
import ProgressBar from "./ProgressBar/ProgressBar";
import SafeguardedLink from "./SafeguardedLink/SafeguardedLink";

const StudentHeader = ({
    playlistManager: { playlist },
    progression,
}: PlayerHeaderProps<BMPlaylistManager>) => {
    const { config } = useContext(configStore);

    return (
        <PlayerHeader>
            <SafeguardedLink
                /*TEMP find a more elegant way to get the url*/
                to={`/${getUrl(
                    config.pages.find((page) => page.type === "MODULELIST")!,
                    UserType.Student
                )}`}
                moduleId={playlist.module.id}
            >
                <div className="material-icons exit__icon" translate="no">
                    arrow_back
                </div>
                <div>{config.i18n.exerciseShell.close}</div>
            </SafeguardedLink>

            <div className="player-header__progression">
                <h2>{playlist.module.title.short}</h2>
                {!playlist.isInitialTest && (
                    <ProgressBar completion={progression!} />
                )}
            </div>

            <div>{/* For layout purposes */}</div>
        </PlayerHeader>
    );
};

export default StudentHeader;
