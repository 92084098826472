import React, { useContext } from 'react'

//Contexts
import { configStore } from '../../../../../../contexts/ConfigContext'

//SCSS
import "./StudentsSummary.scss";

//Interfaces
export interface StudentsSummaryProps {
        total: number;
        hasImproved: number;
        hasDifficulties: number;
}

const StudentsSummary = ({total, hasImproved, hasDifficulties}:StudentsSummaryProps) => {

    //Contexts
    const {config} = useContext(configStore);

    return (
        <div className="students-summary">
            <div className="total"> 
                <span className="number">{total}</span>
                <span className="label">{config.i18n.dashboard?.studentsTotal}</span>
            </div>
            <div className="has-improved">
                <span className="number">{hasImproved}</span>
                <span className="label">{config.i18n.dashboard?.progressImprovemet}</span>
            </div>
            <div className="has-difficulties">
                <span className="number">{hasDifficulties}</span>
                <span className="label">{config.i18n.dashboard?.progressRegression}</span>
            </div>
        </div>
    )
}

export default StudentsSummary
