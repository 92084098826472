import React from "react";
import VisuallyHidden from "../VisuallyHidden/VisuallyHidden";

import "./BackAndForth.scss";

interface BackAndForthProps<T> {
    valuesList: T[];
    onValueChange: (value: T) => void;
    currentValue: T;
    children: React.ReactNode;
    /**
     * True if iterating through the valuesList should be done in a loop
     * @default false
     */
    infinite?: boolean;
    /** Invisible text attached to the buttons for accessibility purposes */
    instructions: {
        back: string;
        forth: string;
    };
    className?: string;
}

/**
 * Generic component that allows the user to go back and forth within a list
 * thanks to 2 arrow buttons
 *
 * The component doesn't manage its own state, updating the current value should
 * be done in the parent component using the onValueChange prop
 */
function BackAndForth<T>({
    valuesList,
    onValueChange,
    currentValue,
    infinite = false,
    instructions,
    children,
    className
}: BackAndForthProps<T>) {
    return (
        <div className={`back-and-forth ${className ?? ""}`}>
            <button
                className="back-button"
                onClick={() => {
                    const valueIndex = valuesList.findIndex(
                        (value) => value === currentValue
                    );
                    if (valueIndex === -1)
                        throw new Error("currentValue not in valueList");
                    if (valueIndex === 0)
                        onValueChange(valuesList[valuesList.length - 1]);
                    else onValueChange(valuesList[valueIndex - 1]);
                }}
                disabled={
                    valuesList.length === 1 ||
                    (!infinite &&
                        valuesList.findIndex(
                            (value) => value === currentValue
                        ) === 0)
                }
            >
                <span className="material-icons" translate="no">arrow_back</span>
                <VisuallyHidden>{instructions.back}</VisuallyHidden>
            </button>

            {children}

            <button
                className="forth-button"
                onClick={() => {
                    const valueIndex = valuesList.findIndex(
                        (value) => value === currentValue
                    );
                    if (valueIndex === -1)
                        throw new Error("currentValue not in valueList");
                    if (valueIndex === valuesList.length - 1)
                        onValueChange(valuesList[0]);
                    else onValueChange(valuesList[valueIndex + 1]);
                }}
                disabled={
                    valuesList.length === 1 ||
                    (!infinite &&
                        valuesList.findIndex(
                            (value) => value === currentValue
                        ) ===
                            valuesList.length - 1)
                }
            >
                <span className="material-icons" translate="no">arrow_forward</span>
                <VisuallyHidden>{instructions.forth}</VisuallyHidden>
            </button>
        </div>
    );
}

export default BackAndForth;
