import React from "react";
import Modal from "../../pages/PlayerBuilder/PlaylistPlayer/PlayerModal/PlayerModal";


import "./BanditManchotErrorModal.scss";

interface Props {
    title: string | JSX.Element;
    description: string;
    primaryButton: {
        label: string;
        fn: () => void;
    };
    secondaryButton?: {
        label: string;
        fn: () => void;
    };
}

/**
 * Modal used to display error messages in the context of the student player
 */
const BanditManchotErrorModal = ({
    title,
    description,
    primaryButton,
    secondaryButton,
}: Props) => {
    return (
        <Modal
            onDismiss={
                /* Prevent modal from closing on background click */ () => {}
            }
        >
            <div className="bm-error-modal">
                {typeof title === "string" ? <h2>{title}</h2> : title}

                <p>{description}</p>

                <div>
                    <button
                        className="bm-error__primary-btn"
                        onClick={primaryButton.fn}
                    >
                        {primaryButton.label}
                    </button>

                    {secondaryButton && (
                        <button
                            className="bm-error__secondary-btn"
                            onClick={secondaryButton.fn}
                        >
                            {secondaryButton.label}
                        </button>
                    )}
                </div>
            </div>
        </Modal>
    );
};

export default BanditManchotErrorModal;
