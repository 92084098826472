import React, { useContext, useMemo } from "react";
import {
    InitializedPlaylistManager,
    Playlist,
    ShellOptions,
} from "@evidenceb/gameplay-interfaces";
import { Config } from "../../interfaces/Config";
import { UserType } from "../../interfaces/User";
import { sessionStore } from "../../contexts/SessionContext";
import { configStore } from "../../contexts/ConfigContext";
import { withBanditManchotPlaylistManager } from "./useBanditManchotPlaylistManager";
import { withSingleExercisePlaylistManager } from "./useSingleExercisePlaylistManager";
import { withTeacherPlaylistManager } from "./useTeacherPlaylistManager";
import StudentPlayerInfoPanel from "./InfoPanel/StudentPlayerInfoPanel/StudentPlayerInfoPanel";
import TeacherPlayerInfoPanel from "./InfoPanel/TeacherPlayerInfoPanel/TeacherPlayerInfoPanel";
import PlaylistPlayer from "./PlaylistPlayer/PlaylistPlayer";
import TeacherHeader from "./Header/TeacherHeader/TeacherHeader";
import StudentHeader from "./Header/StudentHeader/StudentHeader";
import EmptyHeader from "./Header/BreadcrumbHeader/BreadcrumbHeader";

export interface InfoPanelProps {
    playlist: Playlist;
}

export interface PlayerHeaderProps<
    PlaylistManager extends InitializedPlaylistManager
> {
    playlistManager: PlaylistManager;
    progression?: number;
}

type PlayerPath = "play" | "player";

const PlayerBuilder = () => {
    const {
        session: { userType },
    } = useContext(sessionStore);
    const {
        config: { features },
    } = useContext(configStore);

    const path = window.location.pathname.includes("player")
        ? "player"
        : "play";
    const PlaylistPlayer = useMemo(() => getPlaylistPlayer(userType, path), [userType, path]);
    const InfoPanel = useMemo(() => getInfoPanel(features, userType), [features, userType]);
    const Header = useMemo(() => getHeader(userType, path), [userType, path]);
    const opts = useMemo(() => getOpts(path), [path]);

    return <PlaylistPlayer InfoPanel={InfoPanel} Header={Header} opts={opts} />;
};

const getPlaylistPlayer = (userType: UserType, path: "play" | "player") => {
    if (path === "play")
        return withSingleExercisePlaylistManager(PlaylistPlayer);
    if (userType === UserType.Teacher)
        return withTeacherPlaylistManager(PlaylistPlayer);
    return withBanditManchotPlaylistManager(PlaylistPlayer);
};

const getInfoPanel = (
    features: Config["features"],
    userType: UserType,
): ((props: InfoPanelProps) => JSX.Element | null) | undefined => {
    if (features.studentChatbotPlayerInfoPanel && userType === UserType.Student)
        return StudentPlayerInfoPanel;
    if (userType === UserType.Teacher && features.teacherInfoPanel)
        return TeacherPlayerInfoPanel;
    return undefined;
};

const getHeader = (
    userType: UserType,
    path: PlayerPath
): ((props: PlayerHeaderProps<any>) => JSX.Element) => {
    if (path === "play") return EmptyHeader;
    if (userType === UserType.Teacher) return TeacherHeader;
    return StudentHeader;
};

const getOpts = (path: PlayerPath): ShellOptions | undefined => {
    if (path === "play") return { skipIntro: true };
    return undefined;
};

export default PlayerBuilder;
