import React, { useContext, useEffect, useRef, useState } from 'react'

//Contexts
import { configStore } from '../../../../../../contexts/ConfigContext';

//Hooks
import useClassroom from '../../../useClassroom';
import useAthenaAPIClient from '../../../../../../hooks/useAthenaAPIClient';

//Components
import Loader from '../../../../../../components/Loader/Loader';
import Settings, { Setting } from '../Settings/Settings';

//SCSS
import "./ClassroomName.scss";

//Interfaces
import { Classroom } from '../../../../../../interfaces/Dashboard';
interface ClassroomNameProps{
    id: string;
    name: string;
}

const ClassroomName = ({id, name}: ClassroomNameProps) => {
    //Refs
    const inputRef = useRef<HTMLInputElement>(null)
    
    //Contexts
    const { config } = useContext(configStore);
    const [className, setClassName] = useState<string>(name)
    
    //Variables
    const settings: Setting[] = [];
    if (config.features.classManagement)
        settings.push({
            label: config.i18n.dashboard!.renameclass,
            icon: "edit",
            action: () => setEditStatus('edit')
        })

    //Hooks
    const { updateDashboardData } = useClassroom();
    const athenaAPIClient = useAthenaAPIClient();

    //States
    const [editStatus, setEditStatus] = useState<"idle" | "edit" | "saving">("idle")

    //Functions
    const editClassroomName = async (id: string, name: string) => {
        // set state to "saving"
        setEditStatus("saving")

        // Construct body to send to the API
        const body = { name }

        try{
            //Update classroom data
            await athenaAPIClient.updateClassroom<Classroom>(id, body)
            try{
                //Update dashboard with the new data
                await updateDashboardData()
                // Set state to idle
                setEditStatus("idle")
            }catch(error){
                setEditStatus("idle")
            }
        }catch(error){
            setEditStatus("idle")
        }
       
    }

    const cancelEdit = () => {
        setClassName(name)
        setEditStatus('idle')
    }

    useEffect(() => {
        if(editStatus === "idle"){
            setClassName(name)
        }
        if(editStatus === "edit"){
            inputRef.current?.focus()
        }
    },[editStatus, name])

    return (
        <div className="classroom-name">
            {
                editStatus === "idle" &&
                    <div className="idle">
                        <h1><span className="name">{config.i18n.dashboard?.common.class} {name}</span></h1>
                        {
                            settings.length > 0 &&
                                <Settings label={config.i18n.dashboard?.settings} icon={'settings'} settings={settings} />
                        }
                    </div>
            }
            {
                editStatus === "edit" &&
                    <div className="edit">
                        <input ref={inputRef} type="text" value={className} onChange={ (e) => setClassName(e.currentTarget.value) } />
                        <button className="validate" onClick={ () => editClassroomName(id, className) }>
                            <span className="material-icons icon" translate="no"> check </span>
                        </button>
                        <button className="cancel" onClick={ cancelEdit }>
                            <span className="material-icons icon" translate="no"> close </span>
                        </button>
                    </div> 
            }
            {
                editStatus === "saving" &&
                    <div className="saving">
                        {config.i18n.dashboard?.saving}
                        <Loader />
                    </div>
            }
        </div>
    )
}

export default ClassroomName
