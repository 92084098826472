import React, { useContext, useRef } from 'react'

//External modules
import { AlertDialog } from "@reach/alert-dialog";

//Components
import Message from './Message/Message';
import Popup from './Popup/Popup';

//SCSS
import './ErrorModal.scss';

//Interfaces
import { IErrorModal } from '../../interfaces/Error';
import { errorStore } from '../../contexts/ErrorContext';

const ErrorModal = ({type, content, onClick}: IErrorModal) => {

    //Variables
    const cancelRef = useRef(null);
    
    //States
    const { setErrorInfo } = useContext(errorStore);

    return (
        <AlertDialog className="error-modal" leastDestructiveRef={cancelRef}>
            {
                type === "POPUP" ?
                    <Popup 
                        content={content} 
                        action={onClick} 
                        close={() => setErrorInfo({ displayModal: false})}
                    /> :
                    type === "NOTIFICATION" ?
                    <Message 
                        content={content}
                        action={onClick} 
                        close={() => setErrorInfo({ displayModal: false})} 
                    /> :
                null
            }
        </AlertDialog >
    )
}

export default ErrorModal
