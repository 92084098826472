export enum Key {
    "TOKEN" = "TOKEN",
    "VERSION" = "VERSION",
    "LOCAL_HISTORY" = "local-history",
    "CLIENT" = "client"
}

export const setItem = (key: Key, item: any): void => {
    localStorage.setItem(key, JSON.stringify(item));
};

export function getItem<T>(key: Key): T | undefined {
    const rawItem = localStorage.getItem(key);
    try {
        return rawItem ? (JSON.parse(rawItem) as T) : undefined;
    } catch {
        // @ts-ignore: when T is string
        return rawItem ?? undefined;
    }
}

export const removeItem = (key: Key): void => {
    localStorage.removeItem(key);
};

export const clear = (): void => {
    localStorage.clear();
}
