import React from "react";
import { parse } from "../../../../../utils/parse-text";
import { ContentWithTitle } from "@evidenceb/gameplay-interfaces";

import "./WizardFeedbackDisplay.scss";
import ImageAsset from "../../../../../components/ImageAsset/ImageAsset";

const WizardFeedbackDisplay: React.FC<
    ContentWithTitle & { isCorrect: boolean }
> = ({ isCorrect, title, text, image, imageAlt }) => {
    return (
        <div className="wizard-feedback-display">
            {typeof isCorrect !== "undefined" && (
                <div
                    className={`feedback_correctness ${
                        isCorrect ? "--correct" : " --incorrect"
                    }`}
                >
                    <div className="icon-container">
                        <span className="material-icons" translate="no">
                            {isCorrect
                                ? "sentiment_very_satisfied"
                                : "sentiment_very_dissatisfied"}
                        </span>
                    </div>
                </div>
            )}

            {title && (
                <p>
                    <span className={isCorrect ? "correct" : "incorrect"}>
                        {parse(title)}
                    </span>
                </p>
            )}

            <div className="feedback__content">
                {text && (
                    <div className="feedback__explanation">
                        <div className="content">{parse(text.$html)}</div>
                    </div>
                )}
                {image && (
                    <ImageAsset 
                        className="feedback__image"
                        src={image}
                        alt={imageAlt}
                    />
                )}
            </div>
        </div>
    );
};

export default WizardFeedbackDisplay;
