import * as Sentry from "@sentry/react";

//Evb Lib
import { VisibilityStatus } from "@evidenceb/gameplay-interfaces";

//Utils
import { clearLocalStorage, resetUser } from "./dev-tools";

//Interfaces
import { AuthMode, MicroServices } from "../interfaces/Config";
import { TokenPayload, User, UserType } from "../interfaces/User";
import { RawData } from "../interfaces/Data";

/**
 * Returns the set of endpoints that matches the first item in the msConfig file
 */
export const msConfigResolver = (
    config: MicroServices[],
    origin: string
): MicroServices => {
    const msConfig = config.find((endpointSets) => origin.includes(endpointSets.match));
    if(!msConfig)
        throw new Error() //TODO: Error message
    return msConfig
    
};

export function tokenResolver(urlToken: string | undefined, localStorageToken: string | undefined): string{
   
    if(urlToken)
        return urlToken
    if(localStorageToken)
        return localStorageToken
    throw new Error() //TODO: Error message
}

export function versionResolver(decodedToken: TokenPayload,  urlVersion: string | undefined, localStorageVersion: string | undefined): string{
    if (decodedToken.app !== "*" && decodedToken.version !== "*" && decodedToken.branch !=="*")
        return `${decodedToken.app}/${decodedToken.version}/${decodedToken.branch}`;
    if(urlVersion)
        return urlVersion
    if(localStorageVersion)
        return localStorageVersion 
    throw new Error() //TODO: Error message
}

/**
 * Determine whether signIn should be displayed
 */
export async function shouldSignIn(
    user: User,
    authMode: AuthMode, 
    userType: UserType, 
    provider: string,
    getClassroom: any): Promise<boolean> {

    function isBlank(str: string) {
        return (!str || /^\s*$/.test(str));
    }

    async function hasClassroom(classrooms: string[], provider: string){
        return classrooms.some(async classroomId => {
            const classroom = await getClassroom(classroomId);
            console.log(classroom)
            if (classroom.provider === provider){
                return true
            }
        })
    }

    switch (authMode) {
        case AuthMode.Register:
            if (isBlank(user.first_name) || isBlank(user.last_name))
                return true
            if (userType === UserType.Student && !(await hasClassroom(user.classrooms, provider)))
                return true
            return false
        case AuthMode.RegisterNameOnly:
            if (isBlank(user.first_name) || isBlank(user.last_name))
                return true
            return false
        case AuthMode.DirectAccess:
            return false
    }
}

/**
 * Remove Modules/Objectives/Activities/Exercises that are not visible (visibility set in Modules_config on github)
 */
export function removeNonVisible(data: RawData): RawData {

    function isVisible(object: { visibilityStatus: VisibilityStatus }): boolean {
        return object.visibilityStatus === VisibilityStatus.Visible;
    }
    
    function existsIn(elements: { id: string }[]) {
        const ids = new Set(elements.map((element) => element.id));
        return (id: string): boolean => ids.has(id);
    }

    const exercises = data.exercises.filter(isVisible);
    const existsInExercises = existsIn(exercises);
    const activities = data.activities.filter(isVisible).map((activity) => ({
        ...activity,
        exerciseIds: activity.exerciseIds.filter(existsInExercises),
    }));
    const existsInActivities = existsIn(activities);
    const objectives = data.objectives.filter(isVisible).map((objective) => ({
        ...objective,
        activityIds: objective.activityIds.filter(existsInActivities),
    }));
    const existsInObjectives = existsIn(objectives);
    const modules = data.modules.filter(isVisible).map((module) => ({
        ...module,
        objectiveIds: module.objectiveIds.filter(existsInObjectives),
    }));
    return { modules, objectives, activities, exercises };
}

/**
 * Dev tools registration
 */
export function registerDebugUtils(data: RawData, athenaAPIClient: any) {
    (window as any).DEBUG_UTILS = {
        getModuleById: (id: string) =>
            data.modules.find((module) => module.id === id),
        getObjectiveById: (id: string) =>
            data.objectives.find((objective) => objective.id === id),
        getActivityById: (id: string) =>
            data.activities.find((activity) => activity.id === id),
        getExerciseById: (id: string) =>
            data.exercises.find((exercise) => exercise.id === id),
        resetUser: (url:string, userType: string | null) => {
            Sentry.addBreadcrumb({
                category: "window.console",
                message: "Reset user",
                level: Sentry.Severity.Info,
            });
            //TODO: better handling of url parameter
            resetUser(url, userType, athenaAPIClient)
        },
        deleteClassroom: (id: string) => {
            Sentry.addBreadcrumb({
                category: "window.console",
                message: "Class deleted",
                level: Sentry.Severity.Info,
            });
            //TODO: better handling of url parameter
            athenaAPIClient.deleteClassroom(id)
        },
        clearLocalStorage: () => {
            Sentry.addBreadcrumb({
                category: "window.console",
                message: "Clear local storage",
                level: Sentry.Severity.Info,
            });
            clearLocalStorage()
        },
        submitUserFeedback(){
            var error = new Error('User Feedback Submitted');
            Sentry.captureException(error);
            Sentry.showReportDialog();
        }
    };
}