import React, { useContext } from "react";
import { configStore } from "../../contexts/ConfigContext";

import "./LockStatusLabel.scss";

interface Props {
    locked?: boolean;
    style?: React.CSSProperties;
    as?: "span" | "div";
}

const LockStatusLabel = ({ locked, style, as }: Props) => {
    const {
        config: { i18n },
    } = useContext(configStore);

    const Wrapper = as ?? "span";

    return (
        <Wrapper className="lock-status-label" style={style}>
            {i18n.prm.manager.listItem[locked ? "deactivated" : "activated"]}
        </Wrapper>
    );
};

export default LockStatusLabel;
