import React, { useCallback, useContext, useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
import { useIdleTimer, IdleTimerAPI } from "react-idle-timer";
import {
    ComingNext,
    Exercise,
    ExerciseResult,
    PlaylistExecutionStage,
    Hierarchy,
    HierarchyIds,
    Playlist,
    ActivityShell,
    InitializedPlaylistManager,
    PRLockStatus,
} from "@evidenceb/gameplay-interfaces";
import {
    BanditManchotWhisperer,
    History,
    HistoryItem,
    PlaylistProperties,
} from "@evidenceb/bandit-manchot";
import { getDescendants } from "@evidenceb/parametric-graph";
import { dataStore } from "../../contexts/DataContext";
import { sessionStore, Settled } from "../../contexts/SessionContext";
import {
    ImpossibleToRetrieveStatementsError,
    PlayerProgressionError,
    PlaylistManagerError,
} from "../../errors";
import useStatements from "../../hooks/useStatements";
import { getContext } from "../../utils/sentry";
import {
    getHierarchyFromHierarchyId,
    getModuleById,
    getRandomExercise,
} from "../../utils/dataRetrieval";
import { useParams } from "react-router";
import { configStore } from "../../contexts/ConfigContext";
import useBanditManchot, {
    BanditManchotSuccess,
} from "../../hooks/useBanditManchot";
import { isBMWithLockStatus } from "../../utils/pedagogical-ressources";
import { Data } from "../../interfaces/Data";
import { CaptureContext } from "@sentry/types";
import { PlaylistPlayerProps } from "./PlaylistPlayer/PlaylistPlayer";
import { SyncProvider } from "../../contexts/Sync";

const TIMEOUT = 5 * 60 * 1000;
const MAX_MODAL_DURATION_BEFORE_RESET = 30 * 1000;
/*
Here's a little illustration to help with understanding the idle timer

                totalTimeElapsed
 <-------------------------------------------------->
    totalActiveTime             totalIdleTime
 <-------------------> <---------------------------->
                          timeout       modal time
                       <-----------> <-------------->
|                     |             |                |
ex start         last active   modal shown          now

*/

/**
 * This is the Bandit Manchot playlist manager. It uses the Bandit Manchot AI to
 * determine which are the exercises that are played. A Bandot Manchot user
 * should:
 * - not be able to navigate freely between exercises
 * - be assigned their next exercise depending on their history by the Bandit
 *   Manchot AI (if the module is finished, the Bandit Manchot should give a
 *   random exercise)
 * - be made aware whether they are in the initial test or not and, in the
 *   context of the chatbot shell:
 * - start with a clean conversation (without their past responses) when picking
 *   up an already started module
 */

export interface BMPlaylistManager extends InitializedPlaylistManager {
    playlist: Playlist;
    clearHistory: () => void;
    showIdleModal: boolean;
    continueAfterIdle: () => void;
}

const useBanditManchotPlaylistManager = (
    moduleId: string
): BMPlaylistManager | { initialized: false; error?: PlaylistManagerError } => {
    const [initialized, setInitialized] = useState<boolean>(false);
    const [error, setError] = useState<ImpossibleToRetrieveStatementsError>();

    const { data } = useContext(dataStore);
    const {
        session: { initialHistory, prLockStatus },
    } = useContext(sessionStore);
    const {
        config: { ai },
    } = useContext(configStore);
    const { sendResult } = useStatements();
    const bmInfo = useBanditManchot();

    /**
     * Determines if the bandit manchot is in random mode. Random mode is used
     * when the user has already finished the module. Outside of random mode,
     * the user is shown an end of module poge when they finish the module. In
     * random mode, the end of module page is never shown.
     */
    const [randomExerciseMode, setRandomExerciseMode] =
        useState<boolean>(false);
    // History of exercises retrieved from the LRS and exercises completed during the session
    const [history, setHistory] = useState<HistoryItem[]>();
    // List of exercise hierarchy given by the bandit manchot for the current session
    const [hierarchyList, setHierarchyList] = useState<
        (Hierarchy & {
            isInitialTest: boolean;
        })[]
    >();
    // Index of the current exercise in the hierarchy list
    const [currentExerciseIndex, setCurrentExerciseIndex] = useState<number>(0);
    // List of exercise results for the current session
    // TODO: Refactor exerciseResult and history
    const [exerciseResults, setExerciseResults] = useState<
        ExerciseResult<any>[]
    >([]);
    const [currentExerciseResult, setCurrentExerciseResult] = useState<
        ExerciseResult<any> | undefined
    >(undefined);
    const [currentTry, setCurrentTry] = useState<number>(1);
    // Nature of the current step
    const [currentExecutionStage, setCurrentExecutionStage] =
        useState<PlaylistExecutionStage>(
            PlaylistExecutionStage.PlayingCurrentExercise
        );
    // Nature of the next step after the current exercise
    const [comingNext, setComingNext] = useState<ComingNext | undefined>(
        undefined
    );
    const [showIdleModal, setShowIdleModal] = useState<boolean>(false);
    const [forceGoToNext, setForceGoToNext] = useState<boolean>(false);

    const timer = useIdleTimer({
        timeout: TIMEOUT,
        startOnMount: true,
        onIdle: () => {
            setShowIdleModal(true);
        },
    });

    const goToNextExercise = useCallback(() => {
        if (!comingNext)
            throw new PlayerProgressionError(
                "goToNextExercise called before validating exercise"
            );

        if (comingNext === "retry") setCurrentTry((curr) => curr + 1);
        else setCurrentExerciseIndex((curr) => curr + 1);

        timer.reset();
        timer.start();
    }, [comingNext, timer]);

    // Force go to next when flag is set
    useEffect(() => {
        if (!forceGoToNext) return;
        goToNextExercise();
        setForceGoToNext(false);
    }, [forceGoToNext, goToNextExercise]);

    // Initialize Bandit manchot and playlist
    useEffect(() => {
        if (initialized || bmInfo.status !== "success") return;
        const moduleBM = bmInfo.banditManchot[moduleId];
        if (moduleBM.error) return;

        const initializePlaylist = (
            nextHierarchyIds: HierarchyIds & PlaylistProperties
        ): void => {
            setHierarchyList([
                {
                    ...getHierarchyFromHierarchyId(nextHierarchyIds, data),
                    isInitialTest: nextHierarchyIds.isInitialTest,
                },
            ]);
            // If at the begining the module is already finished, enter random mode
            if (nextHierarchyIds.isModuleFinished) setRandomExerciseMode(true);
        };

        const initializeManager = (initialHistory: HistoryItem[]) => {
            setHistory(initialHistory);
            try {
                const nextHierarchyIds =
                    BanditManchotWhisperer.getNextHierarchyId(
                        moduleBM.instance
                    );
                initializePlaylist(
                    nextHierarchyIds || {
                        ...getRandomExercise(data, moduleId),
                        isInitialTest: false,
                        isModuleFinished: true, // Make as if random mode was entered if the BM fails
                    }
                );
            } catch (err) {
                Sentry.captureException(
                    err,
                    getContext({
                        "Bandit Manchot": {
                            moduleId,
                            history: initialHistory,
                        },
                    })
                );
            }
        };

        setHistory((initialHistory as Settled<History>).value![moduleId]);
        initializeManager(
            (initialHistory as Settled<History>).value![moduleId]
        );
        setInitialized(true);
    }, [initialized, bmInfo, moduleId, data, initialHistory]);

    // Set error
    useEffect(() => {
        if (
            bmInfo.status === "error" ||
            (bmInfo.status === "success" &&
                bmInfo.banditManchot[moduleId].error)
        )
            setError(new ImpossibleToRetrieveStatementsError());
    }, [bmInfo, moduleId]);

    // Reinit current exercise related information when current exercise
    // changes or another try starts
    useEffect(() => {
        setComingNext(undefined);
        setCurrentExerciseResult(undefined);
        setCurrentExecutionStage(PlaylistExecutionStage.PlayingCurrentExercise);
    }, [currentExerciseIndex, currentTry]);

    // Reinit current try when current exercise changes
    useEffect(() => {
        setCurrentTry(1);
    }, [currentExerciseIndex]);

    if (!initialized) return { initialized, error };

    return {
        initialized,

        playlist: {
            module: getModuleById(moduleId, data),
            objective: hierarchyList![currentExerciseIndex]
                ? hierarchyList![currentExerciseIndex].objective
                : undefined,
            activity: hierarchyList![currentExerciseIndex]
                ? hierarchyList![currentExerciseIndex].activity
                : undefined,
            exercises: hierarchyList!.map((hierarchy) => hierarchy.exercise),
            currentExercise: hierarchyList![currentExerciseIndex]
                ? hierarchyList![currentExerciseIndex].exercise
                : undefined,
            currentTry,
            currentExerciseResult,
            isInitialTest: hierarchyList![currentExerciseIndex]
                ? hierarchyList![currentExerciseIndex].isInitialTest
                : undefined,
            comingNext,
            exerciseResults,
            currentExecutionStage,
        },

        recordCurrentExerciseResult: (partialExerciseResult, autoGoToNext) => {
            const exerciseResult: ExerciseResult<any> = {
                ...partialExerciseResult,
                exerciseId: hierarchyList![currentExerciseIndex].exercise.id,
                try: currentTry,
                feedback:
                    hierarchyList![currentExerciseIndex].exercise.feedback[
                        currentTry - 1
                    ][partialExerciseResult.correct ? "correct" : "incorrect"],
                activityId: hierarchyList![currentExerciseIndex].activity!.id,
            };
            setCurrentExerciseResult(exerciseResult);
            setExerciseResults((curr) => [...curr, exerciseResult]);

            setCurrentExecutionStage(
                PlaylistExecutionStage.ShowingCurrentExerciseResultFeedback
            );

            if (
                isRetryNext(
                    exerciseResult,
                    currentTry,
                    hierarchyList![currentExerciseIndex].exercise
                )
            ) {
                setComingNext("retry");
                return;
            }

            // Update history
            const historyItem = {
                exerciseId: hierarchyList![currentExerciseIndex].exercise.id,
                activityId: hierarchyList![currentExerciseIndex].activity.id,
                objectiveId: hierarchyList![currentExerciseIndex].objective.id,
                score: partialExerciseResult.score
                    ? partialExerciseResult.score / currentTry
                    : partialExerciseResult.correct
                    ? 1 / currentTry
                    : 0,
            };
            const duration = timer.getElapsedTime();
            sendResult({
                ...historyItem,
                moduleId,
                isInitialTest:
                    hierarchyList![currentExerciseIndex].isInitialTest,
                answer: partialExerciseResult.answer,
                duration,
                success: partialExerciseResult.correct,
            });
            setHistory((curr) => [...curr!, historyItem]);

            // Get next exercise
            let newHierarchyIds: HierarchyIds & PlaylistProperties;
            try {
                newHierarchyIds =
                    BanditManchotWhisperer.updateHistoryAndGetNextHierarchyIds(
                        (bmInfo as BanditManchotSuccess).banditManchot[moduleId]
                            .instance!,
                        historyItem
                    );
                if (
                    !newHierarchyIds.isModuleFinished && isBMWithLockStatus(
                        (bmInfo as BanditManchotSuccess).banditManchot
                    )
                )
                    checkNewHierarchyConformity(
                        newHierarchyIds,
                        (prLockStatus as Settled<PRLockStatus>).value,
                        data,
                        (bmInfo as BanditManchotSuccess).banditManchot[moduleId]
                            .graph,
                        ai!.id
                    );
            } catch (err) {
                Sentry.captureException(
                    err,
                    getContext({ "Bandit Manchot": { moduleId, history } })
                );
                newHierarchyIds = {
                    ...getRandomExercise(data, moduleId),
                    isInitialTest: false,
                    isModuleFinished: false,
                };
            }

            const whatsComingNext = getWhatsComingNext(
                currentExerciseIndex,
                hierarchyList!.map((hierarchy) => hierarchy.exercise),
                currentTry,
                exerciseResult,
                newHierarchyIds,
                randomExerciseMode
            );
            setComingNext(whatsComingNext);

            if (whatsComingNext === "endOfPlaylist") return;

            setHierarchyList((curr) => [
                ...curr!,
                {
                    ...getHierarchyFromHierarchyId(newHierarchyIds, data),
                    isInitialTest: newHierarchyIds.isInitialTest,
                },
            ]);

            // Show end of initial test message when needed
            if (
                hierarchyList![currentExerciseIndex].isInitialTest &&
                !newHierarchyIds.isInitialTest
            ) {
                if (
                    hierarchyList![currentExerciseIndex].activity.shell ===
                    ActivityShell.Chatbot
                )
                    // Timeout to show message after chatbot thinking animation delay
                    setTimeout(() => {
                        setCurrentExecutionStage(
                            PlaylistExecutionStage.ShowingEndOfInitialTestMessage
                        );
                    }, 1000);
                else
                    setCurrentExecutionStage(
                        PlaylistExecutionStage.ShowingEndOfInitialTestMessage
                    );
            }

            if (whatsComingNext === "nextExercise" && autoGoToNext)
                setForceGoToNext(true);
        },

        goToNextExercise,

        goToExercise: () => {
            throw new PlayerProgressionError(
                "Students cannot navigate to a specific exercise"
            );
        },

        clearHistory: () => {
            setExerciseResults([]);
        },

        showIdleModal,

        continueAfterIdle: () => {
            const idleModalTime = getModalTime(timer);
            if (idleModalTime > MAX_MODAL_DURATION_BEFORE_RESET) {
                timer.reset();
                timer.start();
            } else {
                timer.resume();
            }
            setShowIdleModal(false);
        },
    };
};

const getWhatsComingNext = (
    currentExerciseIndex: number,
    exerciseList: Exercise<any, any>[],
    currentTry: number,
    exerciseResult: ExerciseResult<any>,
    nextHierarchyIds: HierarchyIds & PlaylistProperties,
    randomExerciseMode: boolean
): InitializedPlaylistManager["playlist"]["comingNext"] => {
    if (!exerciseResult) return undefined;

    if (
        isRetryNext(
            exerciseResult,
            currentTry,
            exerciseList[currentExerciseIndex]
        )
    )
        return "retry";

    if (nextHierarchyIds.isModuleFinished && !randomExerciseMode)
        return "endOfPlaylist";

    return "nextExercise";
};

const isRetryNext = (
    exerciseResult: ExerciseResult<any>,
    currentTry: number,
    exercise: Exercise<any, any>
): boolean => {
    // For backwards compatibility
    const numberOfTries =
        exercise.executionOptions?.numberOfTries || exercise?.numberOfTries;

    if (!exerciseResult.correct && currentTry < numberOfTries!) return true;
    return false;
};

/**
 * Determines how long the idle modal has been up
 */
const getModalTime = (timer: IdleTimerAPI): number => {
    return timer.getTotalIdleTime() - TIMEOUT;
};

export default useBanditManchotPlaylistManager;

const checkNewHierarchyConformity = (
    hierarchyId: HierarchyIds,
    prLockStatus: PRLockStatus,
    data: Data,
    graph: any,
    bmID: string
): void => {
    const module = getModuleById(hierarchyId.moduleId, data);
    const lockedObjInModule = prLockStatus.objectiveIds.filter((objID) =>
        module.objectiveIds.includes(objID)
    );
    const descendants = lockedObjInModule
        .map((objID) => getDescendants(graph, objID))
        .flat();
    if (
        prLockStatus.moduleIds.includes(hierarchyId.moduleId) ||
        [...lockedObjInModule, ...descendants].includes(hierarchyId.objectiveId)
    ) {
        console.log("Bandit Manchot was bad");
        Sentry.captureException(
            "Bandit Manchot suggested exercise from locked resource",
            {
                contexts: {
                    "Bandit Manchot": {
                        prLockStatus,
                        hierarchyId,
                        bmID,
                    },
                },
            } as CaptureContext
        );
    }
};

/**
 * HOC that inject the teacher playlist manager in the passed component
 */
export const withBanditManchotPlaylistManager: (
    WrappedComponent: (props: PlaylistPlayerProps) => JSX.Element
) => ({
    InfoPanel,
}: Omit<PlaylistPlayerProps, "playlistManager">) => JSX.Element = (
    WrappedComponent
) => {
    return (props) => {
        const { moduleId } = useParams<{ moduleId: string }>();
        const playlistManager = useBanditManchotPlaylistManager(moduleId);

        return (
            <SyncProvider>
                <WrappedComponent
                    playlistManager={playlistManager}
                    {...props}
                />
            </SyncProvider>
        );
    };
};
