import React, { useContext } from "react";
import { configStore } from "../../contexts/ConfigContext";
import Icon, { IconProps } from "../Icon/Icon";

import "./Chip.scss";

export interface ChipProps {
    /** @default light */
    colorMode?: "dark" | "light";
    /** @default neutral */
    type?: "sup" | "inf" | "conf" | "neutral";
    icon?: Omit<IconProps, "size">;
    label?: string;
    input: string;
}

const Chip = ({
    icon,
    label,
    input,
    className,
    colorMode = "light",
    type = "neutral",
    ...props
}: Omit<
    React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLDivElement>,
        HTMLDivElement
    >,
    "children"
> &
    ChipProps) => {
    const {
        config: { i18n },
    } = useContext(configStore);

    return (
        <div
            {...props}
            className={`ds-chip ds-chip--${type} ds-chip--${colorMode}-bg ${
                className ?? ""
            }`}
        >
            {type !== "neutral" && (
                <Icon
                    path={getIconPath(type)}
                    size={24}
                    className="ds-chip__set-type-icon"
                />
            )}
            {type === "neutral" && icon && <Icon {...icon} size="small" />}

            <p>
                {label && (
                    <span className="ds-chip__label">
                        {label}
                        {i18n.misc.colon}
                    </span>
                )}
                <strong>{input}</strong>
            </p>
        </div>
    );
};

const getIconPath = (type: "conf" | "sup" | "inf"): string => {
    switch (type) {
        case "conf":
            return "conf_3d";
        case "sup":
            return "sup_3d";
        case "inf":
            return "inf_3d";
        default:
            throw new Error(`Icon path not configure for type ${type} in Chip`);
    }
};

export default Chip;
