import React, { useContext } from "react";
import {
    MinimalDataItem,
    PRHierarchy,
    PRItemStatus,
    PRTree,
} from "@evidenceb/gameplay-interfaces";
import { Config } from "../../../../../interfaces/Config";
import { configStore } from "../../../../../contexts/ConfigContext";
import { UsePRConfirmationReturn } from "../../usePRConfirmation";
import { UsePRManagerReturn } from "../../usePRManager";
import { prHierarchysToHierarchy } from "../../../../../utils/hierarchyTranslator";
import Toggle from "../../../../../components/Toggle/Toggle";
import { LevelData } from "../../../PedagogicalResourcesManagement";

interface Props {
    item: MinimalDataItem;
    itemStatus: PRItemStatus;
    level: LevelData;
    attemptToggle: UsePRConfirmationReturn["attemptToggle"];
    prLockStatus: Required<UsePRManagerReturn>["currentPRLockStatus"];
    prTree: PRTree;
}

const ToggleItemControls = ({
    attemptToggle,
    itemStatus,
    item,
    prLockStatus,
    prTree,
    level,
}: Props) => {
    const {
        config: { i18n },
    } = useContext(configStore);

    return (
        <div className="list-item__toggle-container">
            <Toggle
                isSelected={itemStatus.status === "unlocked"}
                onChange={() => {
                    attemptToggle(item.id, prLockStatus, prTree, level);
                }}
                accessibleLabel={getToggleAccessibleLabel(
                    itemStatus.status,
                    i18n,
                    level.hierarchy,
                    item.title.long
                )}
                pinIcon={itemStatus.status === "unlocked" ? "done" : "close"}
            />
            <span className="toggle__state">
                {getToggleText(itemStatus.status, i18n)}
            </span>
        </div>
    );
};

const getToggleAccessibleLabel = (
    status: PRItemStatus["status"],
    i18n: Config["i18n"],
    hierarchy: PRHierarchy,
    title: string
): string => {
    let actionText: string;
    switch (status) {
        case "descendent":
        case "locked":
            actionText = i18n.prm.manager.listItem.accessibleActivate;
            break;
        case "unlocked":
            actionText = i18n.prm.manager.listItem.accessibleDeactivate;
            break;
    }
    return (
        actionText +
        i18n.hierarchy[prHierarchysToHierarchy(hierarchy)].full +
        " " +
        title
    );
};

export const getToggleText = (
    status: PRItemStatus["status"],
    i18n: Config["i18n"]
): string => {
    switch (status) {
        case "descendent":
        case "locked":
            return i18n.prm.manager.listItem.deactivated;
        case "unlocked":
            return i18n.prm.manager.listItem.activated;
    }
};

export default ToggleItemControls;
