import { useContext } from 'react';

//External modules

//Utils

//Interfaces
import { Dashboard } from '../../../interfaces/Dashboard';

//Contexts
import { dashboardStore } from '../../../contexts/DashboardContext';
import { configStore } from '../../../contexts/ConfigContext';
import { sessionStore } from '../../../contexts/SessionContext';import useAthenaAPIClient from '../../../hooks/useAthenaAPIClient';
;

export default function useClassroom() {

    //Contexts
    const { config } = useContext(configStore);
    const { session } = useContext(sessionStore);
    const { setDashboard } = useContext(dashboardStore);
    const athenaAPIClient = useAthenaAPIClient();
    //states
   
    //Functions
    const updateDashboardData = async() => {
        // GET Analytics data to update dashboard context
        // TODO: improve this when Analytics will be refactored, it's too heavy on performance at the moment
        const dashboard = await athenaAPIClient.getTeacherDashboard<Dashboard>(
            config.features.clustering, 
            session.userId, 
            config.declinaison)

        // Update dashboard data
        setDashboard(dashboard)
    }

    const getUrl = (
        type: "classroomList" | "classroom" | "progressionOverview" | "viewGroups", 
        classroomId?: string, 
        moduleId?: string
    ) => {
        if(type === "classroomList")
            return `/${config.i18n.dashboard?.paths.dashboard}/${config.i18n.dashboard?.paths.classes}`
        if(type === "classroom")
            return `/${config.i18n.dashboard?.paths.dashboard}/${config.i18n.dashboard?.paths.classes}/${classroomId}`
        if(type === "progressionOverview")
            return `/${config.i18n.dashboard?.paths.dashboard}/${config.i18n.dashboard?.paths.classes}/${config.i18n.dashboard?.paths.progression}/${classroomId}/${moduleId}`
        if(type === "viewGroups")
            return `/${config.i18n.dashboard?.paths.dashboard}/${config.i18n.dashboard?.paths.classes}/${classroomId}/${moduleId}/${config.i18n.dashboard?.paths.groups}`
        return ''
    }

    return {
        updateDashboardData,
        getUrl
    }
}

