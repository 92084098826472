import React, { useRef } from "react";
import { ToggleProps } from "@react-types/checkbox";
import { useToggleState } from "@react-stately/toggle";
import { useToggleButton } from "@react-aria/button";
import VisuallyHidden from "../VisuallyHidden/VisuallyHidden";

// Reference for building an accessible toggle component with react-aria:
// https://react-spectrum.adobe.com/react-aria/useToggleButton.html

import "./Toggle.scss";

// Force controlled usage by making properties required
interface TogglePropsOverrides {
    children?: null;
    isSelected: boolean;
    onChange: (isSelected: boolean) => void;
}

interface AdditionalProps {
    /**
     * An invisible label used by screenreader user to determine the nature of
     * the action
     */
    accessibleLabel: JSX.Element | JSX.Element[] | string;
    /**
     * Name of the material icon to include in the pin
     */
    pinIcon?: string;
}

/**
 * Accessible toggle component
 */
const Toggle = ({
    accessibleLabel,
    pinIcon,
    ...props
}: ToggleProps & TogglePropsOverrides & AdditionalProps) => {
    const ref = useRef();
    const state = useToggleState(props);
    const { buttonProps } = useToggleButton(props, state, ref as any);

    return (
        <button
            {...buttonProps}
            ref={ref}
            className={`toggle --${
                props.isSelected ? "selected" : "unselected"
            }`}
        >
            <div className="toggle__pin" aria-hidden="true">
                {pinIcon && (
                    <span className="material-icons" translate="no">
                        {pinIcon}
                    </span>
                )}
            </div>
            <VisuallyHidden>{accessibleLabel}</VisuallyHidden>
        </button>
    );
};

export default Toggle;
