import React from "react";
import Dialog, { DialogProps } from "@reach/dialog";

import "./LayerModal.scss";

interface LayerModalAdditionalProps {
    /**
     * The two children should be a LayerModalBackdrop and a LayerModalMain
     */
    children: [React.ReactNode, React.ReactNode];
    className?: string;
}

export type LayerModalProps = DialogProps & LayerModalAdditionalProps;

export const LayerModal = ({
    children,
    className,
    ...props
}: LayerModalProps) => {
    return (
        <Dialog {...props} className={`layer-modal ${className ?? ""}`}>
            <div className="layer-modal__inner">{children}</div>
        </Dialog>
    );
};

interface LayerModalBackdropProps {
    children: React.ReactNode;
}

export const LayerModalBackdrop = ({ children }: LayerModalBackdropProps) => {
    return <div className="layer-modal__backdrop">{children}</div>;
};

interface LayerModalMainProps {
    children: React.ReactNode;
}

export const LayerModalMain = ({ children }: LayerModalMainProps) => {
    return <div className="layer-modal__main">{children}</div>;
};
