import React from "react";

import "./ErrorNotification.scss";

export enum NotificationType {
    Success = "success",
    Failure = "failure",
    Neutral = "neutral",
}

interface Props {
    onDismiss?: () => void;
    type?: NotificationType;
    children: JSX.Element | JSX.Element[] | null;
}

/**
 * Notification component. The component doesn't manage its own state, so the
 * onDismiss prop is provided for notification that can be dismissed. The type
 * props defaults to failure.
 */
const ErrorNotification = ({
    children,
    onDismiss,
    type = NotificationType.Failure,
}: Props) => {
    return (
        <div className={`error-notification --${type}`}>
            {children}

            {onDismiss && (
                <button
                    className="error-notification__dismiss-button"
                    onClick={onDismiss}
                >
                    <span className="material-icons" translate="no">
                        close
                    </span>
                </button>
            )}
        </div>
    );
};

export default ErrorNotification;
