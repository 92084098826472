export interface Theme {
    baseFontSize: number;
    colorPrimary: string;
    colorPrimaryLight: string;
    colorSecondary: string;
    colorCorrect: string;
    colorError: string;
    colorAlert: string;
    contentPagesStyle: string;
    chatbot?: {
        backgroundColorCorrect: string,
        backgroundColorIncorrect: string,
        botBubbleColor: string;
        botBubbleColorCorrect: string;
        botBubbleColorFeedback: string;
        botBubbleColorIncorrect: string;
        disabledBg: string;
        disabledText: string;
        textCorrect: string;
        textIncorrect: string;
        userBubbleColorInteractive: string;
        userBubbleColorNonInteractive: string;
        validateBg: string;
    };
}

function addContentPageCss(customCss: string) {
    const style = document.createElement("style");
    style.appendChild(document.createTextNode(customCss));
    document.head.appendChild(style);
}

function injectCss(css: string) {
    const style = document.createElement("style");
    // The following line is required for this to work on WebKit
    style.appendChild(document.createTextNode(""));
    document.head.appendChild(style);
    style.sheet!.insertRule(css, 0);
}

export function applyTheme(theme: Theme) {
    injectCss(`
        :root {
            --base-font-size:${theme.baseFontSize}px;
            --color-primary: ${theme.colorPrimary};
            --color-primary-light: ${theme.colorPrimaryLight};
            --color-secondary: ${theme.colorSecondary};
            --color-correct: ${theme.colorCorrect};
            --color-incorrect: ${theme.colorError};
            --color-alert: ${theme.colorAlert};


            /* Typographical chart for gameplays */
            --color-editor-one: #CA411D;
            --color-editor-two: #155598;

                        ${
                            theme.chatbot
                                ? `
                --chatbot-color-bot-bubble: ${theme.chatbot.botBubbleColor};
                --chatbot-color-bot-bubble-opacity-60: ${theme.chatbot.botBubbleColor}60;
                --chatbot-color-bot-bubble-correct: ${theme.chatbot.botBubbleColorCorrect};
                --chatbot-color-bot-bubble-incorrect: ${theme.chatbot.botBubbleColorIncorrect};
                --chatbot-color-bot-text-correct: ${theme.chatbot.textCorrect};
                --chatbot-color-bot-text-incorrect: ${theme.chatbot.textIncorrect};
                --chatbot-color-background-correct: ${theme.chatbot.backgroundColorCorrect};
                --chatbot-color-background-incorrect: ${theme.chatbot.backgroundColorIncorrect};
                --chatbot-color-bot-bubble-feedback: ${theme.chatbot.botBubbleColorFeedback};
                --chatbot-color-user-bubble-interactive: ${theme.chatbot.userBubbleColorInteractive};
                --chatbot-color-user-bubble-non-interactive: ${theme.chatbot.userBubbleColorNonInteractive};
                --chatbot-color-disabled-bg: ${theme.chatbot.disabledBg};
                --chatbot-color-disabled-txt: ${theme.chatbot.disabledText};
                --chatbot-color-validate-btn: ${theme.chatbot.validateBg};
            `
                                : ""
                        }
        }
    `);
    addContentPageCss(theme.contentPagesStyle);
}

export default applyTheme;
