import React, { useContext } from 'react'

//External modules
import { Link } from 'react-router-dom';

//Contexts
import { configStore } from '../../../../../../contexts/ConfigContext'

//Components
import StudentsSummary from '../StudentsSummary/StudentsSummary';

//SCSS
import "./StudentsOverview.scss";

//Interfaces
import { StudentInDifficulty } from '../../SingleClassroom'
interface StudentsOverviewProps {
    summary: {
        total: number;
        hasImproved: number;
        hasDifficulties: number;
    };
    students: StudentInDifficulty[];
    classroomId: string;
}

const StudentsOverview = ({ summary, students, classroomId }: StudentsOverviewProps) => {

    //Contexts
    const { config } = useContext(configStore)

    //Functions
    const getStudentUrl = (studentId: string) => {
        return `/${config.i18n.dashboard?.paths.dashboard}/${config.i18n.dashboard?.paths.student}/${classroomId}/${studentId}`
    }

    return (
        <div className="students-overview">
            
            <h2>{config.i18n.dashboard?.studentsOverview}</h2>

            <StudentsSummary {...summary} />

            <div className="students-in-difficulty">
                <header>
                    <span>{config.i18n.dashboard?.common.students}</span>
                    <span>{config.i18n.dashboard?.classDetails.titleStudentInDifficulty}</span>
                </header>
                <div className="table">
                    <div className="tr">
                        <div className="th type">{config.i18n.dashboard?.progressionType}</div>
                        <div className="th name">{config.i18n.dashboard?.name}</div>
                        <div className="th total-progression">{config.i18n.dashboard?.progressionTotal}</div>
                    </div>
                    {
                        students.length ?
                        students.map(student =>
                            <div className="tr" key={student.id}>
                                <Link to={getStudentUrl(student.id)}>
                                    <div className="td type"></div>
                                    <div className="td name">
                                        <span>
                                            {student.firstname} {student.lastname}
                                        </span>
                                    </div>
                                    <div className="td total-progression">
                                        <span className="percent">
                                            {(student.progression).toFixed(1)}%
                                            <span className="icon material-icons" translate="no">chevron_right</span>
                                        </span>
                                        <span className="link-label">
                                            {config.i18n.dashboard?.common.seeProfile}
                                            <span className="icon material-icons" translate="no">chevron_right</span>
                                        </span>
                                    </div>
                                </Link>
                            </div>
                        )
                        : <div className="no-students">{config.i18n.dashboard?.noStudentsInDifficulty}</div>
                    }
                </div>
            </div>
        </div>
    )
}

export default StudentsOverview
