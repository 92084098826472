import { useContext, useMemo } from "react";
import { Statement } from "@xapi/xapi";
import { TokenPayload, UserType } from "../interfaces/User";
import { axiosClient } from "../utils/axios-client";
import * as localStorage from "../utils/localStorage";
import { configStore } from "../contexts/ConfigContext";
import { errorStore } from "../contexts/ErrorContext";
import { completeLockStatus } from "../utils/pedagogical-ressources";
import { PRLockStatus } from "@evidenceb/gameplay-interfaces";

const useAthenaAPIClient = () => {
    // const error5xx = /(40[0-9]|4[1-9][0-9])/g;

    const {
        config: { apiUrls, i18n },
    } = useContext(configStore);
    const { setErrorInfo } = useContext(errorStore);

    // const captureError5xx = (error: any) => {
    //     let status = error.response.status.toString();
    //     if (status.match(error5xx)) {
    //         Sentry.captureException(error);
    //     }
    // };

    const athenaAPIClient = useMemo(() => {
        const handleTokenError = (error: any) => {
            if (
                error?.response?.data?.detail?.message ===
                "User session was invalidated."
            ) {
                setErrorInfo((err) => {
                    return {
                        ...err,
                        page: { code: "token" },
                    };
                });
                return true;
            }
            return false;
        };

        return {
            handleTokenError,
            getTokenPayload: (): Promise<TokenPayload> => {
                let headers = {
                    Authorization:
                        "Bearer " +
                        localStorage.getItem<string>(localStorage.Key.TOKEN),
                };
                return axiosClient.axios
                    .get(apiUrls.endpoints.users + "/user-info/", {
                        headers: headers,
                    })
                    .then((response) => {
                        return response.data as TokenPayload;
                    })
                    .catch((error) => {
                        setErrorInfo((err) => {
                            return {
                                ...err,
                                page: { code: "token" },
                            };
                        });
                        console.log(error);
                        throw error;
                    });
            },

            getUser<T>(tokenPayload: TokenPayload): Promise<T> {
                let headers = {
                    Authorization:
                        "Bearer " +
                        localStorage.getItem<string>(localStorage.Key.TOKEN),
                };
                return axiosClient.axios
                    .get(
                        apiUrls.endpoints.users +
                            "/api/" +
                            tokenPayload.role +
                            "s/" +
                            tokenPayload.sub +
                            "/",
                        {
                            headers: headers,
                        }
                    )
                    .then((response) => {
                        return response.data as Promise<T>;
                    })
                    .catch((error) => {
                        setErrorInfo((err) => {
                            return {
                                ...err,
                                page: { code: "token" },
                            };
                        });
                        console.log(error);
                        throw error;
                    });
            },

            getStudent<T>(userId: string): Promise<T> {
                let headers = {
                    Authorization:
                        "Bearer " +
                        localStorage.getItem<string>(localStorage.Key.TOKEN),
                };
                return axiosClient.axios
                    .get(
                        apiUrls.endpoints.users +
                            "/api/students/" +
                            userId +
                            "/",
                        {
                            headers: headers,
                        }
                    )
                    .then((response) => {
                        return response.data as Promise<T>;
                    })
                    .catch((error) => {
                        if (handleTokenError(error)) throw error;
                        console.log(error);
                        return error;
                    });
            },

            getTeacher<T>(userId: string): Promise<T> {
                let headers = {
                    Authorization:
                        "Bearer " +
                        localStorage.getItem<string>(localStorage.Key.TOKEN),
                };
                return axiosClient.axios
                    .get(
                        apiUrls.endpoints.users +
                            "/api/teachers/" +
                            userId +
                            "/",
                        {
                            headers: headers,
                        }
                    )
                    .then((response) => {
                        return response.data as Promise<T>;
                    })
                    .catch((error) => {
                        if (handleTokenError(error)) throw error;
                        console.log(error);
                        return error;
                    });
            },

            updateUser<T>(
                type: string,
                userId: string,
                data: object
            ): Promise<T> {
                let headers = {
                    Authorization:
                        "Bearer " +
                        localStorage.getItem<string>(localStorage.Key.TOKEN),
                    "Content-Type": "application/json",
                };
                return axiosClient.axios
                    .patch(
                        apiUrls.endpoints.users +
                            "/api/" +
                            type +
                            "s/" +
                            userId +
                            "/",
                        {
                            headers: headers,
                            ...data,
                        }
                    )
                    .then((response) => {
                        return response.data as Promise<T>;
                    })
                    .catch((error) => {
                        if (handleTokenError(error)) throw error;
                        console.log(error);
                        return error;
                    });
            },

            getClassroom<T>(classroomId: string): Promise<T> {
                let headers = {
                    Authorization:
                        "Bearer " +
                        localStorage.getItem<string>(localStorage.Key.TOKEN),
                };
                return axiosClient.axios
                    .get(
                        apiUrls.endpoints.users +
                            "/api/classrooms/" +
                            classroomId +
                            "/",
                        {
                            headers: headers,
                        }
                    )
                    .then((response) => {
                        return response.data as Promise<T>;
                    })
                    .catch((error) => {
                        if (handleTokenError(error)) throw error;
                        console.log(error);
                        return error;
                    });
            },

            getAllClassrooms<T>(
                provider: string,
                externalId: string
            ): Promise<T> {
                let headers = {
                    Authorization:
                        "Bearer " +
                        localStorage.getItem<string>(localStorage.Key.TOKEN),
                };
                let params = `?provider=${provider}&external_id=${externalId}`;
                return axiosClient.axios
                    .get(
                        apiUrls.endpoints.users + "/api/classrooms/" + params,
                        {
                            headers: headers,
                        }
                    )
                    .then((response) => {
                        return response.data as Promise<T>;
                    })
                    .catch((error) => {
                        if (handleTokenError(error)) throw error;
                        console.log(error);
                        return error;
                    });
            },

            updateClassroom<T>(
                classroomId: string | undefined,
                data: object
            ): Promise<T> {
                let headers = {
                    Authorization:
                        "Bearer " +
                        localStorage.getItem<string>(localStorage.Key.TOKEN),
                    "Content-Type": "application/json",
                };
                return axiosClient.axios
                    .patch(
                        apiUrls.endpoints.users +
                            "/api/classrooms/" +
                            classroomId +
                            "/",
                        data,
                        {
                            headers: headers,
                        }
                    )
                    .then((response) => {
                        return response.data as Promise<T>;
                    })
                    .catch((error) => {
                        if (handleTokenError(error)) throw error;
                        setErrorInfo({
                            displayModal: true,
                            modal: {
                                type: "NOTIFICATION",
                                content: {
                                    title: i18n.errors.patchData,
                                },
                            },
                        });
                        console.log(error);
                        throw error;
                    });
            },

            createClassroom<T>(data: { name: string | undefined }): Promise<T> {
                let headers = {
                    Authorization:
                        "Bearer " +
                        localStorage.getItem<string>(localStorage.Key.TOKEN),
                    "Content-Type": "application/json",
                };
                return axiosClient.axios
                    .post(apiUrls.endpoints.users + "/api/classrooms/", data, {
                        headers: headers,
                    })
                    .then((response) => {
                        return response.data as Promise<T>;
                    })
                    .catch((error) => {
                        if (handleTokenError(error)) throw error;
                        setErrorInfo({
                            displayModal: true,
                            modal: {
                                type: "NOTIFICATION",
                                content: {
                                    title: i18n.errors.postData,
                                },
                            },
                        });
                        console.log(error);
                        throw error;
                    });
            },

            deleteClassroom<T>(id: string): Promise<T> {
                let headers = {
                    Authorization:
                        "Bearer " +
                        localStorage.getItem<string>(localStorage.Key.TOKEN),
                    "Content-Type": "application/json",
                };
                return axiosClient.axios
                    .delete(apiUrls.endpoints.users + "/api/classrooms/" + id, {
                        headers: headers,
                    })
                    .then((response) => {
                        console.log("Classroom deleted, reload the page");
                        return response.data as Promise<T>;
                    })
                    .catch((error) => {
                        if (handleTokenError(error)) throw error;
                        console.log(error);
                        return error;
                    });
            },

            getGlobalConfig<T>(version: string): Promise<T> {
                let headers = {
                    Authorization:
                        "Bearer " +
                        localStorage.getItem<string>(localStorage.Key.TOKEN),
                    "x-evb-origin": window.location.origin,
                };
                return axiosClient.axios
                    .get(
                        `${apiUrls.endpoints.content}/content?target=config${
                            version !== "" ? "&version=" + version : ""
                        }`,
                        {
                            headers: headers,
                        }
                    )
                    .then((response) => {
                        return response.data as Promise<T>;
                    })
                    .catch((error) => {
                        if (handleTokenError(error)) throw error;

                        setErrorInfo((err) => {
                            return {
                                ...err,
                                page: { code: "config" },
                            };
                        });
                        /* captureError5xx(error) */
                        /* Sentry.captureException(error); */
                        console.log(error);
                        throw error;
                    });
            },

            getData<T>(version: string): Promise<T> {
                let headers = {
                    Authorization:
                        "Bearer " +
                        localStorage.getItem<string>(localStorage.Key.TOKEN),
                    "x-evb-origin": window.location.origin,
                };
                return axiosClient.axios
                    .get(
                        `${apiUrls.endpoints.content}/content?target=questions${
                            version !== "" ? "&version=" + version : ""
                        }`,
                        {
                            headers: headers,
                        }
                    )
                    .then((response) => {
                        return response.data as Promise<T>;
                    })
                    .catch((error) => {
                        if (handleTokenError(error)) throw error;
                        setErrorInfo((err) => {
                            return {
                                ...err,
                                page: { code: "data" },
                            };
                        });
                        console.log(error);
                        throw error;
                    });
            },

            getTeacherDashboard<T>(
                isClusteringOn: boolean | undefined,
                teacherId: string,
                declinaison: string
            ): Promise<T> {
                let headers = {
                    Authorization:
                        "Bearer " +
                        localStorage.getItem<string>(localStorage.Key.TOKEN),
                    "x-evb-origin": window.location.origin,
                    version: declinaison,
                };
                const queryParams = isClusteringOn
                    ? ""
                    : "?enableclustering=false";
                return axiosClient.axios
                    .get(
                        apiUrls.endpoints.analytics +
                            "/teacher/" +
                            teacherId +
                            "/classrooms_analytics/" +
                            queryParams,
                        {
                            headers: headers,
                        }
                    )
                    .then((response) => {
                        return response.data as Promise<T>;
                    })
                    .catch((error) => {
                        if (handleTokenError(error)) throw error;
                        setErrorInfo((err) => {
                            return {
                                ...err,
                                displayModal: true,
                                modal: {
                                    type: "NOTIFICATION",
                                    content: {
                                        title: i18n.errors.getData,
                                    },
                                },
                            };
                        });
                        console.log(error);
                        throw error;
                    });
            },

            postStatement<T>(statement: Statement): Promise<T> {
                let headers = {
                    Authorization:
                        "Bearer " +
                        localStorage.getItem<string>(localStorage.Key.TOKEN),
                    "x-evidenceb-app": "athena",
                    "Content-Type": "application/json",
                };
                return axiosClient.axios
                    .post(apiUrls.endpoints.statements!, statement, {
                        headers: headers,
                    })
                    .then((res) => {
                        return res.data as Promise<T>;
                    });
            },

            getStatements(agent: object): Promise<Statement[]> {
                let headers = {
                    Authorization:
                        "Bearer " +
                        localStorage.getItem<string>(localStorage.Key.TOKEN),
                    "x-evidenceb-app": "athena",
                };
                return axiosClient.axios
                    .get(
                        apiUrls.endpoints.statements! +
                            "?agent=" +
                            encodeURIComponent(JSON.stringify(agent)),
                        {
                            headers: headers,
                        }
                    )
                    .then((res) => {
                        return res.data as Promise<{
                            more: any;
                            statements: Statement[];
                        }>;
                    })
                    .then((res) => res.statements);
            },

            logout<T>(): Promise<T> {
                let headers = {
                    Authorization:
                        "Bearer " +
                        localStorage.getItem<string>(localStorage.Key.TOKEN),
                };
                // URL set in .env for now
                return axiosClient.axios
                    .get(`${apiUrls.endpoints.auth}/v1/token/logout`, {
                        headers: headers,
                    })
                    .then((res) => {
                        return res.data.SessionKilled as Promise<T>;
                    })
                    .catch((error) => {
                        console.log(error);
                        return error;
                    });
            },

            async getResourcesLockStatus(
                userId: string,
                userType: UserType
            ): Promise<{ [classroomId: string]: PRLockStatus }> {
                let headers = {
                    Authorization:
                        "Bearer " +
                        localStorage.getItem<string>(localStorage.Key.TOKEN),
                };
                const res = await axiosClient.axios.get(
                    `${
                        apiUrls.endpoints.users
                    }/lock_status/${userType.toLowerCase()}/${userId}/`,
                    {
                        headers: headers,
                    }
                );
                const allLockStatus = res.data as {
                    [classroomId: string]: Partial<PRLockStatus>;
                };
                return Object.keys(allLockStatus).reduce(
                    (lockStatus, classroomId) => {
                        return {
                            ...lockStatus,
                            [classroomId]: completeLockStatus(
                                allLockStatus[classroomId]
                            ),
                        };
                    },
                    {} as { [classroomId: string]: PRLockStatus }
                );
            },

            async updateResourcesLockStatus(
                classroomId: string,
                lockStatus: PRLockStatus
            ): Promise<void> {
                let headers = {
                    Authorization:
                        "Bearer " +
                        localStorage.getItem<string>(localStorage.Key.TOKEN),
                };
                await axiosClient.axios.patch(
                    `${apiUrls.endpoints.users}/api/classrooms/${classroomId}/`,
                    { lock_status: lockStatus },
                    {
                        headers: headers,
                    }
                );
            },
        };
    }, [apiUrls, i18n, setErrorInfo]);

    return athenaAPIClient;
};

export default useAthenaAPIClient;
