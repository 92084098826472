import React, { useContext } from 'react'

//External modules
import { AlertDialogLabel } from "@reach/alert-dialog";

//Contexts
import { configStore } from '../../../contexts/ConfigContext';

//Components
import VisuallyHidden from '../../VisuallyHidden/VisuallyHidden';

//SCSS
import './Message.scss';

//Interfaces
import { ErrorContent } from '../../../interfaces/Error';

interface MessageProps {
    content: ErrorContent;
    action?: () => void;
    close?: () => void;
}
const Message = ({ content, action, close }: MessageProps) => {

    //Contexts
    const {config} = useContext(configStore)

    return (
        <div className="error-message">
            <AlertDialogLabel>{content.title}</AlertDialogLabel>
            <div className="alert-buttons">
                {
                    content.btn &&
                        <button 
                            onClick={action}>
                                { content.btn.label }
                        </button>
                }
                <button className="close-button" onClick={close}>
                    <VisuallyHidden>{config.i18n.exerciseShell.close}</VisuallyHidden>
                    <span aria-hidden>×</span>
                </button>
            </div>
        </div>
    )
}

export default Message
