import React from "react";

import "./Table.scss";

// Reference for accessible tables:
// - https://www.w3.org/WAI/tutorials/tables/one-header/#table-with-header-cells-in-the-top-row-only
// - https://developer.mozilla.org/en-US/docs/Learn/HTML/Tables/Advanced

export type TableProps = React.DetailedHTMLProps<
    React.TableHTMLAttributes<HTMLTableElement>,
    HTMLTableElement
>;

const Table = ({ children, className, ...props }: TableProps) => {
    return (
        <table {...props} className={`ds-table ${className ?? ""}`}>
            {children}
        </table>
    );
};

export default Table;
