import React, { useState, useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router";
import parse from "html-react-parser";
import { BanditManchotWhisperer } from "@evidenceb/bandit-manchot";
import { PRLockStatus } from "@evidenceb/gameplay-interfaces";
import { UserType } from "../../interfaces/User";
import { Title } from "../../interfaces/Config";
import { configStore } from "../../contexts/ConfigContext";
import { dataStore } from "../../contexts/DataContext";
import { sessionStore, Settled } from "../../contexts/SessionContext";
import useAthenaAPIClient from "../../hooks/useAthenaAPIClient";
import useBanditManchot from "../../hooks/useBanditManchot";
import { isModuleLocked } from "../../utils/prm";
import Loader from "../../components/Loader/Loader";
import ModuleCard from "./ModuleCard/ModuleCard";
import NewModuleCard from "./NewModuleCard/NewModuleCard";
import ImageAsset from "../../components/ImageAsset/ImageAsset";
import BanditManchotErrorModal from "../../components/BanditManchotErrorModal/BanditManchotErrorModal";

import "./ModuleListStudent.scss";

function ModuleListStudent({ title }: Title) {
    const history = useHistory();
    const { config } = useContext(configStore);
    const {
        session: { userId, prLockStatus },
    } = useContext(sessionStore);
    const {
        config: { i18n },
    } = useContext(configStore);
    const { data } = useContext(dataStore);
    const athenaAPIClient = useAthenaAPIClient();
    const bmInfo = useBanditManchot();
    const [diagnostics, setDiagnostics] = useState<{
        [moduleId: string]: {
            progression: number;
            averageScore: number;
            isModuleFinished: boolean;
        };
    }>();
    const [lockedModules, setLockedModules] = useState<string[]>();

    // Get diagnostics
    useEffect(() => {
        if (bmInfo.status !== "success" || diagnostics) return;

        const diag: {
            [moduleId: string]: {
                progression: number;
                averageScore: number;
                isModuleFinished: boolean;
            };
        } = {};
        Object.keys(bmInfo.banditManchot).forEach((moduleId) => {
            const singleBM = bmInfo.banditManchot[moduleId];
            if (singleBM.error) return;

            const basicDiag = BanditManchotWhisperer.getStudentBasicDiagnostic(
                singleBM.instance
            );
            const { isModuleFinished } =
                BanditManchotWhisperer.getNextHierarchyId(singleBM.instance);
            diag[moduleId] = {
                ...basicDiag,
                isModuleFinished,
            };
        });
        setDiagnostics(diag);
    }, [diagnostics, bmInfo]);

    // Get modules lock status
    useEffect(() => {
        if (lockedModules) return;

        (async () => {
            try {
                const allStatus = await athenaAPIClient.getResourcesLockStatus(
                    userId,
                    UserType.Student
                );
                if (Object.keys(allStatus).length > 0)
                    setLockedModules(
                        allStatus[Object.keys(allStatus)[0]].moduleIds ?? []
                    );
                else setLockedModules([]);
            } catch {}
        })();
    }, [lockedModules, userId, athenaAPIClient]);

    if (
        bmInfo.status === "loading" ||
        (!diagnostics && bmInfo.status !== "error")
    )
        return <Loader />;

    return (
        <>
            <Helmet>
                {" "}
                <title>{title}</title>{" "}
            </Helmet>

            {bmInfo.status === "error" && (
                <BanditManchotErrorModal
                    title={i18n.errors.statements.cannotRetrieveHistory.title}
                    description={
                        i18n.errors.statements.cannotRetrieveHistory.description
                    }
                    primaryButton={{
                        label: i18n.errors.statements.cannotRetrieveHistory
                            .goToHomepageButton,
                        fn: () => {
                            history.push("/");
                        },
                    }}
                />
            )}

            <div id="module-list">
                <div id="welcome-message">
                    <ImageAsset
                        src={config.logos.avatar}
                        className="avatar-img"
                        alt=""
                    />
                    <div className="text-content">
                        {areAllModulesLocked(
                            lockedModules ?? [],
                            data.modules.map((mod) => mod.id)
                        )
                            ? i18n.moduleList.areAllModulesLocked
                            : parse(
                                  config.i18n.moduleList.student.message.default
                                      .$html
                              )}
                    </div>
                </div>

                <div id="card-container">
                    {data.modules.map((mod) =>
                        config.features.newStudentModuleList ? (
                            <NewModuleCard
                                key={`card-${mod.id}`}
                                moduleId={mod.id}
                                diagnosis={
                                    diagnostics
                                        ? diagnostics[mod.id]
                                        : undefined
                                }
                                error={
                                    bmInfo.status === "error" ||
                                    bmInfo.banditManchot[mod.id].error ||
                                    !diagnostics ||
                                    !lockedModules
                                }
                                locked={
                                    bmInfo.status === "error"
                                        ? true
                                        : isModuleLocked(
                                              mod,
                                              (
                                                  prLockStatus as Settled<PRLockStatus>
                                              ).value,
                                              bmInfo.banditManchot[mod.id]
                                                  .graph,
                                              data
                                          )
                                }
                            />
                        ) : (
                            <ModuleCard
                                key={`card-${mod.id}`}
                                moduleId={mod.id}
                                diagnosis={
                                    diagnostics
                                        ? diagnostics[mod.id]
                                        : undefined
                                }
                            />
                        )
                    )}
                </div>

                {config.logos.avatarStudentBottomRight && (
                    <div className="avatarBottom">
                        <ImageAsset
                            src={config.logos.avatarStudentBottomRight}
                        />
                    </div>
                )}
            </div>
        </>
    );
}

const areAllModulesLocked = (
    lockedModules: string[],
    modules: string[]
): boolean => {
    return modules.every((mod) => lockedModules.includes(mod));
};

export default ModuleListStudent;
