import { PRTree } from "@evidenceb/gameplay-interfaces";
import Tooltip from "@reach/tooltip";
import React, { useContext, useState } from "react";
import { configStore } from "../../contexts/ConfigContext";
import { dataStore } from "../../contexts/DataContext";
import Icon from "../../design-system-components/Icon/Icon";
import { Objective, Student } from "../../interfaces/Dashboard";
import COLOR_SCHEME from "../../pages/DashBoard/ProgressionOverview/ProgressionTable/COLOR_SCHEME";
import StudentProgression, {
    ProgressionData,
} from "../../pages/DashBoard/StudentProgression/StudentProgression";
import { getObjectiveById } from "../../utils/dataRetrieval";
import LockStatusLabel from "../LockStatusLabel/LockStatusLabel";
import VisuallyHidden from "../VisuallyHidden/VisuallyHidden";

import "./ObjectiveProgression.scss";

const toolTipStyle = {
    backgroundColor: "#444",
    color: "white",
    border: "none",
    borderRadius: "5px",
    padding: "8px",
};

interface Props {
    prTree?: PRTree;
    objective: Objective;
    classroomId: string;
    student: Student;
    type?: "default" | "dashed";
    /** @default false */
    showObjectiveTitle?: boolean;
    /**
     * Required when showing the title
     * @default 1
     */
    objectiveIndex?: number;
}

const ObjectiveProgression = ({
    prTree,
    objective,
    classroomId,
    student,
    type = "default",
    showObjectiveTitle = false,
    objectiveIndex = 1,
}: Props) => {
    const { data } = useContext(dataStore);
    const {
        config: { i18n },
    } = useContext(configStore);

    const [progression, setProgression] = useState<ProgressionData>();

    return (
        <>
            {progression && (
                <StudentProgression
                    {...progression}
                    onDismiss={() => {
                        setProgression(undefined);
                    }}
                />
            )}

            <div
                className={`objective-progression --${objective.status}`}
                /* @ts-ignore allow setting css variables */
                style={
                    prTree &&
                    prTree.objectives[objective.id].status !== "unlocked"
                        ? COLOR_SCHEME.locked
                        : undefined
                }
            >
                {showObjectiveTitle && (
                    <>
                        <div className="objective-title">
                            {i18n.dashboard?.common.objectiveShort}{" "}
                            {objectiveIndex}
                        </div>

                        {prTree?.objectives[objective.id].status !==
                        "unlocked" ? (
                            <LockStatusLabel
                                locked={true}
                                style={{ margin: "4px auto 8px auto" }}
                            />
                        ) : (
                            <div className="objective-title__lock-label-placeholder">
                                {/* placeholder */}
                            </div>
                        )}
                    </>
                )}

                {objective.status === "completed" ||
                objective.status === "inProgress" ? (
                    <Tooltip
                        style={toolTipStyle}
                        label={`${
                            getObjectiveById(objective.id, data).title.short
                        }`}
                    >
                        <button
                            onClick={() =>
                                setProgression({
                                    objective,
                                    student,
                                })
                            }
                            className={`objective-progression__button --${type}`}
                        >
                            {objective.alert !== "none" && (
                                <span aria-hidden="true" className="alert">
                                    <Icon
                                        path={
                                            objective.alert === "positive"
                                                ? "alert_sup"
                                                : "alert_inf"
                                        }
                                        size="medium"
                                    />
                                </span>
                            )}
                            <span
                                className="material-icons icon"
                                translate="no"
                            >
                                open_in_full
                            </span>
                            <VisuallyHidden>
                                {/*TODO: i18*/}
                                Voir la progression de l'objectif
                            </VisuallyHidden>
                        </button>
                    </Tooltip>
                ) : (
                    <Tooltip
                        style={toolTipStyle}
                        label={`${
                            getObjectiveById(objective.id, data).title.short
                        }`}
                    >
                        <div
                            className={`objective-progression__button --${type}`}
                        >
                            <span></span>
                        </div>
                    </Tooltip>
                )}
            </div>
        </>
    );
};

export default ObjectiveProgression;
