import React from 'react'

//External modules
import { Menu, MenuList, MenuButton, MenuItem } from "@reach/menu-button";
import "@reach/menu-button/styles.css";

//SCSS
import "./Settings.scss";

//Interfaces
export interface Setting {
    label: string;
    icon: string;
    action: () => void;
}
interface SettingsProps {
    icon: string;
    label?: string;
    settings: Setting[],
    className?: string
}

const Settings = ({ icon, label, settings, className }: SettingsProps) => {

    return (
        <div className={"settings " + (className ?? "")}>
            <Menu>
                    <MenuButton className="settings__menu-button">
                        <span className="material-icons icon" translate="no" aria-hidden="true">{icon}</span>
                        {
                            label &&
                            <>
                                <span className="menu-button__label">{label}</span>
                                <span aria-hidden="true">▾</span>
                            </> 
                        }
                    </MenuButton>
                    <MenuList className="settings-options">
                        {
                            settings.map((setting, i) => 
                                <MenuItem key={"setting-item-" + i} onSelect={setting.action}>
                                    <span className="material-icons icon" translate="no" aria-hidden="true">{setting.icon}</span>
                                    {setting.label}
                                </MenuItem>
                            )
                        }
                    </MenuList>
            </Menu>
        </div>
    )
}

export default Settings
