import React, { useContext } from "react";
import Tooltip from "@reach/tooltip";
import { PRTree } from "@evidenceb/gameplay-interfaces";
import { configStore } from "../../../../../contexts/ConfigContext";
import { dataStore } from "../../../../../contexts/DataContext";
import { getObjectiveById } from "../../../../../utils/dataRetrieval";
import LockStatusLabel from "../../../../../components/LockStatusLabel/LockStatusLabel";

import "./ObjectiveListLine.scss";

interface Props {
    objectives: string[];
    prTree?: PRTree;
    moduleId: string;
}

const toolTipStyle = {
    backgroundColor: "rgba(121, 121, 121, 1)",
    fontSize: "1rem",
    color: "white",
    border: "none",
    borderRadius: "5px",
    padding: "8px",
};

const ObjectiveListLine = ({ objectives, prTree, moduleId }: Props) => {
    const { config } = useContext(configStore);
    const { data } = useContext(dataStore);

    return (
        <div className="tr objective-list-line">
            {objectives.map((objectiveId, i) => (
                <div className="th" key={"objective-" + i}>
                    <div className="objective-list-line__title-container">
                        <Tooltip
                            style={toolTipStyle}
                            label={
                                <>
                                    {`${
                                        getObjectiveById(objectiveId, data)
                                            .title.short
                                    }`}
                                    {prTree &&
                                        prTree.objectives[objectiveId]
                                            .status !== "unlocked" && (
                                            <LockStatusLabel
                                                style={{
                                                    marginLeft: "8px",
                                                    position: "relative",
                                                    top: "-2px",
                                                }}
                                                locked={true}
                                            />
                                        )}
                                </>
                            }
                        >
                            <button>
                                <span>
                                    {
                                        config.i18n.dashboard?.common
                                            .objectiveShort
                                    }{" "}
                                    {i + 1}
                                </span>
                                {prTree &&
                                    prTree.modules[moduleId].status ===
                                        "unlocked" &&
                                    prTree.objectives[objectiveId].status !==
                                        "unlocked" && (
                                        <LockStatusLabel locked={true} as="div" />
                                    )}
                            </button>
                        </Tooltip>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default ObjectiveListLine;
