import React from "react";

// External modules
import { Link } from "react-router-dom";

//SCSS
import "./Bar.scss";
import { BAR_COLOR_SCHEMES } from "./BAR_COLOR_SCHEMES";

export interface BarColorScheme {
    bg: string;
    value: string;
    progress: string;
}

interface BarProps {
    state: "noData" | undefined;
    url: string;
    type: "progress" | "moduleGroups" | "groups";
    moduleName: string;
    value?: string | number;
    colors?: BarColorScheme;
    displayPRMButton?: boolean;
    classroomId?: string;
}

const Bar = ({
    state,
    url,
    type,
    moduleName,
    value,
    colors = BAR_COLOR_SCHEMES["default"],
    displayPRMButton,
    classroomId
}: BarProps) => {
    // This is to handle the fact that reach menu doesn't let us prevent click
    // through. So when the prm button is displayed, the link to the module is
    // only on the chevron, and otherwise it's on the whole bar
    // TEMP: Hide following comment https://evidencebprod.atlassian.net/browse/EVBPROD-1482?focusedCommentId=12936
    // const Wrapper = displayPRMButton ? "div" : Link;
    // const Chevron = displayPRMButton ? Link : "span";
    const Wrapper = Link;
    const Chevron = "span";

    return (
        <div
            className={`bar-container ${type} ${state ? state : ""}`}
            style={{
                /* @ts-ignore ts doesn't allow variable setting */
                "--color-bar-value": colors.value,
                "--color-bar-bg": colors.bg,
                "--color-bar-progress": colors.progress,
            }}
        >
            {/* @ts-ignore it works fine and I haven't found how to solve */}
            <Wrapper to={url} className="bar-wrapper">
                {type === "progress" ? (
                    <>
                        <span className="label">{moduleName}</span>

                        {/*
                            TEMP: Hide following comment https://evidencebprod.atlassian.net/browse/EVBPROD-1482?focusedCommentId=12936
                            displayPRMButton && <PRMButton classroomId={classroomId!} />
                        */}

                        <span className="value">
                            {value + " %"}

                            {/* @ts-ignore it works fine and I haven't found how to solve */}
                            <Chevron className="material-icons" translate="no" to={url}>
                                {" "}
                                chevron_right{" "}
                            </Chevron>
                        </span>

                        <span
                            className="progress"
                            style={{ width: value + "%" }}
                        ></span>
                    </>
                ) : (
                    <>
                        <span className="label">{moduleName}</span>
                        {type === "moduleGroups" && value && (
                            <span className="value">{value}</span>
                        )}
                    </>
                )}
            </Wrapper>
        </div>
    );
};

export default Bar;
