import React, { useState, useEffect, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { PRTree } from "@evidenceb/gameplay-interfaces";
import { ModuleDashboard, Student } from "../../../interfaces/Dashboard";
import { UserType } from "../../../interfaces/User";
import { errorStore } from "../../../contexts/ErrorContext";
import { dashboardStore } from "../../../contexts/DashboardContext";
import { configStore } from "../../../contexts/ConfigContext";
import { sessionStore } from "../../../contexts/SessionContext";
import { dataStore } from "../../../contexts/DataContext";
import useAthenaAPIClient from "../../../hooks/useAthenaAPIClient";
import { buildPRTree } from "../../../utils/prm";
import StudentIcon from "./StudentIcon/StudentIcon";
import TimeSpentChart from "./TimeSpentChart/TimeSpentChart";
import GroupCard from "./GroupCard/GroupCard";
import Progress from "./Progress/Progress";
import StudentProgression, {
    ProgressionData,
} from "../StudentProgression/StudentProgression";
import Breadcrumbs from "../../../components/Breadcrumbs/Breadcrumbs";
import Loader from "../../../components/Loader/Loader";

import "./StudentDetails.scss";

interface ParamTypes {
    classroomId: string;
    studentId: string;
}

export default function StudentDetails() {
    const [studentData, setStudentData] = useState<Student>();
    const [studentModules, setStudentModules] = useState<ModuleDashboard[]>();
    const { classroomId, studentId } = useParams<ParamTypes>();
    const { dashboard } = useContext(dashboardStore);
    const { config } = useContext(configStore);
    const { data } = useContext(dataStore);
    const {
        session: { userId },
    } = useContext(sessionStore);
    const { setErrorInfo } = useContext(errorStore);
    const history = useHistory();
    const athenaAPIClient = useAthenaAPIClient();

    const [progression, setProgression] = useState<
        ProgressionData | undefined
    >();
    const [isAlert, setIsAlert] = useState<boolean>();
    const [prTree, setPrTree] = useState<PRTree>();

    // Get module lock status
    useEffect(() => {
        if (prTree) return;

        (async () => {
            try {
                const allLockStatus =
                    await athenaAPIClient.getResourcesLockStatus(
                        userId,
                        UserType.Teacher
                    );
                const prTree = buildPRTree(
                    allLockStatus[classroomId],
                    data,
                    config.ai!
                );
                setPrTree(prTree);
            } catch (err) {
                Sentry.captureException(err);
                setErrorInfo({
                    displayModal: true,
                    modal: {
                        type: "NOTIFICATION",
                        content: {
                            title: config.i18n.prm.lockStatusErrorTitle,
                            text: config.i18n.prm.lockStatusErrorText,
                        },
                    },
                });
            }
        })();
    }, [
        athenaAPIClient,
        classroomId,
        config.ai,
        config.i18n.prm,
        data,
        setErrorInfo,
        userId,
        prTree,
    ]);

    useEffect(() => {
        const classroom = dashboard.classrooms.find(
            (el) => classroomId === el.id
        );

        const student = classroom?.modulesList[0].students[studentId];
        const modules = classroom?.modulesList.filter(
            (module) => module.students[studentId]?.id === studentId
        );

        setStudentModules(modules);

        modules &&
            modules.forEach((module) => {
                if (module.students[studentId].studentInDifficulty) {
                    setIsAlert(true);
                }
            });

        setStudentData(student);
    }, [dashboard, classroomId, studentId]);

    const breadcrumbsItems = [
        {
            title: config.i18n.dashboard!.common.classes,
            url: `/${config.i18n.dashboard!.paths.dashboard}/${
                config.i18n.dashboard!.paths.dashboard
            }`,
        },
        {
            title: dashboard.classrooms.find(
                (classroom) => classroom.id === classroomId
            )!.name,
            url: `/${config.i18n.dashboard!.paths.dashboard}/${
                config.i18n.dashboard!.paths.classes
            }/${classroomId}`,
        },
        {
            title: config.i18n.dashboard!.common.progression,
            url: `/${config.i18n.dashboard!.paths.dashboard}/${
                config.i18n.dashboard!.paths.classes
            }/${config.i18n.dashboard!.paths.progression}/${classroomId}/${
                dashboard.classrooms.find(
                    (classroom) => classroom.id === classroomId
                )!.modulesList[0].id
            }`,
        },
    ];

    if (config.features.teacherDashboard)
        breadcrumbsItems.push({
            title: config.i18n.dashboard!.common.groups,
            url: `/${config.i18n.dashboard!.paths.dashboard}/${
                config.i18n.dashboard!.paths.classes
            }/${classroomId}/${
                dashboard.classrooms.find(
                    (classroom) => classroom.id === classroomId
                )!.modulesList[0].id
            }/${config.i18n.dashboard?.paths.groups}`,
        });

    if (
        !studentData ||
        !studentModules ||
        // This happens when navigating from one StudentDetailsPage to another:
        // studentMoules has not yet been updated for the new student
        studentModules.some(
            (module) => typeof module.students[studentId] === "undefined"
        )
    )
        return <Loader />;

    return (
        <>
            <div id="student-details-container">
                <div className="top-container">
                    <div className="back-btn">
                        <button onClick={() => history.goBack()}>
                            <span className="material-icons" translate="no">
                                {" "}
                                arrow_back{" "}
                            </span>
                        </button>
                    </div>

                    <Breadcrumbs items={breadcrumbsItems} />
                </div>

                <div id="student-name-container">
                    {isAlert && <StudentIcon />}

                    <h1 id="student-name">
                        <span>{`${studentData?.firstname} ${studentData?.lastname}`}</span>
                        <span>
                            {config.i18n.dashboard?.studentDetails.lastLogin}{" "}
                        </span>
                    </h1>
                </div>
                <div id="card-container">
                    <div className="top-cards">
                        <TimeSpentChart
                            studentId={studentId}
                            studentModules={studentModules}
                            prTree={prTree}
                        />

                        {config.features.clustering && (
                            <GroupCard
                                classroomId={classroomId}
                                studentId={studentId}
                                prTree={prTree}
                            />
                        )}
                    </div>

                    <Progress
                        modules={studentModules}
                        setProgression={setProgression}
                        prTree={prTree}
                    />
                </div>

                {progression && (
                    <StudentProgression
                        {...progression}
                        onDismiss={() => {
                            setProgression(undefined);
                        }}
                    />
                )}
            </div>
        </>
    );
}
