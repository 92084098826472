import { HtmlString } from "@evidenceb/gameplay-interfaces";
import React from "react";
import ChatBubble from "./ChatBubble/ChatBubble";
import parse from "html-react-parser";

type WelcomeProps = {
    welcomeMessages: HtmlString[];
    startButtonText: HtmlString;
    started: boolean;
    onStart: () => void;
};

const Welcome: React.FC<WelcomeProps> = ({
    welcomeMessages,
    startButtonText,
    started,
    onStart,
}): JSX.Element => (
    <>
        {welcomeMessages.map((welcomeMessage, index) => (
            <ChatBubble
                key={welcomeMessage.$html}
                direction="left"
                color="var(--chatbot-color-bot-bubble)"
                botAvatar={index === 0 ? "visible" : "hidden"}
            >
                {parse(welcomeMessage.$html)}
            </ChatBubble>
        ))}
        {!started ? (
            <div className="welcome-message-start">
                <button onClick={() => onStart()}>
                    {parse(startButtonText.$html)}
                </button>
            </div>
        ) : null}
    </>
);
export default Welcome;
