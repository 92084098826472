import React from "react";

const FrustratedBot = () => (
    <svg
        viewBox="0 0 73 45"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g filter="url(#filter0_d)">
            <path
                d="M16.1797 16.9763C16.1797 21.61 14.7711 25.3648 10.9478 25.3648C7.12451 25.3648 3.99947 21.61 3.99947 16.9763C3.99947 12.3426 7.10238 8.58789 10.9398 8.58789C14.7771 8.58789 16.1797 12.3447 16.1797 16.9763Z"
                fill="#E66342"
            />
            <path
                d="M13.4596 25.3648C10.3145 25.3648 7.7649 21.6091 7.7649 16.9763C7.7649 12.3435 10.3145 8.58789 13.4596 8.58789C16.6047 8.58789 19.1543 12.3435 19.1543 16.9763C19.1543 21.6091 16.6047 25.3648 13.4596 25.3648Z"
                fill="#2C3E50"
            />
            <path
                d="M31.1703 1.05427C34.3342 -0.0163931 37.7133 -0.276712 41.0022 0.296837C53.9712 2.52457 63.2759 16.8023 61.6963 32.2952C61.6138 33.1053 61.1288 33.7412 60.503 33.9498L53.4259 36.2991C54.0517 36.0966 54.5367 35.4606 54.6212 34.6445C56.231 19.1597 46.9746 4.87382 34.0357 2.64609C30.7532 2.07036 27.3797 2.32934 24.2219 3.39947L31.1703 1.05427Z"
                fill="#61A4BF"
            />
            <path
                d="M34.0359 2.64579C21.1796 0.418049 9.38171 10.9127 7.59483 26.1808C7.46805 27.2765 8.10796 28.2992 9.02555 28.4653L52.7017 36.3514C53.6414 36.5195 54.4946 35.756 54.6113 34.6462C56.2292 19.1614 46.9728 4.88365 34.0359 2.64579Z"
                fill="#AFDCE0"
            />
            <path
                d="M59.5319 33.1558C56.3868 33.1558 53.8372 29.4002 53.8372 24.7673C53.8372 20.1345 56.3868 16.3789 59.5319 16.3789C62.677 16.3789 65.2266 20.1345 65.2266 24.7673C65.2266 29.4002 62.677 33.1558 59.5319 33.1558Z"
                fill="#2C3E50"
            />
            <path
                d="M56.8117 24.7673C56.8117 29.401 58.2203 33.1578 62.0436 33.1578C65.8669 33.1578 69 29.401 69 24.7673C69 20.1337 65.889 16.3789 62.0517 16.3789C58.2143 16.3789 56.8117 20.1357 56.8117 24.7673Z"
                fill="#E66342"
            />
            <path
                d="M61.8604 21.2604H62.3756C62.3756 20.1607 62.3273 18.9861 62.283 17.8499C62.1904 15.5959 62.0979 13.2648 62.3715 11.4401C63.3193 5.15182 65.9192 3.06787 65.9453 3.04762L65.6314 2.64258C65.5187 2.72966 62.8444 4.84804 61.8624 11.3692C61.5807 13.2426 61.6753 15.5979 61.7658 17.8762C61.8121 19.0023 61.8604 20.1708 61.8604 21.2604Z"
                fill="#E66342"
            />
            <path
                d="M63.8667 3.98798C63.4585 2.91165 63.9946 1.70611 65.064 1.29532C66.1335 0.884526 67.3313 1.42404 67.7395 2.50037C68.1476 3.57669 67.6116 4.78223 66.5421 5.19302C65.4727 5.60382 64.2749 5.0643 63.8667 3.98798Z"
                fill="#2C3E50"
            />
            <path
                d="M54.143 36.9991C54.4991 36.9988 54.8515 36.9258 55.1787 36.7845C55.5059 36.6432 55.8012 36.4364 56.0466 36.1768L61.3711 30.5427L60.7493 29.9473L55.4249 35.5794C55.2254 35.7908 54.9774 35.9497 54.7027 36.0422C54.428 36.1347 54.135 36.158 53.8493 36.11L38.3428 33.5096L38.2019 34.3622L53.7064 36.9626C53.8507 36.9869 53.9967 36.9991 54.143 36.9991Z"
                fill="#E66342"
            />
            <path
                d="M35.6424 32.0391L40.2983 32.8115C41.0429 32.935 41.547 33.6426 41.4243 34.392L41.4239 34.394C41.3012 35.1433 40.5981 35.6506 39.8536 35.5271L35.1977 34.7547C34.4531 34.6312 33.949 33.9236 34.0717 33.1743L34.0721 33.1723C34.1948 32.4229 34.8979 31.9156 35.6424 32.0391Z"
                fill="#2C3E50"
            />
            <path
                d="M31.8484 17.553C31.8484 17.647 31.8513 17.74 31.8581 17.8329C31.9861 19.6821 33.3768 21.137 35.0727 21.137C36.8532 21.137 38.2969 19.5329 38.2969 17.553C38.2969 16.2986 37.7165 15.1934 36.8378 14.5531C36.4422 14.2654 35.986 14.0707 35.4952 14C34.9745 14.3691 34.5597 14.8505 34.3027 15.4162C34.2401 15.5489 34.2363 15.73 34.3027 15.8617C34.3219 15.8995 34.346 15.9354 34.3749 15.9683C34.14 16.254 33.8417 16.4865 33.5029 16.6483C33.1227 16.8178 32.7079 16.8875 32.3056 16.8498C32.1747 16.8381 32.0477 16.8149 31.9245 16.78C31.8744 17.029 31.8484 17.2876 31.8484 17.553Z"
                fill="#2C3E50"
            />
            <path
                d="M35.4949 13.9992C35.9858 14.0699 36.442 14.2646 36.8375 14.5523C37.1061 14.4361 37.3958 14.3537 37.698 14.3102C38.1994 14.2491 38.7114 14.3005 39.1792 14.4603C39.6469 14.6192 40.0512 14.8797 40.3495 15.2129C40.3976 15.2682 40.4602 15.3146 40.5324 15.3495C40.6046 15.3844 40.6854 15.4067 40.7692 15.4144C40.8519 15.4222 40.9366 15.4164 41.0165 15.396C41.0973 15.3766 41.1715 15.3427 41.235 15.2982C41.3668 15.2042 41.4506 15.0725 41.4689 14.9311C41.4871 14.7887 41.439 14.6463 41.3341 14.531C40.9049 14.0506 40.3216 13.6757 39.6469 13.4461C38.9723 13.2166 38.2331 13.141 37.5103 13.2291C36.7567 13.327 36.0628 13.5963 35.4949 13.9992Z"
                fill="#2C3E50"
            />
            <path
                d="M29.2574 15.352C29.2404 15.4839 29.2847 15.6196 29.3822 15.7297C29.7099 16.111 30.1351 16.4287 30.6247 16.662C30.7312 16.7127 30.841 16.7595 30.9533 16.8019C31.58 17.0389 32.268 17.1338 32.94 17.0753C34.0205 16.9729 34.9728 16.464 35.5623 15.7014C35.7058 15.5171 35.827 15.3184 35.9245 15.1058C35.9834 14.984 35.9815 14.8456 35.9192 14.7207C35.8572 14.5949 35.7405 14.4913 35.5931 14.4307C35.5245 14.4049 35.4527 14.3901 35.3786 14.3877C35.2574 14.3846 35.3754 14.4797 35.2777 14.5354C35.1801 14.5911 35.2575 14.142 34.6459 14.8932C34.6391 14.9012 34.6335 14.9086 34.6266 14.9166C34.072 15.5974 34.0394 15.6149 34.0372 15.6143C33.9974 15.6408 33.9558 15.6657 33.9133 15.6894C33.5761 15.8866 33.1831 16.0128 32.7647 16.0587C32.2984 16.0988 31.821 16.0328 31.3866 15.8683C31.2451 15.8154 31.111 15.7527 30.9843 15.6801C30.7184 15.5304 30.4851 15.3431 30.2974 15.1251C30.2526 15.0722 30.1943 15.0264 30.1272 14.991C30.0599 14.9566 29.9849 14.9329 29.9073 14.9225C29.8296 14.912 29.7515 14.9154 29.6766 14.9317C29.602 14.9471 29.5327 14.976 29.4741 15.016C29.3513 15.0994 29.2743 15.22 29.2574 15.352Z"
                fill="#2C3E50"
            />
            <g filter="url(#filter1_d)">
                <ellipse
                    rx="3.67928"
                    ry="3.84011"
                    transform="matrix(-1 0 0 1 46.6801 18.68)"
                    fill="white"
                />
                <path
                    d="M46.6801 11L43.4937 16.7602H49.8664L46.6801 11Z"
                    fill="white"
                />
            </g>
            <ellipse
                rx="1.53992"
                ry="1.74357"
                transform="matrix(-1 0 0 1 22.128 15.519)"
                fill="#2C3E50"
            />
            <path
                d="M22.425 13.5139C22.4748 13.5137 22.5241 13.503 22.5699 13.4826C22.6702 13.4342 22.7492 13.3475 22.7909 13.2401C22.8325 13.1326 22.8304 12.9849 22.7909 12.8765C22.4525 11.9118 21.6808 11.2452 20.7718 11.0917C20.3165 11.0199 19.8512 11.0811 19.4267 11.2685C19.0022 11.4559 18.6347 11.7624 18.3644 12.1545C18.2984 12.2482 18.2682 12.3646 18.2798 12.4806C18.2913 12.5966 18.3439 12.7038 18.427 12.781C18.467 12.8173 18.5137 12.8445 18.5643 12.8608C18.6148 12.8771 18.668 12.8821 18.7205 12.8755C18.773 12.8689 18.8236 12.8509 18.8691 12.8226C18.9145 12.7942 18.9539 12.7562 18.9847 12.711C19.1719 12.439 19.4267 12.2264 19.721 12.0964C20.0154 11.9664 20.338 11.924 20.6537 11.9739C20.9719 12.0337 21.2692 12.1812 21.5155 12.4012C21.7618 12.6213 21.8805 12.9481 21.9881 13.2687C22.0138 13.3509 22.1736 13.3178 22.197 13.4322C22.2631 13.4836 22.3428 13.5121 22.425 13.5139Z"
                fill="#2C3E50"
            />
            <path
                d="M22.4137 12.3549C22.3617 12.3403 22.3077 12.3354 22.2551 12.3404C22.1392 12.3545 22.0366 12.4098 21.9684 12.495C21.9001 12.5801 21.8712 12.6887 21.8877 12.7987C22.0195 13.7741 22.67 14.6333 23.5848 15.0417C24.0442 15.2416 24.5446 15.3239 25.0315 15.2795C25.5184 15.2352 25.9729 15.0659 26.3456 14.7902C26.4362 14.7247 26.4946 14.6281 26.5091 14.5195C26.5237 14.4108 26.4935 14.298 26.4244 14.2034C26.3909 14.1586 26.3483 14.12 26.2992 14.0903C26.2501 14.0606 26.1957 14.0403 26.1393 14.0307C26.0829 14.0212 26.0259 14.0225 25.9718 14.0348C25.9177 14.047 25.8679 14.0698 25.8253 14.1017C25.567 14.2931 25.2518 14.4105 24.9142 14.4413C24.5767 14.4721 24.2296 14.415 23.9111 14.2763C23.5923 14.1279 23.3154 13.9061 23.1086 13.6335C23.1086 13.6335 23.1039 13.6588 22.8256 12.9234C22.5473 12.188 22.5118 12.5612 22.4545 12.495C22.3972 12.4287 22.4993 12.3808 22.4137 12.3549Z"
                fill="#2C3E50"
            />
            <path
                d="M32.3806 27.1004C32.8407 24.4724 30.5477 22.7765 24.1625 22.4899C23.1442 22.4442 22.3869 23.3702 22.2812 23.4991L32.3806 27.1004ZM32.4158 26.7841C31.0147 32.5325 26.4576 22.8931 22.2812 23.5699L32.4158 26.7841Z"
                fill="white"
            />
            <path
                d="M32.4158 26.7841C31.0147 32.5325 26.4576 22.8931 22.2812 23.5699M32.3806 27.1004C32.8407 24.4724 30.5477 22.7765 24.1625 22.4899C23.1442 22.4442 22.3869 23.3702 22.2812 23.4991L32.3806 27.1004Z"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
            />
        </g>
        <defs>
            <filter
                id="filter0_d"
                x="0"
                y="0"
                width="73.0008"
                height="44.9991"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="2" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow"
                    result="shape"
                />
            </filter>
            <filter
                id="filter1_d"
                x="40"
                y="8"
                width="17.3586"
                height="21.5203"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dx="2" dy="2" />
                <feGaussianBlur stdDeviation="2.5" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow"
                    result="shape"
                />
            </filter>
        </defs>
    </svg>
);
export default FrustratedBot;
