import React, { useContext } from 'react';

//Contexts
import { configStore } from '../../../../../../contexts/ConfigContext'
import Icon from '../../../../../../design-system-components/Icon/Icon';

//SCSS
import "./ModuleInfo.scss";

//Interfaces
interface ModuleInfoProps{
        progression: number
        total:number;
        inProgress: number;
        inDifficulty: number;
}

function ModuleInfo({progression, total, inProgress, inDifficulty}: ModuleInfoProps) {
 
    //Contexts
    const {config} = useContext(configStore)

    return (
        <div className={`registered-progression ${progression === 0 ? "center": ""}`}>
            <div className="container">
                <div className="students">
                    <span className="label">
                        {total} {
                            total <= 1 ? config.i18n.singleClass.savedProgressionSingular :
                            config.i18n.singleClass.savedProgressionPlural
                        }
                    </span>
                </div>
            </div>
            {
                progression !== 0 &&
                    <div className="container">
                        <div className="info in-difficulty">
                            <Icon path="alert_inf" size="medium" />
                            <span className="label">
                                <span>{inDifficulty}</span> {
                                    inDifficulty <= 1 ? config.i18n.singleClass.progressionRegressionSingular :
                                    config.i18n.singleClass.progressionRegressionPlural
                                }
                            </span>
                        </div>
                        <div className="info in-progress">
                            <Icon path="alert_sup" size="medium" />
                            <span className="label">
                                <span>{inProgress}</span> {
                                    inProgress <= 1 ? config.i18n.singleClass.progressionAccelerationSingular :
                                    config.i18n.singleClass.progressionAccelerationPlural

                                }
                            </span>
                        </div>
                    </div>
            }
        </div>
    )
}

export default ModuleInfo
