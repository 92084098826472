import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import {
    ActivityShell,
    Playlist,
    PlaylistManager,
    ShellOptions,
} from "@evidenceb/gameplay-interfaces";
import { BanditManchotWhisperer } from "@evidenceb/bandit-manchot";
import { UserType } from "../../../interfaces/User";
import { ImpossibleToRetrieveStatementsError } from "../../../errors";
import { configStore } from "../../../contexts/ConfigContext";
import { sessionStore } from "../../../contexts/SessionContext";
import ExerciseIdentifier from "./ExerciseIdentifier/ExerciseIdentifier";
import Loader from "../../../components/Loader/Loader";
import ChatbotShell from "../Shell/ChatbotShell/ChatbotShell";
import PlaylistSummary from "./PlaylistSummary/PlaylistSummary";
import WizardShell from "../Shell/WizardShell/WizardShell";
import BanditManchotErrorModal from "../../../components/BanditManchotErrorModal/BanditManchotErrorModal";
import { BMPlaylistManager } from "../useBanditManchotPlaylistManager";
import useBanditManchot, {
    BanditManchotSuccess,
} from "../../../hooks/useBanditManchot";
import { addAssetsDetailsToCurrentExercise } from "../../../utils/assetsUtils";
import { InfoPanelProps, PlayerHeaderProps } from "../PlayerBuilder";

import "./PlaylistPlayer.scss";

export interface PlaylistPlayerProps {
    playlistManager: PlaylistManager<ImpossibleToRetrieveStatementsError>;
    InfoPanel?: (props: InfoPanelProps) => JSX.Element | null;
    Header: (props: PlayerHeaderProps<any>) => JSX.Element;
    opts?: ShellOptions
}

const PlaylistPlayer = ({
    playlistManager,
    InfoPanel,
    Header,
    opts
}: PlaylistPlayerProps) => {
    const { moduleId } = useParams<{ moduleId: string }>();
    const { config } = useContext(configStore);
    const {
        session: { userType, version },
    } = useContext(sessionStore);
    const bmInfo = useBanditManchot();
    const {
        config: { i18n },
    } = useContext(configStore);
    const history = useHistory();

    const [progression, setProgression] = useState<number>();

    // Update the progression when the bandit manchot is updated with a new history
    useEffect(() => {
        if (userType === UserType.Teacher || bmInfo.status !== "success")
            return;
        const moduleBM = bmInfo.banditManchot[moduleId];
        if (moduleBM.error) return;

        const diagnostic = BanditManchotWhisperer.getStudentBasicDiagnostic(
            moduleBM.instance
        );
        setProgression(diagnostic.progression);
    }, [bmInfo, userType, moduleId]);

    if (!playlistManager.initialized && playlistManager.error)
        return (
            <BanditManchotErrorModal
                title={i18n.errors.statements.cannotRetrieveHistory.title}
                description={
                    i18n.errors.statements.cannotRetrieveHistory.description
                }
                primaryButton={{
                    label: i18n.errors.statements.cannotRetrieveHistory
                        .goToHomepageButton,
                    fn: () => {
                        history.push("/");
                    },
                }}
            />
        );

    if (
        !playlistManager.initialized ||
        (userType === UserType.Student && typeof progression === "undefined")
    )
        return <Loader />;

    const Shell =
        playlistManager.playlist.activity?.shell === ActivityShell.Chatbot
            ? ChatbotShell
            : WizardShell;

    return (
        <>
            {userType === UserType.Student &&
                (playlistManager as BMPlaylistManager).showIdleModal && (
                    <BanditManchotErrorModal
                        title={config.i18n.exerciseShell.timeoutModalTitle}
                        description={
                            config.i18n.exerciseShell.timeoutModalDescription
                        }
                        primaryButton={{
                            label: config.i18n.exerciseShell
                                .timeoutModalContinueButton,
                            fn: (playlistManager as BMPlaylistManager)
                                .continueAfterIdle,
                        }}
                        secondaryButton={{
                            label: config.i18n.exerciseShell
                                .timeoutModalQuitButton,
                            fn: () => {
                                history.push("/");
                            },
                        }}
                    />
                )}

            {InfoPanel && <InfoPanel playlist={playlistManager.playlist} />}

            <div
                className={`
            playlist-player-container player-container 
            ${
                userType === UserType.Student
                    ? "player-container--bandit-manchot"
                    : ""
            }
            ${
                playlistManager.playlist.activity?.shell ===
                ActivityShell.Chatbot
                    ? "chatbot-player"
                    : "wizard-player"
            }
        `}
            >
                <Header
                    playlistManager={playlistManager}
                    progression={progression}
                />

                <div className="main__wrapper">
                    {!isPlaylistFinished(playlistManager.playlist) ? (
                        <main>
                            <Shell
                                onGoToNextExercise={
                                    playlistManager.goToNextExercise
                                }
                                onExerciseResult={(result, autoGoToNext) => {
                                    playlistManager.recordCurrentExerciseResult(
                                        result,
                                        autoGoToNext
                                    );

                                    if (userType === UserType.Student) {
                                        const diagnostic =
                                            BanditManchotWhisperer.getStudentBasicDiagnostic(
                                                (bmInfo as BanditManchotSuccess)
                                                    .banditManchot[moduleId]
                                                    .instance!
                                            );
                                        setProgression(diagnostic.progression);
                                    }
                                }}
                                playlist={addAssetsDetailsToCurrentExercise(
                                    playlistManager.playlist,
                                    version,
                                    config.apiUrls.endpoints.assetsProxy
                                )}
                                clearHistory={
                                    userType === UserType.Student
                                        ? (playlistManager as BMPlaylistManager)
                                              .clearHistory
                                        : undefined
                                }
                                opts={opts}
                            />
                        </main>
                    ) : (
                        <PlaylistSummary
                            exerciseResults={
                                playlistManager.playlist.exerciseResults
                            }
                        />
                    )}

                    {config.debug?.exerciseIdentifier &&
                        !isPlaylistFinished(playlistManager.playlist) && (
                            <ExerciseIdentifier
                                moduleId={playlistManager.playlist.module.id}
                                objectiveId={
                                    playlistManager.playlist.objective!.id
                                }
                                activityId={
                                    playlistManager.playlist.activity!.id
                                }
                                exerciseId={
                                    playlistManager.playlist.currentExercise!.id
                                }
                            />
                        )}
                </div>
            </div>
        </>
    );
};

const isPlaylistFinished = (playlist: Playlist): boolean =>
    !playlist.currentExercise;

export default PlaylistPlayer;
