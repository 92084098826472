import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { ExerciseResult } from "@evidenceb/gameplay-interfaces";
import { Pagetype } from "../../../../interfaces/Config";
import { configStore } from "../../../../contexts/ConfigContext";
import { getLastResults } from "../../../../utils/exerciseUtils";
import Button from "../../../../components/Button/Button";
import { getUrl } from "../../../../utils/dataRetrieval";
import { sessionStore } from "../../../../contexts/SessionContext";

import "./PlaylistSummary.scss";
import { UserType } from "../../../../interfaces/User";
import ImageAsset from "../../../../components/ImageAsset/ImageAsset";

const PlaylistSummary: React.FC<{
    exerciseResults: ExerciseResult<any>[];
}> = ({ exerciseResults }) => {
    const { config } = useContext(configStore);
    const {
        session: { userType },
    } = useContext(sessionStore);
    const history = useHistory();

    const moduleList = () => {
        config.pages.forEach((page) => {
            if (page.type === Pagetype.MODULELIST) {
                history.push(`/${getUrl(page, userType)}`);
            }
        });
    };

    const dashboard = () => {
        config.pages.forEach((page) => {
            if (page.type === Pagetype.DASHBOARD) {
                history.push(`/${getUrl(page, userType)}`);
            }
        });
    };

    return (
        <div className="playlist-summary-container">
            <div className="content">
                <div className="icon">
                    <ImageAsset src={config.logos.endPlaylistIcon} />
                </div>
                
                <h1>
                    <span>{config.i18n.endPlaylist.congratulations}</span>
                    <span className="roboto">
                        {config.i18n.endPlaylist.completed}
                    </span>
                </h1>

                <p>{config.i18n.endPlaylist.result}</p>
                <ul>
                    {getLastResults(exerciseResults).map(
                        (exerciseResult, index) => (
                            <li key={exerciseResult.exerciseId}>
                                {index + 1}:
                                {exerciseResult.correct ? (
                                    <span className="--correct">
                                        {
                                            config.i18n.endPlaylist
                                                .exerciseCorrect
                                        }
                                    </span>
                                ) : (
                                    <span className="--incorrect">
                                        {
                                            config.i18n.endPlaylist
                                                .exerciseIncorrect
                                        }
                                    </span>
                                )}
                            </li>
                        )
                    )}
                </ul>

                <nav>
                    <Button
                        onClick={moduleList}
                        type={"primary"}
                        label={config.i18n.endPlaylist.moduleList}
                        icons={[ { placement: "right", code:"arrow_forward" } ]}
                    />

                    {userType === UserType.Teacher &&
                        config.features.teacherDashboard && (
                            <Button
                                onClick={dashboard}
                                type={"primary"}
                                label={config.i18n.endPlaylist.dashboard}
                                icons={[ { placement: "right", code:"arrow_forward" } ]}
                            />
                        )}
                </nav>
            </div>
        </div>
    );
};

export default PlaylistSummary;
