import React, { useContext, useEffect, useRef } from 'react';
// External modules
// Contexts
import { configStore } from '../../contexts/ConfigContext';
// Components
import VisuallyHidden from '../VisuallyHidden/VisuallyHidden';
// Utils
// SCSS
import './GoToTopButton.scss';
// Interfaces

const GoToTopButton = () => {
    //Refs
    const buttonContainer = useRef<HTMLDivElement>(null)
    // Contexts
    const {config} = useContext(configStore);
    // States
    // Functions
    const scrollHandler = () => {
        let sliderHeight = document.getElementsByClassName('slider-container')[0].clientHeight;
        let headerTopHeight = document.getElementsByClassName('header-top')[0].clientHeight;
        if (window.pageYOffset > (sliderHeight + headerTopHeight)) {
            buttonContainer.current?.classList.add('show')
        } else {
            buttonContainer.current?.classList.remove('show')
        }
    }
    const goToTop = () => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }
    // UseEffects
    useEffect(() => {
        window.addEventListener("scroll", scrollHandler);
        return () => {
            window.removeEventListener("scroll", scrollHandler);
        };
    }, [])
    return (
        <div ref={buttonContainer} className='GoToTopButton'>
            <button onClick={goToTop}>
                <span className='material-icons' translate='no'>expand_less</span>
                <VisuallyHidden>{config.i18n.misc.goToTopButtonLabel}</VisuallyHidden>
            </button>
        </div>
    )
}

export default GoToTopButton