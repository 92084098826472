import React, { useContext, useState } from "react";
import { Emotion, EMOTIONS } from "../useEmotionalReport";
import Dialog from "@reach/dialog";
import { configStore } from "../../../../../contexts/ConfigContext";

import "./EmotionalReportPopup.scss";

// See reference for html & accessibility here: https://stackoverflow.com/questions/45449106/how-to-make-a-ul-list-of-buttons-accessible/45491521

const EmotionalReportPopup: React.FC<{
    recordReport: (clickedButton: Emotion, recordType: "FORCED" | "SELF-REPORT") => void;
}> = ({ recordReport }) => {
    const {
        config: { i18n },
    } = useContext(configStore);
    const [clickedButtons, setClickedButtons] = useState<Emotion[]>([]);

    return (
        <Dialog className="emotional-report emotional-report__popup">
            <h3>{i18n.exerciseShell.emotionalReport.popupTitle}</h3>
            <div className="popup__content">
                <ul role="listbox">
                    {(Object.keys(EMOTIONS) as Emotion[]).map(
                        (emotion, index) => (
                            <li
                                key={emotion}
                                className={
                                    emotion === Emotion.Neutral
                                        ? "popup__emotion-neutral"
                                        : ""
                                }
                            >
                                {/* eslint-disable-next-line jsx-a11y/role-supports-aria-props */}
                                <button
                                    aria-setsize={Object.keys(EMOTIONS).length}
                                    aria-posinset={index + 1}
                                    onClick={() => {
                                        setClickedButtons((curr) => {
                                            if (curr.includes(emotion))
                                                return curr.filter(
                                                    (clickedEmotion) =>
                                                        clickedEmotion !==
                                                        emotion
                                                );
                                            return [...curr, emotion];
                                        });
                                    }}
                                    className={`${
                                        clickedButtons.includes(emotion)
                                            ? "popup__button--clicked"
                                            : ""
                                    }`}
                                >
                                    {EMOTIONS[emotion].Component()}
                                    <p>
                                        {
                                            i18n.exerciseShell.emotionalReport[
                                                EMOTIONS[emotion].i18nKey
                                            ]
                                        }
                                    </p>
                                </button>
                            </li>
                        )
                    )}
                </ul>

                {clickedButtons.length > 0 && (
                    <button
                        className="popup__send-button"
                        onClick={() => {
                            clickedButtons.forEach((clickedButton) => {
                                recordReport(clickedButton, "FORCED");
                            });
                        }}
                    >
                        {i18n.exerciseShell.emotionalReport.popupSendBtn}{" "}
                        <span className="material-icons" translate="no">send</span>
                    </button>
                )}
            </div>
        </Dialog>
    );
};

export default EmotionalReportPopup;
