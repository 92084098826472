import React, { useContext, useEffect, useState } from "react";
import { syncStore } from "../../../../contexts/Sync";
import { configStore } from "../../../../contexts/ConfigContext";
import useStatements from "../../../../hooks/useStatements";
import BanditManchotErrorModal from "../../../../components/BanditManchotErrorModal/BanditManchotErrorModal";
import ErrorNotification, {
    NotificationType,
} from "../../../../components/ErrorNotification/ErrorNotification";
import NotificationContent from "./NotificationContent/NotificationContent";

import "./StatementsError.scss";

/**
 * Component that handles the error in syncing statements with the LRS. It
 * displays either a notification of a modal when the error first appears
 */
const StatementsError = () => {
    const {
        sync: { unsyncedStatements, syncingStatements },
    } = useContext(syncStore);
    const {
        config: { i18n },
    } = useContext(configStore);
    const { syncStatements, syncingSuccess } = useStatements();
    // Handle alert when statements go out of sync
    // This is not reset when it happens again because the user already knows
    // what the notifications mean
    const [wasModalDismissed, setModalDismissed] = useState<boolean>(false);
    const [wasNotificationDismissed, setWasNotificationDismissed] =
        useState<boolean>(false);

    // Reset dismissal of notification when new statements are unsynced
    useEffect(() => {
        if (typeof syncingSuccess !== "undefined") return;

        setWasNotificationDismissed(false);
    }, [syncingSuccess]);

    if (
        !syncingStatements &&
        !unsyncedStatements &&
        (typeof syncingSuccess === "undefined" ||
            (syncingSuccess && wasNotificationDismissed))
    )
        return <></>;

    if (!wasModalDismissed)
        return (
            <BanditManchotErrorModal
                title={i18n.errors.statements.cannotSendStatement.modal.title}
                description={
                    i18n.errors.statements.cannotSendStatement.modal.description
                }
                primaryButton={{
                    label: i18n.errors.statements.cannotSendStatement.modal
                        .continueButton,
                    fn: () => {
                        setModalDismissed(true);
                    },
                }}
            />
        );

    return (
        <ErrorNotification
            onDismiss={
                !syncingStatements &&
                !wasNotificationDismissed &&
                syncingSuccess
                    ? () => {
                          setWasNotificationDismissed(true);
                      }
                    : undefined
            }
            type={
                !wasNotificationDismissed && syncingSuccess
                    ? NotificationType.Success
                    : syncingStatements
                    ? NotificationType.Neutral
                    : NotificationType.Failure
            }
        >
            <NotificationContent
                syncingStatements={syncingStatements}
                syncingSuccess={syncingSuccess}
                syncStatements={syncStatements}
            />
        </ErrorNotification>
    );
};

export default StatementsError;
