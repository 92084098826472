import parse from "html-react-parser";
import Collapse, { Panel } from "rc-collapse";
import Drawer from "rc-drawer";
import React, { useContext, useState } from "react";
import { configStore } from "../../../../contexts/ConfigContext";
import { getItemDescription } from "../../../../utils/dataRetrieval";
import { InfoPanelProps } from "../../PlayerBuilder";

import "rc-drawer/assets/index.css";
import "rc-collapse/assets/index.css";
import "./TeacherPlayerInfoPanel.scss";
import arrow from "./arrow.svg";

const DrawerExpandIcon = (props: any) => {
    return (
        <img
            className={"custom-drawer-handle-icon"}
            style={{
                transition: "transform .2s",
                transform: `rotate(${props.open ? 90 : -90}deg)`,
            }}
            src={arrow}
            alt="collapse"
        />
    );
};

const CollapseExpandIcon = (props: any) => {
    return (
        <img
            style={{
                verticalAlign: "-.125em",
                transition: "transform .2s",
                transform: `rotate(${props.isActive ? 180 : 0}deg)`,
            }}
            className={"custom-arrow"}
            src={arrow}
            alt="collapse"
        />
    );
};

const TeacherPlayerInfoPanel = ({
    playlist: { activity, objective },
}: InfoPanelProps) => {
    const {
        config: { i18n },
    } = useContext(configStore);
    const [open, setOpen] = useState<boolean>(true);

    if (!activity || !objective) return null;

    const objectiveDescription = getItemDescription(objective, "teacher");
    const activityDescription = getItemDescription(activity, "teacher");

    if (
        !objectiveDescription &&
        !objective.targetedDifficulties?.$html &&
        !activityDescription
    )
        return null;

    return (
        <Drawer
            className="teacher-player-info-panel"
            defaultOpen={open}
            level={null}
            placement="left"
            width={340}
            onChange={(open) => setOpen(open as boolean)}
            handler={
                <div className="drawer-handle">
                    <DrawerExpandIcon open={open} />
                </div>
            }
        >
            <Collapse
                expandIcon={CollapseExpandIcon}
                accordion={false}
                defaultActiveKey={["objective", "activity"]}
            >
                {(objectiveDescription !== "" ||
                    objective.targetedDifficulties?.$html) && (
                    <Panel
                        header={objective.title.long}
                        headerClass={"title"}
                        key={"objective"}
                    >
                        <div className="panel-section">
                            <p className="description">
                                {parse(objectiveDescription)}
                            </p>
                        </div>

                        {objective.targetedDifficulties?.$html ? (
                            <div className="panel-section">
                                <p className="sub-title">
                                    {i18n.drawer.targetedDifficulties}
                                </p>
                                <p className="description">
                                    {parse(
                                        objective.targetedDifficulties?.$html
                                    )}
                                </p>{" "}
                            </div>
                        ) : null}
                    </Panel>
                )}

                {activityDescription !== "" && (
                    <Panel
                        header={activity.title.long}
                        headerClass={"title"}
                        key={"activity"}
                    >
                        <div className="panel-section">
                            <p className="description">
                                {parse(activityDescription)}
                            </p>
                        </div>
                    </Panel>
                )}
            </Collapse>
        </Drawer>
    );
};

export default TeacherPlayerInfoPanel;
