import React, { MouseEvent } from "react";
import Loader from "../Loader/Loader";

import "./Button.scss";

interface Icon {
    placement: "left" | "right"
    code: string;
    noCircle?: boolean;
}

interface ButtonProps {
    type: "primary" | "secondary" | "tertiary";
    centeredLabel?: boolean;
    label: string | undefined;
    icons?: Icon[];
    loader?: boolean;
    onClick?: (e: MouseEvent) => void;
    disabled?:boolean;
}

const Button = ({
    type,
    label,
    icons,
    centeredLabel,
    onClick,
    disabled,
    loader
}: ButtonProps) => {
    return (
        <button
            className={`btn ${type} ${centeredLabel ? "centered" : ""} ${disabled ? "disabled" : ""}`}
            onClick={onClick}
            disabled={disabled}
        >
            { icons && icons.map((icon, i) => 
                <span key={'btnIcon' + i} className={`icon-container ${icon.placement} ${icon.noCircle ? "no-circle" : ''}`}>
                    <span className="icon-wrapper">
                        <span className="material-icons" translate="no">{icon.code}</span>
                    </span>
                </span>         
            )}
            <span className="label">{label}</span>
            { loader && <Loader /> }
        </button>
    );
};

export default Button;
