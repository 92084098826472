import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router";
import { PRHierarchy, MinimalDataItem } from "@evidenceb/gameplay-interfaces";
import { Dashboard } from "../../interfaces/Dashboard";
import { PageState } from "../../interfaces/Status";
import { configStore } from "../../contexts/ConfigContext";
import { dashboardStore } from "../../contexts/DashboardContext";
import { dataStore } from "../../contexts/DataContext";
import { sessionStore } from "../../contexts/SessionContext";
import useAthenaAPIClient from "../../hooks/useAthenaAPIClient";
import { getClassroomUrl } from "../../utils/navigation";
import { getModuleById } from "../../utils/dataRetrieval";
import Loader from "../../components/Loader/Loader";
import Error from "../Error/Error";
import Manager from "./Manager/Manager";
import usePRManager from "./Manager/usePRManager";
import ActionTray from "./ActionTray/ActionTray";
import SafeguardedLeaveLink from "./SafeguardedLeaveLink/SafeguardedLeaveLink";
import ModuleMap from "./ModuleMap/ModuleMap";

import "./PedagogicalResourcesManagement.scss";

/**
 * The level describe the PR items that are displayed in the manager at one
 * point (either all modules, or the objectives within a selected module, etc.)
 */
export interface LevelData {
    /** What level of hierarchy is currently displayed */
    hierarchy: PRHierarchy;
    /** The list of items from that level */
    prPool: MinimalDataItem[];
    /**
     * Data relating to the parent or the current level (eg the module the
     * objectives are from)
     */
    parentData?: ParentLevelData;
}
export interface ParentLevelData extends LevelData {
    /** id of the parent */
    id: string;
    listTitle: string;
}

interface Props {
    title: string;
}

const PedagogicalResourcesManagement = ({ title }: Props) => {
    const { classroomId } = useParams<{ classroomId: string }>();
    const { dashboard, setDashboard } = useContext(dashboardStore);
    const { config } = useContext(configStore);
    const { session } = useContext(sessionStore);
    const { data } = useContext(dataStore);
    const prManager = usePRManager(classroomId);
    const athenaAPIClient = useAthenaAPIClient();

    const [pageState, setPageState] = useState<PageState>(PageState.Loading);
    const [className, setClassName] = useState<string>();
    const [level, setLevel] = useState<LevelData>({
        hierarchy: PRHierarchy.Modules,
        prPool: data.modules,
    });

    // Load dashboard if it wasn't already
    // TODO Refacto error handling when PR is merged
    useEffect(() => {
        // TODO refacto condition when pr is merged - something feels fishy
        if (
            dashboard &&
            dashboard.classrooms &&
            dashboard.classrooms.length > 0
        ) {
            setClassName(
                dashboard.classrooms.find(
                    (classroom) => classroom.id === classroomId
                )?.name
            );
            setPageState(PageState.Loaded);
            return;
        }

        (async () => {
            try {
                const dashboardData =
                    await athenaAPIClient.getTeacherDashboard<Dashboard>(
                        config.features.clustering,
                        session.userId,
                        config.declinaison
                    );
                if (dashboardData.classrooms) {
                    setDashboard(dashboardData);
                    setPageState(PageState.Loaded);
                    setClassName(
                        dashboardData.classrooms.find(
                            (classroom) => classroom.id === classroomId
                        )?.name
                    );
                } else {
                    setPageState(PageState.StatementsError);
                }
            } catch (err) {
                setPageState(PageState.StatementsError);
            }
        })();
    }, [
        dashboard,
        config,
        session,
        classroomId,
        setDashboard,
        athenaAPIClient,
    ]);

    if (pageState === PageState.Loading) return <Loader />;
    /* TEMP Add actual error when PR is merged */
    if (pageState === PageState.StatementsError || !className) return <Error />;

    return (
        <>
            <Helmet>
                {" "}
                <title>{title}</title>{" "}
            </Helmet>

            <div className="pedagogical-resources-management">
                <aside>
                    <div>
                        <nav>
                            <SafeguardedLeaveLink
                                className="sidebar__backlink"
                                to={getClassroomUrl(config.i18n, classroomId)}
                                dirty={prManager.dirty}
                            >
                                <span className="material-icons" translate="no">
                                    chevron_left
                                </span>
                                <span>{className}</span>
                            </SafeguardedLeaveLink>
                        </nav>

                        {level.parentData && (
                            <ModuleMap
                                module={getModuleById(
                                    level.parentData.id,
                                    data
                                )}
                            />
                        )}
                    </div>
                </aside>

                <main>
                    <div className="prm__content-container">
                        <h1>
                            <span>{className}</span>
                            <span>
                                {level.hierarchy === PRHierarchy.Modules
                                    ? config.i18n.prm.home.title
                                    : config.i18n.prm.home.titleGeneric}
                            </span>
                        </h1>
                        <p className="prm__description">
                            {level.hierarchy === PRHierarchy.Modules
                                ? config.i18n.prm.home.description
                                : config.i18n.prm.home.descriptionGeneric ??
                                  config.i18n.prm.home.description}
                        </p>

                        <div className="prm__manager-container">
                            <Manager
                                prLockStatus={prManager.currentPRLockStatus}
                                toggleItems={prManager.toggleItems}
                                level={level}
                                setLevel={setLevel}
                            />
                        </div>
                    </div>

                    <ActionTray {...prManager} classroomId={classroomId} />
                </main>
            </div>
        </>
    );
};

export default PedagogicalResourcesManagement;
