import React, {DetailedHTMLProps, VideoHTMLAttributes, useContext} from "react";
import {getAssetUrl} from "../../utils/assetsUtils";
import {sessionStore} from "../../contexts/SessionContext";
import { configStore } from "../../contexts/ConfigContext";

const VideoAsset = ({
    src,
    poster,
    ...props
}: DetailedHTMLProps<
    VideoHTMLAttributes<HTMLVideoElement>,
    HTMLVideoElement
> & { src: string; poster: string }) => {
    const {
        session: { version },
    } = useContext(sessionStore);
    const {
        config: { apiUrls },
    } = useContext(configStore);
    return (
        <video
            {...props}
            src={getAssetUrl(src, version, apiUrls.endpoints.assetsProxy)}
            poster={getAssetUrl(poster, version, apiUrls.endpoints.assetsProxy)}
            controls
        />
    );
};

export default VideoAsset