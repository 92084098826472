import React, { useContext } from "react";
import { configStore } from "../../../../../contexts/ConfigContext";

interface Props {
    syncingStatements: boolean;
    syncingSuccess?: boolean;
    syncStatements: () => void;
}

const NotificationContent = ({
    syncingStatements,
    syncingSuccess,
    syncStatements,
}: Props) => {
    const {
        config: { i18n },
    } = useContext(configStore);

    if (syncingStatements)
        return (
            <p>
                {
                    i18n.errors.statements.cannotSendStatement.notification
                        .attemptingToSync
                }
            </p>
        );

    if (syncingSuccess)
        return (
            <p className="notification-message--with-icon --success">
                <span className="material-icons" translate="no">
                    check_circle
                </span>
                {
                    i18n.errors.statements.cannotSendStatement.notification
                        .syncSuccess
                }
            </p>
        );

    return (
        <>
            <p>
                {
                    i18n.errors.statements.cannotSendStatement.notification
                        .description
                }
            </p>
            <button
                onClick={() => {
                    syncStatements();
                }}
            >
                {
                    i18n.errors.statements.cannotSendStatement.notification
                        .tryToSyncButton
                }
            </button>
        </>
    );
};

export default NotificationContent;
