import React from "react";

const NeutralBot = () => (
    <svg
        viewBox="0 0 112 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M90.5996 29.2078C90.5996 37.0474 92.9981 43.3999 99.5083 43.3999C106.018 43.3999 111.34 37.0474 111.34 29.2078C111.34 21.3682 106.056 15.0156 99.522 15.0156C92.9878 15.0156 90.5996 21.3716 90.5996 29.2078Z"
            fill="#E66342"
        />
        <path
            d="M95.2319 43.3999C100.587 43.3999 104.929 37.0459 104.929 29.2078C104.929 21.3697 100.587 15.0156 95.2319 15.0156C89.8765 15.0156 85.5352 21.3697 85.5352 29.2078C85.5352 37.0459 89.8765 43.3999 95.2319 43.3999Z"
            fill="#2C3E50"
        />
        <path
            d="M65.0769 2.27001C59.6896 0.458593 53.9358 0.0181664 48.3355 0.988538C26.2524 4.75758 10.4087 28.9137 13.0984 55.1257C13.2389 56.4963 14.0647 57.5721 15.1303 57.9251L27.1809 61.8997C26.1153 61.557 25.2896 60.4812 25.1457 59.1003C22.4045 32.902 38.166 8.73221 60.1977 4.96316C65.7871 3.98912 71.5313 4.42727 76.9083 6.23779L65.0769 2.27001Z"
            fill="#61A4BF"
        />
        <path
            d="M60.1972 4.95947C82.0885 1.19043 102.177 18.9461 105.22 44.7777C105.436 46.6314 104.346 48.3617 102.784 48.6427L28.4139 61.9851C26.8138 62.2695 25.361 60.9777 25.1622 59.1001C22.4074 32.9018 38.1689 8.74565 60.1972 4.95947Z"
            fill="#AFDCE0"
        />
        <path
            d="M49.3801 38.3714C49.2082 38.3703 49.0382 38.3353 48.8798 38.2686C48.5347 38.1087 48.2632 37.8238 48.1201 37.4715C47.977 37.1191 47.973 36.7255 48.1089 36.3703C48.6413 34.849 49.5684 33.4964 50.7955 32.4512C52.0225 31.406 53.5052 30.7058 55.0919 30.4221C56.6635 30.1864 58.2697 30.387 59.7351 31.0022C61.2004 31.6173 62.4685 32.6233 63.4009 33.9102C63.6286 34.2175 63.7331 34.5989 63.6938 34.9793C63.6544 35.3598 63.4742 35.7118 63.1885 35.966C63.0507 36.0859 62.8892 36.1755 62.7147 36.2292C62.5401 36.283 62.3562 36.2995 62.1748 36.2779C61.9934 36.2563 61.8186 36.1969 61.6615 36.1037C61.5045 36.0104 61.3687 35.8853 61.2629 35.7365C60.6154 34.8446 59.7357 34.1475 58.7196 33.7209C57.7034 33.2944 56.5897 33.1548 55.4997 33.3174C54.4006 33.5131 53.3731 33.9967 52.522 34.719C51.6708 35.4414 51.0265 36.3765 50.6547 37.4291C50.566 37.6988 50.3958 37.9344 50.1674 38.1032C49.9391 38.272 49.664 38.3657 49.3801 38.3714Z"
            fill="#2C3E50"
        />
        <path
            d="M76.9601 33.9377C76.7882 33.937 76.6181 33.902 76.4598 33.8349C76.1136 33.6763 75.8407 33.3917 75.6969 33.0391C75.553 32.6864 75.5489 32.2922 75.6855 31.9367C76.8539 28.7707 79.5299 26.4921 82.6685 25.9884C84.2406 25.7528 85.8472 25.9535 87.3131 26.5686C88.7789 27.1837 90.0477 28.1896 90.9809 29.4765C91.209 29.784 91.3133 30.1658 91.2733 30.5465C91.2334 30.9272 91.052 31.279 90.7651 31.5324C90.6275 31.6521 90.4664 31.7417 90.2921 31.7954C90.1178 31.8491 89.9341 31.8657 89.753 31.844C89.5719 31.8224 89.3974 31.7631 89.2406 31.6698C89.0838 31.5766 88.9483 31.4516 88.8429 31.3028C88.1957 30.4098 87.3155 29.7119 86.2985 29.2852C85.2815 28.8586 84.1668 28.7197 83.0762 28.8838C81.9778 29.08 80.951 29.5639 80.1005 30.2861C79.25 31.0084 78.6062 31.9433 78.2347 32.9954C78.146 33.2652 77.9758 33.5007 77.7474 33.6695C77.5191 33.8383 77.244 33.932 76.9601 33.9377Z"
            fill="#2C3E50"
        />
        <path
            d="M65.0388 42.5604L78.2236 40.4394C78.3173 40.4287 78.4122 40.4402 78.5006 40.4728C78.5891 40.5054 78.6687 40.5584 78.733 40.6274C78.7973 40.6963 78.8446 40.7794 78.8709 40.87C78.8973 40.9605 78.9021 41.056 78.8849 41.1487C78.6587 42.742 77.5623 46.1718 72.4055 47.001C67.2488 47.8302 65.2546 44.8972 64.6002 43.4444C64.5593 43.3586 64.5384 43.2646 64.5391 43.1695C64.5398 43.0745 64.5621 42.9808 64.6044 42.8957C64.6466 42.8105 64.7077 42.7361 64.783 42.678C64.8582 42.6199 64.9457 42.5797 65.0388 42.5604Z"
            fill="#FCFDF4"
        />
        <path
            d="M42.5759 45.7298C44.1484 45.7298 45.4232 44.3676 45.4232 42.6872C45.4232 41.0068 44.1484 39.6445 42.5759 39.6445C41.0033 39.6445 39.7285 41.0068 39.7285 42.6872C39.7285 44.3676 41.0033 45.7298 42.5759 45.7298Z"
            fill="#E66342"
        />
        <path
            d="M96.3141 37.0892C97.8867 37.0892 99.1615 35.727 99.1615 34.0466C99.1615 32.3661 97.8867 31.0039 96.3141 31.0039C94.7416 31.0039 93.4668 32.3661 93.4668 34.0466C93.4668 35.727 94.7416 37.0892 96.3141 37.0892Z"
            fill="#E66342"
        />
        <path
            d="M16.7807 56.5816C22.136 56.5816 26.4774 50.2275 26.4774 42.3894C26.4774 34.5513 22.136 28.1973 16.7807 28.1973C11.4254 28.1973 7.08398 34.5513 7.08398 42.3894C7.08398 50.2275 11.4254 56.5816 16.7807 56.5816Z"
            fill="#2C3E50"
        />
        <path
            d="M21.4139 42.3894C21.4139 50.229 19.0154 56.585 12.5052 56.585C5.99506 56.585 0.660156 50.229 0.660156 42.3894C0.660156 34.5498 5.95737 28.1973 12.4915 28.1973C19.0257 28.1973 21.4139 34.5532 21.4139 42.3894Z"
            fill="#E66342"
        />
        <path
            d="M12.8169 36.4579H11.9398C11.9398 34.5974 12.022 32.61 12.0974 30.6878C12.255 26.8742 12.4126 22.9305 11.9466 19.8433C10.3328 9.2043 5.90587 5.67853 5.86133 5.64426L6.39585 4.95898C6.58772 5.10632 11.1414 8.69033 12.8135 19.7233C13.2932 22.8928 13.1322 26.8777 12.978 30.7324C12.8992 32.6375 12.8169 34.6145 12.8169 36.4579Z"
            fill="#E66342"
        />
        <path
            d="M9.39971 7.23331C10.0947 5.41231 9.18192 3.37269 7.36092 2.67768C5.53993 1.98267 3.5003 2.89547 2.8053 4.71647C2.11029 6.53746 3.02309 8.57709 4.84408 9.27209C6.66508 9.9671 8.7047 9.0543 9.39971 7.23331Z"
            fill="#2C3E50"
        />
        <path
            d="M25.958 63.085C25.3517 63.0847 24.7518 62.9612 24.1946 62.722C23.6374 62.4829 23.1346 62.1331 22.7167 61.6939L13.6504 52.1617L14.7091 51.1543L23.7754 60.6831C24.1151 61.0408 24.5373 61.3096 25.005 61.4662C25.4728 61.6227 25.9718 61.6621 26.4583 61.5808L52.8621 57.1813L53.102 58.6239L26.7016 63.0234C26.4558 63.0645 26.2072 63.0851 25.958 63.085Z"
            fill="#E66342"
        />
        <path
            d="M57.4618 54.6898L49.5338 55.9966C48.266 56.2056 47.4077 57.4028 47.6167 58.6705L47.6172 58.6739C47.8262 59.9417 49.0234 60.8001 50.2912 60.5911L58.2191 59.2843C59.4869 59.0754 60.3452 57.8782 60.1363 56.6104L60.1357 56.607C59.9267 55.3392 58.7296 54.4809 57.4618 54.6898Z"
            fill="#2C3E50"
        />
    </svg>
);

export default NeutralBot;