import React from "react";

import "./ProgressBarWithLabel.scss";

const NO_PROGRESS_COLOR = "#F2F6FB";
const PLACEHOLDER_PROGRESS_IS_NAN = "_ _ _";

/**
 * Generic progress bar component
 *
 * This progress has the label and the percentage displayed within the progress
 * bar
 */
const ProgressBarWithLabel: React.FC<{
    /** Should be expressed as a percentage */
    progress: number;
    label: string;
    color: string;
}> = ({ progress, label, color }) => {
    const backgroundColor = getBackgroundColor(progress, color);

    return (
        <div
            className={`progress-bar-with-label ${
                hasNoProgress(progress) ? "--no-progress" : ""
            }`}
        >
            <span>{label}</span>
            <span>
                {isNaN(progress)
                    ? PLACEHOLDER_PROGRESS_IS_NAN
                    : Math.round(progress) + "%"}
            </span>

            <div
                className="progress-bar__background"
                style={{
                    backgroundColor,
                }}
            ></div>
            <div
                className="progress-bar"
                style={{
                    width: progress + "%",
                    backgroundColor,
                }}
            ></div>
        </div>
    );
};

const hasNoProgress = (progress: number): boolean => {
    if (isNaN(progress) || progress === 0) return true;
    return false;
};

const getBackgroundColor = (progress: number, color: string): string => {
    if (hasNoProgress(progress)) return NO_PROGRESS_COLOR;
    return color;
};

export default ProgressBarWithLabel;
