import React, { useContext, useEffect, useState } from "react";
import parse from "html-react-parser";
import Drawer from "rc-drawer";
import {
    Activity,
    Objective,
    HtmlString,
    Playlist,
} from "@evidenceb/gameplay-interfaces";
import {
    FullDiagnosis,
    Status,
    BanditManchotWhisperer,
    SuccessfullSingleBanditManchot,
} from "@evidenceb/bandit-manchot";
import { Data } from "../../../../interfaces/Data";
import { dataStore } from "../../../../contexts/DataContext";
import { configStore } from "../../../../contexts/ConfigContext";
import useBanditManchot from "../../../../hooks/useBanditManchot";
import { getActivityById, getObjectiveById, getItemDescription } from "../../../../utils/dataRetrieval";
import CurrentObjective from "./CurrentObjective/CurrentObjective";
import ObjectiveBubble from "./ObjectiveBubble/ObjectiveBubble";

import "./StudentPlayerInfoPanel.scss";

enum JourneyObjectiveStatus {
    PAST = -1,
    CURRENT = 0,
    IN_PROGRESS = 1,
    FUTURE = 2,
}

type JourneyObjective = {
    status: JourneyObjectiveStatus;
    description: HtmlString;
};

interface Journey {
    journeyObjectives: JourneyObjective[];
    currentActivities: (Activity & { status: Status })[];
}

type StudentPlayerInfoPanelProps = {
    playlist: Playlist;
};

/**
 * Left panel in the chatbot shell for students, showing their completed
 * objectives.
 *
 * Feature activated with the studentChatbotPlayerInfoPanel flag.
 */
const StudentPlayerInfoPanel = ({
    playlist,
}: StudentPlayerInfoPanelProps) => {
    const {
        config: { i18n },
    } = useContext(configStore);
    const { data } = useContext(dataStore);
    const bmInfo = useBanditManchot();
    const [open, setOpen] = useState<boolean>(true);
    const [journey, setJourney] = useState<Journey>({
        currentActivities: [],
        journeyObjectives: [],
    });
    const [moduleBM, setModuleBM] = useState<SuccessfullSingleBanditManchot>();

    // Add moduleBM to state
    useEffect(() => {
        if (bmInfo.status !== "success" || moduleBM) return;
        const _moduleBM = bmInfo.banditManchot[playlist.module.id];
        if (_moduleBM.error) return;

        setModuleBM(_moduleBM);
    }, [bmInfo, playlist.module.id, moduleBM]);

    // Update the journey when the bandit manchot is loaded or the student
    // progresses
    useEffect(() => {
        if (!moduleBM) return;

        setJourney(
            makeStudentJourney(
                data,
                BanditManchotWhisperer.getStudentFullDiagnosis(
                    moduleBM.instance
                ),
                playlist.objective!,
                playlist.activity!.id,
                i18n.misc.colon
            )
        );
    }, [
        data,
        i18n.misc.colon,
        playlist,
        moduleBM
    ]);

    // No need for particular error checking because it would be caught higher
    // up

    if (playlist.isInitialTest) return null;

    return (
        <Drawer
            className="chatbot-student-drawer"
            defaultOpen={open}
            level={null}
            placement="left"
            width={340}
            onChange={(open) => setOpen(open as boolean)}
            handler={
                <div className="drawer-handle">
                    <DrawerExpandIcon open={open} />
                </div>
            }
        >
            <div>
                <div className="title" key={"drawer-title"}>
                    {i18n.features.studentChatbotPlayerInfoPanel.title}
                </div>
                <div
                    className="journey-objectives-container"
                    key={"drawer-objectives"}
                >
                    {journey.journeyObjectives.map(
                        (journeyObjective, index) => (
                            <>
                                {journeyObjective.status ===
                                JourneyObjectiveStatus.CURRENT ? (
                                    <CurrentObjective
                                        activities={journey.currentActivities}
                                        objective={playlist.objective!}
                                        currentActivityId={
                                            playlist.activity!.id
                                        }
                                        key={"journey-objective-" + index}
                                    />
                                ) : (
                                    <div
                                        className="objective"
                                        key={"journey-objective-" + index}
                                    >
                                        <ObjectiveBubble
                                            objectiveFinished={
                                                journeyObjective.status ===
                                                JourneyObjectiveStatus.PAST
                                            }
                                            objectiveInProgress={
                                                journeyObjective.status ===
                                                JourneyObjectiveStatus.IN_PROGRESS
                                            }
                                        />
                                        <div className="objective-description">
                                            {parse(
                                                journeyObjective.description
                                                    .$html
                                            )}
                                        </div>
                                    </div>
                                )}
                            </>
                        )
                    )}
                </div>
            </div>
        </Drawer>
    );
};

const DrawerExpandIcon: React.FC<{ open: boolean }> = ({ open }) => {
    let classes =
        "custom-drawer-handle-icon material-icons open" +
        (open ? " opened" : "");
    return (
        <span className={classes} translate="no">
            chevron_right
        </span>
    );
};

const translateStatus = (diagnosticStatus: string): JourneyObjectiveStatus => {
    if (diagnosticStatus === "none") return JourneyObjectiveStatus.FUTURE;
    if (diagnosticStatus === "inProgress")
        return JourneyObjectiveStatus.IN_PROGRESS;

    // Bandit Manchot sometimes gives exercises from completed objectives
    if (diagnosticStatus === "completed") return JourneyObjectiveStatus.PAST;

    throw new Error("Unknown status: " + diagnosticStatus);
};

/**
 * Retrieve the numbering of the objective
 */
export const getObjectiveNumber = (
    objective: Objective,
    i18nColon: string
): string => {
    const match = objective.title.short?.match("^O[0-9]+");
    return match ? match[0] + i18nColon : "";
};

const makeStudentJourney = (
    data: Data,
    studentDiagnostic: FullDiagnosis,
    currentObjective: Objective,
    currentActivityId: string,
    i18nColon: string
): Journey => {

    const journeyObjectives = studentDiagnostic.objectives.map(
        (objectiveDiagnosis) => {
            const objective = getObjectiveById(objectiveDiagnosis.id, data);
            const objectiveNumber = getObjectiveNumber(objective, i18nColon);
            const description = getItemDescription(objective, "student")
            return {
                description: {
                    $html: objectiveNumber + description
                },
                status:
                    currentObjective.id === objectiveDiagnosis.id
                        ? JourneyObjectiveStatus.CURRENT
                        : translateStatus(objectiveDiagnosis.status),
            };
        }
    );

    const currentObjectiveDiagnosis = studentDiagnostic.objectives.find(
        (objectiveDiagnosis) => objectiveDiagnosis.id === currentObjective.id
    )!;
    const currentActivities: Journey["currentActivities"] =
        currentObjective.activityIds.map((activityId) => {
            const activity = getActivityById(activityId, data);
            if (activity.id === currentActivityId)
                return {
                    ...activity,
                    status: "inProgress",
                };

            const status = currentObjectiveDiagnosis.progression
                // The array needs to be reversed because the
                // progression is an additive array where the updated
                // status of an array is added at the end. So the most
                // up to date status of an activity is the one
                // associated with the last apperance of an object with
                // the correct id in the array
                // Slice is used before because reverse is an in place function
                // and we don't want to mutate the original array
                .slice()
                .reverse()
                .find(
                    (activityDiagnosis) => activityDiagnosis.id === activityId
                )?.status;
            return {
                ...activity,
                status: status ?? "none",
            };
        });

    return {
        journeyObjectives,
        currentActivities,
    };
};

export default StudentPlayerInfoPanel;
