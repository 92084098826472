import React, { useContext, useEffect, useState, useRef } from "react";
import Dialog from "rc-dialog";
import {
    ExerciseResult,
    HtmlString,
    Playlist,
    PlaylistExecutionStage,
    Shell,
} from "@evidenceb/gameplay-interfaces";
import { UserType } from "../../../../interfaces/User";
import { configStore } from "../../../../contexts/ConfigContext";
import { sessionStore } from "../../../../contexts/SessionContext";
import { getFeedbackButtonText } from "../../../../utils/feedbackUtils";
import { parse } from "../../../../utils/parse-text";
import EmotionalReport from "../../PlaylistPlayer/EmotionalReport/EmotionalReport";
import StatementsError from "../../PlaylistPlayer/StatementsError/StatementsError";
import useEmotionalReport from "../../PlaylistPlayer/EmotionalReport/useEmotionalReport";
import Loader from "../../../../components/Loader/Loader";
import Button from "../../../../components/Button/Button";
import WizardFeedbackDisplay from "./WizardFeedbackDisplay/WizardFeedbackDisplay";
import ImageAsset from "../../../../components/ImageAsset/ImageAsset";

import "rc-dialog/assets/index.css";
import "./WizardShell.scss";

interface Props<Data, Answer> extends Shell<Data, Answer> {
    playlist: Playlist;
}

/**
 * The WizardShell instanciates the Gameplay component from the library and
 * handles interactions with it.
 */
const WizardShell: React.FC<Props<any, any>> = ({
    playlist,
    onGoToNextExercise,
    onExerciseResult,
}) => {
    const { config } = useContext(configStore);
    const { session } = useContext(sessionStore);
    const emotionalReportController = useEmotionalReport();
    const [exerciseIndex, setExerciseIndex] = useState<number>(0);
    const [animateFeedback, setAnimateFeedback] = useState<boolean>(false);
    const [initialTestModalContent, setInitialTestModalContent] = useState<
        HtmlString | undefined
    >(
        playlist.isInitialTest
            ? config.i18n.exerciseShell.initialTestMessages.start
            : undefined
    );

    const gpWrapper = useRef<HTMLDivElement>(null);
    const feedback = useRef<HTMLDivElement>(null);

    // Animate the fedback panel
    useEffect(() => {
        if (!isExerciseSolved(playlist?.currentExerciseResult)) return;

        setTimeout(() => {
            setAnimateFeedback(true);
        });
    }, [playlist?.currentExerciseResult]);

    // Show end of initial test modal
    useEffect(() => {
        if (
            playlist.currentExecutionStage ===
            PlaylistExecutionStage.ShowingEndOfInitialTestMessage
        )
            setInitialTestModalContent(
                config.i18n.exerciseShell.initialTestMessages.end
            );
    }, [playlist.currentExecutionStage, config.i18n.exerciseShell.initialTestMessages.end]);

    if (!playlist || !playlist.currentExercise) return <Loader />;

    return (
        <div
            className={`wizard-shell-container ${
                config.features.studentChatbotPlayerInfoPanel &&
                session.userType === UserType.Student &&
                !playlist.isInitialTest
                    ? "--pannel-offset"
                    : ""
            }`}
        >
            {!!initialTestModalContent && (
                <Dialog
                    visible
                    closable={false}
                    onClose={() => setInitialTestModalContent(undefined)}
                    wrapClassName="initial-test-modal"
                    maskStyle={{
                        backgroundColor: "rgba(0, 0, 0, 0.15)",
                    }}
                >
                    <ImageAsset src={config.logos.avatar} />
                    <p>
                        {parse(initialTestModalContent.$html)}
                        <Button
                            onClick={() => {
                                setInitialTestModalContent(undefined);
                            }}
                            label={
                                playlist.currentExecutionStage ===
                                PlaylistExecutionStage.ShowingEndOfInitialTestMessage
                                    ? config.i18n.exerciseShell
                                          .initialTestMessages.btnEnd
                                    : config.i18n.exerciseShell
                                          .initialTestMessages.btnStart
                            }
                            type={"primary"}
                            centeredLabel
                        />
                    </p>
                </Dialog>
            )}

            <div className="notifications__container">
                <StatementsError />
            </div>

            {playlist.currentExercise.instruction && (
                <div className="instruction">
                    <div className="content">
                        <p>
                            {parse(playlist.currentExercise.instruction.$html)}
                        </p>
                    </div>
                </div>
            )}

            <div
                className="gp-container"
                style={{
                    paddingBottom:
                        !playlist.isInitialTest &&
                        isExerciseSolved(playlist.currentExerciseResult) &&
                        playlist.currentExercise.executionOptions
                            ?.showFeedback &&
                        feedback.current
                            ? config.features.emotionalReport &&
                              session.userType === UserType.Student
                                ? feedback.current.clientHeight - 30
                                : feedback.current.clientHeight + 24
                            : 0,
                }}
            >
                <div
                    className={`gp-wrapper ${
                        isExerciseSolved(playlist.currentExerciseResult)
                            ? "solved"
                            : ""
                    }`}
                    ref={gpWrapper}
                >
                    <playlist.currentExercise.Gameplay
                        key={exerciseIndex}
                        data={playlist.currentExercise.data}
                        nonInteractiveOptions={
                            playlist.comingNext === "retry"
                                ? {
                                      ...playlist.currentExercise
                                          .executionOptions,
                                      showCorrectAnswer: false,
                                  }
                                : playlist.currentExercise.executionOptions
                        }
                        showCorrectAnswer={
                            !isExerciseSolved(playlist.currentExerciseResult) ||
                            playlist.comingNext === "retry"
                                ? false
                                : playlist.currentExercise.showCorrectAnswer!
                        }
                        i18n={{
                            ...config.i18n.gameplays.generic,
                            ...config.i18n.gameplays[
                                playlist.currentExercise.type
                            ],
                        }}
                        onExerciseResult={(result) => {
                            if (
                                config.features.emotionalReport &&
                                session.userType === UserType.Student &&
                                playlist.comingNext !== "retry" &&
                                !playlist.isInitialTest
                            )
                                emotionalReportController.recordValidatedExercise();
                            onExerciseResult(result, playlist.isInitialTest);

                            if (playlist.isInitialTest) setExerciseIndex(curr => curr + 1);
                        }}
                        onTrackingEvent={(statement) => {
                            // TODO: Handle tracking when AI should be implemented
                            console.log("Tracking:", statement);
                        }}
                        assetsDetails={playlist.currentExercise.assetsDetails}
                    />
                </div>

                {config.features.emotionalReport &&
                    session.userType === UserType.Student &&
                    !playlist.isInitialTest && (
                        <div
                            className={`emotional-report__container--wizard ${
                                isExerciseSolved(playlist.currentExerciseResult)
                                    ? "emotional-report__container--feedback"
                                    : ""
                            }`}
                        >
                            <EmotionalReport
                                {...emotionalReportController}
                                hideSpeechBubbles={
                                    !playlist.isInitialTest &&
                                    isExerciseSolved(
                                        playlist.currentExerciseResult
                                    ) &&
                                    playlist.currentExercise.executionOptions
                                        ?.showFeedback
                                }
                            />
                        </div>
                    )}

                {!playlist.isInitialTest &&
                    isExerciseSolved(playlist.currentExerciseResult) && (
                        <div
                            className={`wizard-feedback-container ${
                                animateFeedback ? "--animate-feedback" : ""
                            }`}
                            ref={feedback}
                        >
                            {playlist.currentExercise.executionOptions
                                ?.showFeedback ? (
                                <WizardFeedbackDisplay
                                    isCorrect={
                                        playlist.currentExerciseResult!.correct
                                    }
                                    {...playlist.currentExerciseResult!
                                        .feedback}
                                />
                            ) : null}

                            <div className="navigation-button">
                                <Button
                                    type="primary"
                                    label={getFeedbackButtonText(
                                        playlist.comingNext!,
                                        config
                                    )}
                                    icons={[
                                        { placement: "right", code: playlist.comingNext === "endOfPlaylist" ? "" : "arrow_forward"}   
                                    ]}
                                    centeredLabel={
                                        playlist.comingNext === "endOfPlaylist"
                                            ? true
                                            : false
                                    }
                                    onClick={() => {
                                        onGoToNextExercise(playlist.comingNext);
                                        setExerciseIndex((curr) => curr + 1);
                                    }}
                                />
                            </div>
                        </div>
                    )}
            </div>
        </div>
    );
};

const isExerciseSolved = (
    exerciseResult: ExerciseResult<any> | undefined
): boolean => typeof exerciseResult !== "undefined";

export default WizardShell;
