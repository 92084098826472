import React, { useContext, Fragment, useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import appConfig from "../../config";
// Pages
import Home from "../Home/Home/Home";
import HomeEllaStudent from "../Home/HomeEllaStudent/HomeEllaStudent";
import ModuleList from "../ModuleList/ModuleList";
import DashboardPage from "../DashBoard/Dashboard";
import ContentPage from "../ContentPage/ContentPage";
import Error from "../Error/Error";
import ModuleListStudent from "../ModuleListStudent/ModuleListStudent";

// Components
import Loader from "../../components/Loader/Loader";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Dev from "../../components/Dev/Dev";
import ErrorModal from "../../components/ErrorModal/ErrorModal";

import { configStore } from "../../contexts/ConfigContext";
import { sessionStore } from "../../contexts/SessionContext";
import { errorStore } from "../../contexts/ErrorContext";

import useInitApp from "../../hooks/useInitApp";
import { getLabel, getUrl } from "../../utils/dataRetrieval";
import { AppStatus } from "../../interfaces/Status";
import PedagogicalResourcesManagement from "../PedagogicalResourcesManagement/PedagogicalResourcesManagement";
import { UserType } from "../../interfaces/User";

import "./Root.scss";
import PlayerBuilder from "../PlayerBuilder/PlayerBuilder";

function Root() {
    const { config } = useContext(configStore);
    const { errorInfo } = useContext(errorStore);
    const { status, setStatus } = useInitApp();
    const {
        session: { userType, flags },
    } = useContext(sessionStore);
    const [displayHeader, setDisplayHeader] = useState<boolean>(true);

    useEffect(() => {
        setDisplayHeader(!flags.displaySignIn);
    }, [flags.displaySignIn]);

    useEffect(() => {
        if(errorInfo.page)
            setStatus(AppStatus.Error)
    },[errorInfo, setStatus])

    return (
        <Router basename={appConfig.basePath}>
            <Dev />
            {status === AppStatus.Loaded ? (
                <Switch>
                    <Route
                        path="/player/:moduleId/:objectiveId?"
                        children={<PlayerBuilder />}
                    />
                    <Route
                        path="/play/:exerciseId"
                        children={<PlayerBuilder />}
                    />
                    <Route path="/error" children={<Error />} />

                    {/* TEMP: Use the page system when we're ready to ammend config.jsons */}
                    {userType === UserType.Teacher && (
                        <Route
                            path="/prm/:classroomId"
                            children={
                                <PedagogicalResourcesManagement title={"PRM"} />
                            }
                        />
                    )}

                    <Fragment>
                        <div className="content">
                            {displayHeader ? (
                                <Header
                                    logo={config.logos.header_client}
                                    pages={config.pages}
                                />
                            ) : null}
                            {config.pages.map((page, i) =>
                                page.type === "HOME" ? (
                                    <Route
                                        key={`route-${i}`}
                                        path={`/${getUrl(page, userType)}`}
                                        exact
                                        render={() =>
                                            userType === UserType.Student ? (
                                                <HomeEllaStudent
                                                    title={`${
                                                        config.client_name
                                                    } - ${getLabel(
                                                        page,
                                                        userType
                                                    )}`}
                                                />
                                            ) : (
                                                <Home
                                                    title={`${
                                                        config.client_name
                                                    } - ${getLabel(
                                                        page,
                                                        userType
                                                    )}`}
                                                />
                                            )
                                        }
                                    />
                                ) : page.type === "MODULELIST" ? (
                                    <Route
                                        key={`route-${i}`}
                                        path={`/${getUrl(page, userType)}`}
                                        render={() =>
                                            userType === UserType.Student ? (
                                                <ModuleListStudent
                                                    title={`${
                                                        config.client_name
                                                    } - ${getLabel(
                                                        page,
                                                        userType
                                                    )}`}
                                                />
                                            ) : (
                                                <ModuleList
                                                    title={`${
                                                        config.client_name
                                                    } - ${getLabel(
                                                        page,
                                                        userType
                                                    )}`}
                                                />
                                            )
                                        }
                                    />
                                ) : page.type === "DASHBOARD" &&
                                  config.features.teacherDashboard &&
                                  userType === UserType.Teacher ? (
                                    <Route
                                        key={`route-${i}`}
                                        path={`/${getUrl(page, userType)}`}
                                        render={() => (
                                            <DashboardPage
                                                title={`${
                                                    config.client_name
                                                } - ${getLabel(
                                                    page,
                                                    userType
                                                )}`}
                                            />
                                        )}
                                    />
                                ) : page.type === "CONTENTPAGE" &&
                                  page.authorized?.find(
                                      (user) => user === userType
                                  ) ? (
                                    <Route
                                        key={`route-${i}`}
                                        path={`/${getUrl(page, userType)}`}
                                        render={() => (
                                            <ContentPage
                                                title={`${
                                                    config.client_name
                                                } - ${getLabel(
                                                    page,
                                                    userType
                                                )}`}
                                            />
                                        )}
                                    />
                                ) : null
                            )}
                        </div>
                        <Footer
                            logos={config.logos}
                            pages={config.pages}
                            contact={config.contact}
                            i18n={{
                                contact: config.i18n.misc.contact,
                                developedBy: config.i18n.misc.developedBy,
                            }}
                        />
                    </Fragment>
                </Switch>
            ) : status === AppStatus.Error ? (
                errorInfo.page && <Error />
            ) : (
                <Loader />
            )}
            {errorInfo.displayModal && errorInfo.modal && (
                <ErrorModal {...errorInfo.modal} />
            )}
        </Router>
    );
}

export default Root;
