import React from "react";
import { ClusterInfosClustering } from "../../../interfaces/Dashboard";

import "./ClustersVisualRepresentation.scss";

type StudentCirclesProps = {
    selectedGroups: number[];
    setSelectedGroups: React.Dispatch<React.SetStateAction<number[]>>;
    clusters: ClusterInfosClustering[];
    selectedStudentId?: string;
};

const ClustersVisualRepresentation: React.FC<StudentCirclesProps> = ({
    clusters,
    selectedGroups,
    setSelectedGroups,
    selectedStudentId,
}) => {
    return (
        <div className="circles-container">
            {clusters.map((cluster, clusterIndex) => (
                <div
                    key={"circles" + clusterIndex}
                    className={`circle circle-border-${clusterIndex + 1} ${
                        selectedGroups.includes(clusterIndex) ? "shadow" : ""
                    }`}
                    onClick={() => {
                        selectedGroups.includes(clusterIndex)
                            ? setSelectedGroups((curr) =>
                                  curr.filter((i) => i !== clusterIndex)
                              )
                            : setSelectedGroups((curr) => [
                                  ...curr,
                                  clusterIndex,
                              ]);
                    }}
                >
                    {cluster.eleves.map((student) => {
                        return (
                            <span
                                className={`material-icons student student-cluster-${
                                    clusterIndex + 1
                                } ${
                                    student === selectedStudentId
                                        ? "selected"
                                        : ""
                                }`}
                                key={student}
                                translate="no"
                            >
                                account_circle
                            </span>
                        );
                    })}
                </div>
            ))}
        </div>
    );
};

export default ClustersVisualRepresentation;
