import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { PRTree } from "@evidenceb/gameplay-interfaces";
import { ModuleDashboard } from "../../../../../interfaces/Dashboard";
import { dataStore } from "../../../../../contexts/DataContext";
import { configStore } from "../../../../../contexts/ConfigContext";
import { getModuleById } from "../../../../../utils/dataRetrieval";
import { colors } from "../../utils";
import LockStatusLabel from "../../../../../components/LockStatusLabel/LockStatusLabel";

interface Props {
    module: ModuleDashboard;
    classroomId: string;
    studentId: string;
    prTree: PRTree | undefined;
}

const ModuleTitleInfo = ({ module, classroomId, studentId, prTree }: Props) => {
    const { data } = useContext(dataStore);
    const { config } = useContext(configStore);
    const history = useHistory();

    return (
        <TitleContainer
            onClick={() =>
                history.push(
                    `/${config.i18n.dashboard?.paths.dashboard}/${config.i18n.dashboard?.paths.classes}/${config.i18n.dashboard?.paths.progression}/${classroomId}/${module.id}`
                )
            }
        >
            <FieldTitle>
                {getModuleById(module.id, data).title.short}
            </FieldTitle>

            {prTree?.modules[module.id].status !== "unlocked" && (
                <LockStatusLabel locked={true} />
            )}

            <ProgressBarContainer>
                <ProgressBar
                    percentage={Number(
                        (
                            module.students[studentId].moduleProgression * 100
                        ).toFixed(1)
                    )}
                >
                    <ProgressBarPercentage>{`${
                        (
                            module.students[studentId].moduleProgression * 100
                        ).toFixed(1) || 0
                    }%`}</ProgressBarPercentage>
                </ProgressBar>
                <ClassAverage
                    average={Number((module.progression * 100).toFixed(1)) || 0}
                ></ClassAverage>
                <ClassAverageInfo>
                    {config.i18n.dashboard?.studentDetails.classAverage}{" "}
                    {Number((module.progression * 100).toFixed(1)) || 0}%
                </ClassAverageInfo>
            </ProgressBarContainer>
        </TitleContainer>
    );
};

const FieldTitle = styled.h5`
    font-size: 1.2em;
    padding: 0 4px;
    align-self: flex-start;
`;

const ProgressBarContainer = styled.div`
    border: 2px dashed ${colors.blueTag};
    box-sizing: border-box;
    border-radius: 8px;
    background: #fff;
    width: 70%;
    height: 20px;
    margin: 8px 0;
    position: relative;
`;
const ClassAverage = styled.div<{ average: number }>`
    position: absolute;
    left: ${(props) => props.average + "%"};
    top: 0;
    height: 30px;
    border: 1px dashed #555;
`;

const ClassAverageInfo = styled.div`
    padding: 16px 0 0 0;
    font-size: 0.8em;
`;

//
const ProgressBar = styled.div<{ percentage: number }>`
    height: 100%;
    width: ${(props) => (props.percentage || 0) + "%"};
    border-radius: 8px;
    background: ${colors.blueTag};
    position: relative;
`;

const ProgressBarPercentage = styled.div`
    font-size: 0.8em;
    position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
`;

const TitleContainer = styled.div`
    width: 200px;
    margin: 0px 8px 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    flex-shrink: 0;
    align-self: center;
`;

export default ModuleTitleInfo;
