import React, { useContext, useEffect, useState } from "react"
import SimpleBar from "simplebar-react";
import { Link } from "react-router-dom";
import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@reach/tabs";
import "@reach/tabs/styles.css";
import useClassroom from '../useClassroom';
import { dataStore } from "../../../../contexts/DataContext";
import { configStore } from "../../../../contexts/ConfigContext";
import { dashboardStore } from "../../../../contexts/DashboardContext";
import Bar from "../Components/Bar/Bar";
import { getIdentifiedGroupsNumber, getModuleById } from "../../../../utils/dataRetrieval";
import "./ClassroomList.scss";
import "../Tabs.scss";
import CreateClassroom from "./Components/CreateClassroom/CreateClassroom";
import VisuallyHidden from "../../../../components/VisuallyHidden/VisuallyHidden";
import { sessionStore } from "../../../../contexts/SessionContext";
import useAthenaAPIClient from "../../../../hooks/useAthenaAPIClient";
import { UserType } from "../../../../interfaces/User";
import { errorStore } from "../../../../contexts/ErrorContext";
import { PRLockStatus } from "@evidenceb/gameplay-interfaces";

interface ClassroomInfoProps {
    code: string;
    name: string;
    link: string;
}

const ClassroomList = () => {

    //Hooks
    const {getUrl} = useClassroom();
    const athenaAPIClient = useAthenaAPIClient();

    // Contexts
    const { data } = useContext(dataStore);
    const { config } = useContext(configStore);
    const { dashboard: { classrooms, clustering } } = useContext(dashboardStore);
    const { session: { userId } } = useContext(sessionStore);
    const { setErrorInfo } = useContext(errorStore);

    const [lockStatus, setLockStatus] = useState<{[classroomId: string]: PRLockStatus}>();

    // Get LockStatus
    useEffect(() => {
        if (lockStatus) return;

        (async () => {
            try {
                const lockStatus = await athenaAPIClient.getResourcesLockStatus(userId, UserType.Teacher);
                setLockStatus(lockStatus);
            } catch (err) {
                if(athenaAPIClient.handleTokenError(err)){
                    setLockStatus({}); // To prevent infinite loop of retries of the request
                }else{
                    setErrorInfo({
                        displayModal: true,
                        modal: {
                            type: "NOTIFICATION",
                            content: {
                                title: config.i18n.classList.lockStatusErrorTitle
                            }
                        }
                    })
                }
                setLockStatus({}); // To prevent infinite loop of retries of the request
            }
        })();
    }, [userId, athenaAPIClient, config.i18n.classList.lockStatusErrorTitle, lockStatus, setErrorInfo]);

    return (
        <div className="classroom-list-container">
            <div className="header">
                <h1>{config.i18n.dashboard?.common.classes}</h1>
                {
                    config.features.classManagement && <CreateClassroom />
                }
            </div>

            <div className="tabs-container">
                {
                    classrooms.map((classroom, i) =>
                        <Tabs key={"tabs" + i}>
                            <TabList>
                                <Tab className="tablist__tab">
                                    <span>
                                        {
                                            classroom.alert.isAlert && 
                                            <>
                                                <span aria-hidden="true">!</span>
                                                <VisuallyHidden>{config.i18n.dashboard?.classDetails.titleStudentInDifficulty}</VisuallyHidden>
                                            </>
                                        }
                                        {classroom.name}
                                    </span>
                                </Tab>
                                {
                                    config.features.clustering && clustering &&
                                        <Tab className="tablist__tab"><span>{config.i18n.dashboard?.common.groups}</span></Tab>
                                }
                            </TabList>
                            <TabPanels>
                                {
                                    classroom.modulesList.length ?
                                        <>
                                            <TabPanel>
                                                <ClassroomInfo 
                                                    code={classroom.external_id} 
                                                    name={classroom.name} 
                                                    link={ getUrl("classroom", classroom.id) } 
                                                />   
                                                    <span>{config.i18n.dashboard?.classList.progressionsTitle}</span>                                             
                                                    <SimpleBar style={{ height: 300}} autoHide={false}>
                                                        { classroom.modulesList.map((module, i) =>
                                                            <Bar
                                                                key={module.id}
                                                                state={module.progression === 0 ? "noData" : undefined}
                                                                url={getUrl("progressionOverview", classroom.id, module.id)}
                                                                type={"progress"}
                                                                moduleName={getModuleById(module.id, data).title.short!}
                                                                value={module.progression !== 0 ? (module.progression * 100).toFixed(1) : module.progression}
                                                            />
                                                        )}
                                                    </SimpleBar>
                                            </TabPanel>
                                            {
                                                config.features.clustering && clustering &&
                                                    <TabPanel>
                                                        <span>{config.i18n.dashboard?.classList.groupsTitle}</span>          
                                                        <SimpleBar style={{ height: 300}} autoHide={false}>
                                                        {
                                                            classroom.modulesList.map((module) =>
                                                                <Bar
                                                                    key={module.id}
                                                                    state={module.progression === 0 ? "noData" : undefined}
                                                                    url={getUrl("viewGroups", classroom.id, module.id)}
                                                                    type={"moduleGroups"}
                                                                    moduleName={getModuleById(module.id, data).title.short!}
                                                                    value={ getIdentifiedGroupsNumber(clustering, classroom.id, module.id, config.i18n.dashboard!.classList.identifiedGroups) }
                                                                />
                                                            )
                                                        }
                                                        </SimpleBar>
                                                    </TabPanel>
                                            }
                                        </> :
                                        <>
                                            <TabPanel>
                                                <ClassroomInfo 
                                                    code={classroom.external_id} 
                                                    name={classroom.name} 
                                                    link={getUrl("classroom", classroom.id)} 
                                                />
                                                <span>{config.i18n.dashboard?.classList.progressionsTitle}</span>
                                                <NoData />
                                            </TabPanel>
                                            {
                                                config.features.clustering && clustering &&
                                                    <TabPanel>
                                                        <span>{config.i18n.dashboard?.classList.groupsTitle}</span>   
                                                        <NoData />
                                                    </TabPanel>
                                            }
                                        </>
                                }
                            </TabPanels>
                        </Tabs>
                    )
                }
            </div>
        </div>
    )
}

export default ClassroomList

const NoData = () => {
    const { config } = useContext(configStore);
    return (
        <p className="no-data">
            {config.i18n.dashboard?.common.noStudentsData}
        </p>
    )
}

const ClassroomInfo = ({ code, link }: ClassroomInfoProps) => {
    const { config } = useContext(configStore);
    return (
        <div className="classroomInfos">
            {config.features.classCode !== false &&
                <div className="code">
                    <span>{config.i18n.dashboard?.classDetails.code} </span>
                    <span>{code}</span>
                </div>
            }
            <Link to={link}>
                <span className="material-icons" translate="no"> equalizer </span>
                <span>{config.i18n.dashboard?.seeAll}</span>
                <span className="material-icons" translate="no"> chevron_right </span>
            </Link>
        </div>
    )
}