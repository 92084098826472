import React, {
    useContext,
    Dispatch,
    SetStateAction,
    MouseEvent,
    useState,
} from "react";
import styled from "styled-components/macro";
import { useParams } from "react-router-dom";
import { PRTree } from "@evidenceb/gameplay-interfaces";
import { ModuleDashboard } from "../../../../interfaces/Dashboard";
import { configStore } from "../../../../contexts/ConfigContext";
import { colors } from "../utils";
import { ProgressionData } from "../../StudentProgression/StudentProgression";
import Filter from "./Filter/Filter";
import ModuleTitleInfo from "./ModuleTitleInfo/ModuleTitleInfo";
import ObjectivesList from "./ObjectivesList/ObjectivesList";

import "@reach/tooltip/styles.css";

interface ProgressProps {
    modules?: ModuleDashboard[];
    setProgression: Dispatch<SetStateAction<ProgressionData | undefined>>;
    prTree: PRTree | undefined;
}

interface ParamTypes {
    classroomId: string;
    studentId: string;
}

export type ProgressFilter = string;

export default function Progress({
    modules,
    setProgression,
    prTree,
}: ProgressProps) {
    const { classroomId, studentId } = useParams<ParamTypes>();
    const { config } = useContext(configStore);

    const [progressFilter, setProgressFilter] = useState<ProgressFilter>();

    const handleProgressArrow = (
        e: MouseEvent<HTMLButtonElement>,
        modifier: string,
        objectivesLength: number
    ) => {
        let el = e.currentTarget.closest("div")?.children[3]
            .children[0] as HTMLDivElement;
        let currentX = Number(
            window.getComputedStyle(el).transform.split(",")[4]
        );

        if (modifier === "-") {
            if (isNaN(currentX) && objectivesLength > 7) {
                el.style.transform = "translateX(-115px)";
            } else if (
                !isNaN(currentX) &&
                currentX === -115 * (objectivesLength - 7)
            ) {
                return;
            } else if (!isNaN(currentX)) {
                el.style.transform = "translateX(" + (currentX - 115) + "px)";
            }
        } else if (modifier === "+") {
            if (isNaN(currentX)) {
                return;
            } else if (!isNaN(currentX) && currentX === 0) {
                return;
            } else if (!isNaN(currentX)) {
                el.style.transform = "translateX(" + (currentX + 115) + "px)";
            }
        }
    };

    return (
        <div className="card large">
            <h2 className="card-title">
                {config.i18n.dashboard?.common.progression}
            </h2>

            <Filter
                progressFilter={progressFilter}
                setProgressFilter={setProgressFilter}
            />

            <ProgressContainer>
                {modules?.map((module, i) => (
                    <ModuleContainer key={`module-${i}`}>
                        <ModuleColor color={"#888"}></ModuleColor>

                        <ModuleTitleInfo
                            module={module}
                            classroomId={classroomId}
                            studentId={studentId}
                            prTree={prTree}
                        />

                        <TagArrow
                            onClick={(e) =>
                                handleProgressArrow(
                                    e,
                                    "+",
                                    module.students[studentId]?.objectives
                                        .length
                                )
                            }
                        >
                            <span className="material-icons" translate="no">
                                arrow_back
                            </span>
                        </TagArrow>

                        <ObjectivesList
                            module={module}
                            classroomId={classroomId}
                            studentId={studentId}
                            progressFilter={progressFilter}
                            setProgression={setProgression}
                            prTree={prTree}
                        />

                        <TagArrow
                            onClick={(e) =>
                                handleProgressArrow(
                                    e,
                                    "-",
                                    module.students[studentId]?.objectives
                                        .length
                                )
                            }
                        >
                            <span className="material-icons" translate="no">
                                arrow_forward
                            </span>
                        </TagArrow>
                    </ModuleContainer>
                ))}
            </ProgressContainer>
        </div>
    );
}

const ProgressContainer = styled.div`
    padding: 16px 8px;
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 2px;
    background: var(--white);
    box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
    border: 1px solid var(--medium-grey);
`;

const ModuleContainer = styled.div`
    display: flex;
    align-items: flex-end;
    padding: 24px 16px;
    position: relative;
`;

const ModuleColor = styled.div<{ color: string }>`
    width: 16px;
    height: calc(100% - 48px);
    position: absolute;
    left: -24px;
    bottom: 16px;
    background-color: ${(props) => props.color};
    border-radius: 5px 0 0 5px;
`;

const TagArrow = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-end;
    margin: 0 8px;
    padding: 16px;
    background: ${colors.mediumGrey};
    color: ${colors.lightGrey};
    border-radius: 8px;
`;
