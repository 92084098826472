import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import SimpleBar from "simplebar-react";
import { PRTree } from "@evidenceb/gameplay-interfaces";
import COLOR_SCHEME from "./COLOR_SCHEME";
import { ModuleDashboard, ParamTypes } from "../../../../interfaces/Dashboard";
import { configStore } from "../../../../contexts/ConfigContext";
import TableLegend from "./TableLegend/TableLegend";
import StudentNamesColumn from "./StudentNamesColumn/StudentNamesColumn";
import ObjectiveListLine from "./ObjectiveListLine/ObjectiveListLine";
import ObjectiveProgression from "../../../../components/ObjectiveProgression/ObjectiveProgression";

import "./ProgressionTable.scss";

interface TableProps {
    students: ModuleDashboard["students"];
    objectives: string[];
    prTree?: PRTree;
    moduleId: string;
}

const ProgressionTable = ({
    students,
    objectives,
    prTree,
    moduleId,
}: TableProps) => {
    //Variables
    let { classroomId } = useParams<ParamTypes>();
    //Contexts
    const { config } = useContext(configStore);
    //Functions$
    const sortByName = (a: string, b: string) => {
        return students[a].lastname.localeCompare(students[b].lastname);
    };

    return (
        <div
            className="progression-table"
            /* @ts-ignore TS doesn't allow to set variables but we can */
            style={
                COLOR_SCHEME[
                    prTree?.modules[moduleId].status !== "unlocked"
                        ? "locked"
                        : "unlocked"
                ]
            }
        >
            <TableLegend />

            <div className="table-content">
                <div className="column left">
                    <div className="heading">
                        <span>{config.i18n.dashboard?.common.students}</span>
                        <span>{config.i18n.dashboard?.common.objectives}</span>
                        <span className="split"></span>
                    </div>

                    <StudentNamesColumn
                        classroomId={classroomId}
                        students={students}
                    />
                </div>

                <div className="column right">
                    <SimpleBar style={{ width: "100%" }} autoHide={false}>
                        <div className="table-objectives">
                            <ObjectiveListLine
                                objectives={objectives}
                                prTree={prTree}
                                moduleId={moduleId}
                            />

                            {Object.keys(students)
                                .sort(sortByName)
                                .map((studentId, i) => (
                                    <div className="tr" key={"tr" + i}>
                                        {students[studentId].objectives.map(
                                            (objective, j) => (
                                                <ObjectiveProgression
                                                    key={"td" + j}
                                                    prTree={prTree}
                                                    objective={objective}
                                                    classroomId={classroomId}
                                                    student={
                                                        students[studentId]
                                                    }
                                                />
                                            )
                                        )}
                                    </div>
                                ))}
                        </div>
                    </SimpleBar>
                </div>
            </div>
        </div>
    );
};

export default ProgressionTable;
