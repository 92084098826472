import React, { useContext } from "react";
import { useHistory } from "react-router";
import { configStore } from "../../../contexts/ConfigContext";
import { dataStore } from "../../../contexts/DataContext";
import { getModuleById } from "../../../utils/dataRetrieval";
import StudentPath from "../StudentPath/StudentPath";

import "./ModuleCard.scss";

const ModuleCard: React.FC<{
    moduleId: string;
    diagnosis?: {
        progression: number;
        averageScore: number;
    };
}> = ({ moduleId, diagnosis }) => {
    const { config } = useContext(configStore);
    const { data } = useContext(dataStore);
    const history = useHistory();

    const module = getModuleById(moduleId, data);

    return (
        <div className="student-module-card">
            <div className="title-container">
                {module.studentPath && (
                    <div className="student-path__placeholder"></div>
                )}

                <div
                    className={`title ${diagnosis?.progression ? "active" : ""}`}
                >
                    {module.title.short ?? ""}
                </div>

                {module.studentPath && (
                    <StudentPath
                        url={module.studentPath.url}
                        alt={module.studentPath.alt ?? ""}
                    />
                )}
            </div>

            <div className="card-content">
                <div className="bar-container">
                    <div
                        className={`bar ${
                            diagnosis?.progression ? "progress-active" : ""
                        }`}
                    >
                        <div
                            className="progress-bar"
                            style={{
                                width: `${diagnosis?.progression ?? 0}%`,
                            }}
                        >
                            <div className="text-content">
                                {config.i18n.moduleList.student.progression}
                            </div>
                        </div>
                    </div>
                    {(diagnosis && !isNaN(diagnosis.averageScore)) ? (
                        <div className="bar success-rate-active">
                            <div className="text-content">
                                {config.i18n.moduleList.student.successRate}
                            </div>
                            <span>{`${diagnosis.averageScore}%`}</span>
                        </div>
                    ) : (
                        <div className="bar">
                            <div className="text-content">
                                {config.i18n.moduleList.student.successRate}
                            </div>
                        </div>
                    )}
                </div>
                <div className="card-modal">
                    <button
                        data-key={moduleId}
                        onClick={() => {
                            history.push("/player/" + moduleId);
                        }}
                        className="btn-modal"
                        disabled={diagnosis?.progression === 100}
                    >
                        {diagnosis?.progression === 0
                            ? config.i18n.moduleList.student.start
                            : diagnosis?.progression === 100
                            ? config.i18n.moduleList.student.completedModule
                            : config.i18n.moduleList.student.continue}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ModuleCard;
