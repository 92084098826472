import React, { useContext } from "react";
import { configStore } from "../../../../../contexts/ConfigContext";
import VisuallyHidden from "../../../../../components/VisuallyHidden/VisuallyHidden";

interface Props {
    onClose: () => void;
}

const CloseButton = ({ onClose }: Props) => {
    const {
        config: { i18n },
    } = useContext(configStore);

    return (
        <button className="close-button" onClick={onClose}>
            <VisuallyHidden>{i18n.misc.modalClose}</VisuallyHidden>
            <span aria-hidden>
                <span className="material-icons" translate="no">
                    close
                </span>
            </span>
        </button>
    );
};

export default CloseButton;