import {
    Activity,
    Exercise,
    Hierarchy,
    HierarchyIds,
    MinimalDataItem,
    Module,
    Objective,
    VisibilityStatus,
} from "@evidenceb/gameplay-interfaces";
import {
    ActivityNotVisibleError,
    ActivityNotFoundError,
    ExerciseNotVisibleError,
    ExerciseNotFoundError,
    ModuleNotVisibleError,
    ModuleNotFoundError,
    ObjectiveNotVisibleError,
    ObjectiveNotFoundError,
    ItemNotFoundError,
} from "../errors";
import { Data } from "../interfaces/Data";
import { Page } from "../interfaces/Config";
import { ContentPage } from "../interfaces/ContentPage";
import {
    Classroom,
    ClassroomsClustering,
    ClusterInfosClustering,
    ModuleCluster,
} from "../interfaces/Dashboard";
import { UserType } from "../interfaces/User";
import { addAssetsDetailsToExerciseData } from "./assetsUtils";

export const getUrl = (page: Page | ContentPage, userType: UserType) => {
    let url: string | string[] = "";
    switch (page.display.mode) {
        case "DEFAULT":
            url = page.display.url;
            break;
        case "USER_BASED":
            if (userType === UserType.Teacher) {
                url = page.display.url[0];
            }
            if (userType === UserType.Student) {
                url = page.display.url[1];
            }
            break;
        default:
            url = page.display.url;
    }
    return url;
};

export const getLabel = (page: Page | ContentPage, userType: UserType) => {
    let label: string | string[] = "";
    switch (page.display.mode) {
        case "DEFAULT":
            label = page.display.label;
            break;
        case "USER_BASED":
            if (userType === UserType.Teacher) {
                return page.display.label[0];
            }
            if (userType === UserType.Student) {
                return page.display.label[1];
            }
            break;
        default:
            return page.display.label;
    }
    return label;
};

export const getModuleById = (
    id: string,
    data: Data,
    visibleOnly = true
): Module => {
    const module = data.modules.find((module) => module.id === id);
    if (!module) throw new ModuleNotFoundError();
    if (visibleOnly && module.visibilityStatus !== VisibilityStatus.Visible)
        throw new ModuleNotVisibleError();
    return module;
};

export const getObjectiveById = (
    id: string,
    data: Data,
    visibleOnly = true
): Objective => {
    const objective = data.objectives.find((objective) => objective.id === id);
    if (!objective) throw new ObjectiveNotFoundError();
    if (visibleOnly && objective.visibilityStatus !== VisibilityStatus.Visible)
        throw new ObjectiveNotVisibleError();

    return objective;
};

export const getActivityById = (
    id: string,
    data: Data,
    visibleOnly = true
): Activity => {
    const activity = data.activities.find((activity) => activity.id === id);
    if (!activity) throw new ActivityNotFoundError();
    if (visibleOnly && activity.visibilityStatus !== VisibilityStatus.Visible)
        throw new ActivityNotVisibleError();
    return activity;
};

export const getExerciseById = (
    id: string,
    data: Data,
    visibleOnly = true,
    version?: string,
    contentUrl?: string
): Exercise<any, any> => {
    let exercise = data.exercises.find((exercise) => exercise.id === id);
    if (!exercise) throw new ExerciseNotFoundError();
    if (visibleOnly && exercise.visibilityStatus !== VisibilityStatus.Visible)
        throw new ExerciseNotVisibleError();

    exercise =
        version && contentUrl
            ? addAssetsDetailsToExerciseData(exercise, version, contentUrl)
            : exercise;
    return exercise;
};

/**
 * Returns a complete hierarchy given a set of ids for each level.
 * If the id is not given for a certain level, it defaults to the first item
 * of the superior level.
 */
export const getHierarchy = (
    data: Data,
    moduleId?: string,
    objectiveId?: string,
    activityId?: string
): Omit<Hierarchy, "exercise" | "isInitialTest"> => {
    if (
        (activityId && (!objectiveId || !moduleId)) ||
        (objectiveId && !moduleId)
    )
        throw new Error(
            "The superior id of a given level id should always be defined"
        );

    const module = moduleId ? getModuleById(moduleId, data) : data.modules[0];
    if (module.visibilityStatus !== VisibilityStatus.Visible)
        throw new ModuleNotVisibleError();

    if (objectiveId && !module.objectiveIds.includes(objectiveId))
        throw new ObjectiveNotFoundError();
    const objective = objectiveId
        ? getObjectiveById(objectiveId, data)
        : getObjectiveById(module.objectiveIds[0], data);
    if (objective.visibilityStatus !== VisibilityStatus.Visible)
        throw new ObjectiveNotVisibleError();

    if (activityId && !objective.activityIds.includes(activityId))
        throw new ActivityNotFoundError();
    const activity = activityId
        ? getActivityById(activityId, data)
        : getActivityById(objective.activityIds[0], data);
    if (activity.visibilityStatus !== VisibilityStatus.Visible)
        throw new ActivityNotVisibleError();

    return {
        module,
        objective,
        activity,
    };
};

export const getHierarchyFromHierarchyId = (
    hierarchyId: HierarchyIds & { isInitialTest: boolean },
    data: Data
): Omit<Hierarchy, "isInitialTest"> => {
    return {
        module: getModuleById(hierarchyId.moduleId, data),
        objective: getObjectiveById(hierarchyId.objectiveId, data),
        activity: getActivityById(hierarchyId.activityId, data),
        exercise: getExerciseById(hierarchyId.exerciseId, data),
    };
};

/**
 * Retrieves all exercises that are included in an activity. Exercises are
 * returned in the order their ids are provided in the activity exercise list.
 */
export const getExercisesInActivity = (
    activity: Activity,
    data: Data,
    version: string,
    contentUrl: string
): Exercise<any, any>[] => {
    return activity.exerciseIds
        .map((exerciseId) =>
            getExerciseById(exerciseId, data, false, version, contentUrl)
        )
        .filter(
            (exercise) => exercise.visibilityStatus === VisibilityStatus.Visible
        );
};

export const getActivitiesInModule = (
    module: Module,
    data: Data
): Activity[] => {
    const objectives = module.objectiveIds
        .map((objectiveId) => getObjectiveById(objectiveId, data))
        .filter(
            (objective) =>
                objective.visibilityStatus === VisibilityStatus.Visible
        );
    return objectives
        .map((objective) => objective.activityIds)
        .flat()
        .map((activityId) => getActivityById(activityId, data))
        .filter(
            (activity) => activity.visibilityStatus === VisibilityStatus.Visible
        );
};

/**
 * Given a level of hierarchy, returns the immediately above level.
 * Only visible items in each level are taken into account.
 */
export const getNextHierarchyLevel = (
    data: Data,
    hierarchy: Omit<Hierarchy, "exercise" | "isInitialTest">,
    allowModuleChange: boolean = true
): Omit<Hierarchy, "exercise" | "isInitialTest"> | undefined => {
    const activityPool = getSublevelPool<Activity>(
        hierarchy.objective.activityIds,
        data.activities
    );
    const activityIndex = activityPool.findIndex(
        (availableActivity) => availableActivity.id === hierarchy.activity.id
    );
    if (activityIndex !== activityPool.length - 1)
        return {
            ...hierarchy,
            activity: getActivityById(activityPool[activityIndex + 1].id, data),
        };

    const objectivePool = getSublevelPool<Objective>(
        hierarchy.module.objectiveIds,
        data.objectives
    );
    const objectiveIndex = objectivePool.findIndex(
        (availableObjective) => availableObjective.id === hierarchy.objective.id
    );
    if (objectiveIndex !== objectivePool.length - 1) {
        const newObjective = getObjectiveById(
            objectivePool[objectiveIndex + 1].id,
            data
        );
        const activityPool = getSublevelPool<Activity>(
            newObjective.activityIds,
            data.activities
        );
        return {
            module: hierarchy.module,
            objective: newObjective,
            activity: activityPool[0],
        };
    }

    if (!allowModuleChange) return undefined;
    const modulePool = data.modules.filter(
        (module) => module.visibilityStatus === VisibilityStatus.Visible
    );
    const moduleIndex = modulePool.findIndex(
        (availableModule) => availableModule.id === hierarchy.module.id
    );
    if (moduleIndex !== modulePool.length - 1) {
        const newModule = modulePool[moduleIndex + 1];
        const objectivePool = getSublevelPool<Objective>(
            newModule.objectiveIds,
            data.objectives
        );
        const activityPool = getSublevelPool<Activity>(
            objectivePool[0].activityIds,
            data.activities
        );
        return {
            module: newModule,
            objective: objectivePool[0],
            activity: activityPool[0],
        };
    }

    return undefined;
};

/**
 * Returns all items of a sublevel of hierarchy that are visible and contained
 * in the given level.
 */
export function getSublevelPool<
    Sublevel extends { id: string; visibilityStatus: VisibilityStatus }
>(sublevelIds: string[], availableSublevelItems: Sublevel[]): Sublevel[] {
    return sublevelIds
        .map((sublevelId) => {
            const correspondingItem = availableSublevelItems.find(
                (item) => item.id === sublevelId
            );
            if (!correspondingItem) throw new ItemNotFoundError();
            return correspondingItem;
        })
        .filter((item) => item.visibilityStatus === VisibilityStatus.Visible);
}

export const getRandomExercise = (
    data: Data,
    moduleId?: string
): HierarchyIds => {
    const module = moduleId
        ? getModuleById(moduleId, data)
        : data.modules[Math.floor(Math.random() * data.modules.length)];
    const objective = getObjectiveById(
        module.objectiveIds[
            Math.floor(Math.random() * module.objectiveIds.length)
        ],
        data
    );
    const activity = getActivityById(
        objective.activityIds[
            Math.floor(Math.random() * objective.activityIds.length)
        ],
        data
    );
    return {
        moduleId: module.id,
        objectiveId: objective.id,
        activityId: activity.id,
        exerciseId:
            activity.exerciseIds[
                Math.floor(Math.random() * activity.exerciseIds.length)
            ],
    };
};

/**
 * Get a student's name from their ID
 */
export const getStudentName = (
    classrooms: Classroom[],
    classId: string,
    moduleId: string,
    studentId: string
): string => {
    const classroom = classrooms.find((classroom) => classroom.id === classId);
    if (!classroom) throw new Error("Classroom not found");
    const module = classroom.modulesList.find(
        (module) => module.id === moduleId
    );
    if (!module) throw new Error("Module not found");
    const student = module.students[studentId];
    if (!student) throw new Error("Student not found");
    return student.firstname + " " + student.lastname;
};

/**
 * Returns the list of clusters from the clustering information of a module
 */
export const getClusters = (
    clustering: ModuleCluster
): ClusterInfosClustering[] => {
    // This is due to the weird shape of the infosClustering.clusters list
    return clustering.infosClustering.clusters
        .map((clustersObj) =>
            Object.keys(clustersObj).map((clusterId) => clustersObj[clusterId])
        )
        .flat();
};

/**
 * Returns the number of indentified groups in the clustering
 */
export const getIdentifiedGroupsNumber = (
    clustering: ClassroomsClustering,
    classroomId: string,
    moduleId: string,
    i18n: string
) => {
    return clustering &&
        typeof clustering[classroomId][moduleId] !== "undefined" &&
        typeof clustering[classroomId][moduleId].error === "undefined"
        ? getClusters(clustering[classroomId][moduleId] as ModuleCluster)
              .length +
              " " +
              i18n
        : "0 " + i18n;
};

/**
 * Generates a filter that removes initial test objectives
 */
export const notInitialTest = (
    data: Data
): ((objective: Objective | string) => boolean) => {
    const initialTestsIds = data.modules.map(
        (module) => module.objectiveIds[0]
    );
    return (objective) =>
        !initialTestsIds.includes(
            typeof objective === "string" ? objective : objective.id
        );
};

/**
 * Returns the objective description and if it exists based on the user type (used in infoPanels for students & teachers)
 */
export const getItemDescription = (
    item: Module | Objective | Activity,
    userType: "student" | "teacher"
): string => {
    let description = item.descriptions
        ? item.descriptions[userType].$html
        : item.description
        ? item.description.$html
        : "";
    return description;
};

export const getResourceIndex = (
    id: string,
    pool: MinimalDataItem[] | string[]
) => {
    if (typeof pool[0] === "string")
        return (pool as string[]).findIndex((itemId) => itemId === id);
    return (pool as MinimalDataItem[]).findIndex((item) => item.id === id);
}

/**
 * Returns the activity the exercise is in. It assumes that the exercise is only
 * in one activity/objective/module, which might not be the case inn the future.
 */
export const getExerciseHierarchy = (
    exerciseId: string,
    data: Data
): Hierarchy => {
    const exercise = data.exercises.find(
        (exercise) => exercise.id === exerciseId
    );
    if (!exercise) throw new ExerciseNotFoundError();

    const activity = data.activities.find((activity) =>
        activity.exerciseIds.includes(exerciseId)
    );
    if (!activity) throw new ActivityNotFoundError();

    const objective = data.objectives.find((objective) =>
        objective.activityIds.includes(activity.id)
    );
    if (!objective) throw new ObjectiveNotFoundError();

    const module = data.modules.find((module) =>
        module.objectiveIds.includes(objective.id)
    );
    if (!module) throw new ModuleNotFoundError();

    return {
        exercise,
        activity,
        objective,
        module,
    };
};
