import React from "react";

const ConfusedBot = () => (
    <svg
        viewBox="0 0 73 55"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g filter="url(#filter0_d)">
            <path
                d="M16.1797 26.976C16.1797 31.6095 14.7711 35.3641 10.9478 35.3641C7.12456 35.3641 3.99953 31.6095 3.99953 26.976C3.99953 22.3425 7.10242 18.5879 10.9398 18.5879C14.7771 18.5879 16.1797 22.3445 16.1797 26.976Z"
                fill="#E66342"
            />
            <path
                d="M13.4596 35.3641C10.3145 35.3641 7.76496 31.6086 7.76496 26.976C7.76496 22.3434 10.3145 18.5879 13.4596 18.5879C16.6047 18.5879 19.1543 22.3434 19.1543 26.976C19.1543 31.6086 16.6047 35.3641 13.4596 35.3641Z"
                fill="#2C3E50"
            />
            <path
                d="M31.1704 11.0542C34.3343 9.98361 37.7134 9.7233 41.0023 10.2968C53.9713 12.5245 63.2759 26.8017 61.6963 42.294C61.6138 43.104 61.1288 43.7399 60.503 43.9485L53.4259 46.2977C54.0517 46.0952 54.5367 45.4593 54.6212 44.6431C56.231 29.159 46.9747 14.8736 34.0359 12.646C30.7534 12.0703 27.3799 12.3292 24.2221 13.3993L31.1704 11.0542Z"
                fill="#61A4BF"
            />
            <path
                d="M34.0341 12.6458C21.1778 10.4181 9.37996 20.9124 7.59308 36.1799C7.46631 37.2755 8.10621 38.2982 9.0238 38.4642L52.6997 46.3501C53.6395 46.5182 54.4927 45.7547 54.6094 44.645C56.2272 29.1608 46.9709 14.8836 34.0341 12.6458Z"
                fill="#AFDCE0"
            />
            <path
                d="M59.5338 43.1551C56.3888 43.1551 53.8392 39.3997 53.8392 34.767C53.8392 30.1344 56.3888 26.3789 59.5338 26.3789C62.6789 26.3789 65.2285 30.1344 65.2285 34.767C65.2285 39.3997 62.6789 43.1551 59.5338 43.1551Z"
                fill="#2C3E50"
            />
            <path
                d="M56.8118 34.767C56.8118 39.4005 58.2204 43.1572 62.0436 43.1572C65.8669 43.1572 69 39.4005 69 34.767C69 30.1335 65.8891 26.3789 62.0517 26.3789C58.2143 26.3789 56.8118 30.1355 56.8118 34.767Z"
                fill="#E66342"
            />
            <path
                d="M61.8585 31.2616H62.3736C62.3736 30.162 62.3253 28.9874 62.2811 27.8513C62.1885 25.5973 62.0959 23.2664 62.3696 21.4417C63.3174 15.1537 65.9172 13.0698 65.9434 13.0496L65.6294 12.6445C65.5168 12.7316 62.8425 14.8499 61.8605 21.3708C61.5788 23.2441 61.6734 25.5993 61.7639 27.8776C61.8102 29.0036 61.8585 30.1721 61.8585 31.2616Z"
                fill="#E66342"
            />
            <path
                d="M63.8687 13.988C63.4605 12.9117 63.9966 11.7062 65.066 11.2954C66.1354 10.8847 67.3333 11.4242 67.7414 12.5004C68.1496 13.5767 67.6135 14.7822 66.5441 15.193C65.4747 15.6038 64.2768 15.0643 63.8687 13.988Z"
                fill="#2C3E50"
            />
            <path
                d="M54.145 47.0008C54.5011 47.0005 54.8534 46.9275 55.1807 46.7862C55.5079 46.6449 55.8032 46.4381 56.0486 46.1785L61.373 40.5446L60.7513 39.9492L55.4268 45.5811C55.2274 45.7925 54.9794 45.9514 54.7047 46.0439C54.43 46.1364 54.137 46.1597 53.8512 46.1117L38.3448 43.5114L38.204 44.364L53.7084 46.9643C53.8527 46.9886 53.9987 47.0008 54.145 47.0008Z"
                fill="#E66342"
            />
            <path
                d="M35.6424 42.0372L40.2984 42.8096C41.0429 42.9331 41.547 43.6406 41.4243 44.39L41.4239 44.392C41.3012 45.1413 40.5981 45.6486 39.8536 45.5251L35.1977 44.7527C34.4531 44.6292 33.9491 43.9217 34.0718 43.1723L34.0721 43.1703C34.1948 42.421 34.8979 41.9137 35.6424 42.0372Z"
                fill="#2C3E50"
            />
            <ellipse
                rx="2.65725"
                ry="2.67426"
                transform="matrix(-1 0 0 1 34.0283 26.6508)"
                fill="#2C3E50"
            />
            <ellipse
                rx="2.24844"
                ry="2.26284"
                transform="matrix(-1 0 0 1 24.2164 24.1828)"
                fill="#2C3E50"
            />
            <path
                d="M21.9062 30.4004C30.9101 33.0911 34.1548 35.047 34.9881 38.2175"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
            />
            <path
                d="M46.3338 14.5977C46.3338 13.457 46.4627 12.5586 46.7205 11.9023C46.9783 11.2383 47.3963 10.6641 47.9744 10.1797C48.5525 9.69531 48.99 9.25391 49.2869 8.85547C49.5916 8.44922 49.7439 8.00391 49.7439 7.51953C49.7439 6.33984 49.2361 5.75 48.2205 5.75C47.7517 5.75 47.3728 5.92188 47.0838 6.26562C46.7947 6.60937 46.6423 7.07422 46.6267 7.66016H42.6658C42.6814 6.09766 43.1736 4.88281 44.1423 4.01562C45.1111 3.14062 46.4705 2.70312 48.2205 2.70312C49.9627 2.70312 51.3103 3.10547 52.2634 3.91016C53.2244 4.71484 53.7048 5.85937 53.7048 7.34375C53.7048 7.99219 53.5759 8.58203 53.3181 9.11328C53.0603 9.64453 52.6463 10.1875 52.0759 10.7422L50.7283 11.9961C50.3455 12.3633 50.0798 12.7422 49.9314 13.1328C49.783 13.5156 49.7009 14.0039 49.6853 14.5977H46.3338ZM45.8533 18.1602C45.8533 17.582 46.0603 17.1094 46.4744 16.7422C46.8963 16.3672 47.4197 16.1797 48.0447 16.1797C48.6697 16.1797 49.1892 16.3672 49.6033 16.7422C50.0252 17.1094 50.2361 17.582 50.2361 18.1602C50.2361 18.7383 50.0252 19.2148 49.6033 19.5898C49.1892 19.957 48.6697 20.1406 48.0447 20.1406C47.4197 20.1406 46.8963 19.957 46.4744 19.5898C46.0603 19.2148 45.8533 18.7383 45.8533 18.1602Z"
                fill="black"
            />
        </g>
        <defs>
            <filter
                id="filter0_d"
                x="0"
                y="2.70312"
                width="73.0007"
                height="52.2976"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="2" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow"
                    result="shape"
                />
            </filter>
        </defs>
    </svg>
);

export default ConfusedBot;
