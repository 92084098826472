import React from "react";

interface Props {
    /**
     * @default "span"
     */
    as?: "div" | "p" | "span";
    children: string;
    className?: string;
}

const MaterialIcon = ({
    as,
    className,
    children,
    ...props
}: Props & React.HTMLAttributes<any>) => {
    const Wrapper = as ?? "span";

    return (
        <Wrapper
            {...props}
            translate="no"
            className={`material-icons ${className ?? ""}`}
            aria-hidden={true}
        >
            {children}
        </Wrapper>
    );
};

export default MaterialIcon;
