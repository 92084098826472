import React, { useContext, useState } from "react";
import Button from "../../../../../../components/Button/Button";

//External modules
import { customAlphabet, urlAlphabet } from 'nanoid';

//Components
import Loader from "../../../../../../components/Loader/Loader";
import Modal from "../../../../../../components/Modal/Modal";

//Contexts
import { configStore } from "../../../../../../contexts/ConfigContext";
import { sessionStore } from '../../../../../../contexts/SessionContext';

//Hooks
import useClassroom from "../../../useClassroom";
import useAthenaAPIClient from "../../../../../../hooks/useAthenaAPIClient";

//SCSS
import "./CreateClassroom.scss"

//Interfaces
import { Classroom } from "../../../../../../interfaces/Dashboard";
interface RegisterState {
    step: 'register' | 'saving' | 'saved';
    code: string;
    name: string;
}

const AddClassroom = () => {

    //Hooks
    const { updateDashboardData } = useClassroom();
    const athenaAPIClient = useAthenaAPIClient();

    //Contexts
    const { config } = useContext(configStore);
    const { session } = useContext(sessionStore);

    //States
    const [registerState, setRegisterState] = useState<RegisterState>({ step: 'register', name:'', code:''  })
    const [modal, setModal] = useState<boolean>(false);

    //Functions
    const createClassroom = async () => {

        // set state to "saving"
        setRegisterState( registerState => {
            return ({ ...registerState, step: "saving"})
        })

        // Construct body to send to the API
        const nanoid = customAlphabet(urlAlphabet, 10)
        const body = {
          external_id: nanoid(),
          provider: config.declinaison,
          name: registerState.name,
          level: 'none',
          school: '',
          teachers: [session.userId],
          extra: 'none'
        }
        
        try{
            await athenaAPIClient.createClassroom<Classroom>(body)
            try{
                await updateDashboardData()
                // Set state to saved
                setRegisterState( registerState => {
                    return ({ ...registerState, step: "saved", code: body.external_id })
                })
            }catch(error){
                setModal(false)
                setRegisterState ({ step: 'register', name:'', code:'' })
            }
        }catch(error){
            setModal(false)
            setRegisterState ({ step: 'register', name:'', code:'' })
        }

    };

    const cancel = () => {
        setModal(false)
        setRegisterState({ step: 'register', name:'', code:'' })
    }

    return (
        <>
            <Button 
                type={"primary"} 
                label={config.i18n.dashboard?.classList.form.add} 
                icons={[ { placement: "left", code:"add" } ]}
                onClick={() => setModal(true)}/>
            {
                modal &&
                    <Modal title={config.i18n.dashboard?.classList.form.new} onClose={cancel}>
                        <div className="create-classroom-modal">
                            {
                                registerState.step === "register" ?
                                    <div className="register">
                                        <div className="addName-classroom">
                                            <label>{config.i18n.dashboard?.classList.form.name}</label>
                                            <input name="classroom-name" type="text" value={registerState.name} onChange={(e) => setRegisterState({ ...registerState, name: e.currentTarget.value })}/>
                                        </div>
                                        <div className="actions">
                                            <button className="cancel" onClick={cancel}>{config.i18n.dashboard?.classList.form.cancel}</button>
                                            <Button 
                                                type="tertiary" 
                                                label={config.i18n.dashboard?.classList.form.add} 
                                                centeredLabel={true} 
                                                onClick={createClassroom} 
                                            />
                                        </div>
                                    </div>
                                    : registerState.step === "saving" ?
                                        <div className="saving">
                                            <p>{config.i18n.dashboard?.classList.form.saving}</p>
                                            <Loader />
                                        </div>
                                    : registerState.step === "saved" ?
                                        <div className="saved">
                                            <div className="success">
                                                <p>
                                                    <span className="material-icons success-icon" translate="no">check_circle</span>
                                                    {config.i18n.dashboard?.classList.form.success}
                                                </p>
                                                <div className="message">
                                                    <span className="label">{config.i18n.dashboard?.classList.form.code}</span>
                                                    <span className="code">{registerState.code}</span>
                                                    <p>{config.i18n.dashboard?.classList.form.message}</p>
                                                </div>
                                                <div className="actions">
                                                    <Button 
                                                        type="tertiary"
                                                        label={config.i18n.dashboard?.classList.form.continue} 
                                                        centeredLabel={true}
                                                        onClick={cancel} 
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    : null
                            }
                        </div>
                    </Modal>
            }
        </>

    );
};

export default AddClassroom