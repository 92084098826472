import React, { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import {
    ExerciseResult,
    InitializedPlaylistManager,
    PlaylistExecutionStage,
} from "@evidenceb/gameplay-interfaces";
import { dataStore } from "../../contexts/DataContext";
import { getExerciseHierarchy } from "../../utils/dataRetrieval";
import { PlaylistPlayerProps } from "./PlaylistPlayer/PlaylistPlayer";

const useSingleExercisePlaylistManager = (
    exerciseId: string
): InitializedPlaylistManager => {
    const { data } = useContext(dataStore);

    const [exerciseResult, setExerciseResult] = useState<ExerciseResult<any>>();

    const { exercise, activity, objective, module } = getExerciseHierarchy(
        exerciseId,
        data
    );

    return {
        initialized: true,

        playlist: {
            exercises: [exercise],
            exerciseResults: exerciseResult ? [exerciseResult] : [],
            module,
            objective,
            activity,
            currentExercise: {
                ...exercise,
                executionOptions: exercise.executionOptions
                    ? {
                          ...exercise.executionOptions,
                          numberOfTries: 1,
                      }
                    : undefined,
                numberOfTries: 1,
            },
            isInitialTest: false,
            currentExecutionStage: exerciseResult
                ? PlaylistExecutionStage.ShowingCurrentExerciseResultFeedback
                : PlaylistExecutionStage.PlayingCurrentExercise,
            currentTry: 1,
            currentExerciseResult: exerciseResult,
            comingNext: exerciseResult ? "retry" : undefined,
        },

        recordCurrentExerciseResult: (partialResult) => {
            setExerciseResult({
                ...partialResult,
                exerciseId,
                try: 1,
                activityId: activity.id,
            });
        },

        goToNextExercise: () => {
            setExerciseResult(undefined);
        },

        goToExercise: () => {
            throw new Error(
                "Can't go to another exercise from single exercise player"
            );
        },
    };
};

export default useSingleExercisePlaylistManager;

/**
 * HOC that inject the teacher playlist manager in the passed component
 */
export const withSingleExercisePlaylistManager: (
    WrappedComponent: (props: PlaylistPlayerProps) => JSX.Element
) => ({
    InfoPanel,
}: Omit<PlaylistPlayerProps, "playlistManager">) => JSX.Element = (
    WrappedComponent
) => {
    return (props) => {
        const { exerciseId } = useParams<{ exerciseId: string }>();
        const playlistManager = useSingleExercisePlaylistManager(
            exerciseId
        );

        return (
            <WrappedComponent
                playlistManager={playlistManager}
                {...props}
            />
        );
    };
};

