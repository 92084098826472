import React, { useContext, useRef, useState } from "react";
import { useHistory } from "react-router";
import { configStore } from "../../../contexts/ConfigContext";
import { getClassroomUrl } from "../../../utils/navigation";
import { UsePRManagerReturn } from "../Manager/usePRManager";
import CancelModal from "../Modals/CancelModal";
import SuccessModal from "../Modals/SuccessModal";
import FailureModal from "../Modals/FailureModal";

import "./ActionTray.scss";

interface Props extends Pick<UsePRManagerReturn, "reset" | "save" | "dirty"> {
    classroomId: string;
}

const ActionTray = ({ save, reset, classroomId, dirty }: Props): JSX.Element => {
    const {
        config: { i18n },
    } = useContext(configStore);
    const history= useHistory();
    const confirmButtonRef = useRef<HTMLButtonElement>(null);
    const [modal, setModal] = useState<JSX.Element | null>(null);

    return (
        <>
            {modal}

            <div className="prm__action-tray">
                <button
                    className="action-tray__cancel"
                    onClick={() => {
                        if (!dirty) {
                            history.push(getClassroomUrl(i18n, classroomId));
                            return;
                        }

                        setModal(
                            <CancelModal reset={reset} setModal={setModal} />
                        );
                    }}
                >
                    {i18n.prm.manager.cancel}
                </button>

                <button
                    ref={confirmButtonRef}
                    className={`action-tray__confirm ${dirty ? "" : "--inactive"}`}
                    onClick={async () => {
                        try {
                            await save();
                            setModal(
                                <SuccessModal
                                    url={getClassroomUrl(i18n, classroomId)}
                                />
                            );
                        } catch (err) {
                            setModal(
                                <FailureModal
                                    setModal={setModal}
                                    confirmButtonRef={confirmButtonRef}
                                    url={getClassroomUrl(i18n, classroomId)}
                                />
                            );
                        }
                    }}
                >
                    {i18n.prm.manager.confirm}
                </button>
            </div>
        </>
    );
};

export default ActionTray;
