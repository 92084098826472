import React, { useContext } from "react";
import { configStore } from "../../../../../contexts/ConfigContext";
import Icon from "../../../../../design-system-components/Icon/Icon";

const TableLegend = () => {
    const { config } = useContext(configStore);

    return (
        <ul className="table-legend">
            <li className="box validated">
                {
                    config.i18n.dashboard?.progressionOverview.legend
                        .validObjective
                }
            </li>
            <li className="box in-progress">
                {
                    config.i18n.dashboard?.progressionOverview.legend
                        .currentObjective
                }
            </li>
            <li className="box objective-not-started">
                {
                    config.i18n.dashboard?.progressionOverview.legend
                        .objectiveNotStarted
                }
            </li>
            <li className="circle has-improved">
                <Icon path="alert_sup" size="small" />
                {
                    config.i18n.dashboard?.progressionOverview.legend
                        .progressionAcceleration
                }
            </li>
            <li className="circle has-difficulties">
                <Icon path="alert_inf" size="small" />
                {
                    config.i18n.dashboard?.progressionOverview.legend
                        .progressionRegression
                }
            </li>
        </ul>
    );
};

export default TableLegend;
