import React from "react";
import { Dialog, DialogProps } from "@reach/dialog";
import VisuallyHidden from "../VisuallyHidden/VisuallyHidden";

import "@reach/dialog/styles.css";
import "./Modal.scss";

const Modal: React.FC<
    DialogProps & { title?: string | JSX.Element; onClose: () => void }
> = ({ title, onClose, children, ...dialogProps }) => {
    return (
        <Dialog
            {...dialogProps}
            className="modal-container"
            aria-labelledby={typeof title === "string" ? title : undefined}
            onDismiss={onClose}
        >
            {/*TEMP: condition on the title, TODO => standardize Modal component => correct template is modal in the dashboard*/}
            <div className={`modal ${!title ? 'no-title' : ''}`}>
                {
                    title ?
                        <header>
                            {
                                title ? 
                                    <div className="modal__title">{title}</div> :
                                    <div aria-hidden="true">{/* placeholder */}</div>
                            }
                            <button className="close-button" onClick={onClose}>
                                <VisuallyHidden>Close</VisuallyHidden>
                                <span aria-hidden>
                                    <span className="material-icons" translate="no">close</span>
                                </span>
                            </button>
                        </header>
                        :
                        <div className="actions">
                            <button className="close-button" onClick={onClose}>
                                <VisuallyHidden>Close</VisuallyHidden>
                                <span aria-hidden>
                                    <span className="material-icons" translate="no">close</span>
                                </span>
                            </button>
                        </div> 
                }

                <div className="modal__content-container">{children}</div>
            </div>
        </Dialog>
    );
};

export default Modal;