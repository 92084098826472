import React, { useRef, useContext } from "react";
import { Link, NavLink } from "react-router-dom";
import { Page } from "../../interfaces/Config";
import { configStore } from '../../contexts/ConfigContext';
import { sessionStore } from '../../contexts/SessionContext';

import "./Header.scss";
import { getLabel, getUrl } from "../../utils/dataRetrieval";
import { UserType } from "../../interfaces/User";
import ImageAsset from "../ImageAsset/ImageAsset";
import useAthenaAPIClient from "../../hooks/useAthenaAPIClient";

interface HeaderProps {
    logo: string;
    pages: Page[];
}

function Header({ logo, pages }: HeaderProps) {

    const { config } = useContext(configStore);
    const { session } = useContext(sessionStore);
    const athenaAPIClient = useAthenaAPIClient();
    const menuContainer = useRef<HTMLDivElement>(null);

    const toggleMobileMenuHandler = () => {
        menuContainer.current?.classList.toggle("show");
    };

    const logoutHandler = async () => {
        //toggleMobileMenuHandler();
        let logoutUrl = config.i18n.misc.logoutUrl.find(item => item.provider === session.appProvider)
        let isLoggedOut = await athenaAPIClient.logout<string>();
        if (isLoggedOut && logoutUrl) {
            window.location.assign(logoutUrl.url);
        }
    };

    return (
        <header className={`header-top`}>
            <div className="container">
                <div className="logo">
                    {
                        session.userType === UserType.Teacher ?
                            <Link to={`/`}><ImageAsset src={logo} /></Link>
                            : <ImageAsset src={logo} />
                    }
                </div>
                <button
                    onClick={toggleMobileMenuHandler}
                    className="mobileMenuBtn"
                >
                    <span className="material-icons" translate="no">dehaze</span>
                </button>
                <div ref={menuContainer} className="menu-container">
                    <nav>
                        { pages.map( (page, i) =>
                            page.inTopNav && 
                            page.authorized?.find(user => user === session.userType) ?
                                page.type === "LOGOUT" ?
                                    <button key={`navItem-${i}`} onClick={logoutHandler} >
                                        <span className="label"><span className="material-icons icon" translate="no">logout</span>
                                            {config.i18n.misc.logout}</span>
                                    </button>
                                : <li key={`navItem-${i}`} onClick={toggleMobileMenuHandler} >
                                        <NavLink exact to={`/${getUrl(page, session.userType)}`}>
                                            {getLabel(page, session.userType)}
                                        </NavLink>
                                    </li> : null
                        ) }
                    </nav>
                    <button onClick={toggleMobileMenuHandler} className="closeMobileMenuBtn" >
                        <span className="material-icons" translate="no">clear</span>
                    </button>
                </div>
            </div>
        </header>
    );
}

export default Header;