import React, { useContext } from "react";
import { useHistory } from "react-router";
import BanditManchotErrorModal from "../../../components/BanditManchotErrorModal/BanditManchotErrorModal";
import { configStore } from "../../../contexts/ConfigContext";

interface Props {
    setModal: React.Dispatch<React.SetStateAction<JSX.Element | null>>;
    confirmButtonRef: React.RefObject<HTMLButtonElement>;
    url: string;
}

const FailureModal = ({
    setModal,
    confirmButtonRef,
    url,
}: Props): JSX.Element => {
    const {
        config: { i18n },
    } = useContext(configStore);
    const history = useHistory();

    return (
        <BanditManchotErrorModal
            title={i18n.prm.manager.errorModal.title}
            description={i18n.prm.manager.errorModal.description}
            primaryButton={{
                label: i18n.prm.manager.errorModal.primaryButton,
                fn: () => {
                    setModal(null);
                    confirmButtonRef.current?.click();
                },
            }}
            secondaryButton={{
                label: i18n.prm.manager.errorModal.secondaryButton,
                fn: () => {
                    history.push(url);
                },
            }}
        />
    );
};

export default FailureModal;
