import React, { useContext } from "react";
import { sessionStore } from "../../../contexts/SessionContext";
import { History } from "@evidenceb/bandit-manchot";
import { dataStore } from "../../../contexts/DataContext";
import * as localStorage from "../../../utils/localStorage";
import * as Sentry from "@sentry/react";

const UseLocalHistory: React.FC = () => {
    const {
        session: { flags },
        setSession,
    } = useContext(sessionStore);
    const { data } = useContext(dataStore);

    (window as any).HISTORY = {
        useLocalHistory: async () => {
            if (flags.useHistoryFrom === "localHistory") return;

            Sentry.addBreadcrumb({
                category: "window.console",
                message: "Use local history",
                level: Sentry.Severity.Info,
            });

            setSession((curr) => {
                return {
                    ...curr,
                    banditManchot: undefined,
                    initialHistory: undefined,
                    flags: {
                        ...curr.flags,
                        useHistoryFrom: "localHistory",
                    },
                };
            });
        },

        useLRSHistory: async () => {
            if (flags.useHistoryFrom === "LRS") return;

            Sentry.addBreadcrumb({
                category: "window.console",
                message: "Use LRS history",
                level: Sentry.Severity.Info,
            });

            setSession((curr) => {
                return {
                    ...curr,
                    banditManchot: undefined,
                    initialHistory: undefined,
                    flags: {
                        ...curr.flags,
                        useHistoryFrom: "LRS",
                    },
                };
            });
        },

        clearLocalHistory: async () => {
            Sentry.addBreadcrumb({
                category: "window.console",
                message: "Clear local history",
                level: Sentry.Severity.Info,
            });

            localStorage.removeItem(localStorage.Key.LOCAL_HISTORY);
            if (flags.useHistoryFrom === "localHistory") {
                setSession((curr) => {
                    return {
                        ...curr,
                        banditManchot: undefined,
                        initialHistory: {
                            status: "settled",
                            value: data.modules.reduce((history, module) => {
                                history[module.id] = [];
                                return history;
                            }, {} as History),
                        },
                        flags: {
                            ...curr.flags,
                        }, // This is to trigger the UI refresh
                    };
                });
            }
        },
    };

    return <></>;
};

export default UseLocalHistory;
