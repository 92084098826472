import React from "react";
// External modules
import { ReactSVG } from "react-svg";
import appConfig from "../../config";
// SCSS
import "./Icon.scss";
// Interfaces
export interface IconProps {
    /** @default medium */
    size?: "small" | "medium" | "large" | "clickable" | number;
    /**
     * name of the file in public/icons folder
     */
    path: string;
    color?: string;
    className?: string;
}

const Icon = ({ size = "medium", path, color, className }: IconProps) => {
    return (
        <span
            className={`ds-icon ds-icon--${size} ${className ?? ""}`}
            style={
                typeof size === "number"
                    ? { height: size + "px", width: size + "px" }
                    : undefined
            }
            aria-hidden="true"
        >
            <ReactSVG
                beforeInjection={(svg) => {
                    if (color) svg.setAttribute("fill", color);
                    if (typeof size === "number") {
                        svg.style.width = size + "px";
                        svg.style.height = size + "px";
                    }
                }}
                src={`${appConfig.basePath ?? "/"}icons/${path}.svg`}
            />
        </span>
    );
};
export default Icon;
