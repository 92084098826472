import React, { useContext } from "react";
import { UsePRManagerReturn } from "../Manager/usePRManager";
import BanditManchotErrorModal from "../../../components/BanditManchotErrorModal/BanditManchotErrorModal";

import { useHistory } from "react-router";
import { configStore } from "../../../contexts/ConfigContext";

interface Props {
    reset?: UsePRManagerReturn["reset"];
    url?: string;
    setModal?: React.Dispatch<React.SetStateAction<JSX.Element | null>>;
    setShowModal?: React.Dispatch<React.SetStateAction<boolean>>;
}

const CancelModal = ({ reset, setModal, setShowModal, url }: Props): JSX.Element => {
    const {
        config: { i18n },
    } = useContext(configStore);
    const history = useHistory();

    return (
        <BanditManchotErrorModal
            title={i18n.prm.manager.cancelModal.title}
            description={i18n.prm.manager.cancelModal.description}
            primaryButton={{
                label: i18n.prm.manager.cancelModal.primaryButton,
                fn: () => {
                    if (setModal) setModal(null);
                    if (setShowModal) setShowModal(false);
                },
            }}
            secondaryButton={{
                label: i18n.prm.manager.cancelModal.secondaryButton,
                fn: () => {
                    if (reset) reset();
                    if (url) history.push(url);
                    if (setModal) setModal(null);
                    if (setShowModal) setShowModal(false);
                },
            }}
        />
    );
};

export default CancelModal;
