import React, { useContext, useMemo } from "react";
import parse from "html-react-parser";
import {
    PRHierarchy,
    PRItemStatus,
    PRTree,
} from "@evidenceb/gameplay-interfaces";
import { dataStore } from "../../../contexts/DataContext";
import { configStore } from "../../../contexts/ConfigContext";
import { UsePRManagerReturn } from "./usePRManager";
import usePRConfirmation, { Confirmation } from "./usePRConfirmation";
import { buildPRTree, getDescendentLevel } from "../../../utils/prm";
import ManagerListItem, {
    Props as ManagerListItemProps,
} from "./ManagerListItem/ManagerListItem";
import Loader from "../../../components/Loader/Loader";
import BatchControls from "./BatchControls/BatchControls";
import ConfirmationItemControls from "./ManagerListItem/ConfirmationItemControls/ConfirmationItemControls";
import ToggleItemControls, {
    getToggleText,
} from "./ManagerListItem/ToggleItemControls/ToggleItemControls";
import { LevelData } from "../PedagogicalResourcesManagement";

import "./Manager.scss";

interface Props {
    prLockStatus: UsePRManagerReturn["currentPRLockStatus"];
    toggleItems: UsePRManagerReturn["toggleItems"];
    level: LevelData;
    setLevel: React.Dispatch<React.SetStateAction<LevelData>>;
}

const Manager = ({ prLockStatus, toggleItems, level, setLevel }: Props) => {
    const { data } = useContext(dataStore);
    const {
        config: { i18n, ai },
    } = useContext(configStore);
    const { confirmation, attemptToggle, confirm } =
        usePRConfirmation(toggleItems);

    const prTree = useMemo<PRTree | undefined>(
        () => (prLockStatus ? buildPRTree(prLockStatus, data, ai!) : undefined),
        [prLockStatus, data, ai]
    );

    if (!prTree || !prLockStatus) return <Loader />;

    return (
        <div className="pr-manager">
            <BatchControls
                level={level}
                setLevel={setLevel}
                prLockStatus={prLockStatus}
                prTree={prTree}
                toggleItems={toggleItems}
                confirmToggleAttempt={confirm}
            />

            <ol className="manager__item-list">
                {/* Container for nitial test */}
                {level.hierarchy === PRHierarchy.Objectives && (
                    <ManagerListItem
                        status={
                            level.prPool.every(
                                (item) =>
                                    prTree[level.hierarchy][item.id].status !==
                                    "unlocked"
                            )
                                ? "locked"
                                : "unlocked"
                        }
                        className="item-list__initial-test"
                    >
                        <div className="initial-test__title">
                            {i18n.prm.manager.listItem.initialTest}
                        </div>
                        <p className="initial-test__description">
                            {level.prPool.some(
                                (item) =>
                                    prTree.objectives[item.id].status ===
                                    "unlocked"
                            )
                                ? i18n.prm.manager.listItem
                                      .initialTestDeactivateDescription
                                : i18n.prm.manager.listItem
                                      .initialTestActivateDescription}
                        </p>
                        <div className="list-item__toggle-container">
                            <span className="toggle__state">
                                {level.prPool.some(
                                    (item) =>
                                        prTree.objectives[item.id].status ===
                                        "unlocked"
                                )
                                    ? getToggleText("unlocked", i18n)
                                    : getToggleText("locked", i18n)}
                            </span>
                        </div>
                    </ManagerListItem>
                )}

                {level.prPool.map((item, index) => {
                    const itemStatus = prTree[level.hierarchy][item.id];
                    const isClickable =
                        level.hierarchy === PRHierarchy.Modules &&
                        itemStatus.status === "unlocked";
                    const ItemDescription = isClickable ? "button" : "div";

                    return (
                        <ManagerListItem
                            key={"list-item-" + item.id}
                            className={`${
                                isClickable ? "list-item--clickable" : ""
                            } ${
                                level.hierarchy === PRHierarchy.Modules
                                    ? "list-item--module"
                                    : ""
                            }`}
                            status={getItemStatus(
                                level.hierarchy,
                                confirmation,
                                item.id,
                                itemStatus
                            )}
                        >
                            <ItemDescription
                                className="list-item__description"
                                onClick={
                                    isClickable
                                        ? () => {
                                              setLevel((curr) =>
                                                  getDescendentLevel(
                                                      item.id,
                                                      data,
                                                      curr,
                                                      i18n
                                                  )
                                              );
                                          }
                                        : undefined
                                }
                            >
                                <p>
                                    {level.hierarchy === PRHierarchy.Modules ? (
                                        item.title.short
                                    ) : (
                                        <>
                                            {index + 1}.{" "}
                                            {parse(item.description.$html)}
                                        </>
                                    )}
                                </p>

                                {isClickable && (
                                    <div className="list-item__chevron">
                                        <span
                                            className="material-icons"
                                            translate="no"
                                        >
                                            chevron_right
                                        </span>
                                    </div>
                                )}
                            </ItemDescription>

                            {confirmation?.originId === item.id ? (
                                <ConfirmationItemControls
                                    confirmation={confirmation}
                                    confirm={confirm!}
                                />
                            ) : confirmation ? (
                                <></>
                            ) : (
                                <ToggleItemControls
                                    item={item}
                                    prTree={prTree}
                                    prLockStatus={prLockStatus}
                                    level={level}
                                    attemptToggle={attemptToggle}
                                    itemStatus={itemStatus}
                                />
                            )}
                        </ManagerListItem>
                    );
                })}
            </ol>
        </div>
    );
};

const getItemStatus = (
    hierarchy: PRHierarchy,
    confirmation: Confirmation | undefined,
    id: string,
    status: PRItemStatus
): ManagerListItemProps["status"] => {
    if (
        confirmation &&
        (confirmation.originId === id ||
            confirmation.dependencyIds.includes(id))
    )
        return `${confirmation.type}Dependency` as
            | "activateDependency"
            | "deactivateDependency";
    if (hierarchy === PRHierarchy.Modules && status.status === "descendent")
        return "locked";
    if (status.status === "descendent") return "locked";
    return status.status;
};

export default Manager;
