import React, { useContext } from "react";
import parse from "html-react-parser";
import Collapse, { Panel } from "rc-collapse";
import { Activity, Objective } from "@evidenceb/gameplay-interfaces";
import { Status } from "@evidenceb/bandit-manchot";
import { Config } from "../../../../../interfaces/Config";
import { configStore } from "../../../../../contexts/ConfigContext";
import { getObjectiveNumber } from "../StudentPlayerInfoPanel";
import ActivityLabel from "./ActivityLabel/ActivityLabel";

import tear from "../tear.svg";
import { getItemDescription } from "../../../../../utils/dataRetrieval";

type CurrentObjectiveProps = {
    objective: Objective;
    activities: (Activity & { status: Status })[];
    currentActivityId: string;
};

const CurrentObjective: React.FC<CurrentObjectiveProps> = ({
    activities,
    objective,
}) => {
    const {
        config: { i18n },
    } = useContext(configStore);
    const description = getItemDescription(objective, "student")
    return (
        <>
            <div className="objective current-objective">
                <img className="current-objective-tear" src={tear} alt="" />
                <div className="objective-description">
                    { 
                        parse( getObjectiveNumber(objective, i18n.misc.colon) + description ) 
                    }
                    <Collapse
                        expandIcon={(props) =>
                            ExpanIconClojure(
                                i18n,
                                (props as any)["isActive"] as boolean
                            )
                        }
                        accordion={false}
                    >
                        <Panel>
                            <div className="journey-activities">
                                {activities.map((activity, index) => (
                                    <ActivityLabel
                                        status={activity.status}
                                        key={`activity-${index}`}
                                    >
                                        {
                                            parse( getItemDescription(activity, "student") )
                                        }
                                    </ActivityLabel>
                                ))}
                            </div>
                        </Panel>
                    </Collapse>
                </div>
            </div>
        </>
    );
};

const ExpanIconClojure = (i18n: Config["i18n"], isActive: boolean) => {
    return (
        <div className="expand-icon-current-objective">
            <span
                className="material-icons material-icons-outlined"
                translate="no"
            >
                {isActive ? "remove_circle" : "add_circle"}
            </span>
            <span className="details-text">
                {isActive
                    ? i18n.features.studentChatbotPlayerInfoPanel.reduceDetails
                    : i18n.features.studentChatbotPlayerInfoPanel.details}
            </span>
        </div>
    );
};

export default CurrentObjective;
