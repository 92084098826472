import React, { useContext } from "react";
import { useHistory } from "react-router";
import { Config } from "../../../interfaces/Config";
import { configStore } from "../../../contexts/ConfigContext";
import { dataStore } from "../../../contexts/DataContext";
import { getModuleById } from "../../../utils/dataRetrieval";
import ProgressBarWithLabel from "../../../components/ProgressBarWithLabel/ProgressBarWithLabel";
import StudentPath from "../StudentPath/StudentPath";

import "./NewModuleCard.scss";
import completedMedal from "./completed-medal.svg";
import blazon from "../../../assets/images/blazon.svg";
import InactiveModuleCard from "./InactiveModuleCard/InactiveModuleCard";

enum ModuleState {
    New = "NEW",
    InProgress = "IN_PROGRESS",
    Completed = "COMPLETED",
}

interface Props {
    moduleId: string;
    diagnosis?: {
        progression: number;
        averageScore: number;
        isModuleFinished: boolean;
    };
    error?: boolean;
    locked?: boolean;
}

const NewModuleCard = ({ moduleId, diagnosis, error, locked }: Props) => {
    const { data } = useContext(dataStore);
    const {
        config: { i18n, features },
    } = useContext(configStore);
    const history = useHistory();

    const module = getModuleById(moduleId, data);

    if (error || locked || typeof locked === "undefined" || !diagnosis)
        return (
            <InactiveModuleCard
                module={module}
                type={error ? "error" : "locked"}
                diagnosis={diagnosis}
            />
        );

    const moduleState = getModuleState(diagnosis);

    return (
        <article
            className={`new-student-module-card ${
                moduleState === ModuleState.Completed
                    ? "--module-completed"
                    : ""
            }`}
        >
            {moduleState === ModuleState.Completed && (
                <img
                    className="card__completed-medal"
                    src={completedMedal}
                    alt=""
                />
            )}

            <div className="card__head">
                {features.showModuleMapInStudentModuleList &&
                    module.studentPath && (
                        <StudentPath {...module.studentPath} />
                    )}
                <span className="card__title">{module.title.short}</span>
                {
                    // Placeholder to maintain the title in the center of the
                    // flex div when there is a StudentPath icon
                    module.studentPath && <div></div>
                }
            </div>

            <ProgressBarWithLabel
                progress={diagnosis.progression}
                label={i18n.moduleList.student.progression}
                color="#BAE7E9"
            />
            <ProgressBarWithLabel
                progress={diagnosis.averageScore}
                label={i18n.moduleList.student.successRate}
                color="rgba(255, 184, 0, 0.15)"
            />

            {moduleState !== ModuleState.Completed ? (
                <button
                    className="card__action card__button"
                    onClick={() => {
                        history.push("/player/" + moduleId);
                    }}
                >
                    <div
                        className={`button__icon ${
                            moduleState === ModuleState.InProgress
                                ? "--in-progress"
                                : ""
                        }`}
                    >
                        <span className="material-icons" translate="no">
                            arrow_right
                        </span>
                    </div>
                    <span className="button__label">
                        {getModuleActionText(moduleState, i18n)}
                    </span>
                </button>
            ) : (
                <div className="card__action">
                    <img className="card__blazon" src={blazon} alt="" />
                    <span>{getModuleActionText(moduleState, i18n)}</span>
                </div>
            )}
        </article>
    );
};

const getModuleState = (diagnosis: {
    progression: number;
    averageScore: number;
    isModuleFinished: boolean;
}): ModuleState => {
    if (diagnosis.isModuleFinished) return ModuleState.Completed;
    if (diagnosis.progression === 0) return ModuleState.New;
    return ModuleState.InProgress;
};

const getModuleActionText = (
    moduleState: ModuleState,
    i18n: Config["i18n"]
): string => {
    if (moduleState === ModuleState.New) return i18n.moduleList.student.start;
    if (moduleState === ModuleState.Completed)
        return i18n.moduleList.student.completedModule;
    return i18n.moduleList.student.continue;
};

export default NewModuleCard;
