import React, { useContext } from "react";
import { configStore } from "../../../../../contexts/ConfigContext";
import { Confirmation, UsePRConfirmationReturn } from "../../usePRConfirmation";
import Toggle from "../../../../../components/Toggle/Toggle";

interface Props {
    confirm: UsePRConfirmationReturn["confirm"];
    confirmation: Confirmation;
}

const ConfirmationItemControls = ({ confirmation, confirm }: Props) => {
    const {
        config: { i18n },
    } = useContext(configStore);

    return (
        <>
            <div>
                <Toggle
                    isReadOnly={true}
                    isSelected={confirmation.type === "activate"}
                    onChange={() => {}}
                    accessibleLabel="" // It's ok because the toggle is inactive
                    pinIcon={
                        confirmation.type === "activate" ? "done" : "close"
                    }
                ></Toggle>
            </div>

            <div className="list-item__child--highlighted">
                {confirmation.type === "deactivate"
                    ? i18n.prm.manager.listItem.deactivatePrompt
                    : i18n.prm.manager.listItem.activatePrompt}
            </div>

            <button
                className="list-item__child--highlighted --bold"
                onClick={() => {
                    confirm!(true);
                }}
            >
                {confirmation.type === "deactivate"
                    ? i18n.prm.manager.listItem.deactivate
                    : i18n.prm.manager.listItem.activate}
            </button>

            <button
                className="list-item__child--highlighted"
                onClick={() => {
                    confirm!(false);
                }}
            >
                {i18n.prm.manager.listItem.cancel}
            </button>
        </>
    );
};

export default ConfirmationItemControls;
