import React, { useState } from "react";
import { Link } from "react-router-dom";
import CancelModal from "../Modals/CancelModal";

interface Props {
    to: string;
    /** True if the PR lock status was changed */
    dirty: boolean;
    className?: string;
    children: JSX.Element | JSX.Element[];
}

const SafeguardedLeaveLink = ({
    dirty,
    to,
    className,
    children,
}: Props): JSX.Element => {
    const [showModal, setShowModal] = useState<boolean>(false);

    if (!dirty)
        return (
            <Link to={to} className={className}>
                {children}
            </Link>
        );

    return (
        <>
            <button
                className={className}
                onClick={() => {
                    setShowModal(true);
                }}
            >
                {children}
            </button>

            {showModal && <CancelModal url={to} setShowModal={setShowModal} />}
        </>
    );
};

export default SafeguardedLeaveLink;
