import React, { useContext } from "react";
//External modules
import { useHistory, useParams } from "react-router-dom";
//Contexts
import { dataStore } from "../../../../contexts/DataContext";
import { configStore } from "../../../../contexts/ConfigContext";
import { dashboardStore } from "../../../../contexts/DashboardContext";
//Components
import Button from "../../../../components/Button/Button";
import CustomSelect from "../../../../components/CustomSelect/CustomSelect";
//Utils
import { getModuleById } from "../../../../utils/dataRetrieval";
//SCSS
import "./ClassroomsNav.scss";
//Interfaces
import { ParamTypes, Classroom } from "../../../../interfaces/Dashboard";
import { PRTree } from "@evidenceb/gameplay-interfaces";
import LockStatusLabel from "../../../../components/LockStatusLabel/LockStatusLabel";
interface ProgressionNavProps {
    classrooms: Classroom[];
    classroom: Classroom;
    prTree?: PRTree;
}

const ClassroomsNav = ({
    classrooms,
    classroom,
    prTree,
}: ProgressionNavProps) => {
    //Contexts
    const { data } = useContext(dataStore);
    const { config } = useContext(configStore);
    const { dashboard } = useContext(dashboardStore);
    //Hooks
    const history = useHistory();
    const { moduleId, classroomId } = useParams<ParamTypes>();
    //Functions
    const viewGroups = () => {
        history.push(
            `/${config.i18n.dashboard?.paths.dashboard}/${config.i18n.dashboard?.paths.classes}/${classroomId}/${moduleId}/${config.i18n.dashboard?.paths.groups}`
        );
    };

    const selectClassroom = (value: string) => {
        let url = `/${config.i18n.dashboard!.paths.dashboard}/${
            config.i18n.dashboard!.paths.classes
        }/${config.i18n.dashboard!.paths.progression}/${value}/${moduleId}`;
        history.push(url);
    };

    const selectModule = (value: string) => {
        let url = `/${config.i18n.dashboard!.paths.dashboard}/${
            config.i18n.dashboard!.paths.classes
        }/${config.i18n.dashboard!.paths.progression}/${classroomId}/${value}`;
        history.push(url);
    };

    const isClustering = () => {
        if (config.features.clustering) {
            if (
                dashboard.clustering &&
                dashboard.clustering[classroomId] &&
                dashboard.clustering[classroomId][moduleId] &&
                typeof dashboard.clustering[classroomId][moduleId].error ===
                    "undefined"
            ) {
                return true;
            }
            return false;
        }
        return false;
    };

    return (
        <div className="classrooms-navigation">
            <CustomSelect
                label={config.i18n.dashboard?.common.classes}
                onSelectOption={(value) => {
                    selectClassroom(value);
                }}
                selectedOption={classroom.name}
            >
                {classrooms.map(
                    (classroom, i) =>
                        classroom.studentsNumber > 0 &&
                        classroom.modulesList.length > 0 && (
                            <option key={"class-" + i} value={classroom.id}>
                                {`${config.i18n.dashboard?.common.class} ${classroom.name}`}
                            </option>
                        )
                )}
            </CustomSelect>

            <CustomSelect
                label={config.i18n.dashboard?.common.modules}
                onSelectOption={(value) => {
                    selectModule(value);
                }}
                selectedOption={
                    <div className="selected-module__wrapper">
                        {getModuleById(moduleId, data).title.short!}
                        {prTree?.modules[moduleId].status !== "unlocked" && (
                            <LockStatusLabel locked={true} />
                        )}
                    </div>
                }
            >
                {classroom.modulesList.map((module, i) => (
                    <option key={"module-" + i} value={module.id}>
                        {getModuleById(module.id, data).title.short}
                        {prTree?.modules[module.id].status !== "unlocked" &&
                            ` (${config.i18n.prm.manager.listItem.deactivated.toLowerCase()})`}
                    </option>
                ))}
            </CustomSelect>

            {isClustering() ? (
                <Button
                    type={"primary"}
                    label={
                        config.i18n.dashboard?.progressionOverview
                            .seeGroupsOfSelection
                    }
                    icons={[
                        { placement: "left", code: "timeline", noCircle: true },
                        {
                            placement: "right",
                            code: "arrow_forward",
                            noCircle: true,
                        },
                    ]}
                    onClick={viewGroups}
                />
            ) : (
                <Button
                    type={"primary"}
                    disabled={true}
                    label={
                        config.i18n.dashboard?.progressionOverview
                            .noClusteringGenerated
                    }
                    icons={[
                        { placement: "left", code: "timeline", noCircle: true },
                    ]}
                />
            )}
        </div>
    );
};

export default ClassroomsNav;
