import React, { MouseEvent, useContext } from "react";
import { configStore } from "../../../../../contexts/ConfigContext";
import Icon from "../../../../../design-system-components/Icon/Icon";
import { ProgressFilter } from "../Progress";

interface Props {
    progressFilter: ProgressFilter | undefined;
    setProgressFilter: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const Filter = ({ progressFilter, setProgressFilter }: Props) => {
    const { config } = useContext(configStore);

    const selectHandler = (e: MouseEvent<HTMLDivElement>) => {
        e.currentTarget.parentElement?.classList.toggle("show");
    };
    const filterHandler = (e: MouseEvent<HTMLLIElement>) => {
        e.currentTarget.parentElement?.parentElement?.classList.toggle("show");
        // filter something
        setProgressFilter(e.currentTarget.dataset.value);
    };
    const resetFilter = (e: MouseEvent<HTMLButtonElement>) => {
        setProgressFilter(undefined);
    };

    return (
        <>
            {/* [TODO] This should be a <select> */}
            <div className="details-custom-select filters">
                {progressFilter && <button onClick={resetFilter}>X</button>}

                <div className="label" onClick={selectHandler}>
                    <span className="text">
                        {
                            config.i18n.dashboard?.studentDetails
                                .filterDefaultValue
                        }
                    </span>
                    <span className="material-icons icon open" translate="no">
                        {" "}
                        expand_more{" "}
                    </span>
                    <span className="material-icons icon close" translate="no">
                        {" "}
                        expand_less{" "}
                    </span>
                </div>
                <ul className="select">
                    <li
                        onClick={filterHandler}
                        data-value={"difficulties"}
                        className="option difficulties"
                    >
                        <Icon path="alert_inf" size="medium" />
                        <span className="label">
                            {
                                config.i18n.dashboard?.studentDetails.fileters
                                    .difficulties
                            }
                        </span>
                    </li>
                    <li
                        onClick={filterHandler}
                        data-value={"facilities"}
                        className="option facilities"
                    >
                        <Icon path="alert_sup" size="medium" />
                        <span className="label">
                            {
                                config.i18n.dashboard?.studentDetails.fileters
                                    .facilities
                            }
                        </span>
                    </li>
                    <li
                        onClick={filterHandler}
                        data-value={"validatedObjectives"}
                        className="option validatedObjectives"
                    >
                        <span className="icon"></span>
                        <span className="label">
                            {
                                config.i18n.dashboard?.studentDetails.fileters
                                    .validatedObjectives
                            }
                        </span>
                    </li>
                    <li
                        onClick={filterHandler}
                        data-value={"currentObjective"}
                        className="option currentObjective"
                    >
                        <span className="icon"></span>
                        <span className="label">
                            {
                                config.i18n.dashboard?.studentDetails.fileters
                                    .currentObjectives
                            }
                        </span>
                    </li>
                </ul>
            </div>
        </>
    );
};

export default Filter;
