export enum AppStatus {
    Pending = "PENDING",
    Loaded = "LOADED",
    Error = "ERROR"
}

export enum PageState {
    Loading = "LOADING",
    StatementsError = "STATEMENTS_ERROR",
    StatementsRetry = "STATEMENTS_RETRY",
    Loaded = "LOADED",
}