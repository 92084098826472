import React, { useContext } from "react";
import { useHistory } from "react-router";
import BanditManchotErrorModal from "../../../components/BanditManchotErrorModal/BanditManchotErrorModal";
import { configStore } from "../../../contexts/ConfigContext";

interface Props {
    url: string;
}

const SuccessModal = ({ url }: Props): JSX.Element => {
    const {
        config: { i18n },
    } = useContext(configStore);
    const history = useHistory();

    return (
        <BanditManchotErrorModal
            title={
                <h2 className="prm__success-title">
                    <span
                        className="prm__success-icon material-icons"
                        translate="no"
                    >
                        check_circle
                    </span>
                    <span>{i18n.prm.manager.successModal.title}</span>
                </h2>
            }
            description={i18n.prm.manager.successModal.description}
            primaryButton={{
                label: i18n.prm.manager.successModal.primaryButton,
                fn: () => {
                    history.push(url);
                },
            }}
        />
    );
};

export default SuccessModal;
