import React, { useContext, useEffect, useState } from 'react';
// External modules
// Contexts
import { dataStore } from '../../../../contexts/DataContext';
// Components
// Utils
import { getModuleById, getObjectiveById } from '../../../../utils/dataRetrieval';
// SCSS
import './HomeModulesList.scss';
// Interfaces
import { Objective } from '@evidenceb/gameplay-interfaces';
import { parse } from '../../../../utils/parse-text';
interface Modules {
    [id: string]: {
        title: {
            long: string;
            short: string;
        };
        nbrOfItems: number;
        objectives: Objective[];
        collapsed: boolean;
    };
}
interface HomeModulesListProps {
    btnLabel: {
        more: string;
        less: string;
    }
}
const HomeModulesList = ({ btnLabel }: HomeModulesListProps) => {

    //Contexts
    const { data } = useContext(dataStore);

    //States
    const [modules, setModules] = useState<Modules>({});

    //UseEffets
    useEffect(() => {
        const modulesArray = {} as Modules;
        data.modules.forEach(mod => {
            let objectives = getModuleById(mod.id, data).objectiveIds.map(id => getObjectiveById(id, data)) as Objective[]
            modulesArray[mod.id] = {
                title: {
                    long: mod.title.short!,
                    short: mod.title.long
                },
                nbrOfItems: 4,
                objectives: objectives.slice(1, objectives.length),
                collapsed: true
            }
        })
        setModules(modulesArray)
    }, [data])

    //functions
    const toggleItems = (moduleId: string) => {
        setModules({
            ...modules,
            [moduleId]: {
                ...modules[moduleId],
                nbrOfItems: modules[moduleId].nbrOfItems === modules[moduleId].objectives.length ? 4 : modules[moduleId].objectives.length,
                collapsed: !modules[moduleId].collapsed
            }
        })

    }

    return (
        <div className='HomeModulesList clearfix'>

            {
                modules && Object.keys(modules).map(id =>
                    <div className="module" key={id}>
                        <h3>{modules[id].title.long}</h3>
                        <div className="objectives">
                            <ul>
                                {
                                    modules[id].objectives.map((objective, i) => {
                                        return i <= modules[id].nbrOfItems &&
                                            <li key={objective.id}>
                                                <h4>
                                                    <span className="material-icons" aria-hidden="true" translate="no">circle</span>
                                                    {objective.title.long}
                                                </h4>
                                                <p>
                                                    { 
                                                        objective.descriptions?.student.$html !== "" ? parse(objective.descriptions!.student.$html) : parse(objective.descriptions?.default.$html)
                                                    }
                                                </p>
                                            </li>
                                    }
                                    )
                                }
                            </ul>
                            <button onClick={() => toggleItems(id)} className={!modules[id].collapsed ? "opened" : ""}>
                                <span className="material-icons" aria-hidden="true" translate="no">
                                    {
                                        modules[id].collapsed ? "expand" : "vertical_align_center"
                                    }
                                </span>
                                <span>
                                    {
                                        modules[id].collapsed ? btnLabel.more : btnLabel.less
                                    }
                                </span>
                            </button>
                        </div>
                    </div>
                )
            }

        </div>
    )
}

export default HomeModulesList