import React from "react";
import { Status } from "@evidenceb/bandit-manchot";

import "./ActivityLabel.scss";
import closed from "../../activityCompleted.svg";
import inProgress from "../../tear.svg";

const ActivityLabel = ({
    status,
    children,
}: {
    status: Status;
    children: JSX.Element | JSX.Element[];
}): JSX.Element => {
    return (
        <div className="activity-label">
            <div className="label-icon">
                {(() => {
                    switch (status) {
                        case "closed":
                            return <img alt="" src={closed} />;
                        case "inProgress":
                            return <img alt="" src={inProgress} />;
                        case "none":
                            return <div className="circle --light"></div>;
                        case "open":
                            return <div className="circle"></div>;
                        default:
                            return null;
                    }
                })()}
            </div>
            <div className={`label-description --${status}`}>{children}</div>
        </div>
    );
};

export default ActivityLabel;
