import React from "react";

const BoredBot = () => (
    <svg
        viewBox="0 0 73 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g filter="url(#filter0_d)">
            <path
                d="M15.447 28.5179C15.447 32.6772 14.1241 36.0475 10.5333 36.0475C6.94253 36.0475 4 32.6772 4 28.5179C4 24.3586 6.92174 20.9883 10.5257 20.9883C14.1297 20.9883 15.447 24.3604 15.447 28.5179Z"
                fill="#E66342"
            />
            <path
                d="M12.8933 36.0475C15.8471 36.0475 18.2416 32.6764 18.2416 28.5179C18.2416 24.3594 15.8471 20.9883 12.8933 20.9883C9.93946 20.9883 7.54492 24.3594 7.54492 28.5179C7.54492 32.6764 9.93946 36.0475 12.8933 36.0475Z"
                fill="#2C3E50"
            />
            <path
                d="M29.5142 14.2276C32.485 13.2665 35.658 13.0328 38.7463 13.5477C50.9265 15.5474 59.6653 28.3634 58.1817 42.27C58.1043 42.9972 57.6488 43.568 57.061 43.7552L50.4219 45.8712C51.0097 45.6894 51.4651 45.1186 51.5445 44.386C53.0564 30.4866 44.363 17.6634 32.2111 15.6637C29.1282 15.147 25.9599 15.3794 22.9941 16.34L29.5142 14.2276Z"
                fill="#61A4BF"
            />
            <path
                d="M32.2152 15.6563C20.1408 13.6566 9.06043 23.0768 7.38222 36.7817C7.26316 37.7652 7.86414 38.6832 8.72593 38.8322L49.7551 45.911C50.6377 46.0619 51.4409 45.3766 51.5486 44.3804C53.0586 30.481 44.3652 17.665 32.2152 15.6563Z"
                fill="#AFDCE0"
            />
            <path
                d="M38.8205 29.973C38.9081 29.9948 38.9897 30.0333 39.0607 30.0862C39.2132 30.2087 39.3097 30.3845 39.3309 30.5783C39.3521 30.772 39.2963 30.9692 39.1748 31.1302C38.1095 32.5673 36.4062 33.3748 34.7278 33.238C33.8926 33.1602 33.1045 32.8611 32.4494 32.3731C31.7943 31.8852 31.2971 31.2271 31.0121 30.4706C30.9412 30.2888 30.9445 30.0852 31.0213 29.8999C31.0981 29.7147 31.2428 29.5614 31.4267 29.4703C31.5147 29.4274 31.6102 29.4026 31.7071 29.3973C31.804 29.3921 31.9002 29.4065 31.9895 29.4397C32.0787 29.4729 32.159 29.5241 32.2252 29.59C32.2914 29.656 32.3421 29.7352 32.3739 29.8226C32.5719 30.3462 32.9163 30.8017 33.3697 31.1398C33.8232 31.4779 34.3686 31.6858 34.9468 31.7409C35.5367 31.779 36.1328 31.6644 36.6742 31.4087C37.2156 31.1531 37.683 30.7656 38.0287 30.2857C38.114 30.1617 38.2362 30.065 38.3781 30.0089C38.52 29.9529 38.6746 29.9403 38.8205 29.973Z"
                fill="#2C3E50"
            />
            <path
                d="M24.9181 27.7074C25.0056 27.729 25.0872 27.7674 25.1579 27.8205C25.311 27.9424 25.4079 28.1182 25.4291 28.3121C25.4502 28.506 25.3939 28.7034 25.2716 28.8641C24.2072 30.301 22.5056 31.1085 20.831 30.9726C19.9947 30.8961 19.2053 30.5975 18.5491 30.1094C17.8929 29.6212 17.395 28.9622 17.1097 28.2043C17.039 28.0226 17.0423 27.819 17.1191 27.6338C17.1958 27.4487 17.3404 27.2954 17.5242 27.2042C17.6122 27.1617 17.7078 27.1371 17.8046 27.1319C17.9015 27.1268 17.9976 27.1413 18.0868 27.1744C18.176 27.2076 18.2563 27.2587 18.3226 27.3245C18.3889 27.3903 18.4397 27.4694 18.4719 27.5566C18.6694 28.0823 19.0145 28.5394 19.4695 28.8779C19.9245 29.2165 20.4719 29.4235 21.0518 29.4763C21.6408 29.5139 22.2359 29.399 22.7765 29.1433C23.317 28.8876 23.7836 28.5002 24.1288 28.0206C24.2139 27.8968 24.3356 27.8002 24.477 27.7441C24.6184 27.688 24.7725 27.6752 24.9181 27.7074Z"
                fill="#2C3E50"
            />
            <path
                d="M56.1628 43.0416C59.1166 43.0416 61.5112 39.6705 61.5112 35.512C61.5112 31.3535 59.1166 27.9824 56.1628 27.9824C53.209 27.9824 50.8145 31.3535 50.8145 35.512C50.8145 39.6705 53.209 43.0416 56.1628 43.0416Z"
                fill="#2C3E50"
            />
            <path
                d="M53.6055 35.512C53.6055 39.6713 54.9284 43.0434 58.5191 43.0434C62.1099 43.0434 65.0449 39.6713 65.0449 35.512C65.0449 31.3527 62.1231 27.9824 58.5191 27.9824C54.9152 27.9824 53.6055 31.3546 53.6055 35.512Z"
                fill="#E66342"
            />
            <path
                d="M57.5215 32.0645L57.9732 32.2336C58.3511 31.3119 58.6989 30.3139 59.0409 29.3487C59.7213 27.4326 60.4262 25.4511 61.2804 24.0132C64.2305 19.0595 67.209 18.1633 67.2392 18.1542L67.1032 17.707C66.9746 17.7434 63.9187 18.6432 60.8666 23.7805C59.9878 25.2585 59.2772 27.2599 58.5893 29.1978C58.2415 30.1594 57.89 31.152 57.5215 32.0645Z"
                fill="#E66342"
            />
            <path
                d="M68.9905 18.2807C68.9958 17.2466 68.1285 16.4042 67.0535 16.3992C65.9784 16.3941 65.1027 17.2283 65.0974 18.2624C65.0921 19.2965 65.9594 20.1389 67.0344 20.1439C68.1095 20.149 68.9853 19.3148 68.9905 18.2807Z"
                fill="#2C3E50"
            />
            <path
                d="M52.1758 47.3283C52.5066 47.2794 52.8235 47.1663 53.1074 46.996C53.3914 46.8257 53.6364 46.6017 53.8276 46.3375L57.9702 40.6076L57.3068 40.1641L53.1642 45.894C53.0094 46.1086 52.8021 46.2834 52.5605 46.4029C52.319 46.5224 52.0506 46.583 51.779 46.5793L37.0152 46.3975L37.0039 47.1756L51.7695 47.3483C51.9053 47.3526 52.0412 47.3459 52.1758 47.3283Z"
                fill="#E66342"
            />
            <path
                d="M34.2756 47.9424L38.7071 47.9871C39.4157 47.9942 39.9963 47.4474 40.0037 46.7657L40.0037 46.7639C40.0111 46.0823 39.4426 45.5239 38.734 45.5167L34.3025 45.4721C33.5938 45.4649 33.0133 46.0118 33.0059 46.6934L33.0059 46.6952C32.9984 47.3769 33.5669 47.9353 34.2756 47.9424Z"
                fill="#2C3E50"
            />
            <path
                d="M27.0113 39.6631C28.5364 39.6631 29.7727 38.0801 29.7727 36.1274C29.7727 34.1748 28.5364 32.5918 27.0113 32.5918C25.4863 32.5918 24.25 34.1748 24.25 36.1274C24.25 38.0801 25.4863 39.6631 27.0113 39.6631Z"
                fill="#FCFDF4"
            />
            <path
                d="M33.582 16.8359H41.7383V20H28.4492V17.832L36.582 6.11328H28.4023V2.9375H41.6562V5.04688L33.582 16.8359Z"
                fill="black"
            />
            <path
                d="M49.4365 16.627H55.5537V19H45.5869V17.374L51.6865 8.58496H45.5518V6.20312H55.4922V7.78516L49.4365 16.627Z"
                fill="black"
            />
            <path
                d="M61.1729 20.1543H65.9307V22H58.1787V20.7354L62.9229 13.8994H58.1514V12.0469H65.8828V13.2773L61.1729 20.1543Z"
                fill="black"
            />
        </g>
        <defs>
            <filter
                id="filter0_d"
                x="0"
                y="0"
                width="73"
                height="55.9878"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="2" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow"
                    result="shape"
                />
            </filter>
        </defs>
    </svg>
);

export default BoredBot;
