import React, { useContext, useEffect, useState } from "react";
import { Tab, TabList, TabPanels, Tabs, TabPanel } from "@reach/tabs";
import {
    Classroom,
    ClassroomsClustering,
    ModuleDashboard,
} from "../../../../../../interfaces/Dashboard";
import { configStore } from "../../../../../../contexts/ConfigContext";
import { dataStore } from "../../../../../../contexts/DataContext";
import useClassroom from "../../../useClassroom";
import {
    getIdentifiedGroupsNumber,
    getModuleById,
} from "../../../../../../utils/dataRetrieval";
import Bar from "../../../Components/Bar/Bar";
import ModuleInfo from "../ModuleInfo/ModuleInfo";
import Toggle from "../../../../../../components/Toggle/Toggle";

import { BAR_COLOR_SCHEMES } from "../../../Components/Bar/BAR_COLOR_SCHEMES";
import "./ModuleCard.scss";

import BanditManchotErrorModal from "../../../../../../components/BanditManchotErrorModal/BanditManchotErrorModal";

interface Props {
    module: ModuleDashboard;
    classroom: Classroom;
    clustering: ClassroomsClustering | undefined;
}
interface UnlockedProps extends Props {
    type: "unlocked";
}
interface LockedProps extends Props {
    type: "locked";
    activateModule: () => Promise<void>;
}

const ModuleCard = (props: UnlockedProps | LockedProps): JSX.Element => {
    const { config } = useContext(configStore);
    const { data } = useContext(dataStore);
    const { getUrl } = useClassroom();

    const [isToggleSelected, setIsToggleSelected] = useState<boolean>(false);
    const [openModuleActivationModal, setOpenModuleActivationModal] =
        useState<boolean>(false);

    // TEMP : hide for ver-dever v1 as it should navigate to objective list
    // const settings: Setting[] = [];
    // if (config.features.classManagement)
    //     settings.push({
    //         label: config.i18n.dashboard!.manageModules,
    //         icon: "tune",
    //         action: () => null,
    //     });

    const displayClustering = (moduleId: string) => {
        if (
            config.features.clustering &&
            props.clustering &&
            props.clustering[props.classroom.id][moduleId] &&
            typeof props.clustering[props.classroom.id][moduleId].error ===
                "undefined"
        )
            return true;
        return false;
    };

    // Reinit when module changes
    useEffect(() => {
        setIsToggleSelected(false);
    }, [props.module]);

    return (
        <>
            {openModuleActivationModal && (
                <BanditManchotErrorModal
                    title={config.i18n.singleClass.activateModuleModalTitle}
                    description={
                        config.i18n.singleClass.activateModuleModalDescription
                    }
                    primaryButton={{
                        label: config.i18n.singleClass.activateModuleModalConfirm,
                        fn: async () => {
                            try {
                                await (props as LockedProps).activateModule();
                            } catch (err) {
                                setIsToggleSelected(false);
                            }
                            setOpenModuleActivationModal(false);
                        }
                    }}
                    secondaryButton={{
                        label: config.i18n.singleClass.activateModuleModalCancel,
                        fn: () => {
                            setIsToggleSelected(false);
                            setOpenModuleActivationModal(false);
                        }
                    }}
                />
            )}

            <Tabs className="single-classroom__module-card">
                <TabList>
                    <Tab
                        className={`tablist__tab ${
                            props.type === "locked" ? "--with-toggle" : ""
                        }`}
                    >
                        <span>
                            {getModuleById(props.module.id, data).title.short}
                        </span>

                        {props.type === "locked" && (
                            <Toggle
                                accessibleLabel={
                                    config.i18n.singleClass
                                        .toggleActivateModuleAccessibleLabel +
                                    getModuleById(props.module.id, data).title
                                        .long
                                }
                                isSelected={isToggleSelected}
                                onChange={async (isSelected) => {
                                    if (!isSelected) return;

                                    setIsToggleSelected(true);
                                    setOpenModuleActivationModal(true);
                                }}
                                pinIcon={isToggleSelected ? "done" : "close"}
                            />
                        )}
                    </Tab>

                    {/* TEMP : hide for v1 because it should redirect to objectives list
                {props.type === "unlocked" && (
                    <Settings icon={"more_vert"} settings={settings} />
                )} */}
                </TabList>

                <TabPanels>
                    <TabPanel>
                        {props.module.progression !== 0 ? (
                            <>
                                <Bar
                                    state={
                                        props.module.progression === 0
                                            ? "noData"
                                            : undefined
                                    }
                                    url={getUrl("progressionOverview", props.classroom.id, props.module.id)}
                                    type={"progress"}
                                    moduleName={
                                        config.i18n.dashboard!.common
                                            .progression
                                    }
                                    value={
                                        props.module.progression !== 0
                                            ? (
                                                  props.module.progression * 100
                                              ).toFixed(1)
                                            : props.module.progression
                                    }
                                    colors={
                                        BAR_COLOR_SCHEMES[
                                            (props.type + "Module") as
                                                | "unlockedModule"
                                                | "lockedModule"
                                        ]
                                    }
                                />

                                {displayClustering(props.module.id) && (
                                    <Bar
                                        state={
                                            props.module.progression === 0
                                                ? "noData"
                                                : undefined
                                        }
                                        url={getUrl(
                                            "viewGroups",
                                            props.classroom.id,
                                            props.module.id
                                        )}
                                        type={"groups"}
                                        moduleName={getIdentifiedGroupsNumber(
                                            props.clustering!,
                                            props.classroom.id,
                                            props.module.id,
                                            config.i18n.dashboard!.classList.identifiedGroups
                                        )}
                                        value={
                                            props.module.progression !== 0
                                                ? (
                                                      props.module.progression *
                                                      100
                                                  ).toFixed(1)
                                                : props.module.progression
                                        }
                                        colors={
                                            BAR_COLOR_SCHEMES[
                                                (props.type + "Module") as
                                                    | "unlockedModule"
                                                    | "lockedModule"
                                            ]
                                        }
                                    />
                                )}
                            </>
                        ) : (
                            <div className="no-progression">0%</div>
                        )}

                        <ModuleInfo
                            progression={props.module.progression}
                            total={props.module.participants}
                            inProgress={props.module.alert.hasImproved}
                            inDifficulty={props.module.alert.hasDifficulties}
                        />
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </>
    );
};

export default ModuleCard;
