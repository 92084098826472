import React, { useEffect, useState, useContext, useMemo } from "react";
import { useParams, useHistory, useRouteMatch } from "react-router-dom";
import { dataStore } from "../../contexts/DataContext";
import { configStore } from "../../contexts/ConfigContext";
import {
    Classroom,
    ClusterInfosClustering,
    Dashboard,
    InfosEleves,
    InfosVariables,
    ModuleCluster,
    ModuleDashboard,
    ParamTypes,
} from "../../interfaces/Dashboard";
import GroupCharacteristics from "./GroupCharacteristics/GroupCharacteristics";
import ClustersVisualRepresentation from "./ClustersVisualRepresentation/ClustersVisualRepresentation";
import Group from "./Group/Group";
import {
    getClusters,
    getModuleById,
    getStudentName,
} from "../../utils/dataRetrieval";

import "./Clustering.scss";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import Button from "../../components/Button/Button";

const Clustering: React.FC<{
    classrooms: Dashboard["classrooms"];
    clustering: Required<Dashboard>["clustering"];
}> = ({ classrooms, clustering }) => {
    const { data } = useContext(dataStore);
    const { config } = useContext(configStore);

    const history = useHistory();
    const { path } = useRouteMatch();
    const { classroomId, moduleId } = useParams<ParamTypes>();

    const [currentClassroom, setCurrentClassroom] = useState<Classroom>();
    const [currentModule, setCurrentModule] = useState<ModuleDashboard>();
    const [selectedGroups, setSelectedGroups] = React.useState<number[]>([]);
    const [selectedStudentId, setSelectedStudentId] = React.useState<string>();
    const [breadcrumbsItems, setBreadcrumbsItems] = useState<
        { title: string; url: string; current?: boolean }[]
    >([]);

    const clusters = useMemo(
        () => getClusters(clustering[classroomId][moduleId] as ModuleCluster),
        [clustering, classroomId, moduleId]
    );

    // Set classroom
    useEffect(() => {
        setCurrentClassroom(
            classrooms.find(
                (classroom) => classroom.id === classroomId
            ) as Classroom
        );
    }, [classroomId, classrooms]);

    // Set module
    useEffect(() => {
        if (!currentClassroom) return;

        setCurrentModule(
            currentClassroom.modulesList.find(
                (module) => module.id === moduleId
            )!
        );

        const breadcrumbs = [
            {
                title: config.i18n.dashboard!.common.classes,
                url: `/${config.i18n.dashboard!.paths.dashboard}/${
                    config.i18n.dashboard!.paths.dashboard
                }`,
            },
            {
                title: currentClassroom.name,
                url: `/${config.i18n.dashboard!.paths.dashboard}/${
                    config.i18n.dashboard!.paths.classes
                }/${currentClassroom.id}`,
            },
            {
                title: config.i18n.dashboard!.common.groups,
                url: "",
                current: true,
            },
        ];
        setBreadcrumbsItems(breadcrumbs);
    }, [currentClassroom, moduleId, config.i18n.dashboard]);

    // Reinit selected student when selected group changes
    useEffect(() => {
        setSelectedStudentId(undefined);
    }, [classroomId, moduleId]);

    // If cluster of selected student is unselected, unselect the student
    useEffect(() => {
        const studentClusterIndex = clusters.findIndex((cluster) =>
            cluster.eleves.some((id) => id === selectedStudentId)
        );
        if (!selectedGroups.includes(studentClusterIndex))
            setSelectedStudentId(undefined);
    }, [selectedStudentId, selectedGroups, clusters]);

    const progressionUrl = () => {
        let url = `/${config.i18n.dashboard?.paths.dashboard}/${config.i18n.dashboard?.paths.classes}/${config.i18n.dashboard?.paths.progression}/${classroomId}/${moduleId}`;
        history.push(url);
    };

    return (
        <div className="classroom-modules-overview">
            <header>
                <Breadcrumbs items={breadcrumbsItems} />
            </header>

            <div className="group-body">
                <div className="classroom-navigation">
                    <h1>{config.i18n.dashboard?.common.groups}</h1>
                    <div className="selects">
                        <CustomSelect
                            label={config.i18n.dashboard?.common.classes}
                            onSelectOption={(value) => {
                                history.push(
                                    path
                                        .replace(/:classroomId/, value)
                                        .replace(/:moduleId/, moduleId)
                                );
                            }}
                            selectedOption={
                                currentClassroom ? currentClassroom.name : ""
                            }
                        >
                            {classrooms.map((classroom, i) => (
                                <option
                                    key={"class-" + i}
                                    value={classroom.id}
                                >{`Classe ${classroom.name}`}</option>
                            ))}
                        </CustomSelect>

                        {currentClassroom &&
                            currentClassroom.modulesList.length > 0 && (
                                <CustomSelect
                                    label={
                                        config.i18n.dashboard?.common.modules
                                    }
                                    onSelectOption={(value) => {
                                        history.push(
                                            path
                                                .replace(/:moduleId/, value)
                                                .replace(
                                                    /:classroomId/,
                                                    classroomId
                                                )
                                        );
                                    }}
                                    selectedOption={
                                        currentModule
                                            ? getModuleById(
                                                  currentModule!.id,
                                                  data
                                              ).title.short!
                                            : ""
                                    }
                                >
                                    {currentClassroom.modulesList.map(
                                        (module, i) => (
                                            <option
                                                key={"module-" + i}
                                                value={module.id}
                                            >
                                                {
                                                    getModuleById(
                                                        module.id,
                                                        data
                                                    ).title.short
                                                }
                                            </option>
                                        )
                                    )}
                                </CustomSelect>
                            )}
                    </div>
                    <Button
                        type={"primary"}
                        label={config.i18n.clustering.seeProgressionOverview}
                        icons={[
                            {
                                placement: "right",
                                code: "arrow_forward",
                                noCircle: true,
                            },
                        ]}
                        onClick={progressionUrl}
                    />
                </div>

                <div className="clusters-container">
                    {typeof clustering[classroomId][moduleId] ===
                    "undefined" ? (
                        config.i18n.dashboard!.clustering
                            .noClustersForClassAndModudle
                    ) : typeof clustering[classroomId][moduleId].error !==
                      "undefined" ? (
                        config.i18n.dashboard!.clustering
                            .noClustersForClassAndModudle
                    ) : (
                        <>
                            <div className="clusters__right">
                                <h2>
                                    {
                                        config.i18n.dashboard?.clustering
                                            .characteristics
                                    }
                                </h2>

                                <div className="characteristics-container">
                                    {currentModule &&
                                        clusters.map((cluster, index) => {
                                            return (
                                                <GroupCharacteristics
                                                    key={
                                                        "GroupCharacteristics-" +
                                                        index
                                                    }
                                                    variables={getVariables(
                                                        clustering[classroomId][
                                                            moduleId
                                                        ] as ModuleCluster
                                                    )}
                                                    cluster={cluster}
                                                    groupIndex={index}
                                                    selected={selectedGroups.includes(
                                                        index
                                                    )}
                                                    onSelectGroup={() => {
                                                        if (
                                                            selectedGroups.includes(
                                                                index
                                                            )
                                                        )
                                                            setSelectedGroups(
                                                                (curr) =>
                                                                    curr.filter(
                                                                        (i) =>
                                                                            i !==
                                                                            index
                                                                    )
                                                            );
                                                        else
                                                            setSelectedGroups(
                                                                (curr) => [
                                                                    ...curr,
                                                                    index,
                                                                ]
                                                            );
                                                    }}
                                                    selectedStudent={
                                                        selectedStudentId &&
                                                        isStudentInCluster(selectedStudentId, cluster)
                                                            ? getStudent(
                                                                  clustering[
                                                                      classroomId
                                                                  ][
                                                                      moduleId
                                                                  ] as ModuleCluster,
                                                                  selectedStudentId
                                                              )
                                                            : undefined
                                                    }
                                                />
                                            );
                                        })}
                                </div>
                            </div>

                            <div className="clusters__left">
                                <ClustersVisualRepresentation
                                    clusters={getClusters(
                                        clustering[classroomId][
                                            moduleId
                                        ] as ModuleCluster
                                    )}
                                    selectedGroups={selectedGroups}
                                    setSelectedGroups={setSelectedGroups}
                                    selectedStudentId={selectedStudentId}
                                />

                                <div className="group-container">
                                    {getClusters(
                                        clustering[classroomId][
                                            moduleId
                                        ] as ModuleCluster
                                    ).map((cluster, index) => (
                                        <Group
                                            key={"GroupContainer-" + index}
                                            groupeName={`${
                                                config.i18n.dashboard!.common
                                                    .group
                                            } ${cluster.name}`}
                                            students={cluster.eleves.map(
                                                (studentId) => {
                                                    return {
                                                        id: studentId,
                                                        name: getStudentName(
                                                            classrooms,
                                                            classroomId,
                                                            moduleId,
                                                            studentId
                                                        ),
                                                    };
                                                }
                                            )}
                                            groupIndex={index}
                                            selected={selectedGroups.includes(
                                                index
                                            )}
                                            onSelectGroup={() => {
                                                if (
                                                    selectedGroups.includes(
                                                        index
                                                    )
                                                )
                                                    setSelectedGroups((curr) =>
                                                        curr.filter(
                                                            (i) => i !== index
                                                        )
                                                    );
                                                else
                                                    setSelectedGroups(
                                                        (curr) => [
                                                            ...curr,
                                                            index,
                                                        ]
                                                    );
                                            }}
                                            selectedStudentId={
                                                selectedStudentId
                                            }
                                            setSelectedStudentId={
                                                setSelectedStudentId
                                            }
                                        />
                                    ))}
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

const isStudentInCluster = (
    studentId: string,
    cluster: ClusterInfosClustering
) => {
    return cluster.eleves.some((id) => id === studentId);
};

const getVariables = (
    clustering: ModuleCluster
): (InfosVariables & { name: string })[] => {
    return clustering.infosVariables
        .map((variables) =>
            Object.keys(variables).map((variableName) => {
                return {
                    ...variables[variableName],
                    name: variableName,
                };
            })
        )
        .flat();
};

const getStudent = (
    clustering: ModuleCluster,
    studentId: string
): InfosEleves => {
    return clustering.infosEleves.reduce((studentsObject, studentObject) => {
        Object.keys(studentObject).forEach((key) => {
            const studentId = studentObject[key].id;
            studentsObject[studentId] = studentObject[key];
        });
        return studentsObject;
    }, {} as { [studentId: string]: InfosEleves })[studentId];
};

export default Clustering;
