import React, { useContext, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { PRTree } from "@evidenceb/gameplay-interfaces";
import {
    ClusterInfosClustering,
    InfosVariables,
    ModuleCluster,
} from "../../../../interfaces/Dashboard";
import { configStore } from "../../../../contexts/ConfigContext";
import { dashboardStore } from "../../../../contexts/DashboardContext";
import { dataStore } from "../../../../contexts/DataContext";
import { getModuleById } from "../../../../utils/dataRetrieval";
import DemoBanner from "../../../../components/DemoBanner/DemoBanner";
import BackAndForth from "../../../../components/BackAndForth/BackAndForth";
import LockStatusLabel from "../../../../components/LockStatusLabel/LockStatusLabel";
import Table from "../../../../design-system-components/Table/Table";

import "./GroupCard.scss";

const VARIABLES: ["var1", "var2", "var3"] = ["var1", "var2", "var3"];

interface Props {
    studentId: string;
    classroomId: string;
    prTree: PRTree | undefined;
}

export default function DetailsCard({ studentId, classroomId, prTree }: Props) {
    const { config } = useContext(configStore);
    const { data } = useContext(dataStore);

    const {
        dashboard: { clustering },
    } = useContext(dashboardStore);

    const _clustering = useMemo(() => {
        // Take only current classroom and remove modules that have errors in clustering or for which the student has no cluster
        const _clustering: { [moduleId: string]: ModuleCluster } = {};
        Object.keys(clustering![classroomId]).forEach((moduleId) => {
            if (
                typeof clustering![classroomId][moduleId].error !==
                    "undefined" ||
                !findStudentCluster(
                    studentId,
                    (clustering![classroomId][moduleId] as ModuleCluster)
                        .infosClustering.clusters
                )
            )
                return;

            _clustering[moduleId] = clustering![classroomId][
                moduleId
            ] as ModuleCluster;
        });
        return _clustering;
    }, [clustering, classroomId, studentId]);

    const modulesOrder = useMemo(() => {
        const modulesOrder: string[] =
            Object.keys(_clustering).length > 0
                ? Object.keys(_clustering)
                      .map((moduleId) => {
                          return {
                              moduleId,
                              title: getModuleById(moduleId, data).title.short!,
                          };
                      })
                      .sort((a, b) => {
                          if (a.title < b.title) {
                              return -1;
                          }
                          if (a.title > b.title) {
                              return 1;
                          }
                          return 0;
                      })
                      .map((item) => item.moduleId)
                : [];
        return modulesOrder;
    }, [_clustering, data]);

    const [currentModuleId, setCurrentModuleId] = useState<string | undefined>(
        modulesOrder.length > 0 ? modulesOrder[0] : undefined
    );
    const [studentCluster, setStudentCluster] = useState<
        ClusterInfosClustering | undefined
    >(
        currentModuleId
            ? findStudentCluster(
                  studentId,
                  _clustering[currentModuleId].infosClustering.clusters
              )!
            : undefined
    );

    // If current module id changes, update studentCluster
    useEffect(() => {
        if (!currentModuleId) return;

        setStudentCluster(
            findStudentCluster(
                studentId,
                _clustering[currentModuleId].infosClustering.clusters
            )!
        );
    }, [currentModuleId, _clustering, studentId]);

    if (!currentModuleId || !studentCluster) return <></>;

    return (
        <div className="group-card card small">
            <h2 className="card-title">
                {config.i18n.dashboard?.common.groups}
            </h2>
            <Card>
                {config.features.demoMode?.displayIn.clustering && (
                    <DemoBanner
                        position="custom"
                        message={config.features.demoMode.message.clustering}
                    />
                )}

                <GroupName>
                    {config.i18n.dashboard!.common.group +
                        " " +
                        studentCluster.name}
                </GroupName>

                <BackAndForth<string>
                    valuesList={modulesOrder}
                    onValueChange={(value) => {
                        setCurrentModuleId(value);
                    }}
                    currentValue={currentModuleId}
                    infinite
                    instructions={{
                        back: config.i18n.dashboard!.studentDetails
                            .goToPreviousModule,
                        forth: config.i18n.dashboard!.studentDetails
                            .goToNextModule,
                    }}
                    className="group-card__module-selector"
                >
                    <ModuleTitle>
                        {getModuleById(currentModuleId, data).title.short!}

                        {prTree?.modules[currentModuleId].status !==
                            "unlocked" && <LockStatusLabel locked={true} />}
                    </ModuleTitle>
                </BackAndForth>

                <Table className="group-card__table">
                    <tr>
                        <th>{config.i18n.groupCharacteristic}</th>
                        <th>{config.i18n.student}</th>
                        <th>{config.i18n.dashboard!.common.group}</th>
                    </tr>

                    {VARIABLES.map((variable) => (
                        <tr key={variable}>
                            <td>
                                {
                                    config.i18n.dashboard!.clustering.variables[
                                        getVariable(
                                            variable,
                                            _clustering[currentModuleId]
                                        ).description.type
                                    ]
                                }
                            </td>
                            <td className="group-card__value-cell">
                                <span>
                                    {getStudentValue(
                                        studentId,
                                        variable,
                                        _clustering[currentModuleId]
                                    )}
                                </span>
                            </td>
                            <td>
                                {getGroupValue(
                                    variable,
                                    studentCluster,
                                    _clustering[currentModuleId]
                                )}
                            </td>
                        </tr>
                    ))}
                </Table>
            </Card>
        </div>
    );
}

const findStudentCluster = (
    studentId: string,
    clusters: { [clusterName: string]: ClusterInfosClustering }[]
): ClusterInfosClustering | undefined => {
    const _clusters = clusters
        .map((clustersObj) =>
            Object.keys(clustersObj).map(
                (clusterName) => clustersObj[clusterName]
            )
        )
        .flat();
    return _clusters.find((cluster) => cluster.eleves.includes(studentId));
};

const getVariable = (
    variable: string,
    clustering: ModuleCluster
): InfosVariables & { variableName: "var1" | "var2" | "var3" } => {
    const variables = clustering.infosVariables
        .map((variableObj) =>
            Object.keys(variableObj).map((variableName) => {
                return { ...variableObj[variableName], variableName };
            })
        )
        .flat();
    return variables.find(
        (item) => item.variableName === variable
    )! as InfosVariables & { variableName: "var1" | "var2" | "var3" };
};

const getStudentValue = (
    studentId: string,
    variableName: "var1" | "var2" | "var3",
    clustering: ModuleCluster
): string => {
    const students = clustering.infosEleves
        .map((studentObj) =>
            Object.keys(studentObj).map((studentId) => studentObj[studentId])
        )
        .flat();
    const student = students.find((student) => student.id === studentId)!;
    const variable = getVariable(variableName, clustering);
    return `${student[variableName]} ${variable.unit} ${
        variable.description.type === "MEAN_SCORE" ? "/1" : ""
    }`;
};

const getGroupValue = (
    variableName: "var1" | "var2" | "var3",
    studentCluster: ClusterInfosClustering,
    clustering: ModuleCluster
): string => {
    const variable = getVariable(variableName, clustering);
    return `${studentCluster[variableName]} ${variable.unit} ${
        variable.description.type === "MEAN_SCORE" ? "/1" : ""
    }`;
};

const Card = styled.div`
    padding: 16px 24px;
    width: calc(100% - 48px);
    height: calc(100% - 68px);
    border-radius: 2px;
    background: var(--white);
    box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
    border: 1px solid var(--medium-grey);
    display: flex;
    flex-direction: column;
`;

const GroupName = styled.h4`
    text-align: center;
    font-size: 1.5em;
    margin: 16px 0;
`;

const ModuleTitle = styled.div`
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    gap: 8px;
`;
