import {
    PRLockStatus,
    PRTree,
    PRHierarchy,
    PRItemStatus,
    PRItem,
    Module,
    PRHierarchyIds,
} from "@evidenceb/gameplay-interfaces";
import { getDescendants, makeGraphFromData } from "@evidenceb/parametric-graph";
import { ModuleNotFoundError } from "../errors";
import { Config } from "../interfaces/Config";
import { Data } from "../interfaces/Data";
import {
    getActivitiesInModule,
    getModuleById,
    getObjectiveById,
    notInitialTest,
} from "./dataRetrieval";
import { prHierarchysToHierarchyIds } from "./hierarchyTranslator";
import { LevelData } from "../pages/PedagogicalResourcesManagement/PedagogicalResourcesManagement";

export const buildPRTree = (
    deactivations: PRLockStatus,
    data: Data,
    aiConfig: Required<Config>["ai"]
): PRTree => {
    const tree: PRTree = {
        modules: buildLevelPRTree(data.modules, deactivations.moduleIds),
        objectives: buildLevelPRTree(
            data.objectives.filter(notInitialTest(data)), // Exclude initial tests from the prm
            deactivations.objectiveIds
        ),
    };

    data.modules.forEach((mod) => {
        const graph = getGraph(aiConfig, data, mod.id);

        // Mark module as descendent if all objectives are locked
        if (areAllObjectivesInModuleLocked(mod, deactivations, graph, data))
            tree[PRHierarchy.Modules][mod.id] = {
                status: "descendent",
                of: mod.objectiveIds.filter(notInitialTest(data)).map((id) => {
                    return { id, hierarchy: PRHierarchy.Objectives };
                }),
            };

        // Mark all objectives as descendents if parent module is locked
        if (deactivations.moduleIds.includes(mod.id))
            mod.objectiveIds.forEach((objId) => {
                tree[PRHierarchy.Objectives][objId] = {
                    status: "descendent",
                    of: [{ id: mod.id, hierarchy: PRHierarchy.Modules }],
                };
            });
            
        // Signal descendents of deactivated objectives
        else
            mod.objectiveIds
                .filter((objectiveId) =>
                    deactivations.objectiveIds.includes(objectiveId)
                )
                .forEach((objectiveId) => {
                    const descendents = getDescendants(
                        graph,
                        objectiveId
                    ).filter((descendentId) => descendentId !== objectiveId);

                    descendents.forEach((descendentId) => {
                        if (
                            !Object.keys(tree.objectives).includes(descendentId)
                        )
                            return;

                        const descendentStatus = tree.objectives[descendentId];
                        if (descendentStatus.status !== "descendent") {
                            tree.objectives[descendentId] = {
                                status: "descendent",
                                of: [
                                    {
                                        id: objectiveId,
                                        hierarchy: PRHierarchy.Objectives,
                                    },
                                ],
                            };
                        } else {
                            tree.objectives[descendentId] = {
                                ...descendentStatus,
                                of: [
                                    ...descendentStatus.of,
                                    {
                                        id: objectiveId,
                                        hierarchy: PRHierarchy.Objectives,
                                    },
                                ],
                            };
                        }
                    });
                });
    });

    return tree;
};

const buildLevelPRTree = (
    pool: { id: string }[],
    lockStatuses: string[]
): { [id: string]: PRItemStatus } => {
    return pool.reduce((levelTree, currentItem) => {
        return {
            ...levelTree,
            [currentItem.id]: lockStatuses.some(
                (deactivatedItemId) => deactivatedItemId === currentItem.id
            )
                ? { status: "locked" }
                : { status: "unlocked" },
        };
    }, {} as { [id: string]: PRItemStatus });
};

export const getGraph = (
    config: Required<Config>["ai"],
    data: Data,
    moduleId: string
): any => {
    const mod = getModuleById(moduleId, data);
    const params = {
        ...config.baseConfig,
        graph: {
            ...config.baseConfig.graph,
            graph: config.moduleConfig[mod.id],
            main_act: mod.id,
            exercises_ontology: getActivitiesInModule(mod, data),
            exercisesMetaData: data.exercises,
            initialTest: config.initialTest[mod.id],
        },
    };
    return makeGraphFromData(params, moduleId);
};

export const getDescendentLevel = (
    moduleId: string,
    data: Data,
    currentLevel: LevelData,
    i18n: Config["i18n"]
): LevelData => {
    const mod = getModuleById(moduleId, data);
    return {
        hierarchy: PRHierarchy.Objectives,
        prPool: mod.objectiveIds
            .filter(notInitialTest(data))
            .map((objId) => getObjectiveById(objId, data)),
        parentData: {
            ...currentLevel,
            id: moduleId,
            listTitle: `${
                i18n.prm.listOf
            } ${i18n.dashboard?.common.objectives?.toLowerCase()}`,
        },
    };
};

export const updatePRLockStatus = (
    originalPRLockStatus: PRLockStatus,
    itemsToToggle: PRItem[]
): PRLockStatus => {
    const newPRLockStatus: PRLockStatus = { ...originalPRLockStatus };
    itemsToToggle.forEach(({ id, hierarchy }) => {
        const hierarchyIds = prHierarchysToHierarchyIds(hierarchy);
        if (newPRLockStatus[hierarchyIds].includes(id))
            newPRLockStatus[hierarchyIds] = newPRLockStatus[
                hierarchyIds
            ].filter((lockedId) => lockedId !== id);
        else
            newPRLockStatus[hierarchyIds] = [
                ...newPRLockStatus[hierarchyIds],
                id,
            ];
    });
    return newPRLockStatus;
};

export const getPRModuleId = (id: string, data: Data): string => {
    const mod = data.modules.find((mod) => {
        if (mod.objectiveIds.includes(id)) return true;
        const objectives = mod.objectiveIds.map((objId) =>
            getObjectiveById(objId, data)
        );
        return objectives.some((obj) => obj.activityIds.includes(id));
    });
    if (!mod) throw new ModuleNotFoundError();
    return mod.id;
};

export const getPRHierarchy = (
    id: string,
    data: Data
): PRHierarchy | undefined => {
    if (data.modules.some((mod) => mod.id === id)) return PRHierarchy.Modules;
    if (data.objectives.some((obj) => obj.id === id))
        return PRHierarchy.Objectives;
    return undefined;
};

export const isModuleLocked = (
    module: Module,
    prLockStatus: PRLockStatus | undefined,
    graph: any,
    data: Data
): boolean => {
    if (!prLockStatus) return false;

    if (prLockStatus.moduleIds.includes(module.id)) return true;

    return areAllObjectivesInModuleLocked(module, prLockStatus, graph, data);
};

export const areAllObjectivesInModuleLocked = (
    module: Module,
    prLockStatus: PRLockStatus,
    graph: any,
    data: Data
): boolean => {
    const lockedObjectivesInModule = prLockStatus.objectiveIds.filter((objId) =>
        module.objectiveIds.includes(objId)
    );
    const descendants = lockedObjectivesInModule
        .map((objId) => getDescendants(graph, objId))
        .flat();
    const allLockedObjectivesInModule = [
        ...lockedObjectivesInModule,
        ...descendants,
    ];
    return module.objectiveIds
        .filter(notInitialTest(data))
        .every((objId) => allLockedObjectivesInModule.includes(objId));
};

/**
 * Returns true if the pr Lock statuses are similar
 */
export const comparePRLockStatus = (
    prLockStatus1: PRLockStatus,
    prLockStatus2: PRLockStatus
): boolean => {
    const keys1 = Object.keys(prLockStatus1).sort();
    const keys2 = Object.keys(prLockStatus2).sort();
    if (
        keys1.length !== keys2.length ||
        keys1.some((key1, index) => keys2[index] !== key1)
    )
        return false;

    for (let key1 of keys1) {
        if (
            prLockStatus1[key1 as PRHierarchyIds].some(
                (value) =>
                    !prLockStatus2[key1 as PRHierarchyIds].includes(value)
            ) ||
            prLockStatus2[key1 as PRHierarchyIds].some(
                (value) =>
                    !prLockStatus1[key1 as PRHierarchyIds].includes(value)
            )
        )
            return false;
    }

    return true;
};
