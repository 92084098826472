import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { PRLockStatus, PRItem } from "@evidenceb/gameplay-interfaces";
import { UserType } from "../../../interfaces/User";
import { configStore } from "../../../contexts/ConfigContext";
import { errorStore } from "../../../contexts/ErrorContext";
import { sessionStore } from "../../../contexts/SessionContext";
import useAthenaAPIClient from "../../../hooks/useAthenaAPIClient";
import { comparePRLockStatus, updatePRLockStatus } from "../../../utils/prm";

export interface UsePRManagerReturn {
    currentPRLockStatus?: PRLockStatus;
    dirty: boolean;
    toggleItems: (items: PRItem[]) => void;
    reset: () => void;
    save: () => Promise<void>;
}

/**
 * Hook used to handle the logic of activation/deactivation of pedagogical
 * resources
 */
const usePRManager = (classroomId: string): UsePRManagerReturn => {
    const {
        session: { userId },
    } = useContext(sessionStore);
    const {
        config: { i18n },
    } = useContext(configStore);
    const { setErrorInfo } = useContext(errorStore);
    const history = useHistory();
    const athenaAPIClient = useAthenaAPIClient();

    const [initialPRLockStatus, setInitialPRLockStatus] =
        useState<PRLockStatus>();
    const [currentPRLockStatus, setCurrentPRLockStatus] =
        useState<PRLockStatus>();
    const [dirty, setDirty] = useState<boolean>(false);
    const [lockStatusError, setLockStatusError] = useState<boolean>(false);

    // Get initial lock status
    useEffect(() => {
        if (lockStatusError || initialPRLockStatus) return;

        (async () => {
            try {
                const allLockStatus =
                    await athenaAPIClient.getResourcesLockStatus(
                        userId,
                        UserType.Teacher
                    );
                setInitialPRLockStatus(allLockStatus[classroomId]);
                setCurrentPRLockStatus(allLockStatus[classroomId]);
            } catch {
                setLockStatusError(true);
                setErrorInfo({
                    displayModal: true,
                    modal: {
                        type: "POPUP",
                        content: {
                            title: i18n.prm.lockStatusErrorTitle,
                            text: i18n.prm.lockStatusErrorText,
                            btn: {
                                label: i18n.prm.lockStatusErrorBtn,
                            },
                        },
                        onClick: () => {
                            history.push(
                                `/${i18n.dashboard!.paths.dashboard}/${
                                    i18n.dashboard!.paths.classes
                                }/${classroomId}`
                            );
                        },
                    },
                });
            }
        })();
    }, [
        userId,
        classroomId,
        athenaAPIClient,
        initialPRLockStatus,
        setErrorInfo,
        history,
        i18n,
        lockStatusError,
    ]);

    return {
        currentPRLockStatus,

        dirty,

        toggleItems: (items) => {
            if (!currentPRLockStatus) return;

            const newPRLockStatus = updatePRLockStatus(
                currentPRLockStatus,
                items
            );
            setCurrentPRLockStatus(newPRLockStatus);

            if (
                initialPRLockStatus &&
                comparePRLockStatus(initialPRLockStatus, newPRLockStatus)
            )
                setDirty(false);
            else setDirty(true);
        },

        reset: () => {
            if (!currentPRLockStatus || !initialPRLockStatus) return;

            setCurrentPRLockStatus(initialPRLockStatus);
            setDirty(false);
        },

        save: async () => {
            if (!currentPRLockStatus) return;

            await athenaAPIClient.updateResourcesLockStatus(
                classroomId,
                currentPRLockStatus
            );
        },
    };
};

export default usePRManager;
