import { BanditManchot } from "@evidenceb/bandit-manchot";
import { PRLockStatus } from "@evidenceb/gameplay-interfaces";

/**
 * Adds the required keys in case the locked status obtained from the backends
 * lacks them
 */
export const completeLockStatus = (partial: Partial<PRLockStatus> | undefined): PRLockStatus => {
  const completeDeactivations: PRLockStatus = {
      moduleIds: partial?.moduleIds ?? [],
      objectiveIds: partial?.objectiveIds ?? [],
  };
  return completeDeactivations;
}

/**
 * Returns true if the Bandit Manchot has pedagogical resources locking capabilities
 */
export const isBMWithLockStatus = (banditManchot: BanditManchot): boolean => {
    return Object.keys(banditManchot).some((moduleId) => {
        const moduleBM = banditManchot[moduleId];
        return !moduleBM.error && moduleBM.instance.lock;
    });
};
