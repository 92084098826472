import { useContext, useState } from "react";
import {
    PRHierarchy,
    PRItem,
    PRItemStatus,
    PRLockStatus,
    PRTree,
} from "@evidenceb/gameplay-interfaces";
import { getDescendants } from "@evidenceb/parametric-graph";
import { configStore } from "../../../contexts/ConfigContext";
import { dataStore } from "../../../contexts/DataContext";
import { Data } from "../../../interfaces/Data";
import { prHierarchysToHierarchyIds } from "../../../utils/hierarchyTranslator";
import {
    buildPRTree,
    getGraph,
    getPRHierarchy,
    getPRModuleId,
    updatePRLockStatus,
} from "../../../utils/prm";
import { UsePRManagerReturn } from "./usePRManager";
import { LevelData } from "../PedagogicalResourcesManagement";

export interface UsePRConfirmationReturn {
    attemptToggle: (
        id: string,
        prLockStatus: PRLockStatus,
        prTree: PRTree,
        level: LevelData
    ) => void;
    confirmation?: Confirmation;
    confirm?: (confirm: boolean) => void;
}
export interface Confirmation {
    originId: string;
    type: "activate" | "deactivate";
    dependencyIds: string[];
    itemsToToggle: PRItem[];
}

const usePRConfirmation = (
    toggleItems: UsePRManagerReturn["toggleItems"]
): UsePRConfirmationReturn => {
    const {
        config: { ai },
    } = useContext(configStore);
    const { data } = useContext(dataStore);

    const [confirmation, setConfirmation] = useState<Confirmation>();

    return {
        confirmation,

        attemptToggle: (id, prLockStatus, prTree, level) => {
            if (level.hierarchy === PRHierarchy.Modules) {
                const status = prTree[PRHierarchy.Modules][id];
                let itemsToToggle: PRItem[] = [];
                if (status.status === "descendent")
                    itemsToToggle = [...status.of];
                if (status.status === "unlocked" || prLockStatus["moduleIds"].includes(id))
                    itemsToToggle.push({ id, hierarchy: level.hierarchy });
                toggleItems(itemsToToggle);
                return;
            }

            const itemStatus = prTree[level.hierarchy][id];
            const itemsToToggle = getItemsToToggle(
                id,
                itemStatus,
                level.hierarchy,
                prLockStatus,
                getGraph(ai!, data, getPRModuleId(id, data)),
                data
            );
            const newPRLockStatus = updatePRLockStatus(
                prLockStatus,
                itemsToToggle
            );
            const newPRTree = buildPRTree(newPRLockStatus, data, ai!);

            // Get differencies between original and new tree
            const dependencyIds = Object.keys(
                newPRTree[level.hierarchy]
            ).reduce((dependencyIds, itemId) => {
                if (itemId === id) return dependencyIds;

                const originalStatus = prTree[level.hierarchy][itemId];
                const newStatus = newPRTree[level.hierarchy][itemId];
                if (
                    originalStatus.status === "unlocked" &&
                    newStatus.status !== "unlocked"
                )
                    return [...dependencyIds, itemId];
                if (
                    newStatus.status === "unlocked" &&
                    originalStatus.status !== "unlocked"
                )
                    return [...dependencyIds, itemId];
                return dependencyIds;
            }, [] as string[]);

            if (dependencyIds.length === 0) toggleItems(itemsToToggle);
            else
                setConfirmation({
                    originId: id,
                    dependencyIds,
                    type:
                        prTree[level.hierarchy][id].status === "unlocked"
                            ? "deactivate"
                            : "activate",
                    itemsToToggle,
                });
        },

        confirm: confirmation
            ? (confirm) => {
                  if (confirm) {
                      toggleItems(confirmation.itemsToToggle);
                  }
                  setConfirmation(undefined);
              }
            : undefined,
    };
};

const getItemsToToggle = (
    itemId: string,
    currentItemStatus: PRItemStatus,
    hierarchy: PRHierarchy,
    prLockStatus: PRLockStatus,
    graph: any,
    data: Data
) => {
    switch (currentItemStatus.status) {
        case "unlocked":
            // Lock item and descendents
            const descendents = getDescendants(graph, itemId).reduce(
                (descendents, descId) => {
                    if (descId === itemId) return descendents;

                    const hierarchy = getPRHierarchy(descId, data);
                    if (!hierarchy) return descendents;
                    if (
                        prLockStatus[
                            prHierarchysToHierarchyIds(hierarchy)
                        ].includes(descId)
                    )
                        return descendents;
                    return [...descendents, { id: descId, hierarchy }];
                },
                [] as PRItem[]
            );
            return [{ id: itemId, hierarchy }, ...descendents];

        case "locked":
            // Unlock item
            return [{ id: itemId, hierarchy }];

        case "descendent":
            // Unlock ancestor and item itself if locked
            if (
                prLockStatus[prHierarchysToHierarchyIds(hierarchy)].includes(
                    itemId
                )
            )
                return [{ id: itemId, hierarchy }, ...currentItemStatus.of];
            return [...currentItemStatus.of];
    }
};

export default usePRConfirmation;
