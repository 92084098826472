import React, { useContext } from "react";
import { Link } from "react-router-dom";
import {
    ActivityShell,
    InitializedPlaylistManager,
    Playlist,
} from "@evidenceb/gameplay-interfaces";
import { UserType } from "../../../../interfaces/User";
import { configStore } from "../../../../contexts/ConfigContext";
import { getLastResults } from "../../../../utils/exerciseUtils";
import { getUrl } from "../../../../utils/dataRetrieval";
import PlayerHeader from "../PlayerHeader";
import TeacherProgressBar from "./TeacherProgressBar/TeacherProgressBar";
import PlaylistNavigationBreadCrumbs from "./PlaylistNavigationBreadcrumbs/PlaylistNavigationBreacrumds";
import { PlayerHeaderProps } from "../../PlayerBuilder";

const TeacherHeader = ({
    playlistManager,
}: PlayerHeaderProps<InitializedPlaylistManager>) => {
    const { config } = useContext(configStore);

    return (
        <PlayerHeader>
            {!isPlaylistFinished(playlistManager.playlist) && (
                <PlaylistNavigationBreadCrumbs
                    goToExercise={playlistManager.goToExercise}
                    currentHierarchy={{
                        module: playlistManager.playlist.module,
                        objective: playlistManager.playlist.objective!,
                        activity: playlistManager.playlist.activity!,
                    }}
                    currentExerciseIndex={playlistManager.playlist.activity!.exerciseIds.findIndex(
                        (id) =>
                            id === playlistManager.playlist.currentExercise!.id
                    )}
                    // Do not add activity details tooltip when using the
                    // Chatbot shell because the info is already included
                    // in the left panel
                    showActivityInfo={
                        playlistManager.playlist.activity?.shell !==
                        ActivityShell.Chatbot
                    }
                />
            )}

            <TeacherProgressBar
                exerciseResults={getLastResults(
                    playlistManager.playlist.exerciseResults
                )}
                currentExercise={playlistManager.playlist.currentExercise}
                exercises={playlistManager.playlist.exercises}
            />

            <div className="header_close">
                <Link
                    to={`/${getUrl(
                        config.pages.find(
                            (page) => page.type === "MODULELIST"
                        )!,
                        UserType.Teacher
                    )}`}
                >
                    <span className="material-icons" translate="no">
                        clear
                    </span>
                    <span>{config.i18n.exerciseShell.close}</span>
                </Link>
            </div>
        </PlayerHeader>
    );
};

const isPlaylistFinished = (playlist: Playlist): boolean =>
    !playlist.currentExercise;

export default TeacherHeader;
