import React from "react";
import PlaySingleExercise from "./PlaySingleExercise/PlaySingleExercise";
import UseLocalHistory from "./UseLocalHistory/UseLocalHistory";
import WindowContextLoader from "./WindowContextLoader/WindowContextLoader";

/**
 * This components adds development functionalities to the specimen.
 */
const Dev = () => {
    return (
        <>
            <WindowContextLoader />
            <PlaySingleExercise />
            <UseLocalHistory />
        </>
    );
};

export default Dev;
