export interface TokenPayload {
    app: string;
    branch: string;
    role: string;
    sub: string; // id
    version: string;
}

export interface User{
    classrooms:string[];
    id:string;
    external_id: string;
    extra:string;
    provider: string;
    first_name: string;
    last_name: string;
}

export interface Classroom{
    id:string;
    external_id: string;
    extra:string;
    level: string;
    name: string;
    provider: string;
    school: string;
    students:User[];
    teacher: string;
}

export enum UserType{
    Teacher = "TEACHER",
    Student = "STUDENT"
}