import React from "react";

import "./PlayerHeader.scss";

interface Props {
    className?: string;
    children: (JSX.Element | null | false)[] | JSX.Element | null | false;
}

const PlayerHeader = ({ children, className }: Props) => {
    return (
        <header className={`player-header ${className ? className : ""}`}>
            {children}
        </header>
    );
};

export default PlayerHeader;
