import React, { useContext } from "react";
import { configStore } from "../../../../../contexts/ConfigContext";
import "./ChatBubble.scss";
import cx from "classnames";
import ImageAsset from "../../../../../components/ImageAsset/ImageAsset"

type ChatBubbleProps = {
    direction: "right" | "left";
    borderColor?: string;
    botAvatar?: "visible" | "hidden";
    className?: string;
    color?: string;
    fullWidth?: boolean;
    halfWidth?: boolean;
    marginBottom?: boolean;
    textColor?: string;
};

export const ChatBubble: React.FC<ChatBubbleProps> = ({
    borderColor,
    botAvatar,
    children,
    className,
    color,
    direction,
    fullWidth = false,
    halfWidth = false,
    marginBottom = true,
    textColor,
}) => {

    const { config } = useContext(configStore);

    return (
        <div
            className={cx(
                `bubble-container animated animatedFadeInUp fadeInUp ${direction}`,
                className,
                {
                    "full-width": fullWidth,
                    "half-width": halfWidth,
                    "no-margin-bottom": !marginBottom,
                    "with-bot-avatar": botAvatar === "visible",
                }
            )}
        >
            {botAvatar ? (
                botAvatar === "visible" ? (
                    <ImageAsset src={config.logos.avatar} alt="" />
                ) : (
                    <div className="chatbot-placeholder"></div>
                )
            ) : null}
            <div
                className="chat-bubble"
                style={{
                    backgroundColor: color ?? "",
                    borderColor: borderColor,
                    borderStyle: borderColor ? "solid" : undefined,
                    color: textColor,
                }}
            >
                {children}
            </div>
        </div>
    );
};
export default ChatBubble;
