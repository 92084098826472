import React, { useEffect, useRef } from 'react'
import _parseHTML from 'html-react-parser'

const LATEX = /\\\((.*?)\\\)|\\\[(.*?)\\\]|\$\$?(.*?)\$\$?/
const LATEX_WITH_DELIMITERS = /(\\\(.*?\\\))|(\\\[.*?\\\])|(\$\$?.*?\$\$?)/
const LATEX_BLOCK = /\\\[(.*?)\\\]|\$\$(.*?)\$\$/

/*
    /!\ FIRST OF ALL /!\
    
    If you modify this file, be sure to modify also the
    same file in https://github.com/EvidenceB/gameplays(/src/utils/parse-text.tsx)

    ///////////\\\\\\\\\
*/

// parse() take a string and replace every html tag and LaTex with real html tag and svg LaTex
// @params
// @src is the string to parse
// @options is optional, by default every parameter are true, but you can disable some of them by setting them to false

// Examples:
// parse("<em>Hello</em>") works
// parse("$1\\over2$") works (inline LaTex)
// parse("$$1\\over2$$") works (block LaTex)
// parse("<em>Hello</em> $1\\over2$") works
// parse("<em>Hello $1\\over2$</em>") doesn't work, and these syntax is useless, LaTex cannot be modified with html tag

const parse = (
    src: string,
    options?: {mathjax: boolean, html: boolean}
) => {
    const unmathjaxed = options && !options.html ? src : ToHTML(src)

    // Default to parsing mathjax
    if (!options || options.mathjax) {
        if (typeof(unmathjaxed) === "string" && unmathjaxed.match(LATEX))
            return ToMathjax(unmathjaxed, "")
        
        if (Array.isArray(unmathjaxed)) {
            return unmathjaxed.reduce((mathjaxed, item, index) => {
                if (typeof item === "string" && item.match(LATEX))
                    return [...mathjaxed, ...ToMathjax(item, "" + index)];
                return [...mathjaxed, item];
            }, [] as Array<string | JSX.Element>);
        }
    }
    return unmathjaxed;
}

const ToHTML = (s: string) => _parseHTML(s) as JSX.Element | string | Array<JSX.Element | string>

const ToMathjax = (s: string, indexPrefix: string): Array<string | JSX.Element> => {
    return s.split(LATEX_WITH_DELIMITERS)
        .filter(e => !!e)
        .map((e, i) => {
            if (LATEX.test(e)) {
                return (
                    <MathJaXNode
                        key={indexPrefix + "_" + i}
                        inline={!LATEX_BLOCK.test(e)}
                        latex={e}
                    />
                );
            }
            return e
        })
}

interface MathjaxNodeProps {
    inline: boolean,
    latex: string,
}
const MathJaXNode: React.FC<MathjaxNodeProps> = (props) => {
    const ref = useRef<HTMLSpanElement>(null)

    const reloadLaTeX = async (text: string) => {
        if (!ref.current) return ;

        // @ts-ignore
        window.MathJax.typesetClear([ref.current]);
        ref.current.innerText = text
         // @ts-ignore
         await window.MathJax.typesetPromise([ref.current]);
    }

    useEffect(() => {
        reloadLaTeX(props.latex);
    }, [props.latex])

    return (
        <span className={`mathjax${props.inline ? '-inline' : ''}-container`} ref={ref} />
    )
}


export { parse }