import React, { useContext, useState } from "react";
import { Module } from "@evidenceb/gameplay-interfaces";
import { Config } from "../../../../interfaces/Config";
import { configStore } from "../../../../contexts/ConfigContext";
import BanditManchotErrorModal from "../../../../components/BanditManchotErrorModal/BanditManchotErrorModal";
import StudentPath from "../../StudentPath/StudentPath";
import ProgressBarWithLabel from "../../../../components/ProgressBarWithLabel/ProgressBarWithLabel";

import errorPicto from "../error.svg";
import lockedPicto from "./locked.svg";

interface Props {
    type: "locked" | "error";
    module: Module;
    diagnosis?: {
        progression: number;
        averageScore: number;
        isModuleFinished: boolean;
    };
}

const ErrorModuleCard = ({ type, module, diagnosis }: Props) => {
    const {
        config: { i18n, features },
    } = useContext(configStore);
    const [showModal, setShowModal] = useState<boolean>(false);

    return (
        <>
            {showModal && (
                <BanditManchotErrorModal
                    {...getModalTitleAndDescription(type, i18n)}
                    primaryButton={{
                        label: getModalButtonLabel(type, i18n),
                        fn: () => {
                            setShowModal(false);
                        },
                    }}
                />
            )}

            <article className="new-student-module-card --inactive">
                <img src={lockedPicto} alt="" className="card__locked-picto" />

                <div className="card__head">
                    {features.showModuleMapInStudentModuleList &&
                        module.studentPath && (
                            <StudentPath {...module.studentPath} />
                        )}
                    <span className="card__title">{module.title.short}</span>
                    {
                        // Placeholder to maintain the title in the center of the
                        // flex div when there is a StudentPath icon
                        module.studentPath && <div></div>
                    }
                </div>

                {type === "error" && (
                    <img
                        src={errorPicto}
                        alt=""
                        className="card__error-picto"
                    />
                )}

                {type === "locked" && (
                    <>
                        <ProgressBarWithLabel
                            progress={diagnosis?.progression ?? 0}
                            label={i18n.moduleList.student.progression}
                            color="#BAE7E9"
                        />
                        <ProgressBarWithLabel
                            progress={diagnosis?.averageScore ?? 0}
                            label={i18n.moduleList.student.successRate}
                            color="rgba(255, 184, 0, 0.15)"
                        />
                    </>
                )}

                <div className="card__action">
                    <button
                        className="card__error-button"
                        onClick={() => {
                            setShowModal(true);
                        }}
                    >
                        {getCardButtonLabel(type, i18n)}
                    </button>
                </div>
            </article>
        </>
    );
};

const getModalTitleAndDescription = (
    type: "locked" | "error",
    i18n: Config["i18n"]
): { title: string; description: string } => {
    switch (type) {
        case "error":
            return {
                title: i18n.errors.statements.unavailableModule.moduleCard.modal
                    .title,
                description:
                    i18n.errors.statements.unavailableModule.moduleCard.modal
                        .description,
            };
        case "locked":
            return {
                title: i18n.moduleList.lockedModule.modalTitle,
                description: i18n.moduleList.lockedModule.modalDescription,
            };
    }
};

const getModalButtonLabel = (
    type: "locked" | "error",
    i18n: Config["i18n"]
): string => {
    switch (type) {
        case "error":
            return i18n.errors.statements.unavailableModule.moduleCard.modal
                .continueButton;
        case "locked":
            return i18n.moduleList.lockedModule.modalButton;
    }
};

const getCardButtonLabel = (
    type: "error" | "locked",
    i18n: Config["i18n"]
): string => {
    switch (type) {
        case "error":
            return i18n.errors.statements.unavailableModule.moduleCard
                .description;
        case "locked":
            return i18n.moduleList.lockedModule.modalLink;
    }
};

export default ErrorModuleCard;
