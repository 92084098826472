import { HtmlString } from "@evidenceb/gameplay-interfaces";
import { Config as AiConfig } from "@evidenceb/bandit-manchot";
import { DashboardI18n } from "./i18n";
import { ErrorPageI18n } from "./Error";
export enum Pagetype {
    HOME = "HOME",
    MODULELIST = "MODULELIST",
    DASHBOARD = "DASHBOARD",
    LOGOUT = "LOGOUT",
    CONTENTPAGE = "CONTENTPAGE",
}

export interface Title {
    title: string;
}

export interface UrlDisplay {
    mode: "DEFAULT" | "USER_BASED";
    url: string[] | string;
    label: string[] | string;
}

export interface Page {
    display: UrlDisplay;
    type: Pagetype;
    inTopNav: boolean;
    inFooter: boolean;
    authorized?: string[];
}

export interface MicroServices {
    match: string;
    endpoints: {
        auth?: string;
        content: string;
        analytics?: string;
        users?: string;
        statements?: string;
        assetsProxy: string;
    };
}

export interface Config {
    versioning: number;
    declinaison: string;
    client_name: string;
    lang: string;
    apiUrls: MicroServices;
    activate_contentPage: boolean;
    debug?: {
        exerciseIdentifier: boolean;
    };
    features: {
        teacherDashboard: boolean;
        classManagement?: boolean;
        tracking?: boolean;
        clustering?: boolean;
        demoMode?: {
            displayIn: {
                classList: boolean;
                classDetails: boolean;
                progressionOverview: boolean;
                studentProgression: boolean;
                studentDetails: boolean;
                clustering: boolean;
            };
            message: {
                global: HtmlString;
                studentProgression: HtmlString;
                clustering: HtmlString;
            };
        };
        /**
         * Controls whether or not the class code should be shown in the
         * dashboard.
         * @default true
         */
        classCode?: boolean;
        emotionalReport?: boolean;
        /**
         * Adds a button in the footer allowing to download all the user data in
         * json format
         */
        userDataDownload?: boolean;
        /**
         * Displays the Ella drawer in the student chatbot player
         */
        studentChatbotPlayerInfoPanel?: boolean;
        /**
         * Uses the Ella student homepage instead of the original homepage
         */
        useEllaStudentHomepage?: boolean;
        /**
         * Use the module list corresponding to EVBPROD-1121
         *
         * [TEMP] This flag is meant to be used for a progressive rollout of the
         * new feature
         */
        newStudentModuleList?: boolean;
        /**
         * Activate the teacher informational panel in the player
         *
         * [TEMP] This flag is meant to be used temporarily to be able to deplay
         * the feature in certain variations while the content is being
         * validated by the product management for other variations
         */
        teacherInfoPanel?: boolean;
        /**
         * Show the module map in the student module list
         */
        showModuleMapInStudentModuleList?: boolean;
    };
    pages: Page[];
    auth: {
        mode: AuthMode;
        rightImage: string;
        provider?: string;
    };
    i18n: {
        auth: {
            student: {
                registerClass: {
                    title: string;
                    text: HtmlString;
                    classCode: {
                        info: HtmlString;
                    };
                };
                signIn: {
                    text: HtmlString;
                };
            };
            teacher: {
                signIn: {
                    title: string;
                    text: HtmlString;
                };
            };
            form: {
                classCode: string;
                error: {
                    codeClassError: HtmlString;
                    authError: HtmlString;
                    name: string;
                    firstname: string;
                };
                button: {
                    start: string;
                    next: string;
                };
                firstname: string;
                name: string;
            };
        };
        hierarchy: {
            module: {
                full: string;
                short: string;
            };
            objective: {
                full: string;
                short: string;
            };
            activity: {
                full: string;
                short: string;
            };
            exercise: {
                full: string;
                short: string;
            };
        };
        moduleList: {
            info: string;
            title: string;
            student: {
                message: {
                    default: HtmlString;
                    visited: HtmlString;
                };
                progression: string;
                successRate: string;
                start: string;
                continue: string;
                completedModule: string;
                studentPathIconAlt: string;
                studentPathTitle: string;
            };
            lockedModule: {
                modalTitle: string;
                modalDescription: string;
                modalButton: string;
                modalLink: string;
            };
            areAllModulesLocked: string;
        };
        endPlaylist: {
            congratulations: string;
            completed: string;
            result: string;
            exerciseCorrect: string;
            exerciseIncorrect: string;
            moduleList: string;
            nextModule: string;
            dashboard: string;
        };
        exerciseShell: {
            close: string;
            feedback: {
                nextExercise: string;
                nextActivity: string;
                endPlaylist: string;
                retry: string;
            };
            tryAgain: HtmlString;
            initialTestMessages: {
                start: HtmlString;
                end: HtmlString;
                btnStart: string;
                btnEnd: string;
            };
            emotionalReport: {
                frustrated: string;
                bored: string;
                confused: string;
                neutral: string;
                popupTitle: string;
                popupSendBtn: string;
            };
            timeoutModalTitle: string;
            timeoutModalDescription: string;
            timeoutModalContinueButton: string;
            timeoutModalQuitButton: string;
        };
        dashboard?: DashboardI18n;
        misc: {
            goToTopButtonLabel: string;
            logout: string;
            logoutUrl: {
                provider: string;
                url: string;
            }[];
            contact: string;
            developedBy: string;
            colon: string;
            userDataDownload: string;
            modalClose: string;
        };
        chatbot: {
            welcomeMessages: HtmlString[];
            start: HtmlString;
            // TODO: Remove and use the one in exerciseShell
            initialTestMessages: {
                start: HtmlString;
                end: HtmlString;
            };
        };
        gameplays: {
            generic: {
                btnValidate: string;
            };
            [gameplayType: string]: {
                [key: string]: string;
            };
        };
        drawer: {
            targetedDifficulties: string;
        };
        features: {
            studentChatbotPlayerInfoPanel: {
                title: string;
                details: string;
                reduceDetails: string;
            };
        };
        errors: {
            pages: ErrorPageI18n[];
            getData: string;
            postData: string;
            patchData: string;
            statements: {
                cannotRetrieveHistory: {
                    title: string;
                    description: string;
                    goToHomepageButton: string;
                };
                cannotSendStatement: {
                    modal: {
                        title: string;
                        description: string;
                        continueButton: string;
                    };
                    notification: {
                        description: string;
                        tryToSyncButton: string;
                        attemptingToSync: string;
                        syncSuccess: string;
                    };
                };
                navigationWhenUnsyncedStatements: {
                    modal: {
                        title: string;
                        description: string;
                        leaveAnywayButton: string;
                        syncButton: string;
                    };
                };
                unavailableModule: {
                    moduleCard: {
                        description: string;
                        modal: {
                            title: string;
                            description: string;
                            continueButton: string;
                        };
                    };
                };
            };
        };
        prm: {
            lockStatusErrorTitle: string;
            lockStatusErrorText: string;
            lockStatusErrorBtn: string;
            listOf: string;
            moduleMap: string;
            home: {
                title: string;
                titleGeneric: string;
                description: string;
                descriptionGeneric: string;
            };
            manager: {
                activateAll: string;
                listItem: {
                    accessibleActivate: string;
                    accessibleDeactivate: string;
                    activated: string;
                    deactivated: string;
                    deactivatePrompt: string;
                    activatePrompt: string;
                    activate: string;
                    deactivate: string;
                    cancel: string;
                    initialTest: string;
                    initialTestDeactivateDescription: string;
                    initialTestActivateDescription: string;
                };
                cancel: string;
                confirm: string;
                cancelModal: {
                    title: string;
                    description: string;
                    primaryButton: string;
                    secondaryButton: string;
                };
                successModal: {
                    title: string;
                    description: string;
                    primaryButton: string;
                };
                errorModal: {
                    title: string;
                    description: string;
                    primaryButton: string;
                    secondaryButton: string;
                };
            };
        };
        classList: {
            settingPRMLabel: string;
            lockStatusErrorTitle: string;
            lockedModuleButtonLabel: string;
            prmSettingsLabel: string;
        };
        singleClass: {
            separatorTitle: string;
            inactiveModuleDescription: string;
            toggleActivateModuleAccessibleLabel: string;
            noModuleActivatedTitle: string;
            noModuleActivatedDescription: string;
            activateModuleModalTitle: string;
            activateModuleModalDescription: string;
            activateModuleModalCancel: string;
            activateModuleModalConfirm: string;
            savedProgressionSingular: string;
            savedProgressionPlural: string;
            progressionRegressionSingular: string;
            progressionRegressionPlural: string;
            progressionAccelerationSingular: string;
            progressionAccelerationPlural: string;
        };
        clustering:{
            seeProgressionOverview: string;
        };
        student: string;
        groupCharacteristic: string;
        success: string;
    };
    logos: {
        avatar: string;
        avatarStudentBottomRight?: string;
        demo?: string;
        header_client: string;
        endPlaylistIcon: string;
        footer_evidenceb: string;
        footer_client: string;
    };
    contact: string;
    ai?: AiConfig;
}

export enum AuthMode {
    Register = "REGISTER",
    RegisterNameOnly = "REGISTER_NAMEONLY",
    DirectAccess = "DIRECT_ACCESS",
}
