import React, { useContext, useState } from "react";
import { Module } from "@evidenceb/gameplay-interfaces";
import { configStore } from "../../../contexts/ConfigContext";
import ImageAsset from "../../../components/ImageAsset/ImageAsset";
import Modal from "../../../components/Modal/Modal";

import "./ModuleMap.scss";
import mapIcon from "../../../assets/images/Map_icon.svg";

interface Props {
    module: Module;
}

const ModuleMap = ({ module }: Props) => {
    const {
        config: { i18n },
    } = useContext(configStore);
    const [showModal, setShowModal] = useState<boolean>(false);

    if (!module.studentPath) return null;

    return (
        <>
            <button
                className="module-map"
                onClick={() => {
                    setShowModal(true);
                }}
            >
                <img src={mapIcon} alt="" />
                <span className="module-map__button-title">
                    {i18n.prm.moduleMap}
                </span>
            </button>

            <Modal
                isOpen={showModal}
                onClose={() => {
                    setShowModal(false);
                }}
                title={
                    <div className="module-map__modal-title">
                        <img src={mapIcon} alt="" />
                        <span>{i18n.prm.moduleMap}</span>
                    </div>
                }
            >
                <ImageAsset
                    src={module.studentPath.url}
                    alt={module.studentPath.alt}
                />
            </Modal>
        </>
    );
};

export default ModuleMap;
