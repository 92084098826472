import React, {
    createContext,
    useState,
    Dispatch,
    SetStateAction,
} from "react";
import { BanditManchot, History } from "@evidenceb/bandit-manchot";
import { UserType } from "../interfaces/User";
import { v4 as uuid } from "uuid";
import { PRLockStatus } from "@evidenceb/gameplay-interfaces";

interface Pending {
    status: "pending"
}
export interface Settled<T> {
    status: "settled",
    value: T
}
type Requesting<T> = Pending | Settled<T>;

export interface Session {
    banditManchot?: BanditManchot;
    initialHistory?: Requesting<History>;
    prLockStatus?: Requesting<PRLockStatus>;
    sessionId: string;
    userId: string;
    userProvider: string;
    appProvider: string;
    userType: UserType;
    evidencebId: string;
    token: string;
    version: string;
    flags: {
        useHistoryFrom: "localHistory" | "LRS";
        displaySignIn: boolean;
    };
}

interface SessionContext {
    session: Session;
    setSession: Dispatch<SetStateAction<Session>>;
}

const initialState: Session = {
    sessionId: uuid(),
    userId: "",
    userProvider: "",
    appProvider: "",
    userType: UserType.Student,
    evidencebId: "",
    token: "",
    version: "",
    flags: { useHistoryFrom: "LRS", displaySignIn: false },
};

// Context init
const sessionStore = createContext<SessionContext>({
    session: initialState,
    setSession: () => null,
});

// Provider init
const { Provider } = sessionStore;

// Final setup
const SessionProvider = ({ children }: { children: JSX.Element }) => {
    const [session, setSession] = useState(initialState);
    return <Provider value={{ session, setSession }}>{children}</Provider>;
};

export { sessionStore, SessionProvider };
