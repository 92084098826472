import React, {
    Dispatch,
    SetStateAction,
    useRef,
} from "react";
import styled from "styled-components";
import { ModuleDashboard } from "../../../../../interfaces/Dashboard";
import ObjectiveProgression from "../../../../../components/ObjectiveProgression/ObjectiveProgression";
import COLOR_SCHEME from "../../../ProgressionOverview/ProgressionTable/COLOR_SCHEME";
import { ProgressFilter } from "../Progress";
import { ProgressionData } from "../../../StudentProgression/StudentProgression";
import { PRTree } from "@evidenceb/gameplay-interfaces";

interface Props {
    module: ModuleDashboard;
    classroomId: string;
    studentId: string;
    progressFilter: ProgressFilter | undefined;
    setProgression: Dispatch<SetStateAction<ProgressionData | undefined>>;
    prTree: PRTree | undefined;
}

interface TagContainerProps {
    moduleId: string | null;
}

const ObjectivesList = ({
    module,
    classroomId,
    studentId,
    prTree,
}: Props) => {
    const TagContainerRef = useRef<HTMLDivElement>(null);

    return (
        <TagOverflowContainer
            className="test"
            /* @ts-ignore TS doesn't allow to set variables but we can */
            style={
                COLOR_SCHEME[
                    prTree?.modules[module.id].status !== "unlocked"
                        ? "locked"
                        : "unlocked"
                ]
            }
        >
            <TagContainer
                ref={TagContainerRef}
                moduleId={module.id}
                style={{
                    width: module.students[studentId]?.objectives.length * 119,
                }}
            >
                {module.students[studentId]?.objectives.map((objective, i) => (
                    <ObjectiveProgression
                        key={`objective-progression-${i}`}
                        objective={objective}
                        student={module.students[studentId]}
                        prTree={prTree}
                        classroomId={classroomId}
                        type="dashed"
                        showObjectiveTitle={true}
                        objectiveIndex={i + 1}
                    />
                ))}
            </TagContainer>
        </TagOverflowContainer>
    );
};

const TagOverflowContainer = styled.div`
    overflow: hidden;
    width: 100%;
    position: relative;
`;

const TagContainer = styled.div<TagContainerProps>`
    display: flex;
    width: 100%;
    gap: 4px;
`;

export default ObjectivesList;
