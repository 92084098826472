import React from "react";

import blazon from "../../../../../assets/images/blazon.svg"
import lock from "../lock.svg";

type ObjectiveBubbleProps = {
    objectiveFinished: boolean;
    objectiveInProgress: boolean;
};

/**
 * ObjectiveBubbles are the colored circle on the left of an objective, and also
 * the vertical bar (the "handle") that comes with it. That handle goes
 * downward if the objective is before the current objective, upward if it after
 * the current objective.
 * - handleDown: boolean indicating whether the handle should go downward (true)
 *   or upward (false)
 * - shouldShortenDownHandle: boolean indicating whether the handle is going
 *   downward while it's just before the current objective (in that case, it must
 *   be shorter)
 */
const ObjectiveBubble: React.FC<ObjectiveBubbleProps> = ({
    objectiveFinished,
    objectiveInProgress,
}) => {
    return (
        <div className="objective-bubble-container">
            {objectiveFinished ? (
                <div className="objective-bubble">
                    <img className="blazon" src={blazon} alt="" />
                </div>
            ) : objectiveInProgress ? (
                <div className="objective-bubble"></div>
            ) : (
                <div className="objective-bubble locked">
                    <img className="lock" src={lock} alt="" />
                </div>
            )}
        </div>
    );
};

export default ObjectiveBubble;
