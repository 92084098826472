import React, { useContext, useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
import { PRLockStatus } from "@evidenceb/gameplay-interfaces";
import {
    Classroom,
    ModuleDashboard,
} from "../../../../../../interfaces/Dashboard";
import { UserType } from "../../../../../../interfaces/User";
import { sessionStore } from "../../../../../../contexts/SessionContext";
import { dataStore } from "../../../../../../contexts/DataContext";
import { configStore } from "../../../../../../contexts/ConfigContext";
import { dashboardStore } from "../../../../../../contexts/DashboardContext";
import useAthenaAPIClient from "../../../../../../hooks/useAthenaAPIClient";
import { getGraph, isModuleLocked } from "../../../../../../utils/prm";
import { getModuleById } from "../../../../../../utils/dataRetrieval";
import ModuleCard from "../ModuleCard/ModuleCard";

import lockedIcon from "./locked-icon.svg";

interface Props {
    classroom: Classroom;
}

const ModulesList = ({ classroom }: Props): JSX.Element => {
    const {
        session: { userId },
    } = useContext(sessionStore);
    const {
        dashboard: { clustering },
    } = useContext(dashboardStore);
    const {
        config: { i18n, ai },
    } = useContext(configStore);
    const { data } = useContext(dataStore);
    const [lockStatus, setLockStatus] = useState<PRLockStatus>();
    const [{ unlockedModules, lockedModules }, setModules] = useState<{
        lockedModules: ModuleDashboard[];
        unlockedModules: ModuleDashboard[];
    }>({ unlockedModules: [], lockedModules: [] });
    const athenaAPIClient = useAthenaAPIClient();

    // Get locked status
    useEffect(() => {
        (async () => {
            try {
                const allClassesLockStatus =
                    await athenaAPIClient.getResourcesLockStatus(
                        userId,
                        UserType.Teacher
                    );
                setLockStatus(allClassesLockStatus[classroom.id]);
            } catch (err) {
                Sentry.captureException(err);
                // TODO add error handling
            }
        })();
    }, [userId, classroom.id, athenaAPIClient]);

    // Update locked/unlocked modules
    useEffect(() => {
        if (!classroom || !lockStatus) return;

        const lockedModules: ModuleDashboard[] = [];
        const unlockedModules: ModuleDashboard[] = [];
        classroom.modulesList.forEach((dashMod) => {
            const mod = getModuleById(dashMod.id, data);
            if (
                isModuleLocked(
                    mod,
                    lockStatus,
                    getGraph(ai!, data, mod.id),
                    data
                )
            )
                lockedModules.push(dashMod);
            else unlockedModules.push(dashMod);
        });

        setModules({
            lockedModules,
            unlockedModules,
        });
    }, [classroom, lockStatus, ai, data]);

    return (
        <>
            <div className="active-modules">
                {unlockedModules.map((mod, i) => (
                    <ModuleCard
                        key={"tabs-module" + i}
                        module={mod}
                        classroom={classroom}
                        clustering={clustering}
                        type="unlocked"
                    />
                ))}
            </div>

            {lockedModules.length > 0 && (
                <>
                    {unlockedModules.length === 0 ? (
                        <>
                            <p className="no-active-modules__title">
                                {i18n.singleClass.noModuleActivatedTitle}
                            </p>
                            <p className="inactive-modules__description">
                                {i18n.singleClass.noModuleActivatedDescription}
                            </p>
                        </>
                    ) : (
                        <>
                            <div className="separator">
                                <div className="separator__strike-through"></div>
                                <div className="separator__title">
                                    {i18n.singleClass.separatorTitle}
                                </div>
                                <img
                                    src={lockedIcon}
                                    alt=""
                                    className="separator__icon"
                                />
                            </div>

                            <p className="inactive-modules__description">
                                {i18n.singleClass.inactiveModuleDescription}
                            </p>
                        </>
                    )}

                    <div className="inactive-modules">
                        {lockedModules.map((mod, i) => (
                            <ModuleCard
                                key={"tabs-module" + i}
                                module={mod}
                                classroom={classroom}
                                clustering={clustering}
                                type="locked"
                                activateModule={async () => {
                                    try {
                                        const updatedLockStatus =
                                            getNewLockStatus(
                                                lockStatus!,
                                                mod.id
                                            );
                                        await athenaAPIClient.updateResourcesLockStatus(
                                            classroom.id,
                                            updatedLockStatus
                                        );
                                        setLockStatus(updatedLockStatus);
                                    } catch (err) {
                                        Sentry.captureException(err);
                                    }
                                }}
                            />
                        ))}
                    </div>
                </>
            )}
        </>
    );
};

const getNewLockStatus = (
    currentLockStatus: PRLockStatus,
    moduleToActivate: string
): PRLockStatus => {
    return {
        ...currentLockStatus,
        moduleIds: currentLockStatus.moduleIds.filter(
            (moduleId) => moduleId !== moduleToActivate
        ),
    };
};

export default ModulesList;
