import React from "react";
import { Dialog, DialogProps } from "@reach/dialog";
import CloseButton from "./CloseButton/CloseButton";

import "@reach/dialog/styles.css";
import "./PlayerModal.scss";

interface Props {
    title?: string | JSX.Element;
    onClose?: () => void;
}

const PlayerModal = ({
    title,
    onClose,
    children,
    ...dialogProps
}: DialogProps & Props) => {
    return (
        <Dialog
            {...dialogProps}
            className="player-modal-container"
            aria-labelledby={typeof title === "string" ? title : undefined}
        >
            <div className="modal">
                {title && (
                    <header>
                        <div className="modal__title">{title}</div>
                        {onClose && <CloseButton onClose={onClose} />}
                    </header>
                )}

                {!title && onClose && <CloseButton onClose={onClose} />}

                {children}
            </div>
        </Dialog>
    );
};

export default PlayerModal;
