import { useContext, useEffect, useState } from "react";
import merge from 'lodash.merge'
import { Exercise } from "@evidenceb/gameplay-interfaces";

import { applyTheme, Theme } from "../utils/theme-handler";
import { shouldSignIn, removeNonVisible, tokenResolver, versionResolver, registerDebugUtils } from "../utils/init";
import { axiosClient } from "../utils/axios-client";
import * as localStorage from "../utils/localStorage";
import { getExercisesWithAvailableGameplays } from "../utils/fetch-gameplays";

import chatbotTheme from "./chatbotTheme";

import { configStore } from "../contexts/ConfigContext";
import { contentPagesStore } from "../contexts/ContentPagesContext";
import { dataStore } from "../contexts/DataContext";
import { homeStore } from "../contexts/HomeContext";
import { sessionStore } from "../contexts/SessionContext";
import { errorStore } from "../contexts/ErrorContext";

import { Config } from "../interfaces/Config";
import { AppStatus } from "../interfaces/Status";
import { TokenPayload, User, UserType } from "../interfaces/User";
import { RawData } from "../interfaces/Data";
import { Home } from "../interfaces/Home";
import { ContentPage } from "../interfaces/ContentPage";
import useAthenaAPIClient from "./useAthenaAPIClient";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

export interface GlobalConfig {
    config: Config;
    home: Home;
    contentPages: ContentPage[];
    theme: Theme;
}

export default function useInitApp() {

    const { setData } = useContext(dataStore);
    const { setHome } = useContext(homeStore);
    const { setContentPages } = useContext(contentPagesStore);
    const { config, setConfig } = useContext(configStore);
    const { session, setSession } = useContext(sessionStore);
    const { setErrorInfo } = useContext(errorStore);
    const [status, setStatus] = useState<AppStatus>();
    const athenaAPIClient = useAthenaAPIClient();

    useEffect(() => {
        //SENTRY INIT
        if(process.env.NODE_ENV !== "development")
            Sentry.init({
                dsn: "https://7467730f826844f68ecd3b39ec8b0ca8@o340691.ingest.sentry.io/5601505",
                autoSessionTracking: true,
                integrations: [
                    new Integrations.BrowserTracing({
                      tracingOrigins: ["https://athena-auth", "https://athena-content-access", "https://analytics","https://athena-analytics", "https://xapi"],
                    }),
                  ],
                // We recommend adjusting this value in production, or using tracesSampler
                // for finer control
                tracesSampleRate: 1.0,
                environment: process.env.NODE_ENV,
            });
         
        (async () => {
            const apiUrls = await axiosClient.init();
            setConfig((config) => { return {...config, apiUrls}});
        })(); 
       
    }, [setConfig]);

    useEffect(() => {
        if (config.apiUrls.match === "" || typeof status !== "undefined") return;

        setStatus(AppStatus.Pending);
        
        (async function initApp (){
            // Token resolution
            let queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const urlToken = urlParams.get('token') as string;
            const localStorageToken = localStorage.getItem<string>(localStorage.Key.TOKEN);
            let token: string;
            try {
                token = tokenResolver(urlToken, localStorageToken);
                axiosClient.apiToken = token;
                localStorage.setItem(localStorage.Key.TOKEN, token);
            } catch(error){
                setErrorInfo(err => {
                    return {
                        ...err,
                        page:{ code: "token" }
                    }
                })
                setStatus(AppStatus.Error)
                return 
            };

            // Get token payload
            let tokenPayload: TokenPayload;       
            try {
                tokenPayload = await athenaAPIClient.getTokenPayload();
            } catch(error) {
                setStatus(AppStatus.Error) //TODO error details
                return 
            }

            // Version resolution
            const urlVersion = urlParams.get('version') as string;
            const localStorageVersion= localStorage.getItem<string>(localStorage.Key.VERSION);
            let version: string;
            try {
                version = versionResolver(tokenPayload, urlVersion, localStorageVersion)
                localStorage.setItem(localStorage.Key.VERSION, version)
            } catch(error) {
                version = '' // If error, version variable needs to be assigned here so it can be a string type
                setErrorInfo(err => {
                        return { 
                            ...err,
                            page: { code:"version" }
                        }
                    }
                )
                setStatus(AppStatus.Error)
                return
            }

            // Get user info
            let user: User;
            try {
                user = await athenaAPIClient.getUser(tokenPayload);
            } catch(error) {
                setStatus(AppStatus.Error)
                return 
            }

            // Get GlobalConfig
            let globalConfig: GlobalConfig;
            try {
                globalConfig = await athenaAPIClient.getGlobalConfig(version);
                
                // Update config errors (if subsequent requests fail we can show errors in the user language)
                let errorPages = config.i18n.errors.pages;
                globalConfig.config.i18n.errors.pages.forEach(page =>{
                    errorPages.push(page)
                })
                setConfig( config => {
                    return {
                        ...config,
                        lang: globalConfig.config.lang,
                        i18n:{
                            ...config.i18n,
                            errors:{
                                ...config.i18n.errors,
                                pages: errorPages
                            }
                        }
                    }
                })
                
            } catch(error) {
                setStatus(AppStatus.Error)
                return 
            }
            
            // Resolve authentication
            let displaySignIn: boolean;
            try {
                displaySignIn = await shouldSignIn(user, globalConfig.config.auth.mode, tokenPayload.role.toUpperCase() as UserType, globalConfig.config.declinaison, athenaAPIClient.getClassroom.bind(athenaAPIClient));
                console.log(displaySignIn)
            } catch(error) {
                //TODO error details ?
            }
       
            // Get data
            let data: RawData, exercises: Exercise<any, any>[]
            try {
                data = removeNonVisible(
                    await athenaAPIClient.getData(version)
                );
                console.log("DATA", data);
                exercises = await getExercisesWithAvailableGameplays(data.exercises);
            } catch(error) {
                setStatus(AppStatus.Error) 
                return 
            }
            registerDebugUtils(data, athenaAPIClient);
            setSession(session => {
                return {
                    ...session,
                    version,
                    token,
                    userId: user.id,
                    userProvider: user.provider,
                    appProvider: globalConfig.config.declinaison,
                    userType: tokenPayload.role.toUpperCase() as UserType,
                    evidencebId: tokenPayload.sub,
                    flags: {
                        ...session.flags,
                        displaySignIn: displaySignIn
                    }
                }
            })
            
            setConfig(config => merge({}, config, globalConfig.config))
            setHome(globalConfig.home)
            setContentPages(globalConfig.contentPages)
            applyTheme({ ...globalConfig.theme, chatbot: chatbotTheme }); //TODO: fallback theme, theme errors handler
            setData({
                modules: data.modules,
                objectives: data.objectives,
                activities: data.activities,
                exercises,
            });
            setStatus(AppStatus.Loaded);
            
        })();
    },[config.apiUrls, setConfig, setContentPages, setData, setHome, setSession, athenaAPIClient, config.i18n.errors.pages, setErrorInfo, status])

    //Add session info to sentry reports
    useEffect(() => {
        const sessionInfo = {
            ...session,
            banditManchot: null, //TEMP: Exclude banditManchot so it doesn't break sentry
            initialHistory: null //TEMP: Exclude initialHistory so it doesn't break sentry
        }
        Sentry.setContext("Session", sessionInfo);
    },[session])

    return {status, setStatus}
}
