import React, { useCallback, useContext, useEffect, useState } from "react";

//External modules
import { Helmet } from "react-helmet";
import { Switch, Route, useRouteMatch } from "react-router-dom";

//Contexts
import { dashboardStore } from "../../contexts/DashboardContext";
import { sessionStore } from "../../contexts/SessionContext";
import { configStore } from "../../contexts/ConfigContext";

//Components
import ProgressionOverview from "./ProgressionOverview/ProgressionOverview";
import Clustering from "../Clustering/Clustering";
import StudentDetails from "../DashBoard/StudentDetails/StudentDetails"
import Loader from "../../components/Loader/Loader";
import DemoBanner from "../../components/DemoBanner/DemoBanner";
import Search from "./Search/Search";
import ClassroomList from "./Classroom/List/ClassroomList";
import SingleClassroom from "./Classroom/Single/SingleClassroom";

//SCSS
import "./Dashboard.scss";

//Hooks
import useAthenaAPIClient from "../../hooks/useAthenaAPIClient";

//Interfaces
import { Dashboard } from "../../interfaces/Dashboard";
import { Title } from "../../interfaces/Config";

function DashboardPage({ title }: Title) {

  //Misc
  let { path } = useRouteMatch();

  //Contexts
  const { dashboard, setDashboard } = useContext(dashboardStore);
  const { config } = useContext(configStore);
  const { session } = useContext(sessionStore);
  const athenaAPIClient = useAthenaAPIClient();

  //States
  const [loader, setLoader] = useState<boolean>(true)

  //Functions
  const getTeacherData = useCallback(async () => {
    console.log("DASHBOARD: GETTING DATA...");
    try{
      const dashboard = await athenaAPIClient.getTeacherDashboard<Dashboard>(config.features.clustering, session.userId, config.declinaison)
      setDashboard(dashboard)
      setLoader(false)
      console.log("DASHBOARD: DATA SET", dashboard);
    }catch(err){
      setLoader(false)
    }
  }, [athenaAPIClient, config.features.clustering, session.userId, config.declinaison, setDashboard]);

  useEffect(() => {
    if (!dashboard.clustering) {
      getTeacherData()
    } else {
      setLoader(false)
    }
  }, [getTeacherData, dashboard.clustering])

  return (
    <div className="dashboard">
      <Helmet>
        {" "}
        <title>{title}</title>{" "}
      </Helmet>

      <div className="search-container">
        <Search />
      </div>

      <Switch>

        <Route exact path={`${path}/${config.i18n.dashboard?.paths.classes}/:classroomId`}>
          <>
            {config.features.demoMode?.displayIn.classDetails ? <DemoBanner position={'right'} message={config.features.demoMode.message.global} /> : null}
            {loader ? <Loader /> :
              <SingleClassroom />
            }
          </>
        </Route>

        <Route exact path={`${path}/${config.i18n.dashboard?.paths.classes}/${config.i18n.dashboard?.paths.progression}/:classroomId/:moduleId`} >
          <>
            {config.features.demoMode?.displayIn.progressionOverview ? <DemoBanner position={'right'} message={config.features.demoMode.message.global} /> : null}
            {loader ? <Loader /> :
              <ProgressionOverview classrooms={dashboard.classrooms} />
            }
          </>
        </Route>

        <Route exact path={`${path}/${config.i18n.dashboard?.paths.student}/:classroomId/:studentId`} >
          <>
            {config.features.demoMode?.displayIn.studentDetails ? <DemoBanner position={'right'} message={config.features.demoMode.message.global} /> : null}
            {loader ? <Loader /> :
              <StudentDetails />
            }
          </>
        </Route>

        {dashboard.clustering && <Route exact path={`${path}/${config.i18n.dashboard?.paths.classes}/:classroomId/:moduleId/${config.i18n.dashboard?.paths.groups}`} >
          <>
            {config.features.demoMode?.displayIn.clustering ? <DemoBanner position={'right'} message={config.features.demoMode.message.global} /> : null}
            {loader ? <Loader /> :
              <Clustering classrooms={dashboard.classrooms} clustering={dashboard.clustering} />
            }

          </>
        </Route>}

        {/* Default route (no path) */}

        <Route>
          <>
            {config.features.demoMode?.displayIn.classList ? <DemoBanner position={'right'} message={config.features.demoMode.message.global} /> : null}
            {loader ? <Loader /> :
              <ClassroomList />
            }
          </>
        </Route>
      </Switch>
    </div>
  );
}

export default DashboardPage;
