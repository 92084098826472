import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { ModuleDashboard } from "../../../../../interfaces/Dashboard";
import { configStore } from "../../../../../contexts/ConfigContext";
import MaterialIcon from "../../../../../components/MaterialIcon/MaterialIcon";

interface Props {
    students: ModuleDashboard["students"];
    classroomId: string;
}

const StudentNamesColumn = ({ students, classroomId }: Props) => {
    const { config } = useContext(configStore);

    const sortByName = (a: string, b: string) => {
        return students[a].lastname.localeCompare(students[b].lastname);
    };
    const getStudentUrl = (studentId: string) => {
        return `/${config.i18n.dashboard?.paths.dashboard}/${config.i18n.dashboard?.paths.student}/${classroomId}/${studentId}`;
    };

    return (
        <ul>
            {Object.keys(students)
                .sort(sortByName)
                .map((studentId, i) => (
                    <li key={studentId}>
                        <Link to={getStudentUrl(studentId)}>
                            <span className="inside-container">
                                <span className="name-container">
                                    <span className="name">
                                        {students[studentId].firstname}{" "}
                                        {students[studentId].lastname}
                                    </span>
                                    <span className="label">
                                        {
                                            config.i18n.dashboard?.common
                                                .seeProfile
                                        }
                                        <MaterialIcon className="icon">
                                            chevron_right
                                        </MaterialIcon>
                                    </span>
                                </span>
                            </span>
                            <span
                                className="scalable-background"
                                aria-hidden="true"
                            ></span>
                        </Link>
                    </li>
                ))}
        </ul>
    );
};

export default StudentNamesColumn;
