import React, { useEffect } from 'react'

const MathJaxConfig = {
    loader: {load: ["input/tex", "output/chtml", "ui/menu"]},
    tex: {
        inlineMath: [['$', '$'], ['\\(', '\\)']]
    },
}

const MathJaxContext = React.createContext({})

const MathJaxProvider: React.FC = ({
    children
}) => {
    useEffect(() => {
        //@ts-ignore
        if (!window.MathJax) {
            //@ts-ignore
            window.MathJax = MathJaxConfig;
        }
        loadScript([
            "https://cdn.jsdelivr.net/npm/mathjax@3/es5/startup.js",
        ])
    }, [])

    return (
        <MathJaxContext.Provider value={MathJaxConfig} >
            {children}
        </MathJaxContext.Provider>
    )
}

const loadScript = (scripts: Array<string>) => {
    return new Promise((resolve, _) => {
        const scriptPromises = scripts.map(script => new Promise((resolve, _) => {
            const scriptElem = document.createElement('script');
            scriptElem.src = script
            scriptElem.async = true
            scriptElem.onload = resolve
            document.head.appendChild(scriptElem)
        }))

        Promise.all(scriptPromises).then(resolve)
    })
}

export { MathJaxProvider }