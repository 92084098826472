import { Exercise, Playlist } from "@evidenceb/gameplay-interfaces";

export const getAssetUrl = (
    assetPath: string,
    version: string,
    contentUrl: string
): string => {
    if (assetPath.startsWith("http")) {
        return assetPath;
    }
    const assetUrl = `${contentUrl}/interface${assetPath}?version=${version}`;

    return assetUrl;
};

/**
 * Add assetsDetails object when needed,
 * including version and contentUrl keys
 * which will let middleware find the right asset cellar
 */
export const addAssetsDetailsToExerciseData = (
    exercise: Exercise<any, any>,
    version: string,
    contentUrl: string
): Exercise<any, any> => {
    exercise.assetsDetails = {
        contentUrl: contentUrl,
        version: version,
    };
    return exercise;
};

export const addAssetsDetailsToCurrentExercise = (
    playlist: Playlist,
    version: string,
    contentUrl: string
): Playlist => {
    if (playlist.currentExercise) {
        return {
            ...playlist,
            currentExercise: {
                ...playlist.currentExercise,
                assetsDetails: {
                    contentUrl: contentUrl,
                    version: version,
                },
            },
        };
    }
    return playlist;
};