import React, { useContext, useState } from "react";
import { configStore } from "../../../contexts/ConfigContext";
import VisuallyHidden from "../../../components/VisuallyHidden/VisuallyHidden";
import ImageAsset from "../../../components/ImageAsset/ImageAsset";
import Modal from "../../../components/Modal/Modal";

import "./StudentPath.scss";
import mapIcon from "../../../assets/images/Map_icon.svg";

const StudentPath: React.FC<{ url: string; alt: string }> = ({ url, alt }) => {
    const {
        config: { i18n },
    } = useContext(configStore);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    return (
        <>
            <button
                className="student-path__button"
                onClick={() => {
                    setIsModalOpen(true);
                }}
            >
                <VisuallyHidden>
                    {i18n.moduleList.student.studentPathIconAlt}
                </VisuallyHidden>
                <img src={mapIcon} alt="" className="student-path__icon" />
            </button>

            <Modal
                title={
                    <span className="student-path__modal-title">
                        <img src={mapIcon} alt="" />
                        <span>{i18n.moduleList.student.studentPathTitle}</span>
                    </span>
                }
                isOpen={isModalOpen}
                onClose={() => {
                    setIsModalOpen(false);
                }}
            >
                <ImageAsset src={url} alt={alt} className="student-path__image" />
            </Modal>
        </>
    );
};

export default StudentPath;
