import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { configStore } from "../../../../contexts/ConfigContext";
import MaterialIcon from "../../../../components/MaterialIcon/MaterialIcon";
import VisuallyHidden from "../../../../components/VisuallyHidden/VisuallyHidden";
import PlayerHeader from "../PlayerHeader";
import { PlayerHeaderProps } from "../../PlayerBuilder";
import { InitializedPlaylistManager } from "@evidenceb/gameplay-interfaces";

import "./BreadcrumbsHeader.scss";

const BreadcrumbsHeader = ({
    playlistManager: { playlist: {module, activity, objective} },
}: PlayerHeaderProps<InitializedPlaylistManager>) => {
    const {
        config: { i18n },
    } = useContext(configStore);

    return (
        <PlayerHeader className="player-header--empty">
            <ul className="player-header__breadcrumbs">
                <li>{module.title.short}</li>
                <li>{objective?.title.short}</li>
                <li>{activity?.title.short}</li>
            </ul>

            <Link to="/" className="player-header__leave-link">
                <MaterialIcon>close</MaterialIcon>
                <VisuallyHidden>
                    {
                        i18n.errors.statements.cannotRetrieveHistory
                            .goToHomepageButton
                    }
                </VisuallyHidden>
            </Link>
        </PlayerHeader>
    );
};

export default BreadcrumbsHeader;
